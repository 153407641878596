import * as React from 'react';
import { Provider } from 'react-redux';
import createStore from './redux/chatStore';
import Routes from './Routes';

interface ChatProps {
  isTherapistChat: boolean;
  isAcking?: boolean;
  stopInputAutofocus?: boolean;
  isTherapistCheckInActive?: boolean;
  shouldPlaySoundNotifications?: boolean;
  style?: {};
  banners?: JSX.Element[];
  priorityBanner?: JSX.Element;
  SLAComponent?: React.FunctionComponent<{ scrollToEnd?: Function }>;
  isFocusedAfterModal?: boolean;
  isOffline?: boolean;
}

const Chat: React.FunctionComponent<ChatProps> = ({
  isTherapistCheckInActive,
  isTherapistChat,
  banners,
  priorityBanner,
  isFocusedAfterModal = true,
  ...otherProps
}) => (
  <Provider store={createStore(isTherapistChat)}>
    <Routes
      {...otherProps}
      banners={banners}
      priorityBanner={priorityBanner}
      isTherapistChat={isTherapistChat}
      isTherapistCheckInActive={isTherapistCheckInActive}
      isFocusedAfterModal={isFocusedAfterModal}
    />
  </Provider>
);

export default Chat;
