import { FunctionComponent, useCallback, useEffect } from 'react';
import {
  ExtraHuge,
  View,
  TouchableView,
  Button,
  BaseButton,
  Standard,
  Large,
  Big,
  Spinner,
  useEmotionTheme,
  useWindowWidthState,
  withVersioning,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import RadioButton from '@talkspace/react-toolkit/src/designSystems/components/RadioButton';
import TabRadioGroup from '@talkspace/react-toolkit/src/designSystems/components/TabRadioGroup';

import { VideoCreditOffer } from 'ts-frontend/types';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQuerySubscriptions from 'ts-frontend/hooks/useQuerySubscriptions';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { getUserData } from '@/auth/helpers/token';
import styled from '@/core/styled';
import { useHistory, useParams } from '@/core/routerLib';
import InRoomSchedulingError from '../inRoomSchedulingError';
import KeepUpTheGreatWorkHeading from '../KeepUpTheGreatWorkHeading';
import NoCreditsDTEHeading from '../NoCreditsDTEHeading';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import { trackSelectScheduleDuration } from '@/utils/analytics/events';
import BottomButtonContainer from '../BottomButtonContainer';

const CreditOptionWrapper = styled(TouchableView)<{
  index: number;
}>(({ index, theme: { colorRoles } }) => {
  return {
    width: 335,
    height: 100,
    borderRadius: 10,
    justifyContent: 'space-between',
    marginTop: index !== 0 ? 10 : 0,
    border: `1px solid ${colorRoles.system.borderSubtle}`,
    padding: 16,
  };
});

const SkipButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    color: colors.slateGrey,
    backgroundColor: colors.white,
    marginBottom: 16,
    marginTop: 20,
  };
});

const CreditOptionBlock: FunctionComponent<{
  creditOption: VideoCreditOffer;
  isActive: boolean;
  index: number;
  handleChooseCredit: (creditOption: VideoCreditOffer) => void;
  dataQa?: string;
  showPriceText?: boolean;
  style?: EmotionStyle;
}> = ({
  creditOption,
  isActive,
  handleChooseCredit,
  index,
  dataQa,
  style,
  showPriceText = true,
}) => {
  const { colors } = useEmotionTheme();
  return (
    <CreditOptionWrapper
      row
      index={index}
      onPress={() => handleChooseCredit(creditOption)}
      tabIndex={-1}
      style={style}
    >
      <View justify="center">
        <Big>{creditOption.displayName}</Big>
        {showPriceText && (
          <Standard style={{ marginTop: 2, color: colors.slateGrey }}>
            {creditOption.priceText}
          </Standard>
        )}
      </View>
      <View row align="center" justify="center">
        <RadioButton
          dataQa={dataQa}
          isActive={isActive}
          onPress={() => handleChooseCredit(creditOption)}
        />
      </View>
    </CreditOptionWrapper>
  );
};

const CreditOptionBlockV1: FunctionComponent<{
  creditOption: VideoCreditOffer;
  isActive: boolean;
  index: number;
  handleChooseCredit: (creditOption: VideoCreditOffer) => void;
  dataQa?: string;
  showPriceText?: boolean;
}> = (props) => {
  const { colorRoles } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  return (
    <CreditOptionBlock
      {...props}
      style={{
        height: 'unset',
        minWidth: 335,
        maxWidth: 430,
        width: isMobile ? 'unset' : '100%',
        border: `1px solid ${colorRoles.system.borderSubtle}`,
        '&:hover:focus': {
          border: `1px solid ${colorRoles.system.borderSubtle}`,
        },
      }}
    />
  );
};

const CreditOptionBlockVersioned = withVersioning({
  '1.0.0': CreditOptionBlockV1,
  DefaultComponent: CreditOptionBlock,
});

const SelectCredit: FunctionComponent<{
  isTherapist: boolean;
  handleChooseCredit: (creditOption: VideoCreditOffer) => void;
  onClose: () => void;
  onSkipBookNextSession: () => void;
  isFromCheckInWizard: boolean;
  isFromPostLVSCheckInWizard: boolean;
  shouldRenderSelectCredit: boolean;
}> = ({
  isTherapist,
  handleChooseCredit,
  onClose,
  isFromCheckInWizard,
  isFromPostLVSCheckInWizard,
  shouldRenderSelectCredit,
  onSkipBookNextSession,
}) => {
  const { colorRoles } = useEmotionTheme();
  const {
    selectedCreditOption,
    creditOptions,
    isLoading: isLoadingSchedulingState,
    isError,
    modality,
    room,
  } = useInRoomSchedulingState();
  const { isMobile } = useWindowWidthState();

  const { roomID } = useParams<{ roomID: string }>();
  const { id: userID } = getUserData();

  const { data: businessLine, isLoading: isLoadingBusinessLine } = useQueryBusinessLine(
    roomID,
    userID,
    !!isTherapist
  );
  const { isDTE } = businessLine || { isDTE: false };

  const { data: subscriptions, isLoading: isLoadingSubscriptions } = useQuerySubscriptions({
    clientUserID: userID,
    roomID: Number(roomID),
    disabled: isTherapist || !userID || !roomID,
  });

  const {
    postLvsCheckInNoBookings,
    dteNoFreeCreditsHeadsUpWarning: isActiveDTENoFreeCreditsHeadsUpWarning,
  } = useFlags();

  const subscription = subscriptions?.find((s) => s.id === Number(roomID));

  const isBH = room?.isSessionBased;

  const freeCredit = creditOptions?.find(
    (credit) => credit.priceText === 'Session credits available'
  );
  const showDTENoCreditWarning = isActiveDTENoFreeCreditsHeadsUpWarning && isDTE && !freeCredit;

  const history = useHistory();

  const selectTimeslotPath = `select-timeslot${
    selectedCreditOption ? `?creditID=${selectedCreditOption.id}` : ''
  }`;

  useEffect(() => {
    if (!shouldRenderSelectCredit) {
      history.replace(selectTimeslotPath);
    }
  }, [history, selectTimeslotPath, shouldRenderSelectCredit]);

  const handleContinuePress = useCallback(() => {
    trackSelectScheduleDuration(
      modality,
      selectedCreditOption?.creditMinutes,
      room?.roomID,
      selectedCreditOption?.type,
      selectedCreditOption?.planID
    );
    history.push(selectTimeslotPath);
  }, [history, selectTimeslotPath, modality, room, selectedCreditOption]);

  const legendText = isTherapist
    ? 'Select the length of session you would like to schedule.'
    : 'Select the length of session you would like to have.';

  let headingComponent: React.ReactNode;
  if (showDTENoCreditWarning) {
    headingComponent = (
      <NoCreditsDTEHeading
        modality={modality}
        subscription={subscription}
        creditOptions={creditOptions}
      />
    );
  } else if (isFromCheckInWizard) {
    headingComponent = (
      <View style={{ marginBottom: isMobile ? 24 : 40 }} align="center">
        <KeepUpTheGreatWorkHeading />
      </View>
    );
  } else if (isFromPostLVSCheckInWizard || postLvsCheckInNoBookings) {
    headingComponent = (
      <View style={{ textAlign: 'center', marginBottom: isMobile ? 24 : 40 }}>
        <ExtraHuge>How long would you like the session to be?</ExtraHuge>
      </View>
    );
  } else {
    headingComponent = (
      <Large variant="largeDarkGrey" style={{ width: 265, textAlign: 'center', marginBottom: 50 }}>
        Select the length of session you would like to {isTherapist ? 'schedule' : 'have'}.
      </Large>
    );
  }

  const isLoading = [isLoadingBusinessLine, isLoadingSchedulingState, isLoadingSubscriptions].some(
    (x) => x
  );

  if (isLoading) return <Spinner />;
  if (isError) {
    return <InRoomSchedulingError userID={userID} roomID={roomID} onClosePress={onClose} />;
  }

  return shouldRenderSelectCredit ? (
    <View flex={1} align="center" justify="center">
      <View align="stretch" style={isMobile ? { paddingLeft: 20, paddingRight: 20 } : undefined}>
        {headingComponent}
        <TabRadioGroup legendText={legendText}>
          {creditOptions &&
            creditOptions.map((option, i) => (
              <CreditOptionBlockVersioned
                creditOption={option}
                index={i}
                key={option.id}
                isActive={option.creditMinutes === selectedCreditOption?.creditMinutes}
                handleChooseCredit={handleChooseCredit}
                dataQa={`selectCreditRadioButton_${option.id}`}
                showPriceText={!isBH || isTherapist}
              />
            ))}
        </TabRadioGroup>
      </View>
      <BottomButtonContainer>
        <Button
          onPress={handleContinuePress}
          stretch
          text="Continue"
          disabled={!(creditOptions && creditOptions.length)}
          style={{
            backgroundColor: colorRoles.system.actionPrimaryDefault,
            maxWidth: 335,
          }}
          dataQa="inRoomSchedulerSelectCreditContinue"
        />
        {isFromCheckInWizard && (
          <SkipButton onPress={onSkipBookNextSession}>
            <Standard variant="standardDarkGrey">Skip</Standard>
          </SkipButton>
        )}
        {showDTENoCreditWarning && (
          <BaseButton
            style={{
              color: colorRoles.system.actionSecondaryDefault,
              fontWeight: 500,
              marginTop: 12,
            }}
            onPress={onClose}
          >
            Wait for sessions to renew
          </BaseButton>
        )}
      </BottomButtonContainer>
    </View>
  ) : null;
};

export default SelectCredit;
