import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M11.0539 4.34093V19.6866C5.83784 16.7565 4.47099 10.9277 4.45527 7.48704C4.45527 7.29065 4.57703 7.08641 4.80877 6.98429L11.0539 4.34093ZM12.9392 19.6905V4.34093L19.1843 6.98822C19.416 7.08641 19.5417 7.29458 19.5378 7.49097C19.522 10.9277 18.1552 16.7604 12.9392 19.6905ZM19.9187 5.25216L12.5228 2.1139C12.3579 2.03928 12.1811 2 11.9965 2C11.8119 2 11.6352 2.03928 11.4702 2.1139L4.07428 5.25216C3.21018 5.61744 2.56604 6.46976 2.56996 7.49882C2.5896 11.3951 4.19212 18.524 10.9596 21.7643C11.6155 22.0786 12.3775 22.0786 13.0334 21.7643C19.8048 18.524 21.4034 11.3951 21.4231 7.49882C21.427 6.46976 20.7828 5.61744 19.9187 5.25216Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const ShieldHalved = withDSIconMaker(pathConfig, 'halved shield icon');

export default ShieldHalved;
