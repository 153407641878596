import {
  EmotionStyle,
  spacing,
  useEmotionTheme,
  useWindowWidthState,
  View,
} from '@talkspace/react-toolkit';
import { NAV_SHELL_MOBILE_HEADER_HEIGHT } from 'ts-frontend/constants';
import { Svg, Path } from 'svgs';
import styled from '../../core/styled';

type SwoopBackgroundProps = {
  style?: EmotionStyle;
  isHeader?: boolean;
};

const { space050, space100, space200, space600 } = spacing;

const MobileSwoopBackground = ({ isHeader }: { isHeader?: boolean }) => {
  const { colorRoles } = useEmotionTheme();

  return (
    <Svg
      style={isHeader ? { marginRight: 10 } : undefined}
      height="172"
      preserveAspectRatio="xMidYMax slice"
      viewBox="0 0 375 212"
      fill="none"
    >
      <Path
        d="M375 0.000586283L4.75947e-05 0L0 205.416C31.8242 215.773 91.9782 213.488 195.7 201.53C306.987 188.699 375 212 375 212V0.000586283Z"
        fill={colorRoles.surfaces.decorativeTealExtralite}
      />
    </Svg>
  );
};

const TabletSwoopBackground = () => {
  const { colorRoles } = useEmotionTheme();

  return (
    <View>
      <Svg height="124" preserveAspectRatio="xMidYMax slice" viewBox="0 0 688 180" fill="none">
        <Path
          d="M688-32H0v205.416c58.387 10.357 168.749 8.072 359.044-3.886C563.219 156.699 688 180 688 180V-32z"
          fill={colorRoles.surfaces.decorativeTealExtralite}
        />
      </Svg>
    </View>
  );
};

const Container = styled(View)<SwoopBackgroundProps>(
  ({
    theme: {
      window: { isMobile },
    },
    isHeader,
  }) => {
    if (isMobile)
      return {
        position: 'relative',
        width: isHeader ? '100%' : undefined,
        top: `calc(-${NAV_SHELL_MOBILE_HEADER_HEIGHT}px - env(safe-area-inset-top))`,
      };
    return {
      position: 'relative',
      width: '100%',
    };
  }
);

const Content = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    const leftSpacing = isMobile ? space200 : 0;
    return {
      position: 'absolute',
      top: isMobile ? space600 : 0,
      left: leftSpacing,
      width: `calc(100% - ${leftSpacing}px)`,
      height: '100%',
      paddingLeft: isMobile ? space100 : 0,
      gap: space050,
    };
  }
);

const SwoopBackground: React.FC<SwoopBackgroundProps> = ({ children, isHeader, ...props }) => {
  const { isMobile } = useWindowWidthState();
  return (
    <Container isHeader={isHeader} {...props}>
      {isMobile ? <MobileSwoopBackground isHeader={isHeader} /> : <TabletSwoopBackground />}
      <Content>{children}</Content>
    </Container>
  );
};

export default SwoopBackground;
