import { FunctionComponent } from 'react';
import { View, ExtraHuge, Large } from '@talkspace/react-toolkit';

import styled from '../../../core/styled/styled';
import FlatRoundedButton from '../FlatRoundedButton';
import RoundCheckmark from '../../assets/RoundCheckmark';

const PaddedText = styled(Large)({
  marginTop: 10,
  marginBottom: 40,
});

interface Props {
  therapistFirstName: string;
  nextReplyTime: string;
  onAskReplyPress: () => void;
  onSwitchTherapistPress: () => void;
  header: string;
}

const CouponReceived: FunctionComponent<Props> = (props: Props) => {
  const { therapistFirstName, nextReplyTime, onAskReplyPress, onSwitchTherapistPress, header } =
    props;
  return (
    <>
      <View style={{ marginBottom: 20 }}>
        <RoundCheckmark />
      </View>
      <ExtraHuge as="h1">{header}</ExtraHuge>
      <PaddedText variant="largeDarkGrey">
        We’ve credited your account for one complimentary week of therapy next renewal.
      </PaddedText>
      <FlatRoundedButton onPress={onSwitchTherapistPress}>
        <Large variant="largeBoldWideGreen">I'd like to switch to another therapist</Large>
      </FlatRoundedButton>
      <FlatRoundedButton onPress={onAskReplyPress} style={{ marginTop: 10, marginBottom: 60 }}>
        <Large variant="largeBoldWideGreen">
          Please ask {therapistFirstName} to reply by {nextReplyTime}
        </Large>
      </FlatRoundedButton>
    </>
  );
};

export default CouponReceived;
