import { FunctionComponent } from 'react';
import { Button, BaseButton, ExtraHuge, Large, Standard, Big } from '@talkspace/react-toolkit';

import styled from '../../../core/styled/styled';

const PaddedText = styled(Large)({
  marginTop: 10,
  marginBottom: 40,
});

const SendButton = styled(Button)({
  alignSelf: 'center',
});

const CancelButton = styled(BaseButton)(({ theme: { colors } }) => {
  return { color: colors.slateGrey, backgroundColor: '#fff', marginBottom: 80, marginTop: 10 };
});

interface Props {
  therapistFirstName: string;
  onSendPress: () => void;
  onCancelPress: () => void;
  header: string;
}

const Ready: FunctionComponent<Props> = (props: Props) => {
  const { onSendPress, onCancelPress, header, therapistFirstName } = props;
  return (
    <>
      <ExtraHuge as="h1" style={{ textAlign: 'center' }}>
        {header}
      </ExtraHuge>
      <PaddedText>
        When you’re done writing or recording your thoughts, click Send to let {therapistFirstName}{' '}
        know you’d like to hear from them.
      </PaddedText>
      <SendButton onPress={onSendPress}>
        <Big style={{ color: '#fff' }}>Send</Big>
      </SendButton>
      <CancelButton onPress={onCancelPress}>
        <Standard variant="standardDarkGrey">Cancel</Standard>
      </CancelButton>
    </>
  );
};

export default Ready;
