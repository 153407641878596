import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { CANCELLATION_WINDOW_HOURS } from 'inRoomScheduling/utils/constants';
import isBookingBetweenNowAndNHoursLater from 'inRoomScheduling/utils/isBookingBetweenNowAndNHoursLater';
import { ClientCancellationReasonEnum } from 'ts-frontend/types';
import { getUserData } from '@/auth/helpers/token';
import { useLocation, useParams } from '@/core/routerLib';

import useQueryClientBooking from '../../hooks/useQueryClientBooking';
import CancelSessionConfirmation from '../components/CancelSessionConfirmation';
import { useCloseModal } from '@/utils/ModalsContextProvider';

const CancelSessionConfirmationContainer = () => {
  const { roomID, bookingID } = useParams<{ roomID: string; bookingID: string }>();
  const closeModal = useCloseModal();

  const { data: booking, isLoading: isLoadingBooking } = useQueryClientBooking({
    roomID,
    bookingID,
  });
  const { id: clientUserID } = getUserData();
  const { data: businessLineData, isLoading: isLoadingBusinessLine } = useQueryBusinessLine(
    roomID,
    clientUserID
  );
  const { isBH, isEAP } = businessLineData || {};

  const isBookingTooSoon =
    booking && isBookingBetweenNowAndNHoursLater(booking, CANCELLATION_WINDOW_HOURS);

  const isBookingConfirmed = booking?.timekitBookingState === 'confirmed';
  const { state: locationState } =
    useLocation<{ cancellationReason: ClientCancellationReasonEnum }>();
  const { cancellationReason } = locationState || {};
  const canSwitchProvider =
    cancellationReason === ClientCancellationReasonEnum.PROVIDER_NOT_RIGHT_FIT;
  return (
    <CancelSessionConfirmation
      roomID={Number(roomID)}
      isLoading={isLoadingBooking || isLoadingBusinessLine}
      isBH={isBH}
      isEAP={isEAP}
      isBookingTooSoon={isBookingTooSoon}
      onClose={() => closeModal()}
      isBookingConfirmed={isBookingConfirmed}
      canSwitchProvider={canSwitchProvider}
    />
  );
};

export default CancelSessionConfirmationContainer;
