import { FunctionComponent } from 'react';
import * as React from 'react';
import { useComponentSize, View } from '@talkspace/react-toolkit';
import KeyboardAvoidingView from '../components/KeyboardAvoidingView';
import { ToastContainer } from '../components/Toast';
import MessagesContainer from '../containers/MessagesContainer';
import InputContainer from '../containers/InputContainer';

interface ChatScreenProps {
  roomID: number;
  isAcking?: boolean;
  stopInputAutofocus?: boolean;
  shouldPlaySoundNotifications?: boolean;
  style?: {};
  banners?: JSX.Element[];
  priorityBanner?: JSX.Element;
  isTherapistCheckInActive?: boolean;
  SLAComponent?: React.FunctionComponent<{ scrollToEnd?: Function }>;
  isFocusedAfterModal: boolean;
  isOffline: boolean;
}

const ChatScreen: FunctionComponent<ChatScreenProps> = ({
  roomID,
  isAcking,
  stopInputAutofocus,
  shouldPlaySoundNotifications,
  style = { height: '100%' },
  SLAComponent,
  banners,
  priorityBanner,
  isTherapistCheckInActive,
  isFocusedAfterModal,
  isOffline,
  ...props
}) => {
  const [componentSizeRef, componentSize] = useComponentSize<HTMLDivElement>();
  return (
    <View style={style} flex={1}>
      <KeyboardAvoidingView style={style} behavior="padding" enabled>
        <View
          style={{
            height: '100%',
            position: 'relative',
            backgroundColor: '#FCFCFD',
          }}
          ref={componentSizeRef}
        >
          <MessagesContainer
            roomID={roomID}
            isAcking={!!isAcking}
            shouldPlaySoundNotifications={!!shouldPlaySoundNotifications}
            SLAComponent={SLAComponent}
            containerWidth={componentSize.width || 0}
            banners={banners}
            priorityBanner={priorityBanner}
            isTherapistCheckInActive={isTherapistCheckInActive}
            isOffline={isOffline}
            {...props}
          />
          <InputContainer
            stopInputAutofocus={stopInputAutofocus}
            roomID={roomID}
            isFocusedAfterModal={isFocusedAfterModal}
            shouldMatchNewProvider={(banners || []).some(
              (banner) => banner.key === 'matchWithNewProvider'
            )}
            isOffline={isOffline}
          />
        </View>
        <ToastContainer />
      </KeyboardAvoidingView>
    </View>
  );
};

export default ChatScreen;
