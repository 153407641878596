/* eslint-disable react/no-unused-prop-types */
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import TouchableView from '../TouchableView';
import View from '../View';
import Small from '../Typography/Small';
import styled, { EmotionStyle } from '../../core/styled';

interface TabsProps {
  initialTab?: number;
  onHandleSelect?: (index: number) => void;
}
interface TabProps {
  component: ReactElement;
  index?: number;
  setSelectedTab?: (index: number) => void;
  isActive?: boolean;
  tabTitleStyle?: EmotionStyle;
}

const RedDot = styled(View)(({ theme: { colors } }) => {
  return {
    height: 7,
    width: 7,
    borderRadius: 5,
    marginRight: 5,
    backgroundColor: colors.jellybeanRed,
  };
});

export const BasicTab = styled(Small)<{ isActive: boolean }>(({ isActive, theme: { colors } }) => {
  return {
    color: colors.permaTalkspaceDarkGreen,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: !isActive && 'none',
      color: !isActive && colors.permaBlueStone,
    },
    ...(isActive && { textDecoration: 'underline', color: colors.black }),
  };
});

export const NumberText = styled(Small)<{ isActive?: boolean }>(
  ({ isActive, theme: { colors } }) => {
    return {
      marginLeft: 3,
      color: colors.permaTalkspaceDarkGreen,
      ...(isActive && { color: colors.grey }),
    };
  }
);

export const NumberTab = ({
  isActive,
  label,
  count = 0,
  showNotificationDot = false,
}: {
  isActive?: boolean;
  label: string;
  count: number;
  showNotificationDot?: boolean;
}) => (
  <View row>
    <BasicTab isActive={!!isActive}>{label}</BasicTab>
    <NumberText isActive={!!isActive}>({count})</NumberText>
    {showNotificationDot && <RedDot />}
  </View>
);

export const Tab: FunctionComponent<TabProps> = ({
  component,
  index,
  setSelectedTab,
  isActive,
  tabTitleStyle,
}) => {
  const onPress = () => {
    if (setSelectedTab) {
      setSelectedTab(index || 0);
    }
  };

  return (
    <View style={{ padding: 20, paddingRight: 0, ...tabTitleStyle }}>
      <TouchableView onPress={onPress} allowOnKeyDownPropagation={false}>
        {React.isValidElement<{ isActive: boolean }>(component) ? (
          React.cloneElement(component, { isActive })
        ) : (
          <BasicTab isActive={!!isActive} variant="smallBoldGreen">
            {component}
          </BasicTab>
        )}
      </TouchableView>
    </View>
  );
};

export const Tabs: FunctionComponent<TabsProps> = ({
  children,
  initialTab = 0,
  onHandleSelect,
}) => {
  const [selectedTab, setSelectedTab] = useState(initialTab);

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [initialTab]);

  useEffect(() => {
    onHandleSelect && onHandleSelect(selectedTab);
  }, [selectedTab, onHandleSelect]);

  const buildTab = () =>
    React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          setSelectedTab,
          index,
          // eslint-disable-next-line react/no-array-index-key
          key: index,
          isActive: index === selectedTab,
        });
      }
      return null;
    });

  const buildContent = () => {
    const hasManyTabs = React.Children.count(children) > 1;

    // we are selecting which react children element to show content based on what tab was selected
    if (hasManyTabs) {
      return (children as ReactElement[])[selectedTab].props.children;
    }
    if (React.isValidElement(children)) {
      return children.props.children;
    }
    return null;
  };

  return (
    <View>
      <View row>{buildTab()}</View>
      {buildContent()}
    </View>
  );
};
