import { useEffect, useState, useCallback } from 'react';
import {
  getIonicPasscodeEnabled,
  setIonicPasscodeEnabled,
  getIsIonicPasscodeExist,
  removeIonicPasscode,
} from 'ts-ionic/plugins/secureStorage';
import { View, useWindowWidthState } from '@talkspace/react-toolkit';
import { useHistory } from '@/core/routerLib';
import { useCloseModal } from '@/utils/ModalsContextProvider';

import styled from '../../core/styled';
import useAccountDetails from '../hooks/useAccountDetails';
import { getActionAuth } from '../utils/tokenHelper';
import { ModalType } from '../reducers/accountDetails';
import AccountDetails from '../components/AccountDetails';
import AccountDetailsModal from '../components/AccountDetailsModal';
import { useAccountDetailsDeepLinks } from '../../utils/deeplinksHelper';
import { useMainState, useMainActions } from '../../hooks/mainContext';
import { getUserData } from '../../auth/helpers/token';
import { useClientAuthState } from '../../hooks/clientAuthContext';
import useMutationChangeNumber from '../../TwoFactorAuthentication/hooks/useMutationChangeNumber';
import useMutationResendOTP from '../../TwoFactorAuthentication/hooks/useMutationResendOTP';
import useMutationVerifyPhone from '../../TwoFactorAuthentication/hooks/useMutationVerifyPhone';
import { trackClient2FAVerification } from '../../TwoFactorAuthentication/utils/analytics';

const Wrapper = styled(View)({
  flex: 1,
  height: '100%',
});

interface Props {
  onCloseButtonPress: () => void;
  onBackButtonPress: () => void;
  inNavShell?: boolean;
}

const AccountDetailsContainer: React.VFC<Props> = ({
  onCloseButtonPress,
  onBackButtonPress,
  inNavShell,
}) => {
  const { mutate: changeNumber, isLoading: isChangeNumberLoading } = useMutationChangeNumber();
  const { mutate: resendOTP, isLoading: isResendOTPLoading } = useMutationResendOTP();
  const { mutate: verifyPhoneNumber, isLoading: isVerifyLoading } = useMutationVerifyPhone();
  const [
    state,
    {
      dispatchOpenModal,
      dispatchCloseModal,
      dispatchClearError,
      updateNickname,
      verifyPassword,
      dispatchReceiveActionAuth,
      updateEmail,
      updatePassword,
      updatePaymentDetails,
      sendEmailVerification,
      getPaymentDetails,
      getClientPhoneNumber,
      clearVerificationCodeError,
      dispatchSetSuccessModalText,
      setOTPToken,
      clearOtpToken,
      verifyCode,
      setPendingPhoneNumber,
      setPhoneNumber,
      setBackModal,
      set2faTokenExpiredError,
      clear2faTokenExpiredError,
      setError,
    },
  ] = useAccountDetails();
  const {
    adminConfigs: { isCognitoLoginEnabled },
  } = useClientAuthState();
  const history = useHistory();
  const closeModal = useCloseModal();
  const { isMobile } = useWindowWidthState();

  const [isPasscodeEnabled, setIsPasscodeEnabled] = useState<boolean>(false);
  const [isPasscodeValueSet, setIsPasscodeValueSet] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const [storagePasscodeEnabled, storagePasscodeExists] = await Promise.all([
        getIonicPasscodeEnabled(),
        getIsIonicPasscodeExist(),
      ]);
      const { value: enabledValue } = storagePasscodeEnabled;
      const existsValue = storagePasscodeExists;
      setIsPasscodeEnabled(enabledValue === 'true');
      setIsPasscodeValueSet(existsValue);
    })();
  }, []);

  const [nextModal, setNextModal] = useState<ModalType>('changeEmail');

  const {
    adminConfigs: { emailVerificationFlagIsActive },
    me: userInfo,
  } = useMainState();
  const { getAdminConfigOption, getClient } = useMainActions();

  const { id: userID } = getUserData();
  const { token, expiration } = getActionAuth();

  useEffect(() => {
    if (token && expiration && new Date(expiration) > new Date()) {
      dispatchReceiveActionAuth(token, new Date(expiration));
    }
  }, [dispatchReceiveActionAuth, expiration, token]);

  useEffect(() => {
    getClient(userID);
    getClientPhoneNumber();
    getPaymentDetails();
    getAdminConfigOption('email_verification');
  }, [getClient, getAdminConfigOption, userID, getPaymentDetails, getClientPhoneNumber]);

  const handleChangeEmailPress = useCallback(
    function handleChangeEmailPress() {
      if (state.actionAuth.token && state.actionAuth.expiration > new Date()) {
        dispatchOpenModal('changeEmail');
      } else {
        dispatchOpenModal('passwordRequired');
        setNextModal('changeEmail');
      }
    },
    [dispatchOpenModal, state.actionAuth.expiration, state.actionAuth.token]
  );

  const handleChangePasswordPress = useCallback(
    function handleChangePasswordPress() {
      const hasAuthorization = isCognitoLoginEnabled
        ? !!state.currentPassword
        : state.actionAuth.token && state.actionAuth.expiration > new Date();
      if (hasAuthorization) {
        dispatchOpenModal('changePassword');
      } else {
        dispatchOpenModal('passwordRequired');
        setNextModal('changePassword');
      }
    },
    [
      dispatchOpenModal,
      isCognitoLoginEnabled,
      state.actionAuth.expiration,
      state.actionAuth.token,
      state.currentPassword,
    ]
  );

  const handleChangePhoneNumberPress = useCallback(
    function handleChangePhoneNumberPress() {
      trackClient2FAVerification('Change phone number - Login and Security');
      setBackModal('changePhoneNumber');
      if (state.actionAuth.token && state.actionAuth.expiration > new Date()) {
        dispatchOpenModal('changePhoneNumber');
      } else {
        dispatchOpenModal('passwordRequired');
        setNextModal('changePhoneNumber');
      }
    },
    [dispatchOpenModal, setBackModal, state.actionAuth.expiration, state.actionAuth.token]
  );

  const handle2FATogglePress = useCallback(() => {
    trackClient2FAVerification('Enable text message (SMS) - Login and Security');
    if (state.disable2FA) {
      const modalType = state.phoneNumber ? 'verifyPhoneNumber' : 'changePhoneNumber2FA';
      setBackModal('changePhoneNumber2FA');
      if (state.actionAuth.token && state.actionAuth.expiration > new Date()) {
        dispatchOpenModal(modalType);
      } else {
        dispatchOpenModal(modalType);
      }
    }
  }, [
    dispatchOpenModal,
    setBackModal,
    state.actionAuth.expiration,
    state.actionAuth.token,
    state.phoneNumber,
    state.disable2FA,
  ]);

  const handleBackToChangePhoneNumber = useCallback(() => {
    trackClient2FAVerification('Go back to change phone number - Login and Security');
    clearVerificationCodeError();
    clearOtpToken();
    dispatchOpenModal(state.backModal);
  }, [state.backModal, dispatchOpenModal, clearVerificationCodeError, clearOtpToken]);

  useAccountDetailsDeepLinks({
    changeEmail: handleChangeEmailPress,
    changePassword: handleChangePasswordPress,
    updatePaymentDetails: () => dispatchOpenModal('updatePaymentDetails'),
    changePhoneNumber: handleChangePhoneNumberPress,
  });

  const handleChangeNumber = (phoneNumber: string, phoneNumberCountryCode: string) => {
    trackClient2FAVerification('Save phone number - Login and Security');
    changeNumber(
      { phoneNumber, phoneNumberCountryCode, updateEmergencyContact: true },
      {
        onSuccess: (data) => {
          if (data) {
            setOTPToken(data.otpToken);
            setPendingPhoneNumber(phoneNumber, phoneNumberCountryCode);
            dispatchOpenModal('verifyPhoneNumber');
          } else {
            setPhoneNumber(phoneNumber, phoneNumberCountryCode);
            dispatchSetSuccessModalText('Your phone number was changed.');
          }
        },
        onError: () => {
          setError();
        },
      }
    );
  };

  const resendVerificationCode = () => {
    trackClient2FAVerification(`Resend code - Login and Security`);
    if (state.otpToken) {
      resendOTP(
        { otpToken: state.otpToken, sendEmail: false },
        {
          onSuccess: (res) => {
            if (res.otpToken) {
              setOTPToken(res.otpToken);
            }
          },
          onError: () => {
            set2faTokenExpiredError();
            setTimeout(() => {
              if (state.backModal) {
                dispatchOpenModal(state.backModal);
              }
            }, 2000);
          },
        }
      );
    }
  };

  const handleVerifyNumber = useCallback(() => {
    if (!state.otpToken && state.phoneNumber && !isVerifyLoading) {
      verifyPhoneNumber(null, {
        onSuccess: (res) => {
          setOTPToken(res.otpToken);
        },
      });
    }
  }, [state.otpToken, state.phoneNumber, isVerifyLoading, verifyPhoneNumber, setOTPToken]);

  const handleClearErrorsAndOTP = useCallback(() => {
    clearOtpToken();
    clearVerificationCodeError();
    clear2faTokenExpiredError();
  }, [clearOtpToken, clearVerificationCodeError, clear2faTokenExpiredError]);

  const handleChangePasscodeValue = useCallback(async () => {
    await setIonicPasscodeEnabled(true);
    setIsPasscodeEnabled(true);
    if (isMobile) {
      closeModal({ navigateTo: 'passcode' });
    } else {
      history.push('/passcode');
    }
  }, [isMobile, history, closeModal]);

  const handleChangePasscodeEnabled = useCallback(async () => {
    if (!isPasscodeValueSet) {
      await setIonicPasscodeEnabled(true);
      setIsPasscodeEnabled(true);
      if (isMobile) {
        closeModal({ navigateTo: 'passcode' });
      } else {
        history.push('/passcode');
      }
    } else {
      await setIonicPasscodeEnabled(false);
      await removeIonicPasscode();
      setIsPasscodeEnabled(false);
      setIsPasscodeValueSet(false);
    }
  }, [isMobile, history, closeModal, isPasscodeValueSet]);

  const { email: currentEmail, phoneNumber: currentPhoneNumber, disable2FA } = state;

  const pendingEmail = state.pendingEmail || (userInfo && userInfo.pendingEmail) || '';

  const displayedEmail = emailVerificationFlagIsActive
    ? pendingEmail || currentEmail
    : currentEmail;

  const emailVerificationStatus =
    state.emailVerificationStatus || (userInfo && userInfo.emailVerificationStatus);

  const isLoading = state.isLoading || isChangeNumberLoading || isVerifyLoading;

  return (
    <Wrapper>
      <AccountDetails
        isLoading={state.isLoading}
        isPhoneNumberLoading={state.isPhoneNumberLoading}
        nickname={state.nickname}
        currentEmail={currentEmail}
        pendingEmail={pendingEmail}
        displayedEmail={displayedEmail}
        phoneNumber={currentPhoneNumber}
        disable2FA={disable2FA}
        paymentDetails={state.paymentDetails}
        emailIsVerified={emailVerificationStatus === 'verified'}
        emailVerificationFlagIsActive={!!emailVerificationFlagIsActive}
        isPasscodeEnabled={isPasscodeEnabled}
        isPasscodeValueSet={isPasscodeValueSet}
        handleSMSTogglePress={handle2FATogglePress}
        handleBackButtonPress={onBackButtonPress}
        handleCloseButtonPress={onCloseButtonPress}
        handleChangeNicknamePress={() => dispatchOpenModal('changeNickname')}
        handleChangeEmailPress={handleChangeEmailPress}
        handleChangePasswordPress={handleChangePasswordPress}
        handleChangePhoneNumberPress={handleChangePhoneNumberPress}
        handleResendEmailVerificationPress={() => sendEmailVerification(displayedEmail)}
        handleChangePasscodeEnabled={handleChangePasscodeEnabled}
        handleChangePasscodeValue={handleChangePasscodeValue}
        inNavShell={inNavShell}
      />
      {state.isModalOpen && (
        <AccountDetailsModal
          isLoading={isLoading}
          error={state.error}
          openModalType={state.openModalType}
          successModalText={state.successModalText}
          successModalTitle={state.successModalTitle}
          nextModalType={nextModal}
          nickname={state.nickname}
          currentPhoneNumber={currentPhoneNumber}
          email={displayedEmail}
          paymentDetails={state.paymentDetails}
          verificationCodeError={state.verificationCodeError}
          pendingPhoneNumber={state.pendingPhoneNumber}
          twoFATokenExpiredError={state.twoFATokenExpiredError}
          isResendOTPLoading={isResendOTPLoading}
          isVerifyCodeLoading={state.isVerifyCodeLoading}
          closeModal={() => dispatchCloseModal()}
          clearError={() => dispatchClearError()}
          updateNickname={updateNickname}
          verifyPassword={verifyPassword}
          updateEmail={updateEmail}
          updatePassword={updatePassword}
          updatePaymentDetails={updatePaymentDetails}
          changePhoneNumber={handleChangeNumber}
          clearVerificationCodeError={clearVerificationCodeError}
          handleBackToChangePhoneNumber={handleBackToChangePhoneNumber}
          resendVerificationCode={resendVerificationCode}
          verifyCode={verifyCode}
          clearErrorsAndOTP={handleClearErrorsAndOTP}
          verifyNumber={handleVerifyNumber}
        />
      )}
    </Wrapper>
  );
};

export default AccountDetailsContainer;
