import { FunctionComponent } from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  View,
  Big,
  Small,
  TouchableView,
  BaseButton,
  HiddenText,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  SkipNavigation,
  usePanelManagerActions,
} from '@talkspace/react-toolkit';
import { DEFAULT_HEADER_HEIGHT } from '@talkspace/react-toolkit/src/components/ResponsiveLayoutWithHeader';
import { roomHasRealProvider } from 'ts-frontend/helpers';
import {
  isTherapistCT,
  checkIfPsychiatrist,
  Availability,
  TherapistType,
  INACTIVE_PHASE_VALUES,
} from 'ts-frontend/entities/Therapist';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';
import useChatContainerSocket from '../hooks/useChatContainerSocket';
import ReactivationTray from '../components/ReactivationTray';
import styled from '../core/styled';
import { RouteComponentProps } from '../core/routerLib/routerLib';
import ClientChat from '../clientChat';
import extractRoomEntities from '../utils/extractRoomEntitiesFromState';
import { useMainState } from '../hooks/mainContext';
import TherapistAvatar from '../components/TherapistAvatar';
import ToggleDetailsIcon from '../components/icons/ToggleDetailsIcon';
import ArrowLeft from '../assets/ArrowLeft';
import { useChatDeepLinks } from '../utils/deeplinksHelper';
import {
  ID_CHAT_PROVIDER_DETAILS_BUTTON,
  ID_PROVIDER_DETAILS_PANEL,
  ID_ROOM_DETAILS_BUTTON,
  ID_ROOM_DETAILS_PANEL,
  ID_CHAT_INPUT,
  ID_SENT_BY_THERAPIST,
  ID_SENT_BY_USER,
  ID_STARRED_MESSAGE,
} from '../utils/IDConstants';
import useQueryCanRematch from '../clientChat/hooks/useCanRematch';
import useNavShellWindowHeight from '@/hooks/useNavShellWindowHeight';
import useMutationActivateRoom from '../clientChat/hooks/useMutationActivateRoom';
import useReactivationTray from '../clientChat/hooks/useReactivationTray';
import useOpenModalSafe from '@/hooks/useOpenModalSafe';
import useReactivationTrayV2 from '../clientChat/hooks/useReactivationTrayV2';
import { trackCTAClick } from '../utils/analytics/events';

const RoomDetailsIcon = styled(ToggleDetailsIcon)(({ theme: { colors } }) => {
  return {
    stroke: colors.green,
    '&:hover': {
      stroke: colors.darkGreen,
    },
  };
});

const LeftArrowWrapper = styled(View)<{ useNewNav?: boolean }>(
  ({ useNewNav, theme: { window, colors } }) => {
    const showArrow = useNewNav
      ? window.isSmall || window.isMedium || window.isLarge
      : window.isSmall || window.isMedium;
    return {
      display: showArrow ? 'flex' : 'none',
      backgroundColor: colors.white,
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 2,
      marginRight: 4,
      marginLeft: -16,
    };
  }
);

const ChatHeightWrapper = styled(View)<{
  outsideHeaderHeight?: number;
  outsideFooterHeight?: number;
}>(({ outsideHeaderHeight, outsideFooterHeight, theme: { window } }) => {
  const { height } = window;

  return {
    height:
      outsideHeaderHeight || outsideFooterHeight
        ? height - (outsideHeaderHeight || 0) - (outsideFooterHeight || 0) - DEFAULT_HEADER_HEIGHT
        : height - DEFAULT_HEADER_HEIGHT,
  };
});

const showAvailability = (
  availability: Availability,
  therapistType: TherapistType,
  phase: number
) => {
  if (isTherapistCT(therapistType)) {
    return 'Matching agent';
  }

  if (INACTIVE_PHASE_VALUES.includes(phase)) {
    return 'Unavailable';
  }

  if (availability === 'time-off') {
    return 'On time off';
  }

  if (availability === 'unavailable') {
    return 'Off today';
  }
  if (availability === 'available') {
    return 'Working today';
  }
  return null;
};

type Props = RouteComponentProps<{ roomID: string }> & {
  isChatHidden?: boolean;
  hideBackButton?: boolean;
};

const ChatContainer: FunctionComponent<Props> = (props) => {
  const { history, match, isChatHidden, hideBackButton } = props;

  const { subscriptionsByID } = useMainState();
  const { subscription } = subscriptionsByID[match.params.roomID] || {};

  const { data: { shouldActivateDueToDischarge } = {}, refetch: refetchRoomDetails } =
    useQueryRoomDetails(match.params.roomID);
  const { data: canRematch, isLoading: isCanRematchLoading } = useQueryCanRematch({
    roomID: Number(match.params.roomID),
    isClosedInactive: !!subscription?.isClosedInactive,
    enableCanRematch: true,
  });

  useChatContainerSocket({ refetchRoomDetails });

  const { mutate: activateRoom } = useMutationActivateRoom();

  const { therapist, room } = extractRoomEntities(useMainState(), Number(match.params.roomID));

  useChatDeepLinks({ roomID: Number(match.params.roomID), planID: room.planID });

  const openModalSafe = useOpenModalSafe();

  const useNewNav = useNewMemberNav();
  const { memberNewNav } = useFlags();

  const handleOnTherapistPress = () => {
    if (useNewNav) openModalSafe(`${match.url}/therapist-details`, undefined, true);
    else history.push(`${match.url}/therapist-details`);
  };

  const handleOnRoomDetailsPress = () => {
    if (memberNewNav && memberNewNav !== 'disabled') {
      trackCTAClick('Room details', 'messages', { roomID: match.params.roomID });
    }

    if (useNewNav) openModalSafe(`${match.url}/room-details`, undefined, true);
    else {
      history.push(`${match.url}/room-details`);
      history.push({
        pathname: `${match.url}/room-details`,
        state: {
          from: 'chat',
        },
      });
    }
  };

  const handleMatchProvider = () => {
    history.push(`/switch-provider/room/${match.params.roomID}`, { source: 'b2bInactivity' });
  };

  const handleReactivation = () => {
    activateRoom({ roomID: Number(match.params.roomID) });
  };

  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(match.params.roomID);

  const useReactivationTrayParams = {
    isLoading: isCanRematchLoading,
    canRematch: Boolean(canRematch && canRematch.available),
    isClosedInactive: !!subscription?.isClosedInactive,
    isB2B: !!subscription?.isB2B,
    isB2C: !!subscription?.isB2C,
    roomID: room.roomID,
    shouldActivateDueToDischarge: !!shouldActivateDueToDischarge,
  };

  const trayV2Result = useReactivationTrayV2(useReactivationTrayParams);
  const trayV1Result = useReactivationTray(useReactivationTrayParams);

  const {
    isProviderAvailable,
    hasSeenWelcomeBackInactivityTray,
    hasSeenWelcomeBackActivityTray,
    dismissReactivationTray,
    applyReactivationTray = null,
    showReactivationTray,
    showReactivationFlow = null,
  } = isClientDischargeV3OnForPlan ? trayV2Result : trayV1Result;

  const { setIsLeftPanelOnTop } = usePanelManagerActions();

  const skipNavs: SkipNavigation[] = [
    {
      buttonText: 'Chat Now',
      returnID: ID_CHAT_INPUT,
    },
  ];
  const isRoomDetailsPanelRendered = history.location.pathname.includes('room-details');
  const isProviderDetailsPanelRendered = history.location.pathname.includes('therapist-details');

  const hasProvider = roomHasRealProvider({
    room,
    provider: therapist,
  });
  const shouldShowAvailability = hasProvider && !checkIfPsychiatrist(therapist.type);

  const therapistTitleName = !hasProvider
    ? therapist.firstName
    : `${therapist.title} ${therapist.firstName} ${therapist.lastName}`;

  const { outsideHeaderHeight, outsideFooterHeight } = useNavShellWindowHeight();

  return (
    <ResponsiveLayoutWithHeader
      outsideHeaderHeight={outsideHeaderHeight}
      outsideFooterHeight={outsideFooterHeight}
      shouldScroll={false}
      shouldNotFocus={useNewNav}
      renderHeader={() => (
        <PanelHeader
          skipNavs={skipNavs}
          renderLeft={() => (
            <View row>
              {(!hideBackButton || !useNewNav) && (
                <LeftArrowWrapper aria-label="Return to dashboard" useNewNav={useNewNav}>
                  <BaseButton
                    style={{
                      paddingRight: 12,
                      paddingLeft: 12,
                      marginTop: 0,
                      paddingBottom: 0,
                      backgroundColor: 'transparent',
                    }}
                    onPress={() => setIsLeftPanelOnTop(true)}
                  >
                    <ArrowLeft height={21} width={13} hasHoverStyles />
                  </BaseButton>
                </LeftArrowWrapper>
              )}
              <TouchableView
                onPress={!hasProvider ? handleOnRoomDetailsPress : handleOnTherapistPress}
                id={ID_CHAT_PROVIDER_DETAILS_BUTTON}
                aria-label={`provider ${therapist.title} ${therapist.firstName} ${therapist.lastName}. Opens profile region`}
                aria-controls={
                  isProviderDetailsPanelRendered ? ID_PROVIDER_DETAILS_PANEL : undefined
                }
                aria-expanded={isProviderDetailsPanelRendered}
              >
                <View row>
                  <TherapistAvatar
                    size="small"
                    source="inRoom"
                    roomType={room.roomType}
                    roomStatus={room.status}
                    therapist={therapist}
                  />
                  <View justify="center" style={{ marginLeft: 8 }}>
                    <Big as="h1">{therapistTitleName}</Big>
                    {shouldShowAvailability && (
                      <Small style={{ textAlign: 'left' }}>
                        {showAvailability(therapist.availability, therapist.type, therapist.phase)}
                      </Small>
                    )}
                  </View>
                </View>
              </TouchableView>
            </View>
          )}
          renderRight={() => (
            <TouchableView
              onPress={handleOnRoomDetailsPress}
              id={ID_ROOM_DETAILS_BUTTON}
              aria-label="Room details"
              aria-controls={isRoomDetailsPanelRendered ? ID_ROOM_DETAILS_PANEL : undefined}
              aria-expanded={isRoomDetailsPanelRendered}
            >
              <RoomDetailsIcon />
            </TouchableView>
          )}
        />
      )}
    >
      <ChatHeightWrapper
        outsideHeaderHeight={outsideHeaderHeight}
        outsideFooterHeight={outsideFooterHeight}
      >
        {isClientDischargeV3OnForPlan ? (
          <ClientChat
            roomStatus={room.status}
            therapistType={therapist.type}
            therapistFirstName={therapist.firstName}
            isChatHidden={isChatHidden}
            hasSeenWelcomeBackInactivityTray={
              hasSeenWelcomeBackInactivityTray && !isProviderAvailable && !!showReactivationFlow
            }
            hasSeenWelcomeBackActivityTray={
              hasSeenWelcomeBackActivityTray && isProviderAvailable && !!showReactivationFlow
            }
            applyReactivationTray={applyReactivationTray!}
          />
        ) : (
          <ClientChat
            roomStatus={room.status}
            therapistType={therapist.type}
            therapistFirstName={therapist.firstName}
            isChatHidden={isChatHidden}
            hasSeenWelcomeBackInactivityTray={
              hasSeenWelcomeBackInactivityTray && !isProviderAvailable
            }
          />
        )}
      </ChatHeightWrapper>
      <HiddenText id={ID_SENT_BY_THERAPIST}>sent by {therapist.firstName}</HiddenText>
      <HiddenText id={ID_SENT_BY_USER}>sent by you</HiddenText>
      <HiddenText id={ID_STARRED_MESSAGE}>starred message</HiddenText>
      {showReactivationTray && (
        <ReactivationTray
          therapist={therapist}
          room={room}
          isProviderAvailable={isProviderAvailable}
          dismissReactivationTray={dismissReactivationTray}
          handleMatchProvider={handleMatchProvider}
          handleReactivation={handleReactivation}
          roomID={room.roomID}
        />
      )}
    </ResponsiveLayoutWithHeader>
  );
};

export default ChatContainer;
