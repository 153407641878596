import {
  VideoCreditOffer,
  FlowVariant,
  SessionModality,
  ChargeType,
  CreditMinutes,
} from 'ts-frontend/types';
import { ERoom } from 'ts-frontend/entities/Room';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { Switch, Route, Redirect } from 'react-router';
import {
  PropsWithChildren,
  FunctionComponent,
  ComponentProps,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import moment from 'moment';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { ThemedFlagsProvider } from 'launchDarkly';
import redirectToLiveSession from 'chat/utils/redirectToLiveSession';
import { VIDEO_CALL_SESSION_STORAGE_KEY } from 'chat/types/videoCallTypes';
import {
  View,
  TouchableView,
  ResponsiveLayoutWithHeader,
  useEmotionTheme,
  SkipNavigation,
  Big,
  TSLogo,
  CancellationPolicy,
  CaretLeft,
  PanelHeader,
  StickyDrawerParentContainer,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import {
  trackBookingSession,
  trackJoinedAdHocSession,
  trackPayingAdHocSession,
} from 'ts-analytics/mixpanel/events';

import PendingInvoicesForm from 'checkout/components/PendingInvoicesForm';
import PaymentWizardSuccess from 'checkout/components/PaymentWizardSuccess';
import PendingInvoicesList from 'checkout/components/PendingInvoicesList';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import appConfigs from '@/utils/configs';
import useAccountDetails from '@/myAccount/hooks/useAccountDetails'; // Temporary solution
import useMutationCreateBookingActivation from '../hooks/useMutationCreateBookingActivation';
import { useHistory, useRouteMatch, useLocation } from '@/core/routerLib';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { useA11y } from './InRoomSchedulingContainer.a11y';
import { deleteLastElementInPath } from '../utils/url';
import getFunnelName from '../utils/getFunnelName';
import {
  getSkipOnboardingAllDone,
  removeSkipOnboardingAllDone,
  setSkipOnboardingAllDone,
} from '../utils/onboarding';
import {
  CancelBookingParams,
  ConfirmAsyncSessionParams,
  ConfirmBookingOptions,
  CreateBookingParams,
  CreateRecurringBookingParams,
  TherapistInfo,
  InRoomSchedulingLocation,
} from '../types';
import {
  useInRoomSchedulingState,
  useInRoomSchedulingActions,
} from '../hooks/inRoomSchedulingContext';
import useRepeatingBooking from '../hooks/useRepeatingBooking';
import MbaMatchInProgress from '../components/MbaMatchInProgress';
import SelectTimeslot from '../components/SelectTimeslot';
import SelectModality from '../components/SelectModality';
import SelectCredit from '../components/SelectCredit';
import PaymentDetails from '../components/PaymentDetails';
import BookLaterModal from '../components/BookLaterModal';
import MessagingInformationFlow from '../components/MessagingInformationFlow';
import ConfirmDecline from '../components/ConfirmDecline';
import ConfirmCancel from '../components/ConfirmCancel';
import ConfirmBookingContainer from './ConfirmBookingContainer';
import RecurringBookingConflicts from '../components/RecurringBookingConflicts';
import CheckInDone from '../components/CheckInDone';
import CancelReason from '../components/CancelReason';
import CancelBookingContainer from './CancelBookingContainer';
import AddToCalendarScreen from '../components/AddToCalendarScreen';
import BookingCheckout from '../components/BookingCheckout';
import BookingConfirmationSuccess from '../components/BookingConfirmationSuccess';
import OnboardingMatchQueueConfirmation from '../components/OnboardingMatchQueueConfirmation';
import UpdatePaymentDetails from '../components/UpdatePaymentDetails';
import IneligibleMembers from '../components/IneligibleMembers';
import { CANCELLATION_WINDOW_HOURS } from '../utils/constants';
import getRoomBusinessLine from '../utils/getRoomBusinessLine';
import { ClosePopupAction } from '@/auth/reactFrame/ReactFrameTypes';

export type InRoomSchedulingContainerProps = {
  room: ERoom;
  bookWithIntroSession?: boolean;
  hideHeader?: boolean;
  isCloseable?: boolean;
  isOnboarding?: boolean;
  isInModal?: boolean;
  isTherapist?: boolean;
  isChatHidden?: boolean;
  therapist: TherapistInfo;
  clientDisplayName?: string;
  clientUserID?: number;
  useStripeLink?: boolean;
  flowVariant?: FlowVariant;
  preRegistrationSelectedModality?: SessionModality;
  shouldSkipModalitySelection?: boolean;
  onLoadStripeLink?: () => void;
  dismissOnboarding?: () => void;
  fetchOnboarding?: () => Promise<any>;
  setWrapperTitle?: (title: string) => void;
};

// if another step gets added, add the step-specific part of the pathname to this array
export const LVSPathnameArray = [
  'cancel-booking',
  'cancel',
  'case-tab',
  'payment-details',
  'cancellation-policy',
  'select-timeslot',
  'confirm-cancel',
  'cancel-reason',
  'add-to-calendar',
  'booking-success',
  'messaging-session',
];

const BackButton = ({
  hidden,
  onPress,
  zeroHeight,
}: {
  hidden?: boolean;
  zeroHeight?: boolean;
  onPress: () => void;
}) => {
  const { colors } = useEmotionTheme();
  if (hidden) {
    return null;
  }
  return (
    <View
      row
      alignSelf="start"
      justify="space-between"
      style={zeroHeight ? { height: 0, overflow: 'visible' } : undefined}
    >
      <TouchableView
        dataQa="inRoomSchedulerBackButton"
        onPress={onPress}
        style={zeroHeight ? { margin: 10, padding: 20 } : {}}
        id="back"
        aria-label="back"
      >
        <CaretLeft
          inherit
          color={colors.green}
          style={zeroHeight ? { marginTop: -30 } : undefined}
        />
      </TouchableView>
    </View>
  );
};

export const ClientHeader = ({
  skipNavs,
  children,
  isChatHidden,
  handleBackClick,
  shouldHideBackArrow,
  handleOnRoomPanelClosePress,
  titleText,
  isMobile,
}: PropsWithChildren<{
  skipNavs?: SkipNavigation[];
  isChatHidden?: boolean;
  shouldHideBackArrow: boolean;
  handleBackClick: () => void;
  handleOnRoomPanelClosePress?: () => void | undefined;
  titleText: string;
  isMobile: boolean;
}>) => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { brandRefreshChanges } = useFlags();

  useEffect(() => {
    if (containerRef?.current && location?.pathname) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [containerRef, location?.pathname]);
  const renderLeft = () => (
    <>
      <View style={{ width: 13 }}>
        <BackButton onPress={handleBackClick} hidden={shouldHideBackArrow} />
      </View>
      <View flex={1} align="center">
        {titleText ? (
          <Big>{titleText}</Big>
        ) : (
          <TSLogo variant={brandRefreshChanges ? '2024' : 'old'} />
        )}
      </View>
    </>
  );
  return (
    <ResponsiveLayoutWithHeader
      ref={containerRef}
      renderHeader={() => (
        <PanelHeader
          renderLeft={renderLeft}
          onRightPress={handleOnRoomPanelClosePress}
          skipNavs={skipNavs}
          isChatHidden={isChatHidden}
          isMobile={isMobile}
          dataQa="closeInRoomScheduler"
        />
      )}
    >
      {children}
    </ResponsiveLayoutWithHeader>
  );
};

const InRoomSchedulingContainer: FunctionComponent<InRoomSchedulingContainerProps> = ({
  room,
  therapist,
  isTherapist,
  isChatHidden,
  clientDisplayName,
  clientUserID,
  flowVariant,
  bookWithIntroSession = false,
  hideHeader = false,
  isCloseable = true,
  isOnboarding = false,
  isInModal = true,
  useStripeLink = true,
  preRegistrationSelectedModality,
  shouldSkipModalitySelection = false,
  onLoadStripeLink = () => {},
  dismissOnboarding = () => {},
  fetchOnboarding = () => Promise.resolve(),
  setWrapperTitle = () => {}, // setWrapperTitle is used in therapist CRM, setTitleText is used in webviews for client web
}) => {
  const {
    dispatchSetRoomAndTherapistInfo,
    dispatchCreateBooking,
    dispatchCreateRecurringBooking,
    dispatchCreateAsyncSession,
    dispatchRescheduleBooking,
    dispatchGetSelectedBooking,
    dispatchSetSelectedBookingDuration,
    dispatchSetSelectedCreditOption,
    dispatchClientConfirmBooking,
    dispatchSetJoinData,
    dispatchGetSubscriptionsAndCreditOptions,
    dispatchModalityType,
    dispatchClientJoinLiveChat,
    dispatchSetIsError,
    dispatchGetVideoCreditOffers,
  } = useInRoomSchedulingActions();
  const {
    selectedCreditOption,
    selectedBookingDuration,
    selectedCancelBooking,
    selectedTimeslot,
    hasBreakAfterSession,
    selectedConfirmBooking,
    shouldShowBookingSuccess,
    cancelReason,
    schedulerMode,
    isError,
    isB2B,
    videoCredits,
    creditOptions,
    videoCall,
    isJoin,
    room: stateRoom,
    clientJoinedLiveChat,
    modality,
    isBookedMessagingSession,
    isBHAdHoc,
    videoCallID,
    adHocDuration,
    planInfo,
    isLoading,
    isClientConfirmError,
    isClientRescheduleError,
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
    repeatingPeriod,
    repeatingSessions,
    isPermanentIneligible,
  } = useInRoomSchedulingState();
  const useNewNav = useNewMemberNav();

  const { isRecurringBooking: isRecurringConfirmBooking } = useRepeatingBooking({
    selectedBooking: selectedConfirmBooking,
    onlyConfirmedOrTentative: true,
  });
  const { isRecurringBooking: isRecurringCancelBooking } = useRepeatingBooking({
    selectedBooking: selectedCancelBooking,
  });

  const {
    mutate: createBookingActivation,
    isError: isCreateBookingActivationError,
    isSuccess: isCreateBookingActivationSuccess,
    isLoading: isCreateBookingActivationLoading,
    reset: resetCreateBookingActivation,
    data: bookingActivationData,
    error: bookingActivationErrorPayload,
  } = useMutationCreateBookingActivation();

  const bookingActivationError = bookingActivationErrorPayload?.data?.data.errors?.[0] || undefined;

  const [bookingIntroSession, setBookingIntroSession] = useState(false);
  const [
    {
      paymentDetails,
      email,
      error: updatePaymentDetailsError,
      isLoading: isPaymentLoading,
      isLinkLoaded,
    },
    {
      getPaymentDetails,
      updatePaymentDetails,
      dispatchClearError,
      dispatchPaymentMethodError,
      onLoadStripeLink: accountDetailsOnLoadStripeLink,
    },
  ] = useAccountDetails();
  const { isMobile } = useWindowWidthState();
  const closeModal = useCloseModal();
  const [isWithin24Hours, setIsWithin24Hours] = useState(false);
  const [therapistRating, setTherapistRating] = useState<number | undefined>();
  const [savedSource, setSavedSource] = useState<string | null>();
  const [hasSingleNonIntroCredit, setHasSingleNonIntroCredit] = useState<boolean | undefined>(
    undefined
  );
  const match = useRouteMatch<{ roomID: string }>();
  const [titleText, setTitleText] = useState('Book a session');

  const location = useLocation<InRoomSchedulingLocation | undefined>();
  const params = new URLSearchParams(location.search);
  const closePopupWhenDoneRef = useRef(params.get('closePopupWhenDone') === 'true');
  const isFromPendingInvoicesBanner = params.get('source') === 'pendingInvoicesBanner';
  const isBatchMode = params.get('batch') === 'true';
  const shouldRedirectToRoom = params.get('redirectToRoom') === 'true';

  const history = useHistory();

  const {
    pendingInvoicesChargeTypes,
    copayAtTimeOfService: copayAtTimeOfServiceActive,
    bookSession0Copay: bookSession0CopayActive,
  } = useFlags<{ pendingInvoicesChargeTypes?: Array<ChargeType>; copayAtTimeOfService: boolean }>();

  const isBH = stateRoom?.isSessionBased;

  const isiOS = ReactFrameService.isiOS();
  const isAndroid = ReactFrameService.isAndroid();
  const { roomID: stateRoomID, asyncOnboarding } = location.state || {};
  const isInTherapistCRM = isTherapist && !isInModal;
  const isFromCheckInWizard = savedSource === 'check-in-wizard';
  const isFromPostLVSCheckInWizard = savedSource === 'post-lvs-check-in';
  const shouldRenderConfirmBooking = !!(
    (therapist && selectedTimeslot && selectedCreditOption) ||
    modality === 'messaging' ||
    isBHAdHoc
  );

  const shouldRenderCancelBooking = !!(therapist && selectedCancelBooking);
  const shouldRenderCheckInDone = !isTherapist;
  const shouldRenderSelectCredit =
    (!isFromCheckInWizard && !bookWithIntroSession) || hasSingleNonIntroCredit === false;
  const shouldHideBackArrow = !!(
    isError ||
    shouldShowBookingSuccess ||
    isTherapist ||
    (isFromCheckInWizard && !location.pathname.includes('cancellation-policy')) ||
    isInTherapistCRM ||
    (isOnboarding && !location.pathname.includes('cancellation-policy')) ||
    bookingIntroSession ||
    asyncOnboarding ||
    isBHAdHoc ||
    location.pathname.includes('pending-invoices/success')
  );

  const showMobileView = !!(isMobile || isInTherapistCRM);

  const pluralCopy = !!(
    ((isRecurringConfirmBooking || isRecurringCancelBooking) && isBatchMode) ||
    recurringAvailableTimeslots
  );

  const roomID = stateRoomID || Number(match.params.roomID);

  useEffect(() => {
    if (
      recurringAvailableTimeslots ||
      recurringConflictingTimeslots ||
      recurringRequestedTimeslots
    ) {
      // setWrapperTitle is used in therapist CRM, setTitleText is used in webviews for client web
      setWrapperTitle('Schedule recurring sessions');
    } else {
      setWrapperTitle('Schedule a session');
    }
  }, [
    setWrapperTitle,
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
  ]);

  useEffect(() => {
    if (pluralCopy && location.pathname.includes('booking-checkout')) {
      setTitleText('Confirm or Decline sessions');
    } else if (location.pathname.includes('booking-checkout') && selectedConfirmBooking) {
      setTitleText('Confirm session');
    } else if (
      pluralCopy &&
      (location.pathname.includes('decline-booking') ||
        location.pathname.includes('confirm-decline'))
    ) {
      setTitleText('Decline sessions');
    } else if (location.pathname.includes('confirm-booking/booking/') && !isJoin) {
      switch (selectedConfirmBooking?.status ?? selectedCancelBooking?.status) {
        case 'client-canceled':
        case 'therapist-canceled':
          setTitleText(`Decline session${pluralCopy ? 's' : ''}`);

          break;
        default:
          setTitleText(`${pluralCopy ? 'Confirm sessions' : 'Confirm session'}`);

          break;
      }
    } else if (schedulerMode === 'providerScheduled' && isJoin) {
      setTitleText('Join a live session');
    } else if (
      location.pathname.includes('decline-booking') ||
      location.pathname.includes('confirm-decline')
    ) {
      setTitleText('Decline a session');
    } else if (
      location.pathname.includes('cancel-booking') ||
      location.pathname.includes('confirm-cancel') ||
      location.pathname.includes('cancel-reason')
    ) {
      setTitleText('Cancel session');
    } else if (location.pathname.includes('add-to-calendar')) {
      setTitleText('Add to calendar');
    } else if (location.pathname.includes('booking-success') && modality === 'messaging') {
      setTitleText('Session is starting');
    } else if (location.pathname.includes('booking-success') && selectedConfirmBooking) {
      setTitleText(`Confirm session${pluralCopy ? 's' : ''}`);
    } else if (location.pathname.includes('book-and-activate/confirm-booking')) {
      setTitleText('Booking summary');
    } else if (location.pathname.includes('book-and-activate')) {
      setTitleText('');
    } else if (location.pathname.includes('pending-invoices') && isFromPendingInvoicesBanner) {
      setTitleText('Update payment method');
    } else {
      setTitleText(pluralCopy ? 'Book sessions' : 'Book a session');
    }
  }, [
    schedulerMode,
    isJoin,
    match.url,
    location.pathname,
    modality,
    isFromPendingInvoicesBanner,
    pluralCopy,
    selectedCancelBooking?.status,
    selectedConfirmBooking,
  ]);

  const ignoreCredits =
    location.pathname.includes('booking-checkout') && isRecurringConfirmBooking && isBatchMode;

  useEffect(() => {
    (async () => {
      if (roomID) {
        await dispatchGetVideoCreditOffers(roomID, ignoreCredits);
      }
    })();
  }, [roomID, stateRoomID, dispatchGetVideoCreditOffers, ignoreCredits]);

  const onStripeLinkError = (error: any) => {
    dispatchSetIsError(error);
  };

  const handleChooseCredit = useCallback(
    (selectedCredit: VideoCreditOffer) => {
      const bookingDuration = selectedCredit.creditMinutes;
      dispatchSetSelectedBookingDuration(bookingDuration);
      dispatchSetSelectedCreditOption(selectedCredit);
    },
    [dispatchSetSelectedBookingDuration, dispatchSetSelectedCreditOption]
  );

  useEffect(() => {
    if (bookWithIntroSession && creditOptions && !selectedTimeslot) {
      setBookingIntroSession(true);
      const introCreditOffer = creditOptions.find((credit) => credit.type === 'introduction');
      if (introCreditOffer && room) {
        handleChooseCredit(introCreditOffer);
        history.push(`${match.url}/select-timeslot`, { roomID: room.roomID });
      }
    }
  }, [
    creditOptions,
    bookWithIntroSession,
    history,
    match.url,
    room,
    handleChooseCredit,
    selectedTimeslot,
  ]);

  useEffect(() => {
    dispatchSetRoomAndTherapistInfo(room, therapist);
    const searchParams = new URLSearchParams(location.search);

    dispatchSetJoinData({
      isJoin: location.search.includes('isJoin=true'),
      videoCallID: Number(searchParams.get('videoCallID')) || undefined,
      isBHAdHoc: location.search.includes('isBHAdHoc=true'),
      adHocDuration: (Number(searchParams.get('adHocDuration')) as CreditMinutes) || undefined,
    });
  }, [dispatchSetRoomAndTherapistInfo, dispatchSetJoinData, room, therapist, location.search]);

  useEffect(() => {
    const source = new URLSearchParams(location.search).get('source');
    if (source === 'check-in-wizard' && !isTherapist && clientUserID && room) {
      const { roomID: clientRoomID, roomType, isVideoOnly } = room;
      if (!videoCredits || isB2B === undefined || (isFromCheckInWizard && !creditOptions)) {
        dispatchGetSubscriptionsAndCreditOptions(clientUserID, clientRoomID);
      } else if (creditOptions) {
        const filterIntroCredits = (credit) => credit.type !== 'introduction';
        const creditOptionsToCheck = creditOptions.filter(filterIntroCredits);
        const videoCreditsToCheck = videoCredits.filter(filterIntroCredits);
        const hasCreditsAvailable = creditOptionsToCheck.length;
        const hasSingleTypeOfCredit = creditOptionsToCheck.length === 1;
        setHasSingleNonIntroCredit(hasSingleTypeOfCredit);
        const isMessagingOnly = !isB2B && !videoCreditsToCheck.length;
        const isOnSubscriptionPlan = !!videoCredits.filter((credit) => credit.creditTotal !== null);
        if (
          !hasCreditsAvailable &&
          ((!isVideoOnly && !isMessagingOnly && roomType !== 'psychiatry_room') ||
            isOnSubscriptionPlan)
        ) {
          history.push(`${match.url}/checkin-done`);
        } else if (hasSingleTypeOfCredit) {
          dispatchSetSelectedCreditOption(creditOptionsToCheck[0]);
        }
      }
    }
  }, [
    clientUserID,
    creditOptions,
    dispatchSetSelectedCreditOption,
    history,
    isB2B,
    location.search,
    match.url,
    room,
    videoCredits,
    isTherapist,
    dispatchGetSubscriptionsAndCreditOptions,
    isFromCheckInWizard,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const therapistRatingQueryParam = queryParams.get('therapistRating');
    const source = queryParams.get('source');

    if (therapistRatingQueryParam) {
      setTherapistRating(Number(therapistRatingQueryParam));
    }
    if (source) {
      setSavedSource(source);
    }
  }, [location.search]);

  useEffect(() => {
    if (isTherapist) return;
    getPaymentDetails();
  }, [getPaymentDetails, isTherapist]);

  useEffect(() => {
    if (
      moment(selectedTimeslot?.start).isBefore(moment().add(CANCELLATION_WINDOW_HOURS, 'hours'))
    ) {
      setIsWithin24Hours(true);
    } else {
      setIsWithin24Hours(false);
    }
  }, [selectedTimeslot]);

  useEffect(() => {
    if (videoCall) {
      const videoCallState = {
        therapistUserID: therapist.id,
        roomID: room.roomID,
        therapistFirstName: videoCall.therapist.therapistFirstName,
        therapistLastName: videoCall.therapist.therapistLastName,
        startTime: videoCall.booking.startTime,
        creditMinutes: videoCall.booking.creditMinutes,
        videoCreditType: videoCall.booking.type,
        videoCallID: videoCall.videoCallID,
        modality: videoCall.modality,
        tokenExpiresAt: moment(videoCall.booking.startTime)
          .add(videoCall.booking.creditMinutes || 30, 'minute')
          .toISOString(),
      };
      if (!isBHAdHoc) {
        redirectToLiveSession(room.roomID, videoCallState);
      }
    } else {
      sessionStorage.removeItem(VIDEO_CALL_SESSION_STORAGE_KEY);
    }
  }, [videoCall, therapist, room, dispatchClientJoinLiveChat, isBHAdHoc]);

  useEffect(() => {
    if (clientJoinedLiveChat) closeModal({ navigateTo: 'videoCall' });
  }, [clientJoinedLiveChat, closeModal]);

  const handleBackClick = () => {
    const didClientSelectProvider = location.state?.selectedProvider;
    const isClientOnFirstScreen =
      location.pathname.includes('cancel-booking') ||
      location.pathname === match.url ||
      location.pathname === `/in-room-scheduling/room/${match.params.roomID}/pending-invoices`;
    const isTherapistOnDeclineBookingScreen =
      location.pathname ===
      `/in-room-scheduling/room/${match.params.roomID}/decline-booking/booking/${selectedCancelBooking?.id}`;

    if (didClientSelectProvider) {
      history.goBack();
    } else if (isClientOnFirstScreen) {
      closeModal();
    } else if (isTherapistOnDeclineBookingScreen) {
      history.replace(`/room/${match.params.roomID}/room-details`);
    } else {
      history.goBack();
    }
  };

  const handleClose = () => {
    const reactFrameService = ReactFrameService.instance();
    if (isOnboarding && !isCreateBookingActivationError) {
      if (getSkipOnboardingAllDone()) {
        fetchOnboarding().then(() => {
          removeSkipOnboardingAllDone();
          history.push(`/room/${roomID}`);
        });
      } else {
        history.push(`/room/${roomID}/onboarding/next`);
      }
    } else if (isOnboarding && isCreateBookingActivationError) {
      const selectTimeslotPath = selectedCreditOption ? `?creditID=${selectedCreditOption.id}` : '';
      resetCreateBookingActivation();
      if (bookingActivationError?.code === 'PAYMENT_ERROR') {
        history.push(`/room/${roomID}/onboarding/book-and-activate/update-payment`);
      } else {
        history.push(
          `/room/${roomID}/onboarding/book-and-activate/select-timeslot${selectTimeslotPath}`
        );
      }
    } else if (closePopupWhenDoneRef.current && reactFrameService.isInFrame()) {
      reactFrameService.closePopup();
    } else if (isInTherapistCRM) {
      history.push(`/room/${roomID}/lvs-tab`);
    } else {
      const MAX_CHECK_IN_RATING = 5;
      const shouldOpenAppReview = therapistRating === MAX_CHECK_IN_RATING;
      const closeMethod = reactFrameService.isInFrame() ? reactFrameService.closePopup : closeModal;
      isFromCheckInWizard
        ? closeMethod({
            navigateTo: 'room',
            metadata: {
              roomID,
              additionalMetaData: {
                actionType: shouldOpenAppReview ? 'openAppReview' : undefined,
              },
            },
          })
        : closeMethod({ navigateTo: 'room', metadata: { roomID } });
    }
  };

  const onSkipBookNextSession = () => {
    const baseURL = deleteLastElementInPath(match.url);
    history.push(`${baseURL}/${match.params.roomID}/checkin-done`);
  };

  const handleConfirmAsyncSession = ({
    isPurchase = false,
    creditCardToken,
  }: ConfirmAsyncSessionParams) => {
    if (isPurchase && !paymentDetails && !creditCardToken) {
      history.push(`${match.url}/payment-details`);
      return;
    }
    if (!isBookedMessagingSession) {
      if (therapist?.id && clientUserID) {
        trackBookingSession({
          userID: clientUserID,
          roomID: Number(match.params.roomID),
          providerID: therapist.id,
          sessionModality: 'messaging',
          planID: selectedCreditOption?.planID,
          sessionLength: null,
          copayFeatureFlag: copayAtTimeOfServiceActive,
          accountType: stateRoom?.accountType,
          businessLine: stateRoom ? getRoomBusinessLine(stateRoom) : undefined,
          bookSession0CopayFF: bookSession0CopayActive,
          copayAmount: isBH ? selectedCreditOption?.price : null,
        });
      }
      dispatchCreateAsyncSession({
        isPurchase,
        creditCardToken,
      });
    }
  };

  const handleConfirmAppointment = ({
    isReschedule = false,
    isPurchase = false,
    shouldRedeemCredit = false,
    creditCardToken,
    modality: appointmentModality,
    isRecurringBooking,
    isB2BTeen,
  }: ConfirmBookingOptions) => {
    if (!selectedTimeslot || !selectedBookingDuration || !selectedCreditOption || !stateRoom) {
      return;
    }

    const funnelName = getFunnelName({ isFromCheckInWizard, isFromPostLVSCheckInWizard });

    const { isVideoOnly } = stateRoom;
    const newBookingPayload: CreateBookingParams = {
      type: selectedCreditOption.type,
      start: new Date(selectedTimeslot.start).toISOString(),
      creditMinutes: selectedCreditOption.creditMinutes || 30,
      therapistUserID: therapist.id,
      isPurchase,
      isVideoOnly,
      withinAllowRefundHours: isWithin24Hours,
      planID: selectedCreditOption.planID,
      modality: appointmentModality,
      funnelName,
      ...(isTherapist ? { hasBreakAfterSession } : {}),
    };
    if (schedulerMode === 'providerScheduled' && selectedConfirmBooking && !isReschedule) {
      dispatchClientConfirmBooking({
        roomID: Number(match.params.roomID),
        booking: selectedConfirmBooking,
        isPurchase,
        creditCardToken,
        confirmBatch: !!isRecurringBooking,
        isB2BTeen,
      });
    } else if (isReschedule && selectedCancelBooking) {
      const cancelPayload: CancelBookingParams = {
        shouldRedeemCredit,
        reason: cancelReason || 'client_cancel_default_reason',
      };
      dispatchRescheduleBooking({
        bookingID: selectedCancelBooking.id,
        cancelData: cancelPayload,
        newBookingData: newBookingPayload,
        isPurchase,
        rescheduleBatch: false,
        isTherapist: !!isTherapist,
      });
    } else {
      dispatchCreateBooking(newBookingPayload, {
        creditCardToken,
        planID: selectedCreditOption.planID!,
        couponCode: undefined,
      });
      if (!isTherapist && clientUserID) {
        trackBookingSession({
          userID: clientUserID,
          roomID: Number(match.params.roomID),
          providerID: therapist.id,
          sessionModality: appointmentModality,
          planID: selectedCreditOption.planID,
          sessionLength: selectedCreditOption.creditMinutes || 30,
          copayFeatureFlag: copayAtTimeOfServiceActive,
          accountType: stateRoom.accountType,
          businessLine: getRoomBusinessLine(stateRoom),
          bookSession0CopayFF: bookSession0CopayActive,
          copayAmount: isBH ? selectedCreditOption.price : null,
        });
      }
    }
  };

  const handleCreateRecurringBooking = () => {
    if (
      !selectedTimeslot ||
      !selectedBookingDuration ||
      !selectedCreditOption ||
      !stateRoom ||
      !recurringAvailableTimeslots
    ) {
      return;
    }

    const funnelName = getFunnelName({ isFromCheckInWizard, isFromPostLVSCheckInWizard });

    const newBookingPayload: CreateRecurringBookingParams = {
      creditMinutes: selectedCreditOption.creditMinutes || 30,
      funnelName,
      hasBreakAfterSession: !!hasBreakAfterSession,
      modality,
      therapistUserID: therapist.id,
      type: selectedCreditOption.type,
      repeatingSessions,
      repeatingPeriod,
      startDates: recurringAvailableTimeslots.map((availableTimeslot) => availableTimeslot.start),
    };

    dispatchCreateRecurringBooking(newBookingPayload);
  };

  const handleConfirmBHAdHoc = ({ isPurchase }: { isPurchase?: boolean }) => {
    if (videoCallID && videoCall && adHocDuration) {
      if (clientUserID && therapist?.id && adHocDuration) {
        if (isPurchase) {
          trackPayingAdHocSession({
            userID: clientUserID,
            roomID: room.roomID,
            sessionModality: videoCall.modality,
            providerID: therapist.id,
            sessionLength: adHocDuration,
            planID: planInfo?.id,
          });
        }
        trackJoinedAdHocSession({
          userID: clientUserID,
          roomID: room.roomID,
          sessionModality: videoCall.modality,
          providerID: therapist.id,
          sessionLength: videoCall.booking.creditMinutes,
          planID: planInfo?.id,
        });
      }
      if (ReactFrameService.instance().isInFrame()) {
        closeModal({
          navigateTo: 'liveCall',
          metadata: {
            roomID: room.roomID,
            modality: videoCall.modality,
            creditMinutes: adHocDuration,
            videoCallID,
          },
        });
      } else {
        const videoCallState = {
          therapistUserID: therapist.id,
          roomID: room.roomID,
          therapistFirstName: videoCall.therapist.therapistFirstName,
          therapistLastName: videoCall.therapist.therapistLastName,
          startTime: videoCall.booking.startTime,
          creditMinutes: adHocDuration,
          videoCreditType: videoCall.booking.type,
          videoCallID,
          modality: videoCall.modality,
          tokenExpiresAt: moment(videoCall.booking.startTime)
            .add(adHocDuration, 'minute')
            .toISOString(),
        };

        redirectToLiveSession(roomID, videoCallState);
      }
    }
  };

  const handleReviewCancellationPolicy = () => {
    history.push(`${match.url}/cancellation-policy`);
  };

  const getCancellationVariant = () => {
    const selectedModality = preRegistrationSelectedModality || modality;
    if (selectedModality === 'messaging') {
      return 'async';
    }
    if (room?.accountType === 'bh' && room?.isSessionBased) {
      return 'liveWithFee';
    }
    return 'liveWithoutFee';
  };

  const { skipNavs } = useA11y(location.state?.from);
  const Header = useCallback(
    (props: ComponentProps<typeof ClientHeader>) =>
      isInTherapistCRM || hideHeader ? (
        <View flex={1}>{props.children}</View>
      ) : (
        <ClientHeader {...props} />
      ),
    [isInTherapistCRM, hideHeader]
  );

  const { selectedBookingID, isReschedule } = location.state || {};

  useEffect(() => {
    // setup reschedule booking when redirected with state
    if (isReschedule && selectedBookingID) {
      dispatchGetSelectedBooking(roomID, selectedBookingID, true);
    }
  }, [selectedBookingID, isReschedule, dispatchGetSelectedBooking, roomID]);

  useEffect(() => {
    if (
      (!selectedTimeslot || !selectedCreditOption || !therapist) &&
      location.pathname === `/in-room-scheduling/room/${match.params.roomID}/confirm-booking` &&
      modality !== 'messaging' &&
      !isBHAdHoc &&
      !isPaymentLoading &&
      !isLoading &&
      !isError &&
      !isCreateBookingActivationError &&
      !isClientConfirmError &&
      !isClientRescheduleError &&
      (!location.search || location.search.length === 0)
    ) {
      history.push(match.url);
    }
  }, [
    adHocDuration,
    history,
    isBHAdHoc,
    isClientConfirmError,
    isClientRescheduleError,
    isCreateBookingActivationError,
    isError,
    isJoin,
    isLoading,
    isPaymentLoading,
    location.pathname,
    location.search,
    match.params.roomID,
    match.url,
    modality,
    roomID,
    selectedCreditOption,
    selectedTimeslot,
    therapist,
    updatePaymentDetailsError,
  ]);

  const handleConfirm = (() => {
    if (modality === 'messaging') {
      return handleConfirmAsyncSession;
    }

    if (isBHAdHoc) {
      return handleConfirmBHAdHoc;
    }

    if (recurringAvailableTimeslots) {
      return handleCreateRecurringBooking;
    }

    return handleConfirmAppointment;
  })();

  const startMbaAnimation: () => void = useCallback(() => {
    history.push(`${match.url}/confirm-booking/finding-match`);
  }, [history, match.url]);

  const startBooking: () => void = useCallback(() => {
    if (
      (preRegistrationSelectedModality || modality) &&
      selectedCreditOption?.creditMinutes &&
      selectedCreditOption?.type &&
      selectedTimeslot?.start &&
      selectedTimeslot?.therapists?.length
    ) {
      setSkipOnboardingAllDone();
      createBookingActivation({
        roomID: Number(roomID),
        modality: preRegistrationSelectedModality || modality,
        therapistUserID: selectedTimeslot?.therapists[0],
        creditMinutes: selectedCreditOption!.creditMinutes,
        type: selectedCreditOption!.type,
        start: selectedTimeslot.start,
        funnelName: 'Based on Availability',
      });
    }
  }, [
    createBookingActivation,
    modality,
    preRegistrationSelectedModality,
    roomID,
    selectedCreditOption,
    selectedTimeslot?.start,
    selectedTimeslot?.therapists,
  ]);

  const isSelectTimeslotPath = location.pathname.includes('select-timeslot');
  const isBookingCheckoutPath = location.pathname.includes('booking-checkout');

  const { postLvsCheckInNoBookings } = useFlags();

  const topPadding = useMemo(() => {
    if (!postLvsCheckInNoBookings) return 16;

    if (!isMobile) return 56; // All the same on desktop
    if (isSelectTimeslotPath) return 22; // From arrow to header
    if (isBookingCheckoutPath) return 20;
    return 32;
  }, [isSelectTimeslotPath, isBookingCheckoutPath, isMobile, postLvsCheckInNoBookings]);

  return (
    <ThemedFlagsProvider versionKey="inRoomScheduling">
      <Header
        isMobile={showMobileView}
        skipNavs={skipNavs}
        titleText={titleText}
        isChatHidden={isChatHidden}
        handleBackClick={handleBackClick}
        handleOnRoomPanelClosePress={isCloseable ? handleClose : undefined}
        shouldHideBackArrow={shouldHideBackArrow}
      >
        <StickyDrawerParentContainer drawerWidthFollowsContainer={!isMobile}>
          <View flex={1} style={{ padding: '0 20px', paddingBottom: 69 }} align="center">
            <View
              flex={1}
              style={{
                maxWidth: isMobile || !postLvsCheckInNoBookings ? 375 : 430,
                paddingTop: topPadding,
              }}
            >
              {stateRoom && (
                <Switch>
                  <Route
                    path={`${match.path}/update-payment`}
                    render={() => (
                      <div>
                        <UpdatePaymentDetails
                          email={email}
                          updatePaymentDetails={updatePaymentDetails}
                          isLoading={isPaymentLoading}
                          error={updatePaymentDetailsError}
                          clearError={dispatchClearError}
                          onSuccess={() => {
                            history.push(`/room/${room.roomID}/onboarding/book-and-activate`);
                          }}
                          isLinkLoaded={isLinkLoaded}
                          onLoadStripeLink={accountDetailsOnLoadStripeLink}
                          dispatchPaymentMethodError={dispatchPaymentMethodError}
                          useStripeLink={useStripeLink}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={`${match.path}/select-timeslot`}
                    render={() => (
                      <SelectTimeslot
                        clientUserID={clientUserID}
                        isMobile={showMobileView}
                        therapist={therapist}
                        isTherapist={!!isTherapist}
                        flowVariant={flowVariant}
                        isFromCheckInWizard={isFromCheckInWizard}
                        isFromPostLVSCheckInWizard={isFromPostLVSCheckInWizard}
                        isCreateBookingActivationError={isCreateBookingActivationError}
                        onClosePress={handleClose}
                        bookWithIntroSession={bookingIntroSession}
                        onSkipBookNextSession={onSkipBookNextSession}
                        handleChooseCredit={handleChooseCredit}
                        dismissOnboarding={dismissOnboarding}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/recurring-booking-conflicts`}
                    exact
                    render={() => <RecurringBookingConflicts setWrapperTitle={setWrapperTitle} />}
                  />
                  {shouldRenderConfirmBooking && (
                    <Route
                      path={`${match.path}/confirm-booking`}
                      exact
                      render={() => (
                        <ConfirmBookingContainer
                          bookWithIntroSession={bookingIntroSession}
                          handleConfirmAppointment={handleConfirm}
                          handleOnBoardingBookSession={startMbaAnimation}
                          therapist={therapist}
                          paymentDetails={paymentDetails}
                          isPaymentLoading={isPaymentLoading}
                          isTherapist={!!isTherapist}
                          clientDisplayName={clientDisplayName}
                          preRegistrationSelectedModality={preRegistrationSelectedModality}
                          flowVariant={flowVariant}
                          onClosePress={handleClose}
                          handleReviewCancellationPolicy={handleReviewCancellationPolicy}
                          isCreateBookingActivationSuccess={isCreateBookingActivationSuccess}
                          isCreateBookingActivationError={isCreateBookingActivationError}
                          isCreateBookingActivationLoading={isCreateBookingActivationLoading}
                          requestData={bookingActivationData}
                          requestError={bookingActivationError}
                        />
                      )}
                    />
                  )}
                  <Route
                    path={`${match.path}/confirm-booking/finding-match`}
                    exact
                    render={() => (
                      <MbaMatchInProgress
                        startBooking={startBooking}
                        handleAnimationAndBookingFinished={handleClose}
                        isBookingSuccess={isCreateBookingActivationSuccess}
                      />
                    )}
                  />
                  {shouldRenderCheckInDone && (
                    <Route
                      path={`${match.path}/checkin-done`}
                      render={() => (
                        <CheckInDone
                          therapistFirstName={therapist.firstName}
                          therapistRating={therapistRating}
                          roomID={Number(match.params.roomID)}
                        />
                      )}
                    />
                  )}
                  <Route
                    path={`${match.path}/payment-details`}
                    render={() => (
                      <PaymentDetails
                        handleConfirmAppointment={handleConfirm}
                        onClosePress={handleClose}
                        useStripeLink={useStripeLink}
                        onStripeLinkError={onStripeLinkError}
                        onLoadStripeLink={onLoadStripeLink}
                        bookingSuccessRoute="confirm-booking"
                      />
                    )}
                  />
                  {shouldRenderCancelBooking && (
                    <Route
                      path={`${match.path}/confirm-cancel`}
                      render={() => (
                        <ConfirmCancel onClosePress={handleClose} therapist={therapist} />
                      )}
                    />
                  )}
                  {shouldRenderCancelBooking && (
                    <Route
                      path={`${match.path}/cancel-reason`}
                      render={() => <CancelReason therapist={therapist} />}
                    />
                  )}
                  {appConfigs.featureFlags.addToCalendar && !isiOS && !isAndroid && (
                    <Route
                      path={`${match.path}/add-to-calendar`}
                      render={() => <AddToCalendarScreen therapist={therapist} />}
                    />
                  )}
                  <Route
                    path={`${match.path}/cancellation-policy`}
                    render={() => <CancellationPolicy variant={getCancellationVariant()} />}
                  />
                  <Route
                    path={`${match.path}/cancel-booking/booking/:bookingID`}
                    render={() => (
                      <CancelBookingContainer
                        therapist={therapist}
                        onClosePress={handleClose}
                        isCancel
                        isTherapist={!!isTherapist}
                        clientUserID={clientUserID}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/confirm-booking/booking/:bookingID`}
                    render={() => (
                      <ConfirmBookingContainer
                        handleConfirmAppointment={
                          recurringAvailableTimeslots
                            ? handleCreateRecurringBooking
                            : handleConfirmAppointment
                        }
                        handleOnBoardingBookSession={startBooking}
                        therapist={therapist}
                        paymentDetails={paymentDetails}
                        isPaymentLoading={isPaymentLoading}
                        isTherapist={!!isTherapist}
                        clientDisplayName={clientDisplayName}
                        onClosePress={handleClose}
                        handleReviewCancellationPolicy={handleReviewCancellationPolicy}
                        isCreateBookingActivationSuccess={isCreateBookingActivationSuccess}
                        isCreateBookingActivationError={isCreateBookingActivationError}
                        isCreateBookingActivationLoading={isCreateBookingActivationLoading}
                        clientUserID={clientUserID}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/decline-booking/booking/:bookingID`}
                    render={() => (
                      <CancelBookingContainer
                        therapist={therapist}
                        onClosePress={handleClose}
                        isCancel={false}
                        isTherapist={!!isTherapist}
                        clientUserID={clientUserID}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/booking-checkout`}
                    render={() => (
                      <BookingCheckout
                        handleConfirmAppointment={handleConfirm}
                        therapist={therapist}
                        paymentDetails={paymentDetails}
                        isTherapist={!!isTherapist}
                        onClosePress={handleClose}
                        isFromCheckInWizard={isFromCheckInWizard}
                        isFromPostLVSCheckInWizard={isFromPostLVSCheckInWizard}
                        handleReviewCancellationPolicy={handleReviewCancellationPolicy}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/booking-success`}
                    render={() => (
                      <BookingConfirmationSuccess
                        therapist={therapist}
                        isTherapist={!!isTherapist}
                        handleOnClosePress={handleClose}
                        handleOnCTAPress={() => {
                          if (isFromPostLVSCheckInWizard) {
                            history.push(
                              `/check-in/room/${roomID}/source/post-lvs-check-in/check-in-source/lvs/video-call/${videoCallID}?bookingCompleted=true`
                            );
                          } else {
                            handleClose();
                          }
                        }}
                        isWithin24Hours={isWithin24Hours}
                        hasBreakAfterSession={hasBreakAfterSession}
                        isInTherapistCRM={!!isInTherapistCRM}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/confirm-decline`}
                    render={() => <ConfirmDecline onClosePress={handleClose} plural={pluralCopy} />}
                  />
                  <Route
                    path={`${match.path}/select-duration`}
                    render={() => (
                      <SelectCredit
                        isTherapist={!!isTherapist}
                        isFromPostLVSCheckInWizard={isFromPostLVSCheckInWizard}
                        handleChooseCredit={handleChooseCredit}
                        onClose={handleClose}
                        isFromCheckInWizard={isFromCheckInWizard}
                        shouldRenderSelectCredit={shouldRenderSelectCredit}
                        onSkipBookNextSession={onSkipBookNextSession}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/messaging-session`}
                    render={() => <MessagingInformationFlow paymentDetails={paymentDetails} />}
                  />
                  <Route
                    path={`${match.path}/ineligible-member`}
                    render={() => (
                      <IneligibleMembers
                        therapist={therapist}
                        isPermanentIneligible={isPermanentIneligible}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={match.path}
                    render={() => (
                      <SelectModality
                        isTherapist={!!isTherapist}
                        onSkipBookNextSession={onSkipBookNextSession}
                        isFromCheckInWizard={isFromCheckInWizard}
                        isFromPostLVSCheckInWizard={isFromPostLVSCheckInWizard}
                        isOnboarding={isOnboarding}
                        handleChooseModality={dispatchModalityType}
                        onClose={handleClose}
                        roomID={room.roomID}
                        bookingIntroSession={bookingIntroSession}
                        roomType={room.roomType || stateRoom.roomType}
                        shouldSkipModalitySelection={shouldSkipModalitySelection}
                        flowVariant={flowVariant}
                        clientUserID={clientUserID}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/book-later`}
                    render={() => (
                      <BookLaterModal
                        onBookLaterPress={() => {
                          dismissOnboarding();
                          history.push(
                            `/room/${room.roomID}/onboarding/book-and-activate/match-queue-confirmation`
                          );
                        }}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}/match-queue-confirmation`}
                    render={() => (
                      <OnboardingMatchQueueConfirmation
                        onSuccess={() => {
                          fetchOnboarding().then(() => {
                            const roomAction: ClosePopupAction = {
                              navigateTo: 'room',
                              metadata: { roomID: room.roomID },
                            };
                            const homeAction: ClosePopupAction = {
                              navigateTo: 'home',
                              metadata: { path: '/' },
                            };
                            closeModal(
                              !useNewNav || shouldRedirectToRoom ? roomAction : homeAction
                            );
                          });
                        }}
                      />
                    )}
                  />
                  {pendingInvoicesChargeTypes &&
                    pendingInvoicesChargeTypes.length > 0 &&
                    !isTherapist &&
                    clientUserID && (
                      <>
                        <Route
                          exact
                          path={`${match.path}/pending-invoices`}
                          render={() => (
                            <PendingInvoicesList
                              clientUserID={clientUserID}
                              therapist={therapist}
                            />
                          )}
                        />
                        <Route
                          path={`${match.path}/pending-invoices/pay`}
                          render={() => (
                            <PendingInvoicesForm
                              clientUserID={clientUserID}
                              onLoadStripeLink={onLoadStripeLink}
                              onStripeLinkError={onStripeLinkError}
                              therapist={therapist}
                            />
                          )}
                        />
                        <Route
                          path={`${match.path}/pending-invoices/success`}
                          render={() => (
                            <PaymentWizardSuccess
                              clientUserID={clientUserID}
                              therapist={therapist}
                            />
                          )}
                        />
                      </>
                    )}
                  <Redirect to={{ pathname: match.path }} />
                </Switch>
              )}
            </View>
          </View>
        </StickyDrawerParentContainer>
      </Header>
    </ThemedFlagsProvider>
  );
};

export default InRoomSchedulingContainer;
