import { FunctionComponent, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Spinner, TouchableView } from '@talkspace/react-toolkit';
import { useSharedChatState } from 'chat/hooks/sharedChatContext';
import styled from '../../../core/styled';
import { SLAStatus } from '../../reducers/SLAReducer';
import Resolved from '../SLAModalTypes/Resolved';
import Ready from '../SLAModalTypes/Ready';
import Missed3Weeks from '../SLAModalTypes/Missed3Weeks';
import Missed2Weeks from '../SLAModalTypes/Missed2Weeks';
import Missed1Week from '../SLAModalTypes/Missed1Week';
import Patience from '../SLAModalTypes/Patience';
import CouponReceived from '../SLAModalTypes/CouponReceived';
import NewTherapist from '../SLAModalTypes/NewTherapist';
import Error from '../SLAModalTypes/Error';
import ChatModal from '../ChatModal';

interface SLAModalProps {
  onClosePress: () => void;
  status: SLAStatus;
  eligibleForCoupon: boolean;
  missesCurrentWeek: number;
  roomID: number;
  isLoading: boolean;
  isError: boolean;
  nextReplyTime: string;
  createSLA: (roomID: number, successCallback?: Function) => void;
  getNextReplyTime: (roomID: number) => void;
  applyCoupon: (roomID: number, successCallback?: Function) => void;
  suggestTherapists: (roomID: number, successCallback?: Function) => void;
  setModalTitle?: Dispatch<SetStateAction<string>>;
}

const ContentWrapper = styled(TouchableView)(({ theme: { window } }) => {
  return {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 20,
    paddingLeft: window.isMobile ? 20 : 70,
    paddingRight: window.isMobile ? 20 : 70,
  };
});

const SLAModalContent: FunctionComponent<SLAModalProps> = ({
  onClosePress,
  status,
  eligibleForCoupon,
  missesCurrentWeek,
  roomID,
  isLoading,
  isError,
  nextReplyTime,
  createSLA,
  getNextReplyTime,
  applyCoupon,
  suggestTherapists,
  setModalTitle,
}) => {
  const [isAskToReplyPressed, setAskToReplyPressed] = useState(false);
  const [shouldShowPatienceModal, setShouldShowPatienceModal] = useState(false);
  const [shouldShowCouponModal, setShouldShowCouponModal] = useState(false);
  const [shouldShowNewTherapistModal, setShouldShowNewTherapistModal] = useState(false);
  const { therapistFirstName = '' } = useSharedChatState();

  useEffect(() => {
    if (status === 'missed') {
      getNextReplyTime(roomID);
    }
  }, [status, getNextReplyTime, roomID]);

  const onSendPress = () => {
    createSLA(roomID, onClosePress);
  };

  const onAskReplyPress = () => {
    setAskToReplyPressed(true);
    createSLA(roomID, () => setShouldShowPatienceModal(true));
  };

  const onCouponPress = () => {
    applyCoupon(roomID, () => setShouldShowCouponModal(true));
  };

  const onContinuePress = () => {
    suggestTherapists(roomID, onClosePress);
  };

  if (isLoading) return <Spinner isLoading />;

  if (isError) {
    const header = 'Something went wrong';
    if (setModalTitle) setModalTitle(header);
    return <Error onClosePress={onClosePress} header={header} />;
  }

  if (shouldShowPatienceModal) {
    const header = 'Thanks for your patience';
    if (setModalTitle) setModalTitle(header);
    return (
      <Patience
        header={header}
        therapistFirstName={isAskToReplyPressed ? therapistFirstName : undefined}
        onClosePress={onClosePress}
      />
    );
  }

  if (shouldShowNewTherapistModal) {
    const header = "Let's find you a new provider!";
    if (setModalTitle) setModalTitle(header);
    return <NewTherapist onContinuePress={onContinuePress} header={header} />;
  }

  if (shouldShowCouponModal) {
    const header = "You've got it!";
    if (setModalTitle) setModalTitle(header);
    return (
      <CouponReceived
        header={header}
        therapistFirstName={therapistFirstName}
        nextReplyTime={nextReplyTime}
        onAskReplyPress={onAskReplyPress}
        onSwitchTherapistPress={() => setShouldShowNewTherapistModal(true)}
      />
    );
  }

  let header;
  switch (status) {
    case 'resolved':
    case 'none':
      header = 'When to use this button';
      if (setModalTitle) setModalTitle(header);
      return <Resolved onGotItPress={onClosePress} header={header} />;
    case 'ready':
      header = `Ask for ${therapistFirstName}'s response`;
      if (setModalTitle) setModalTitle(header);
      return (
        <Ready
          header={header}
          therapistFirstName={therapistFirstName}
          onSendPress={onSendPress}
          onCancelPress={onClosePress}
        />
      );
    case 'missed':
      if (eligibleForCoupon) {
        header = "We're sorry!";
        if (setModalTitle) setModalTitle(header);
        return (
          <Missed3Weeks
            header={header}
            therapistFirstName={therapistFirstName}
            onCouponPress={onCouponPress}
            onCancelPress={() => setShouldShowPatienceModal(true)}
          />
        );
      }
      if (missesCurrentWeek >= 2) {
        header = "We're sorry!";
        if (setModalTitle) setModalTitle(header);
        return (
          <Missed2Weeks
            header={header}
            therapistFirstName={therapistFirstName}
            nextReplyTime={nextReplyTime}
            onAskReplyPress={onAskReplyPress}
            onSwitchTherapistPress={() => setShouldShowNewTherapistModal(true)}
            onCancelPress={() => setShouldShowPatienceModal(true)}
          />
        );
      }
      header = `${therapistFirstName} missed their expected response time. Please let us know how you’d like to continue.`;
      if (setModalTitle) setModalTitle(header);
      return (
        <Missed1Week
          header={header}
          therapistFirstName={therapistFirstName}
          nextReplyTime={nextReplyTime}
          onAskReplyPress={onAskReplyPress}
          onCancelPress={() => setShouldShowPatienceModal(true)}
        />
      );
    default:
      return null;
  }
};

const SLAModal: FunctionComponent<SLAModalProps> = ({ onClosePress, ...otherProps }) => {
  const [modalTitle, setModalTitle] = useState('');
  // ContentWrapper should not be recognized by SR as button or be in the tab order
  return (
    <ChatModal onBackdropPress={onClosePress} isVisible titleText={modalTitle}>
      <ContentWrapper tabIndex={-1} elementType="div">
        <SLAModalContent
          onClosePress={onClosePress}
          setModalTitle={setModalTitle}
          {...otherProps}
        />
      </ContentWrapper>
    </ChatModal>
  );
};

export default SLAModal;
