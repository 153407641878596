import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M10 18.3965C12.1217 18.3965 14.1566 17.5536 15.6569 16.0533C17.1571 14.553 18 12.5182 18 10.3965C18 8.27475 17.1571 6.23992 15.6569 4.73963C14.1566 3.23934 12.1217 2.39648 10 2.39648C7.87827 2.39648 5.84344 3.23934 4.34315 4.73963C2.84285 6.23992 2 8.27475 2 10.3965C2 12.5182 2.84285 14.553 4.34315 16.0533C5.84344 17.5536 7.87827 18.3965 10 18.3965ZM7.30625 7.56211C7.55312 6.86523 8.21563 6.39648 8.95625 6.39648H10.7781C11.8687 6.39648 12.75 7.28086 12.75 8.36836C12.75 9.07461 12.3719 9.72773 11.7594 10.0809L10.75 10.659C10.7437 11.0652 10.4094 11.3965 10 11.3965C9.58437 11.3965 9.25 11.0621 9.25 10.6465V10.2246C9.25 9.95586 9.39375 9.70898 9.62813 9.57461L11.0125 8.78086C11.1594 8.69648 11.25 8.54023 11.25 8.37148C11.25 8.10898 11.0375 7.89961 10.7781 7.89961H8.95625C8.85 7.89961 8.75625 7.96523 8.72188 8.06523L8.70937 8.10273C8.57188 8.49336 8.14062 8.69648 7.75313 8.55898C7.36563 8.42148 7.15938 7.99023 7.29688 7.60273L7.30937 7.56523L7.30625 7.56211ZM9 13.3965C9 13.1313 9.10536 12.8769 9.29289 12.6894C9.48043 12.5018 9.73478 12.3965 10 12.3965C10.2652 12.3965 10.5196 12.5018 10.7071 12.6894C10.8946 12.8769 11 13.1313 11 13.3965C11 13.6617 10.8946 13.9161 10.7071 14.1036C10.5196 14.2911 10.2652 14.3965 10 14.3965C9.73478 14.3965 9.48043 14.2911 9.29289 14.1036C9.10536 13.9161 9 13.6617 9 13.3965Z';
const majorPath =
  'M20.125 12.793C20.125 10.6381 19.269 8.57146 17.7452 7.04773C16.2215 5.52399 14.1549 4.66797 12 4.66797C9.84512 4.66797 7.77849 5.52399 6.25476 7.04773C4.73102 8.57146 3.875 10.6381 3.875 12.793C3.875 14.9479 4.73102 17.0145 6.25476 18.5382C7.77849 20.0619 9.84512 20.918 12 20.918C14.1549 20.918 16.2215 20.0619 17.7452 18.5382C19.269 17.0145 20.125 14.9479 20.125 12.793ZM2 12.793C2 10.1408 3.05357 7.59726 4.92893 5.7219C6.8043 3.84654 9.34784 2.79297 12 2.79297C14.6522 2.79297 17.1957 3.84654 19.0711 5.7219C20.9464 7.59726 22 10.1408 22 12.793C22 15.4451 20.9464 17.9887 19.0711 19.864C17.1957 21.7394 14.6522 22.793 12 22.793C9.34784 22.793 6.8043 21.7394 4.92893 19.864C3.05357 17.9887 2 15.4451 2 12.793ZM8.63281 9.25C8.94141 8.37891 9.76953 7.79297 10.6953 7.79297H12.9727C14.3359 7.79297 15.4375 8.89844 15.4375 10.2578C15.4375 11.1406 14.9648 11.957 14.1992 12.3984L12.9375 13.1211C12.9297 13.6289 12.5117 14.043 12 14.043C11.4805 14.043 11.0625 13.625 11.0625 13.1055V12.5781C11.0625 12.2422 11.2422 11.9336 11.5352 11.7656L13.2656 10.7734C13.4492 10.668 13.5625 10.4727 13.5625 10.2617C13.5625 9.93359 13.2969 9.67188 12.9727 9.67188H10.6953C10.5625 9.67188 10.4453 9.75391 10.4023 9.87891L10.3867 9.92578C10.2148 10.4141 9.67578 10.668 9.19141 10.4961C8.70703 10.3242 8.44922 9.78516 8.62109 9.30078L8.63672 9.25391L8.63281 9.25ZM10.75 16.543C10.75 16.2114 10.8817 15.8935 11.1161 15.6591C11.3505 15.4247 11.6685 15.293 12 15.293C12.3315 15.293 12.6495 15.4247 12.8839 15.6591C13.1183 15.8935 13.25 16.2114 13.25 16.543C13.25 16.8745 13.1183 17.1924 12.8839 17.4269C12.6495 17.6613 12.3315 17.793 12 17.793C11.6685 17.793 11.3505 17.6613 11.1161 17.4269C10.8817 17.1924 10.75 16.8745 10.75 16.543Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const CircleQuestion = withDSIconMaker(pathConfig, 'questions inside circle icon');

export default CircleQuestion;
