import { styled, TSLogo, View, useWindowWidth } from '@talkspace/react-toolkit';
import { useEffect } from 'react';
import { useHistory } from 'core/routerLib';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useClientAuthState } from '../../hooks/clientAuthContext';

const Wrapper = styled(View)(({ theme: { colors } }) => {
  return {
    align: 'center',
    backgroundColor: colors.white,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
    overflowY: 'auto',
  };
});

const NavBar = styled(View)(({ theme: { colors } }) => {
  return {
    height: 66,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: colors.white,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.04)',
  };
});

const TwoFactorAuthenticationWrapper = ({ children }) => {
  const { isMobile } = useWindowWidth();
  const history = useHistory();
  const { isError, enabled2faSuccess, twoFAStatus, otpToken } = useClientAuthState();
  const { brandRefreshChanges } = useFlags();

  useEffect(() => {
    if (isError || enabled2faSuccess) {
      history.push('/2fa/close');
    }
  }, [isError, enabled2faSuccess, history]);

  useEffect(() => {
    if (!twoFAStatus && !otpToken) {
      history.push('/login');
    }
  }, [twoFAStatus, otpToken, history]);

  return (
    <Wrapper>
      <NavBar>
        <View style={{ width: '100%' }}>
          <TSLogo
            style={
              isMobile ? { alignSelf: 'center' } : { alignSelf: 'flex-start', paddingLeft: 20 }
            }
            variant={brandRefreshChanges ? '2024' : 'old'}
          />
        </View>
      </NavBar>
      <View flex={1} align="center">
        {children}
      </View>
    </Wrapper>
  );
};

export default TwoFactorAuthenticationWrapper;
