import { VoidFunctionComponent } from 'react';
import moment from 'moment';
import {
  View,
  Button,
  SmallCalendar,
  Standard,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

import { VideoCreditOffer } from 'ts-frontend/types';
import styled from '@/core/styled';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { ClosePopupAction } from '@/auth/reactFrame/ReactFrameTypes';

const reactFrameService = ReactFrameService.instance();

const IconWrapper = styled(View)({ width: 24 });

const CalendarButton = styled(Button)(({ theme: { colorRoles, colors }, style }) => {
  return {
    flex: 1,
    marginRight: 8,
    backgroundColor: colorRoles.system.actionSecondaryDefault,
    color: colorRoles.system.actionSecondaryText,
    border: `1px solid ${colors.green}`,
    ...style,
  };
});

interface SelectedBooking {
  startTime: string;
  creditMinutes: number;
}

interface AddToCalendarParams {
  bookingID?: string;
  selectedBooking?: SelectedBooking;
  videoCreditType?: VideoCreditOffer['type'];
  therapistName?: string;
  roomId?: number;
}

const getEventDates = (selectedBooking?: SelectedBooking) => {
  const startDate = moment(selectedBooking?.startTime);
  const endDate = moment(selectedBooking?.startTime).add(selectedBooking?.creditMinutes, 'm');
  return { startDate, endDate };
};

const AddToCalendar: VoidFunctionComponent<AddToCalendarParams> = ({
  bookingID,
  selectedBooking,
  videoCreditType,
  therapistName,
  roomId,
}) => {
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();

  if (!selectedBooking) {
    return null;
  }

  const therapist = therapistName;
  const title = `Talkspace ${videoCreditType || 'live'} session`;
  const link = `https://app.talkspace.com/room/${roomId}`;
  const body = `To join your therapy session with ${therapist}, click <a href=${link}>here</a>.<br /><br />Helpful notes:<br /><br />- Please make sure you are in a quiet place with good internet connection.<br />- Please enable your mic and camera.<br /><br />If you need to cancel or reschedule your session, click here. Please note cancellations within 24 hours of session start time or no-shows will result in a fee or lost credit.`;

  const closePopup = (url: string) => {
    const closingPayload: ClosePopupAction = {
      navigateTo: 'browser',
      metadata: { path: url },
    };
    reactFrameService.closePopup(closingPayload);
  };

  const handleGooglePress = () => {
    const { startDate, endDate } = getEventDates(selectedBooking);
    const dates = `${startDate.format('YYYYMMDDTHHmmSSZ')}/${endDate.format('YYYYMMDDTHHmmSSZ')}`;
    const url = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${dates}&text=${title}&details=${body}&location=${link}`;

    if (reactFrameService.isInFrame()) {
      closePopup(url);
    } else {
      window.open(url);
    }
  };

  const handleApplePress = () => {
    const { startDate, endDate } = getEventDates(selectedBooking);
    const url = encodeURI(
      `data:text/calendar;charset=utf8;filename=hi.ics,${[
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'PRODID:www.talkspace.com',
        'BEGIN:VEVENT',
        'CLASS:PUBLIC',
        `DTSTART;VALUE=DATE-TIME:${startDate.format('YYYYMMDDTHHmmSS')}`,
        `DTEND;VALUE=DATE-TIME:${endDate.format('YYYYMMDDTHHmmSS')}`,
        `SUMMARY:${title}`,
        'TRANSP:TRANSPARENT',
        `DESCRIPTION:To join your therapy session with ${therapist}, click url in this event.  Here's some helpful notes.  Please make sure you are in a quiet place with good internet connection.  Please enable your mic and camera.  If you need to cancel or reschedule your session, you can. Please note cancellations within 24 hours of session start time or no-shows will result in a fee or lost credit.`,
        `LOCATION:${link}`,
        `UID:${bookingID || startDate.format('YYYYMMDDTHHmmSS').toString()}`,
        `DTSTAMP;VALUE=DATE-TIME:${startDate.format('YYYYMMDDTHHmmSS')}`,
        'END:VEVENT',
        'END:VCALENDAR',
        `URL:${link}`,
      ].join('\n')}`
    );

    if (reactFrameService.isInFrame()) {
      closePopup(url);
    } else {
      window.location.href = url;
    }
  };

  const handleOutlookPress = () => {
    const { startDate, endDate } = getEventDates(selectedBooking);
    const url = `https://outlook.live.com/calendar/0/action/compose?subject=${title}&startdt=${startDate.format(
      'YYYY-MM-DDTHH:mm:SS'
    )}&enddt=${endDate.format(
      'YYYY-MM-DDTHH:mm:SS'
    )}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&body=${body}`;

    if (reactFrameService.isInFrame()) {
      closePopup(url);
    } else {
      window.open(url);
    }
  };

  return (
    <View align="start" justify="space-between" style={{ width: isMobile ? 335 : 430 }}>
      <View align="start">
        <View row tabIndex={-1}>
          <IconWrapper>
            <SmallCalendar color={colors.black} />
          </IconWrapper>
          <Standard style={{ marginLeft: 15, marginTop: 1 }}>Add to calendar</Standard>
        </View>
      </View>
      <View row justify="space-between" style={{ width: '100%', marginTop: 16 }}>
        <CalendarButton text="Google" onPress={handleGooglePress} dataQa="addToGoogleCalendar" />
        <CalendarButton text="Apple" onPress={handleApplePress} dataQa="addToAppleCalendar" />
        <CalendarButton
          text="Outlook"
          onPress={handleOutlookPress}
          dataQa="addToOutlookCalendar"
          style={{ marginRight: 0 }}
        />
      </View>
    </View>
  );
};

export default AddToCalendar;
