import { ExtraHuge } from '@talkspace/react-toolkit';
import { WizardScheme } from 'stepWizard';

const CheckInWizardV2Scheme: WizardScheme = {
  wizardVersion: 2,
  wizardType: 'checkIn',
  wizardModalTitle: 'check in',
  hideProgressIndicator: true,
  steps: [
    {
      title: 'How have things been going with <!therapistInfo.firstName>?',
      subtitle: 'Your rating is anonymous and contributes to your provider’s overall rating',
      inputType: 'starsRating',
      inputState: 'therapistStarsRating',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Therapist Stars Rating',
      additionalInfo: ['therapistInfo'],
      displayCondition: 'periodicCheckIn',
      postAction: 'ratingPostAction',
    },
    {
      title: 'How was your experience with <!therapistInfo.firstName>?',
      titleSize: ExtraHuge,
      subtitle: 'Your rating is anonymous and contributes to your provider`s overall rating',
      inputType: 'starsRating',
      inputState: 'sessionStarsRating',
      nextButton: {
        displayText: 'Continue',
        actionDispatch: 'next',
        whenVisible: 'onSelect',
      },
      name: 'Session Stars Rating',
      displayCondition: 'postLVS',
      postAction: 'ratingPostAction',
      additionalInfo: ['postLVSSubtitle', 'therapistInfo'],
    },
    {
      inputType: 'checkIn',
      inputState: 'checkInData',
      inputOptions: 'brickOptions',
      nextButton: {
        displayText: '',
        actionDispatch: (value, wizardContext) => {
          if (
            (value.rating && value.rating > 3) ||
            wizardContext.checkInSource === 'psychiatry-lvs'
          ) {
            return `/check-in/done/${wizardContext.checkInSource}`;
          }
          return 'next';
        },
        displayStyle: 'primary',
        fullReload: false,
        source: 'check-in-wizard',
        whenVisible: 'autoAdvance',
        persistOnDispatch: true,
      },
      name: 'Check In',
      postAction: 'bricksPostAction',
    },
    {
      title: 'Would you like to change your provider?',
      subtitle: '<!changeProviderSubtitle>',
      inputState: 'changeProvider',
      nextButton: {
        displayText: 'Continue with <!therapistInfo.firstName>',
        actionDispatch: (value, wizardContext) => `/check-in/done/${wizardContext.checkInSource}`,
        whenVisible: 'always',
      },
      secondaryButton: {
        displayText: 'Change provider',
        actionDispatch:
          '/room/:roomID/my-account/manage-subscription?action=cancellation-switch-exit&source=:source&contextID=:contextID',
        whenVisible: 'always',
        displayStyle: 'primary',
        fullReload: true,
        source: 'checkIn',
        persistOnDispatch: true,
      },
      name: 'Change therapist',
      additionalInfo: ['changeProviderSubtitle', 'therapistInfo'],
      displayCondition: 'shouldShowChangeTherapist',
    },
  ],
};

export default CheckInWizardV2Scheme;
