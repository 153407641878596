import { forwardRef } from 'react';
import Button from './Button';
import { ColorRolesVersion } from '../../types';

import styled, { useEmotionTheme, EmotionStyle } from '../../../core/styled';
import { getSpacing } from '../../tokens';
import { ChevronDown, ChevronUp } from '../../icons';
import TextDS from '../typography/TextDS';
import {
  getActiveStyles,
  getDisabledStyles,
  getButtonTextColor,
  getLeftIconStyles,
  getRightIconStyles,
} from './utils';
import { ButtonV2Props } from './types';
import { useWindowWidthState } from '../../../hooks/windowWidthContext';

const primaryButtonStyle = (colorRoles: ColorRolesVersion) => {
  return {
    backgroundColor: colorRoles.button.brandPrimarySurfaceDefault,
    '&:hover': {
      backgroundColor: colorRoles.button.brandPrimarySurfaceHovered,
    },
    '&:active': {
      backgroundColor: colorRoles.button.brandPrimarySurfacePressed,
    },
  };
};

const secondaryButtonStyle = (colorRoles: ColorRolesVersion) => {
  return {
    backgroundColor: colorRoles.button.brandSecondarySurfaceDefault,
    border: `1px solid ${colorRoles.button.brandSecondaryBorderDefault}`,
    '&:hover': {
      backgroundColor: colorRoles.button.brandSecondarySurfaceHovered,
      border: `1px solid ${colorRoles.button.brandSecondaryBorderHovered}`,
    },
    '&:active': {
      backgroundColor: colorRoles.button.brandSecondarySurfacePressed,
      border: `1px solid ${colorRoles.button.brandSecondaryBorderPressed}`,
    },
  };
};

const tertiaryButtonStyle = (colorRoles: ColorRolesVersion) => {
  return {
    backgroundColor: colorRoles.button.brandTertiarySurfaceDefault,
    '&:hover': {
      backgroundColor: colorRoles.button.brandTertiarySurfaceHovered,
    },
    '&:active': {
      backgroundColor: colorRoles.button.brandTertiarySurfacePressed,
    },
  };
};

const getVariantStyle = (
  variant: ButtonV2Props['variant'],
  colorRoles: ColorRolesVersion
): EmotionStyle => {
  switch (variant) {
    case 'primary':
      return primaryButtonStyle(colorRoles);
    case 'secondary':
      return secondaryButtonStyle(colorRoles);
    case 'tertiary':
      return tertiaryButtonStyle(colorRoles);
    default:
      return primaryButtonStyle(colorRoles);
  }
};

const getSizeStyle = (size: ButtonV2Props['sizeDS'], isMobile: boolean): EmotionStyle => {
  const mediumPadding = `${getSpacing('space100') + getSpacing('space025')}px ${getSpacing(
    'space300',
    true
  )}`;
  const largePadding = `${getSpacing('space200') + getSpacing('space025')}px ${getSpacing(
    'space300',
    true
  )}`;

  switch (size) {
    case 'slim':
      return { padding: `${getSpacing('space075', true)} ${getSpacing('space200', true)}` };
    case 'large':
      return { padding: largePadding };
    case 'medium':
    default:
      if (isMobile) {
        return { padding: largePadding };
      }
      return { padding: mediumPadding };
  }
};

const ButtonV2StyledWithIcon = styled(Button)<{
  variant?: ButtonV2Props['variant'];
  sizeDS?: ButtonV2Props['sizeDS'];
  // TODO: simplify how button icons are styled
  iconConfig?: ButtonV2Props['iconConfig'];
  isActive?: ButtonV2Props['isActive'];
  Icon?: ButtonV2Props['Icon'];
  hasCaretDown?: ButtonV2Props['hasCaretDown'];
  isMobile: boolean;
  disabled?: ButtonV2Props['disabled'];
}>(
  ({
    variant,
    sizeDS,
    iconConfig,
    isActive,
    Icon,
    hasCaretDown,
    isMobile,
    theme: { colorRoles },
    disabled,
  }) => {
    const baseButtonStyles = {
      padding: `${getSpacing('space100', true)} ${getSpacing('space400', true)}`,
      marginTop: 0,
    };
    const variantStyles = getVariantStyle(variant, colorRoles);
    const sizeStyles = getSizeStyle(sizeDS, isMobile);
    const iconStyles =
      Icon && iconConfig ? getLeftIconStyles(variant, colorRoles, iconConfig, isActive) : {};
    const disclosureStyles = hasCaretDown
      ? getRightIconStyles(variant, colorRoles, { path: ['fill'] }, isActive)
      : {};
    const activeStyles = isActive ? getActiveStyles(colorRoles) : {};
    const disabledStyles = disabled ? getDisabledStyles(variant || 'primary', colorRoles) : {};
    return {
      ...baseButtonStyles,
      ...variantStyles,
      ...iconStyles,
      ...disclosureStyles,
      ...activeStyles,
      ...sizeStyles,
      ...disabledStyles,
    };
  }
);

const ButtonV2 = forwardRef<HTMLButtonElement, ButtonV2Props>(
  ({ text, ...otherProps }: ButtonV2Props, ref) => {
    const { isMobile } = useWindowWidthState();
    const { variant = 'primary', Icon, hasCaretDown, isActive, disabled, style } = otherProps;

    const { colorRoles } = useEmotionTheme();

    const iconColor =
      variant === 'primary'
        ? colorRoles.button.brandPrimaryIconDefault
        : colorRoles.button.brandSecondaryIconDefault;

    const textColor = getButtonTextColor({ colorRoles, variant, isActive, disabled });

    const chevronIconColorType = variant === 'primary' ? 'inverse' : 'brand';

    return (
      <ButtonV2StyledWithIcon
        stretch
        isMobile={isMobile}
        {...otherProps}
        style={{
          marginTop: 0,
          maxWidth: 335,
          width: 'fit-content',
          height: 'unset',
          minHeight: 'unset',
          display: 'flex',
          gap: 6,
          ...style,
        }}
        ref={ref}
      >
        {Icon && <Icon color={iconColor} />}
        <TextDS variant="headingMd" style={{ color: textColor }}>
          {text}
        </TextDS>
        {hasCaretDown &&
          (isActive ? (
            <ChevronUp colorType={chevronIconColorType} />
          ) : (
            <ChevronDown colorType={chevronIconColorType} />
          ))}
      </ButtonV2StyledWithIcon>
    );
  }
);

export default ButtonV2;
