import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import getStringOrJSXElementKey, { StringOrJSXElement } from '../../utils/getStringOrJSXElementKey';
import { CheckMark } from '../icons';
import ListItem from '../ListItem';
import { Large, Standard } from '../Typography';
import UnorderedList from '../UnorderedList';
import View from '../View';

const Styled = {
  BulletText: styled(Standard)(({ theme: { colors } }) => {
    return {
      fontWeight: 400,
      fontSize: 16,
      color: colors.trueBlack,
      textAlign: 'left',
    };
  }),
};

type CheckListProps = {
  heading?: string;
  items: Array<StringOrJSXElement>;
  bulletTextStyle?: EmotionStyle;
};

const CheckList = ({ heading, items, bulletTextStyle }: CheckListProps) => {
  const { colors } = useEmotionTheme();

  return (
    <>
      {heading && (
        <Large
          style={{
            fontWeight: 700,
            color: colors.green,
            marginTop: 12,
            textAlign: 'left',
            marginBottom: 16,
            textTransform: 'uppercase',
          }}
        >
          {heading}
        </Large>
      )}
      <UnorderedList
        style={{
          paddingLeft: 0,
          marginLeft: 0,
          listStyleType: 'none',
          marginBottom: 0,
        }}
      >
        {items.map((item, index) => (
          <ListItem
            key={getStringOrJSXElementKey(item, index)}
            style={{
              paddingBottom: index === items.length - 1 ? 0 : 15,
              display: 'flex',
              alignItems: 'baseline',
              flexDirection: 'row',
              textAlign: 'left',
            }}
          >
            <View row flex={1}>
              <View style={{ marginRight: 15, marginTop: 3, height: 20 }}>
                <CheckMark color={colors.permaTalkspaceDarkGreen} />
              </View>
              <Styled.BulletText inline style={bulletTextStyle}>
                {item}
              </Styled.BulletText>
            </View>
          </ListItem>
        ))}
      </UnorderedList>
    </>
  );
};

export default CheckList;
