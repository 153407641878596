import { useEffect } from 'react';

import { useLocation, useParams } from '@/core/routerLib';

import { useCloseModal } from '../../utils/ModalsContextProvider';
import EligibilityWarningSuccess from '../components/EligibilityWarningSuccess';
import useEligibilityWarning from '../hooks/useEligibilityWarning';

const EligibilityWarningSuccessContainer = () => {
  const closeModal = useCloseModal();
  const { roomID } = useParams<{ roomID: string }>();
  const { refetchEligibilityWarning } = useEligibilityWarning({
    roomID: Number(roomID),
  });

  useEffect(() => {
    refetchEligibilityWarning();
  }, [refetchEligibilityWarning]);

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const isNewProvider = urlParams.get('isNewProvider') === 'true';
  const newRoomID = Number(urlParams.get('newRoomID'));
  const sessionsCanceled = urlParams.get('sessionsCanceled') === 'true';
  const sessionsTransferred = urlParams.get('sessionsTransferred') === 'true';

  const shouldBookSession = !isNewProvider && !sessionsTransferred;
  const ctaText = shouldBookSession ? 'Book a session' : 'Go to new room';
  const onPressCTA = shouldBookSession
    ? () =>
        closeModal({
          navigateTo: 'bookSession',
          metadata: { roomID: newRoomID },
          forceWebRedirect: true,
        })
    : () => closeModal({ navigateTo: 'room', metadata: { roomID: newRoomID } });
  return (
    <EligibilityWarningSuccess
      sessionsTransferred={sessionsTransferred}
      sessionsCanceled={sessionsCanceled}
      isNewProvider={isNewProvider}
      ctaText={ctaText}
      onPressCTA={onPressCTA}
      onClose={() => closeModal()}
    />
  );
};

export default EligibilityWarningSuccessContainer;
