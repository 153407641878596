/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useCallback, useRef, useEffect } from 'react';
import ApiHelper from '../utils/ApiHelper';
import SLA, { SLAReducer, State, initialState } from '../reducers/SLAReducer';
import SLAUtils from '../utils/SLAUtils';

export default function useSLA(): [
  State,
  {
    createSLA;
    getNextReplyTime;
    applyCoupon;
    suggestTherapists;
    receiveSLA;
    resetSLA;
  }
] {
  const [state, dispatch] = useReducer(SLAReducer, initialState);
  const apiRef = useRef(new ApiHelper());
  const { current: api } = apiRef;
  useEffect(
    () => () => {
      apiRef.current.cancelAll();
    },
    []
  );

  function createSLA(roomID: number, successCallback?: Function): void {
    dispatch({ type: 'getCreateSLA' });
    api
      .createSla(roomID)
      .then((sla) => {
        if (successCallback) {
          successCallback();
        }
        dispatch({
          type: 'receiveCreateSLA',
          payload: {
            SLA: sla,
          },
        });
      })
      .catch(api.dismissIfCancelled)
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: error.message || 'Oops! Something went wrong. Please refresh the page',
        });
      });
  }

  function getNextReplyTime(roomID: number): void {
    dispatch({ type: 'getNextReplyTime' });
    api
      .getNextReplyTime(roomID)
      .then((nextReplyTime) => {
        const displayedTime = SLAUtils.getDisplayedTime(nextReplyTime);
        dispatch({
          type: 'receiveGetNextReplyTime',
          payload: {
            nextReplyTime: displayedTime,
          },
        });
      })
      .catch(api.dismissIfCancelled)
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: error.message || 'Oops! Something went wrong. Please refresh the page',
        });
      });
  }

  function applyCoupon(roomID: number, successCallback?: Function): void {
    dispatch({ type: 'getApplyCoupon' });
    api
      .applyCoupon(roomID)
      .then((sla) => {
        if (successCallback) {
          successCallback();
        }
        dispatch({
          type: 'receiveGetSLA',
          payload: {
            SLA: sla,
          },
        });
      })
      .catch(api.dismissIfCancelled)
      .catch((error) => {
        dispatch({
          type: 'setError',
          error: error.message || 'Oops! Something went wrong. Please refresh the page',
        });
      });
  }

  async function suggestTherapists(roomID: number, successCallback?: Function): Promise<{}> {
    dispatch({ type: 'getSuggestTherapists' });
    try {
      await api.suggestTherapists(roomID);
      if (successCallback) {
        successCallback();
      }
    } catch (error) {
      dispatch({
        type: 'setError',
        error: error.message || 'Oops! Something went wrong. Please refresh the page',
      });
    }
    return {};
  }

  function receiveSLA(sla: SLA) {
    dispatch({ type: 'receiveGetSLA', payload: { SLA: sla } });
  }

  function resetSLA() {
    dispatch({ type: 'resetSLA' });
  }

  return [
    state,
    {
      createSLA: useCallback(createSLA, [api]),
      getNextReplyTime: useCallback(getNextReplyTime, [api]),
      applyCoupon: useCallback(applyCoupon, [api]),
      suggestTherapists: useCallback(suggestTherapists, [api]),
      receiveSLA: useCallback(receiveSLA, []),
      resetSLA: useCallback(resetSLA, []),
    },
  ];
}
