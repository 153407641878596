import { FunctionComponent } from 'react';
import { BaseButton, View, Large } from '@talkspace/react-toolkit';

import ErrorIcon from '../../assets/Error';

interface Props {
  onClosePress: () => void;
  header: string;
}

const Error: FunctionComponent<Props> = (props: Props) => {
  const { onClosePress, header } = props;
  return (
    <>
      <View style={{ marginBottom: 20 }}>
        <ErrorIcon />
      </View>
      <Large as="h1" variant="largeDarkGrey">
        {header}
      </Large>
      <Large variant="largeDarkGrey">Please try again later.</Large>
      <BaseButton onPress={onClosePress} style={{ marginTop: 60, marginBottom: 60 }}>
        <Large variant="largeBoldWideGreen">Close</Large>
      </BaseButton>
    </>
  );
};

export default Error;
