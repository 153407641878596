import { useState, useEffect, FunctionComponent } from 'react';
import * as React from 'react';
import {
  View,
  Link,
  Small,
  Large,
  Massive,
  ExtraHuge,
  TextInputAuth,
  Spinner,
  Button,
  useEmotionTheme,
  useErrorFocus,
  Big,
} from '@talkspace/react-toolkit';
import { routePromise } from 'ts-frontend/helpers';
import { RouteComponentProps, withRouter } from '../../../core/routerLib/routerLib';
import styled from '../../../core/styled';
import { webOnlyStyle } from '../../../core/styled/styleHelpers';
import MagnifyingGlass from '../../../components/assets/MagnifyingGlass';
import RoundCheckmark from '../../../components/icons/RoundCheckmark';
import useScreenSize, { isResponsive } from '../../../core/hooks/useScreenSize';
import { useClientAuthActions, useClientAuthState } from '../../../hooks/clientAuthContext';

const Form = styled(View)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginLeft: 0,
  marginRight: 0,
});

const ResendEmailButton = styled(Button)(({ theme: { colors } }) => {
  return {
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    color: colors.green,
  };
});

const SubmitButton = ({ onPress, ...props }) => (
  <Button
    type="submit"
    onPress={onPress}
    style={{
      fontFamily: 'Roboto',
      fontSize: 19,
      fontWeight: 'bold',
      width: 335,
      minHeight: 55,
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      letterSpacing: 0.74,
      textAlign: 'center',
    }}
    {...props}
  />
);

const ForgotPassword: FunctionComponent<RouteComponentProps & { isResetPassword?: boolean }> = ({
  isResetPassword,
}) => {
  const { formContainerRef, setShouldFocusFirstInvalidField } = useErrorFocus();
  const [email, setEmail] = useState('');
  const {
    adminConfigs: { isCognitoLoginEnabled },
    isUpdating,
  } = useClientAuthState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const { forgotPasswordAction, forgotPasswordCognitoAction } = useClientAuthActions();
  const screenSize = useScreenSize();
  const { colors } = useEmotionTheme();
  const isFromProvider = sessionStorage.getItem('oauthQuery') !== null;

  useEffect(() => {
    let timeout;
    if (isSuccess) {
      timeout = setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isSuccess]);

  const onChange =
    (changeHandler: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      changeHandler(e.target.value);

  const onSubmit = async (e) => {
    e?.preventDefault();

    setIsSuccess(false);
    setHasSubmitted(true);

    if (!isEmailValid) {
      setShouldFocusFirstInvalidField(true);
      return;
    }

    if (isUpdating) {
      return;
    }

    try {
      if (isCognitoLoginEnabled) {
        await forgotPasswordCognitoAction({ email });
      } else {
        await forgotPasswordAction({ email });
      }

      if (!hasSubmitted) {
        setHasSubmitted(true);
      }

      setIsSuccess(true);
    } catch {
      setIsSuccess(false);
      setHasSubmitted(false);
    }
  };
  const TitleComponent = isResponsive(screenSize) ? ExtraHuge : Massive;
  const titleText = isResetPassword ? 'Please change your password' : 'Forgot your password?';
  const entrySubtitle = `${
    isResetPassword
      ? 'Your password has expired, please enter your email address to start the reset process.'
      : 'Please enter the email address you used to register.'
  } We’ll send a link with instructions to reset your password.`;
  return (
    <View align="center" ref={formContainerRef}>
      <View
        align="center"
        style={{
          marginTop: isResponsive(screenSize) ? 30 : 60,
          marginBottom: 29,
        }}
      >
        {!isResponsive(screenSize) && (
          <View style={{ marginBottom: 10, width: 74, height: 74 }}>
            {isUpdating && (
              <Spinner primaryColor={colors.loblollyGrey} style={{ width: 50, height: 50 }} />
            )}
            {!isUpdating &&
              (isSuccess ? <RoundCheckmark width={74} height={74} /> : <MagnifyingGlass />)}
          </View>
        )}
        <TitleComponent style={{ marginBottom: 10, textAlign: 'center' }}>
          {titleText}
        </TitleComponent>
        <View
          style={{
            textAlign: 'center',
            width: isResponsive(screenSize) ? 335 : 425,
          }}
        >
          {hasSubmitted ? (
            <Large variant="largeDarkGrey">
              A password reset link has been sent. If you no longer have access to this email
              account, please
              <Link
                target="_blank"
                href="https://help.talkspace.com"
                style={{ marginLeft: 0, marginRight: 0 }}
              >
                <Large
                  inline
                  style={{
                    ...webOnlyStyle({ display: 'inline' }),
                    color: colors.green,
                    fontWeight: 700,
                  }}
                >
                  {' '}
                  Contact Support
                </Large>
              </Link>
            </Large>
          ) : (
            <Large variant="largeDarkGrey">{entrySubtitle}</Large>
          )}
        </View>
      </View>
      <Form>
        <TextInputAuth
          aria-label="Email"
          aria-required="true"
          type="email"
          label="Email"
          value={email}
          onValidationChanged={setIsEmailValid}
          placeholder="Enter email address"
          errorMessage="Invalid email."
          onChange={onChange(setEmail)}
          containerStyle={{ marginBottom: 18 }}
          onSubmitEditing={onSubmit}
        />
        <SubmitButton
          onPress={onSubmit}
          isLoading={isUpdating}
          disabled={isUpdating}
          text={hasSubmitted && isSuccess ? 'Resend link' : 'Request password reset'}
        />
        {isFromProvider && (
          <ResendEmailButton
            isSecondary
            onPress={() => routePromise(`/oauth${sessionStorage.getItem('oauthQuery')}`)}
          >
            <Big variant="bigDarkGreen">Complete Alexa account linking</Big>
          </ResendEmailButton>
        )}
        <View style={{ marginTop: 20 }}>
          <Small>
            Need help?{' '}
            <Link
              target="_blank"
              href="https://help.talkspace.com"
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              <Small
                inline
                style={{
                  ...webOnlyStyle({ display: 'inline' }),
                  color: colors.green,
                  fontWeight: 700,
                }}
              >
                Customer support
              </Small>
            </Link>
          </Small>
        </View>
      </Form>
    </View>
  );
};

export default withRouter(ForgotPassword);
