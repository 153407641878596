import { FunctionComponent } from 'react';
import { View, Button, ExtraHuge, Large } from '@talkspace/react-toolkit';

import styled from '../../../core/styled/styled';

const Wrapper = styled(View)({
  textAlign: 'left',
});

const PaddedText = styled(Large)({
  marginTop: 10,
  marginBottom: 10,
});

const GotItButton = styled(Button)({
  alignSelf: 'center',
  marginTop: 20,
  marginBottom: 40,
});

interface Props {
  onGotItPress: () => void;
  header: string;
}

const Resolved: FunctionComponent<Props> = (props: Props) => {
  const { onGotItPress, header } = props;
  return (
    <Wrapper>
      <ExtraHuge as="h1">{header}</ExtraHuge>
      <PaddedText variant="largeDarkGrey">
        Use this button once you’re done articulating your thoughts and feelings, and want your
        therapist to join the conversation and share their thoughts.
      </PaddedText>
      <PaddedText variant="largeDarkGrey">
        When using this button, your therapist will be notified and you’ll be provided with their
        predicted response time. The predicted response time will take into account your therapist’s
        daily availability and time off.
      </PaddedText>
      <PaddedText variant="largeDarkGrey">
        Talking with your therapist every day is key to making progress in treatment and achieving
        your goals.
      </PaddedText>
      <GotItButton onPress={onGotItPress}>Got it</GotItButton>
    </Wrapper>
  );
};

export default Resolved;
