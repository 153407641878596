import { FunctionComponent } from 'react';
import {
  View,
  TouchableView,
  Big,
  Large,
  Tiny,
  Mini,
  useEmotionTheme,
  usePanelManagerActions,
  EmotionThemeProvider,
  TextDS,
} from '@talkspace/react-toolkit';
import { ERoom } from 'ts-frontend/entities/Room';
import { EMessagePreview } from 'ts-frontend/entities/MessagePreview';
import { roomHasRealProvider } from 'ts-frontend/helpers';
import { getLastMessageTimeDisplayString } from 'ts-frontend/utils/displayStrings';
import { checkIfPsychiatrist, ETherapistInfo, isTherapistCT } from 'ts-frontend/entities/Therapist';
import { useNewMemberNav } from 'launchDarkly/hooks';
import styled from '@/core/styled';
import ArrowLeft from '../../assets/ArrowLeft';
import { webOnlyStyle } from '../../core/styled/styleHelpers';
import TherapistAvatar, { TherapistAvatarSizeEnum } from '../TherapistAvatar/TherapistAvatar';
import { RouteComponentProps, withRouter } from '../../core/routerLib/index';
import { getUserData } from '../../auth/helpers/token';
import { useMainState } from '../../hooks/mainContext';
import { EMe } from '../../entities/Me';
import { ID_CHAT_PANEL } from '../../utils/IDConstants';
import RoomWarningStatusTag from './RoomWarningStatusTag';
import useEligibilityWarning from '../../EligibilityWarning/hooks/useEligibilityWarning';

const Wrapper = styled(TouchableView)<{ isActive?: boolean }>(({ isActive, theme: { colors } }) => {
  return {
    padding: '16px 0',
    flexDirection: 'row',
    backgroundColor: isActive ? colors.permaLinkWaterGrey : colors.white,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    alignItems: 'center',
    paddingLeft: 14,
    paddingRight: 16,
    '&:hover': {
      backgroundColor: isActive ? undefined : colors.athensGrey,
    },
    ':focus': {
      outlineOffset: -4,
    },
  };
});

const MessageSnippet = styled(Large)({
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ArrowRight = styled(ArrowLeft)<{ useNewNav: boolean }>(
  ({
    theme: {
      window: { isDesktop },
    },
    useNewNav,
  }) => {
    const stylesObject = {
      marginLeft: 4,
      ...webOnlyStyle({ transform: 'rotate(180deg) scale(0.86)' }),
    };
    return isDesktop && useNewNav ? { display: 'none', ...stylesObject } : stylesObject;
  }
);

const NotificationDot = styled(View)<{ useNewNav: boolean }>(
  ({ theme: { colors, colorRoles }, useNewNav }) => {
    return {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: useNewNav ? colorRoles.surfaces.criticalBoldDefault : colors.green,
    };
  }
);

const TherapistNameTimeAndArrow: FunctionComponent<{
  name: string;
  displayTime: string | null;
  isActive?: boolean;
  useNewNav: boolean;
}> = ({ name, displayTime, isActive, useNewNav }) => {
  const { colors } = useEmotionTheme();
  return (
    <View row justify="space-between" align="center">
      {useNewNav ? (
        <TextDS variant="headingMd">{name}</TextDS>
      ) : (
        <Big variant={isActive ? 'bigMedium' : 'big'}>{name}</Big>
      )}
      <View row align="center">
        <Mini>{displayTime}</Mini>
        <ArrowRight
          useNewNav={useNewNav}
          color={useNewNav ? colors.accessibilityGreenDark : colors.periwinkleGrey}
        />
      </View>
    </View>
  );
};

const getLastMessageSnippet = (
  lastMessage: EMessagePreview | null,
  currentUserPseudonym: string
) => {
  if (lastMessage) {
    if (lastMessage.messageType === 46) {
      const searchString = `${currentUserPseudonym} has`;
      return lastMessage.message.replace(searchString, 'You have');
    }
    return lastMessage.message;
  }
  return '';
};

const MessageSnippetAndDot: FunctionComponent<{
  message: string;
  showUnreadDot: boolean;
  isAcking: boolean;
  useNewNav: boolean;
}> = ({ message, showUnreadDot, isAcking, useNewNav }) => (
  <View row justify="space-between" align="center" style={{ height: 20 }}>
    <MessageSnippet>{message}</MessageSnippet>
    {isAcking && showUnreadDot && (
      <EmotionThemeProvider version="2.0.0">
        <NotificationDot useNewNav={useNewNav} />
      </EmotionThemeProvider>
    )}
  </View>
);

const RoomSummaryCard: FunctionComponent<
  {
    room: ERoom;
    therapist: ETherapistInfo;
    isActive?: boolean;
    isAcking: boolean;
    isChatHidden?: boolean;
  } & RouteComponentProps
> = ({ isActive, room, therapist, history, location, isAcking, isChatHidden }) => {
  const { setIsLeftPanelOnTop } = usePanelManagerActions();
  const { clientsByID } = useMainState();
  const me = useMainState().me as EMe;
  const { id: clientUserID } = getUserData();
  let displayName;
  const therapistDisplayType = therapist.type === 'psychiatrist' ? 'Psychiatrist' : 'Therapist';
  const { isInsuranceIneligible, isClosedUpdatedCoverage } = useEligibilityWarning({
    roomID: room.roomID,
  });
  const useNewNav = useNewMemberNav();

  let therapistLicenseText = `${therapistDisplayType}, ${therapist.licenses[0]}`;
  if (isTherapistCT(therapist.type)) {
    therapistLicenseText = 'Matching agent';
  } else if (room.roomType === 'couples_room') {
    therapistLicenseText = 'Couples therapy';
  }

  const therapistTitle = therapist.title ? `${therapist.title} ` : '';
  if (room.roomType === 'couples_room') {
    const otherClientID = Object.keys(clientsByID).find(
      (clientID) =>
        Number(clientID) !== me.id && clientsByID[clientID].roomIDs.includes(room.roomID)
    );
    if (otherClientID)
      displayName = `${therapistTitle}${therapist.firstName}, ${clientsByID[otherClientID].displayName}`;
    else displayName = `${therapistTitle}${therapist.firstName} ${therapist.lastName}`;
  } else {
    displayName = `${therapistTitle}${therapist.firstName} ${therapist.lastName}`;
  }

  const handleOnPress = () => {
    const destinationPath = `/room/${room.roomID}`;
    if (destinationPath !== location.pathname) history.push(destinationPath);
    setIsLeftPanelOnTop(false);
  };

  const { lastMessage } = room;
  const message = getLastMessageSnippet(lastMessage, me.displayName);
  const showUnreadDot = !!(
    lastMessage &&
    room.lastReadAckedMessageID > 0 &&
    lastMessage.messageID > room.lastReadAckedMessageID &&
    lastMessage.userID !== clientUserID
  );

  let from = '';
  if (
    lastMessage &&
    [1, 15, 16, 17, 40, 22, 23, 24, 25, 26, 49].includes(lastMessage.messageType) // (Text, Media, LVS)
  ) {
    if (lastMessage.userID === me.id) {
      from = 'You: ';
    } else if (lastMessage.userID === room.therapistID) {
      if (room.roomType === 'couples_room') from = `${therapist.firstName}: `;
    } else {
      const other = clientsByID[lastMessage.userID];
      if (other) from = `${other.displayName}: `;
    }
  }

  const displayTime = getLastMessageTimeDisplayString({ createdAt: lastMessage?.createdAt });
  const isRoomPanelRendered =
    !isChatHidden && history.location.pathname.includes(`room/${room.roomID}`);

  let hideStatus = checkIfPsychiatrist(therapist.type);
  let size: TherapistAvatarSizeEnum = 'medium';

  if (!roomHasRealProvider({ room, provider: therapist })) {
    therapistLicenseText = therapist.lastName;
    displayName = therapist.firstName;
    hideStatus = true;
    size = 'medium';
  }

  return (
    <Wrapper
      isActive={isActive}
      onPress={handleOnPress}
      aria-label={`Room: ${displayName}. Last message: ${message}, ${displayTime}`}
      aria-controls={isRoomPanelRendered ? `${ID_CHAT_PANEL}-${room.roomID}` : undefined}
      aria-expanded={isRoomPanelRendered}
    >
      <TherapistAvatar
        therapist={therapist}
        roomStatus={room.status}
        roomType={room.roomType}
        source="dashboard"
        isActive={isActive}
        hideStatus={hideStatus}
        size={size}
      />
      <View flex={1} style={{ marginLeft: 14, minWidth: 0 }}>
        <TherapistNameTimeAndArrow
          name={displayName}
          displayTime={displayTime}
          isActive={isActive}
          useNewNav={useNewNav}
        />
        <Tiny style={{ textAlign: 'left' }}>{therapistLicenseText}</Tiny>
        <MessageSnippetAndDot
          message={`${from}${message}`}
          showUnreadDot={showUnreadDot}
          isAcking={isAcking}
          useNewNav={useNewNav}
        />
        {isInsuranceIneligible && !isClosedUpdatedCoverage && (
          <RoomWarningStatusTag text="Insurance coverage expired" />
        )}
        {isInsuranceIneligible && isClosedUpdatedCoverage && (
          <RoomWarningStatusTag text="Room closed" isGreyVariant />
        )}
      </View>
    </Wrapper>
  );
};

export default withRouter(RoomSummaryCard);
