import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M10 10C11.0609 10 12.0783 9.57857 12.8284 8.82843C13.5786 8.07828 14 7.06087 14 6C14 4.93913 13.5786 3.92172 12.8284 3.17157C12.0783 2.42143 11.0609 2 10 2C8.93913 2 7.92172 2.42143 7.17157 3.17157C6.42143 3.92172 6 4.93913 6 6C6 7.06087 6.42143 8.07828 7.17157 8.82843C7.92172 9.57857 8.93913 10 10 10ZM8.57188 11.5C5.49375 11.5 3 13.9937 3 17.0719C3 17.5844 3.41563 18 3.92813 18H16.0719C16.5844 18 17 17.5844 17 17.0719C17 13.9937 14.5063 11.5 11.4281 11.5H8.57188Z';
const majorPath =
  'M15.105 7.79297C15.105 6.96417 14.7757 6.16931 14.1897 5.58326C13.6036 4.99721 12.8088 4.66797 11.98 4.66797C11.1512 4.66797 10.3563 4.99721 9.77027 5.58326C9.18422 6.16931 8.85498 6.96417 8.85498 7.79297C8.85498 8.62177 9.18422 9.41663 9.77027 10.0027C10.3563 10.5887 11.1512 10.918 11.98 10.918C12.8088 10.918 13.6036 10.5887 14.1897 10.0027C14.7757 9.41663 15.105 8.62177 15.105 7.79297ZM6.97998 7.79297C6.97998 6.46689 7.50676 5.19512 8.44445 4.25743C9.38213 3.31975 10.6539 2.79297 11.98 2.79297C13.3061 2.79297 14.5778 3.31975 15.5155 4.25743C16.4532 5.19512 16.98 6.46689 16.98 7.79297C16.98 9.11905 16.4532 10.3908 15.5155 11.3285C14.5778 12.2662 13.3061 12.793 11.98 12.793C10.6539 12.793 9.38213 12.2662 8.44445 11.3285C7.50676 10.3908 6.97998 9.11905 6.97998 7.79297ZM5.15576 20.918H18.8042C18.4565 18.4453 16.3315 16.543 13.7651 16.543H10.1948C7.62842 16.543 5.50342 18.4453 5.15576 20.918ZM3.22998 21.6328C3.22998 17.7852 6.34717 14.668 10.1948 14.668H13.7651C17.6128 14.668 20.73 17.7852 20.73 21.6328C20.73 22.2734 20.2104 22.793 19.5698 22.793H4.39014C3.74951 22.793 3.22998 22.2734 3.22998 21.6328Z';
const hugePath =
  'M16 16.1896C17.5913 16.1896 19.1174 15.5574 20.2426 14.4322C21.3679 13.307 22 11.7809 22 10.1896C22 8.59828 21.3679 7.07215 20.2426 5.94693C19.1174 4.82172 17.5913 4.18958 16 4.18958C14.4087 4.18958 12.8826 4.82172 11.7574 5.94693C10.6321 7.07215 10 8.59828 10 10.1896C10 11.7809 10.6321 13.307 11.7574 14.4322C12.8826 15.5574 14.4087 16.1896 16 16.1896ZM13.8578 18.4396C9.24063 18.4396 5.5 22.1802 5.5 26.7974C5.5 27.5661 6.12344 28.1896 6.89219 28.1896H25.1078C25.8766 28.1896 26.5 27.5661 26.5 26.7974C26.5 22.1802 22.7594 18.4396 18.1422 18.4396H13.8578Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const User = withDSIconMaker(pathConfig, 'user icon');

export default User;
