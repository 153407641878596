import Svg, { Defs, G, Path, Use } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import { EmotionStyle } from '@/core/styled';

const File = ({
  width = 17,
  height = 21,
  color,
  style,
  ...props
}: {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'file';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
      {...style}
    >
      <Defs>
        <Path d="M0 2.06069536e-13L375 2.06069536e-13 375 67 3.5453411e-13 67z" />
        <filter width="100.3%" height="103%" x="-.1%" y="-.7%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0558763587 0"
          />
        </filter>
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <G transform="translate(-45 -24)">
          <G>
            <Use fill={colors.trueBlack} href="#b" xlinkHref="#b" />
            <Use fill={colors.white} href="#b" xlinkHref="#b" />
          </G>
          <Path
            fill={color || colors.cobaltGrey}
            d="M48 24h5.34a4.2 4.2 0 012.998 1.258l4.46 4.544A4.2 4.2 0 0162 32.744V42a3 3 0 01-3 3H48a3 3 0 01-3-3V27a3 3 0 013-3z"
          />
        </G>
      </G>
    </Svg>
  );
};

export default File;
