import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M10 18.3965C12.1217 18.3965 14.1566 17.5536 15.6569 16.0533C17.1571 14.553 18 12.5182 18 10.3965C18 8.27475 17.1571 6.23992 15.6569 4.73963C14.1566 3.23934 12.1217 2.39648 10 2.39648C7.87827 2.39648 5.84344 3.23934 4.34315 4.73963C2.84285 6.23992 2 8.27475 2 10.3965C2 12.5182 2.84285 14.553 4.34315 16.0533C5.84344 17.5536 7.87827 18.3965 10 18.3965ZM10 6.39648C10.4156 6.39648 10.75 6.73086 10.75 7.14648V10.6465C10.75 11.0621 10.4156 11.3965 10 11.3965C9.58437 11.3965 9.25 11.0621 9.25 10.6465V7.14648C9.25 6.73086 9.58437 6.39648 10 6.39648ZM9 13.3965C9 13.1313 9.10536 12.8769 9.29289 12.6894C9.48043 12.5018 9.73478 12.3965 10 12.3965C10.2652 12.3965 10.5196 12.5018 10.7071 12.6894C10.8946 12.8769 11 13.1313 11 13.3965C11 13.6617 10.8946 13.9161 10.7071 14.1036C10.5196 14.2911 10.2652 14.3965 10 14.3965C9.73478 14.3965 9.48043 14.2911 9.29289 14.1036C9.10536 13.9161 9 13.6617 9 13.3965Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
};

const CircleExclamation = withDSIconMaker(pathConfig, 'exclamation point inside circle icon');

export default CircleExclamation;
