import { FunctionComponent } from 'react';
import { BaseButton, ExtraHuge, Large, Standard } from '@talkspace/react-toolkit';

import styled from '../../../core/styled/styled';
import FlatRoundedButton from '../FlatRoundedButton';

const PaddedText = styled(Large)({
  marginTop: 10,
  marginBottom: 50,
});

const FlatButton = styled(BaseButton)({
  marginTop: 20,
  marginBottom: 40,
  backgroundColor: '#fff',
});

interface Props {
  therapistFirstName: string;
  nextReplyTime: string;
  onAskReplyPress: () => void;
  onSwitchTherapistPress: () => void;
  onCancelPress: () => void;
  header: string;
}

const Missed2Weeks: FunctionComponent<Props> = (props: Props) => {
  const {
    therapistFirstName,
    nextReplyTime,
    onAskReplyPress,
    onSwitchTherapistPress,
    onCancelPress,
    header,
  } = props;
  return (
    <>
      <ExtraHuge as="h1" style={{ textAlign: 'center' }}>
        {header}
      </ExtraHuge>
      <PaddedText variant="largeDarkGrey">
        {therapistFirstName} missed their expected response time again. How can we help?
      </PaddedText>
      <FlatRoundedButton
        onPress={onAskReplyPress}
        style={{ marginBottom: 10, paddingLeft: 5, paddingRight: 5 }}
      >
        <Large variant="largeBoldWideGreen">
          Please ask {therapistFirstName} to reply by {nextReplyTime}
        </Large>
      </FlatRoundedButton>
      <FlatRoundedButton onPress={onSwitchTherapistPress}>
        <Large variant="largeBoldWideGreen">I'd like to switch to another therapist</Large>
      </FlatRoundedButton>
      <FlatButton onPress={onCancelPress}>
        <Standard variant="standardDarkGrey">I’ll wait just a little longer</Standard>
      </FlatButton>
    </>
  );
};

export default Missed2Weeks;
