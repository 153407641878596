import {
  TextDS,
  TouchableView,
  House,
  DSCalendar,
  Message,
  Seedling,
  useWindowWidthState,
  View,
  DSIcon,
  spacing,
  StatusAlertDot,
} from '@talkspace/react-toolkit';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { withTouchableStyles } from '@talkspace/react-toolkit/src/designSystems';
import styled from '@/core/styled';
import { trackCTAClick } from '../../utils/analytics/events';

const { space050, space100, space150, space200 } = spacing;

type ClientTab = 'home' | 'schedule' | 'messages' | 'journey';

type NavItemConfig = {
  Icon: DSIcon;
  text: string;
};

type ResponsiveButtonProps = {
  isSelected: boolean;
  onPress: () => void;
  dataQa: string;
  hasAlert?: boolean;
  as: string;
} & Pick<NavItemConfig, 'Icon' | 'text'>;

const config: Record<ClientTab, NavItemConfig> = {
  home: {
    Icon: House,
    text: 'Home',
  },
  schedule: {
    Icon: DSCalendar,
    text: 'Schedule',
  },
  messages: {
    Icon: Message,
    text: 'Messages',
  },
  journey: {
    Icon: Seedling,
    text: 'Journey',
  },
};

const Styled = {
  NavigationButtonText: ({ children }) => {
    const { isTablet } = useWindowWidthState();
    return (
      <TextDS colorRole="textBrandDefault" variant={isTablet ? 'bodyXs' : 'headingSm'}>
        {children}
      </TextDS>
    );
  },
  NavPanelButtonWrapper: styled(withTouchableStyles(TouchableView))(
    ({
      theme: {
        window: { isTablet },
      },
    }) => {
      if (isTablet) {
        return {
          width: 60,
          transition: 'width 0.3s linear, background-color 0.4s ease-in',
          alignItems: 'center',
          borderRadius: 10,
        };
      }
      return {
        gap: 8,
        height: 36,
        width: '100%',
        borderRadius: 10,
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: `${space100}px ${space200}px ${space100}px ${space150}px`,
        transition: 'width 0.1s linear, background-color 0.2s linear',
      };
    }
  ),
  IconContainer: styled(withTouchableStyles(View))(
    ({
      theme: {
        window: { isTablet },
      },
    }) => {
      if (!isTablet)
        return {
          position: 'relative',
        };
      return {
        width: 36,
        height: 36,
        borderRadius: 10,
        padding: space100,
        position: 'relative',
      };
    }
  ),
  ResponsiveButton: ({
    Icon,
    text,
    isSelected,
    onPress,
    dataQa,
    hasAlert,
    as,
  }: ResponsiveButtonProps) => {
    const { isTablet, isMobile } = useWindowWidthState();
    if (isMobile)
      return (
        <TouchableView
          as={as}
          onPress={onPress}
          dataQa={dataQa}
          allowOnKeyDownPropagation
          style={{
            gap: 2,
            width: 60,
            height: 46,
            paddingTop: space050,
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {hasAlert ? <StatusAlertDot style={{ position: 'absolute', top: 0, right: 12 }} /> : null}
          <Icon size="major" colorType="brand" variant={isSelected ? 'filled' : 'default'} />
          <TextDS
            colorRole={isSelected ? 'textBrandDefault' : 'textSubtlest'}
            variant={isSelected ? 'headingXs' : 'bodyXs'}
          >
            {text}
          </TextDS>
        </TouchableView>
      );
    return (
      <Styled.NavPanelButtonWrapper
        as={as}
        multiplyBackground
        colorRoleType="surfaceDefault"
        isActive={isSelected}
        onPress={onPress}
        disableSurfaceStyles={isTablet}
        dataQa={dataQa}
        allowOnKeyDownPropagation
      >
        {({ isActive, isHovering }) => (
          <>
            <Styled.IconContainer
              isActive={isActive}
              isHovering={isHovering}
              disableSurfaceStyles={!isTablet}
              colorRoleType="surfaceDefault"
            >
              {hasAlert && isTablet ? (
                <StatusAlertDot style={{ position: 'absolute', top: 2, right: 2 }} />
              ) : null}
              {hasAlert && !isTablet ? (
                <StatusAlertDot style={{ position: 'absolute', top: -5, left: 10 }} />
              ) : null}
              <Icon colorType="brand" variant={isActive ? 'filled' : 'default'} />
            </Styled.IconContainer>
            <Styled.NavigationButtonText>{text}</Styled.NavigationButtonText>
          </>
        )}
      </Styled.NavPanelButtonWrapper>
    );
  },
};

const NavItem: React.VFC<{
  dataQa: string;
  tabName: ClientTab;
  route: string;
  hasAlert?: boolean;
  isSelectedRegex?: RegExp;
  isSelectedExceptions?: string[];
  analyticsName: string;
}> = ({
  tabName,
  route,
  dataQa,
  hasAlert,
  isSelectedRegex,
  isSelectedExceptions,
  analyticsName,
}) => {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;
  const { Icon, text } = config[tabName];

  let isSelected = isSelectedRegex
    ? !!path.match(isSelectedRegex) || path === route
    : path === route;

  if (isSelectedExceptions && isSelected) {
    isSelected = !isSelectedExceptions.some((el) => path.includes(el));
  }

  const onPress = useCallback(() => {
    if (!isSelected) {
      trackCTAClick(analyticsName, 'nav');
      history.push(route);
    }
  }, [history, route, analyticsName, isSelected]);

  return (
    <Styled.ResponsiveButton
      as="nav"
      Icon={Icon}
      text={text}
      isSelected={isSelected}
      onPress={onPress}
      dataQa={dataQa}
      hasAlert={hasAlert}
    />
  );
};

export default NavItem;
