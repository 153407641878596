import { useCallback } from 'react';
import { Subscription } from 'ts-frontend/types';
import { useHistory } from '@/core/routerLib';

import { trackCTAClick } from '../../utils/analytics/events';
import BookSessionCard from '../components/BookSessionCard';

interface Props {
  subscriptions: Subscription[];
  isLoading: boolean;
}
const BookSessionCardController = ({ subscriptions, isLoading }: Props) => {
  const history = useHistory();

  const onPress = useCallback(() => {
    if (isLoading) return;
    trackCTAClick('Book a session', 'schedule', { component: 'BookSessionCard' });
    history.push('/in-room-scheduling/choose-provider');
  }, [history, isLoading]);

  return <BookSessionCard subscriptions={subscriptions} onPress={onPress} isLoading={isLoading} />;
};

export default BookSessionCardController;
