import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { useQueryClient } from 'react-query';

import { countries, states } from '@talkspace/configs';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { getClientQueryKey } from '@/utils/queryKeys';
import { getUserData } from '@/auth/helpers/token';

import PersonalInformationEditMyInformationModal from '../components/PersonalInformationEditMyInformationModal';
import {
  usePersonalInformationState,
  usePersonalInformationActions,
} from '../hooks/usePersonalInformation';
import { MyInformationData } from '../types';

const REQUIRED_ERROR = 'Required field.';

const schema: yup.SchemaOf<MyInformationData> = yup.object().shape({
  firstName: yup.string().required(REQUIRED_ERROR).nullable(),
  lastName: yup.string().required(REQUIRED_ERROR).nullable(),
  dateOfBirth: yup
    .string()
    .required(REQUIRED_ERROR)
    .test('is-valid-format-date', 'Please provide valid date (MM/DD/YYYY)', (userInput) =>
      moment(userInput, 'MM/DD/YYYY', true).isValid()
    )
    .nullable(),
  country: yup
    .string()
    .required(REQUIRED_ERROR)
    .oneOf(Object.keys(countries), REQUIRED_ERROR)
    .nullable(),
  state: yup.string().when('country', {
    is: 'US',
    then: yup
      .string()
      .required(REQUIRED_ERROR)
      .oneOf(Object.keys(states), REQUIRED_ERROR)
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  city: yup.string().required(REQUIRED_ERROR).nullable(),
  zipcode: yup.string().required(REQUIRED_ERROR).nullable(),
  address: yup.string().required(REQUIRED_ERROR).nullable(),
  address2: yup.string().nullable(),
});

const PersonalInformationEditMyInformationModalContainer = () => {
  const { myInformation, isLoading, error } = usePersonalInformationState();
  const { getMyInformation, updateMyInformation } = usePersonalInformationActions();
  const queryClient = useQueryClient();
  const closeModal = useCloseModal();
  const methods = useForm<MyInformationData>({
    resolver: yupResolver(schema),
    defaultValues: myInformation,
  });
  const { getValues, reset, handleSubmit } = methods;

  const { id: userID } = getUserData();

  useEffect(() => {
    getMyInformation();
  }, [getMyInformation]);

  useEffect(() => {
    reset(myInformation);
  }, [myInformation, reset]);

  const onSubmit = () => {
    updateMyInformation(getValues()).then(() => {
      if (!error) {
        queryClient.refetchQueries(getClientQueryKey({ clientUserID: userID }), { exact: true });
        closeModal();
      }
    });
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformationEditMyInformationModal isLoading={isLoading} onClose={closeModal} />
      </form>
    </FormProvider>
  );
};

export default PersonalInformationEditMyInformationModalContainer;
