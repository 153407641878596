import { useCallback } from 'react';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useQueryClient } from 'react-query';
import { subscriptionsQueryKey } from 'ts-frontend/hooks/queryKeys';
import { useParams } from '@/core/routerLib';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import OnboardingPage from './OnboardingPage';
import AllDone from '../assets/AllDone';
import AllDoneTeens from '../assets/AllDoneTeens';

import { onboardingQueryKey, onboardingV3QueryKey, getAllRoomsQueryKey } from '@/utils/queryKeys';
import { getUserData } from '@/auth/helpers/token';

const OnboardingPageAllDone = ({
  totalSteps,
  isTeensIntake,
  isRedirectToRoom,
}: {
  totalSteps: number;
  isTeensIntake?: boolean;
  isRedirectToRoom?: boolean;
}) => {
  const queryClient = useQueryClient();
  const useNewNav = useNewMemberNav();
  const { roomID } = useParams<{ roomID: string }>();
  const { id: userID } = getUserData();
  const closeModal = useCloseModal();

  const onClose = useCallback(() => {
    const queryKeys = [
      onboardingQueryKey({ roomID, userID }),
      onboardingV3QueryKey({ roomID, userID }),
      getAllRoomsQueryKey({ clientUserID: userID }),
      subscriptionsQueryKey({
        clientUserID: userID,
        roomID: Number(roomID),
      }),
    ];
    queryKeys.forEach((queryKey) => {
      queryClient.invalidateQueries(queryKey, { refetchActive: false });
    });
    closeModal(
      useNewNav && !isRedirectToRoom
        ? {
            navigateTo: 'home',
            metadata: { path: '/' },
          }
        : {
            navigateTo: 'room',
            metadata: { roomID: Number(roomID) },
          }
    );
  }, [closeModal, queryClient, roomID, userID, isRedirectToRoom, useNewNav]);
  return (
    <OnboardingPage
      totalSteps={totalSteps}
      currentStep={totalSteps}
      illustration={isTeensIntake ? <AllDoneTeens /> : <AllDone />}
      title="You’re all done!"
      description={
        isTeensIntake
          ? 'You’ve completed all the steps needed to begin therapy'
          : 'You’ve completed all the steps needed to begin treatment'
      }
      ctaText="Close"
      onPress={onClose}
    />
  );
};

export default OnboardingPageAllDone;
