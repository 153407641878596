import {
  TouchableView,
  TextDS,
  DSIcon,
  useWindowWidthState,
  spacing,
} from '@talkspace/react-toolkit';
import { SquarePlus, CircleUser } from '@talkspace/react-toolkit/src/designSystems/icons';
import { withTouchableStyles } from '@talkspace/react-toolkit/src/designSystems';
import { useHistory, useLocation } from 'react-router';
import { useCallback } from 'react';
import styled from '@/core/styled';
import { useOpenModal } from '@/utils/ModalsContextProvider';
import { trackCTAClick } from '../../utils/analytics/events';

type HeaderItemType = 'account' | 'help';

type HeaderItemConfig = {
  Icon: DSIcon;
  text: string;
  iconOnlyMobile?: boolean;
};

const { space0, space050, space100, space150, space200 } = spacing;

const config: Record<HeaderItemType, HeaderItemConfig> = {
  account: {
    Icon: CircleUser,
    text: 'Account',
    iconOnlyMobile: true,
  },
  help: {
    Icon: SquarePlus,
    text: 'Help',
  },
};

const Styled = {
  NavHeaderButton: styled(withTouchableStyles(TouchableView))<{ iconOnly?: boolean }>(
    ({
      iconOnly,
      theme: {
        window: { isMobile },
      },
    }) => {
      if (isMobile)
        return {
          gap: 8,
          padding: iconOnly ? space0 : `${space0} ${space050}px ${space0} ${space0}`,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        };
      return {
        gap: 8,
        padding: `${space100}px ${space200}px ${space100}px ${space150}px`,
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      };
    }
  ),
  NavigationButtonText: ({ children }) => {
    const { isMobile } = useWindowWidthState();
    return (
      <TextDS colorRole="textBrandDefault" variant={isMobile ? 'headingMd' : 'headingSm'}>
        {children}
      </TextDS>
    );
  },
};

type HeaderItemProps = {
  headerType: HeaderItemType;
  route?: string;
  modalRoute?: string;
  externalUrl?: string;
  dataQa: string;
  analyticsName: string;
};

const HeaderItem = ({
  headerType,
  route,
  modalRoute,
  externalUrl,
  dataQa,
  analyticsName,
}: HeaderItemProps) => {
  const { isMobile } = useWindowWidthState();
  const history = useHistory();
  const location = useLocation();
  const openModal = useOpenModal();
  const { Icon, text, iconOnlyMobile } = config[headerType];

  const onPress = useCallback(() => {
    trackCTAClick(analyticsName, 'nav');
    if (isMobile && modalRoute) {
      openModal(modalRoute, undefined, true);
    } else if (externalUrl) {
      globalThis.window.open(externalUrl, '_blank');
    } else if (route) {
      history.push(route);
    }
  }, [externalUrl, route, modalRoute, openModal, isMobile, history, analyticsName]);

  const isSelected = !!route && location.pathname.includes(route);

  return (
    <Styled.NavHeaderButton
      colorRoleType="surfaceDefault"
      isActive={isSelected}
      onPress={onPress}
      dataQa={dataQa}
      disableSurfaceStyles={isMobile}
      iconOnly={iconOnlyMobile}
      as="nav"
      allowOnKeyDownPropagation
    >
      {({ isActive }) =>
        iconOnlyMobile && isMobile ? (
          <Icon size="huge" colorType="brand" state={isActive ? 'pressed' : 'default'} />
        ) : (
          <>
            <Icon
              size={isMobile ? 'huge' : 'standard'}
              colorType="brand"
              variant={isActive ? 'filled' : 'default'}
            />
            <Styled.NavigationButtonText>{text}</Styled.NavigationButtonText>
          </>
        )
      }
    </Styled.NavHeaderButton>
  );
};

export default HeaderItem;
