import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { safeIonicWrapper } from '../../ionicUtils';

interface GetIonicStorageResponse {
  value?: string | null;
  errorMessage?: string;
  isError?: boolean;
}

interface SetIonicStorageProps {
  key: string;
  value: string;
  callback?: () => void;
}

export const PASSCODE_KEY = 'ionic_passcode';

export const PASSCODE_ATTEMPTS = 'ionic_passcode_attempts';

export const PASSCODE_ENABLED = 'ionic_passcode_enabled';

export const DENIED_PERMISSION = 'denied_permission';

export const OPEN_APP_REVIEW = 'open_app_review';

export const setIonicStorage = safeIonicWrapper(
  async ({ key, value, callback }: SetIonicStorageProps): Promise<GetIonicStorageResponse> => {
    await SecureStoragePlugin.set({ key, value }).catch((err) => {
      return { isError: true, errorMessage: 'Error setting ionic storage' };
    });
    if (callback) {
      callback();
    }
    return {};
  },
  Promise.resolve({})
);

export const getIonicStorage = safeIonicWrapper(
  async (key: string): Promise<GetIonicStorageResponse | null> => {
    try {
      const storage = await SecureStoragePlugin.get({ key });
      if (storage) {
        return { value: storage.value };
      }
      return null;
    } catch (error) {
      return { isError: true, errorMessage: `Error getting value for ${key}` };
    }
  },
  Promise.resolve(null)
);

export const removeIonicStorage = async (key: string): Promise<GetIonicStorageResponse> => {
  await SecureStoragePlugin.remove({ key }).catch(() => {
    return { isError: true, errorMessage: 'Error removing storage' };
  });
  return {};
};

export const clearIonicStorage = async (): Promise<GetIonicStorageResponse> => {
  await SecureStoragePlugin.clear().catch(() => {
    return { isError: true, errorMessage: 'Error clearing storage' };
  });
  return {};
};

// TODO: Consider moving to their own module
export const getIsIonicPasscodeExist = async (): Promise<boolean> => {
  try {
    const savedPasscode = await SecureStoragePlugin.get({ key: PASSCODE_KEY });
    return !!savedPasscode.value;
  } catch (error) {
    return false;
  }
};

export const removeIonicPasscode = async (): Promise<GetIonicStorageResponse> => {
  await SecureStoragePlugin.remove({ key: PASSCODE_KEY }).catch(() => {
    return { isError: true, errorMessage: 'Error removing storage' };
  });
  return {};
};

export const getIonicPasscode = async (): Promise<GetIonicStorageResponse> => {
  try {
    const savedPasscode = await SecureStoragePlugin.get({ key: PASSCODE_KEY });
    return { value: savedPasscode.value || null };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { errorMessage: error };
  }
};

export const getIonicPasscodeAttempts = async (): Promise<number> => {
  try {
    const passcodeAttempts = await SecureStoragePlugin.get({ key: PASSCODE_ATTEMPTS });
    return Number(passcodeAttempts.value) || 0;
  } catch (error) {
    return 0;
  }
};

export const getIonicPasscodeEnabled = async (): Promise<GetIonicStorageResponse> => {
  try {
    const savedPasscode = await SecureStoragePlugin.get({ key: PASSCODE_ENABLED });
    return { value: savedPasscode.value === 'true' ? 'true' : null };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { errorMessage: error };
  }
};

export const setIonicPasscodeEnabled = (value: boolean): Promise<GetIonicStorageResponse> =>
  setIonicStorage({ key: PASSCODE_ENABLED, value: value ? 'true' : 'false' });
