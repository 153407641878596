export type SLAStatus = 'ready' | 'waiting' | 'missed' | 'resolved' | 'unavailable' | 'none';

export interface ApiResponse {
  id: number;
  eligibleForCoupon: number;
  extendedCount: number;
  missesCurrentWeek: number;
  originalReplyBy: string;
  replyBy: string;
  roomID: number;
  status: SLAStatus;
  therapistRespondedAt: string;
}

export default class SLA {
  id: number;

  eligibleForCoupon: boolean;

  extendedCount: number;

  missesCurrentWeek: number;

  originalReplyBy?: Date;

  replyBy?: Date;

  roomID: number;

  status: SLAStatus;

  therapistRespondedAt?: Date;

  constructor(apiResponse: ApiResponse) {
    this.id = apiResponse.id;
    this.eligibleForCoupon = Boolean(apiResponse.eligibleForCoupon);
    this.extendedCount = apiResponse.extendedCount;
    this.missesCurrentWeek = apiResponse.missesCurrentWeek;
    this.originalReplyBy = apiResponse.originalReplyBy
      ? new Date(apiResponse.originalReplyBy)
      : undefined;
    this.replyBy = apiResponse.replyBy ? new Date(apiResponse.replyBy) : undefined;
    this.roomID = apiResponse.roomID;
    this.status = apiResponse.status || 'unavailable';
    this.therapistRespondedAt = apiResponse.therapistRespondedAt
      ? new Date(apiResponse.therapistRespondedAt)
      : undefined;
  }
}

export interface State {
  SLA: SLA;
  nextReplyTime: string;
  isLoading: boolean;
  error: string;
}

export type Action =
  | { type: 'getGetSLA' }
  | { type: 'getCreateSLA' }
  | { type: 'getNextReplyTime' }
  | { type: 'getApplyCoupon' }
  | { type: 'getSuggestTherapists' }
  | { type: 'resetSLA' }
  | { type: 'receiveGetSLA'; payload: Partial<State> }
  | { type: 'receiveCreateSLA'; payload: Partial<State> }
  | { type: 'receiveGetNextReplyTime'; payload: Partial<State> }
  | { type: 'setError'; error: string };

export const initialState: State = {
  SLA: {} as unknown as SLA,
  nextReplyTime: '',
  isLoading: false,
  error: '',
};

export const SLAReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'getGetSLA':
    case 'getCreateSLA':
    case 'getNextReplyTime':
    case 'getApplyCoupon':
    case 'getSuggestTherapists':
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case 'receiveGetSLA':
    case 'receiveCreateSLA':
    case 'receiveGetNextReplyTime':
      return {
        ...state,
        isLoading: false,
        error: '',
        ...action.payload,
      };
    case 'setError':
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case 'resetSLA':
      return { ...initialState };
    default:
      return state;
  }
};
