import {
  NAV_SHELL_HEADER_HEIGHT,
  NAV_SHELL_MOBILE_HEADER_HEIGHT,
  NAV_SHELL_MOBILE_FOOTER_HEIGHT,
} from 'ts-frontend/constants';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useWindowWidthState } from '@talkspace/react-toolkit';
import { useLocation } from '@/core/routerLib/routerLib';

/*
 * designed to pass props to ResponsiveLayoutWithHeader
 */

const useNavShellWindowHeight = () => {
  const location = useLocation();
  const { isMobile, isTablet } = useWindowWidthState();
  const useNewNav = useNewMemberNav();
  if (!useNewNav) {
    return {
      useNewNav,
      outsideHeaderHeight: undefined,
      outsideFooterHeight: undefined,
    };
  }

  const isInMobileVideoCall = isMobile && location.pathname.includes('/modal/video-call');
  const mobileHeaderHeight = isInMobileVideoCall ? 0 : NAV_SHELL_MOBILE_HEADER_HEIGHT;
  const mobileFooterHeight = isInMobileVideoCall ? 0 : NAV_SHELL_MOBILE_FOOTER_HEIGHT;
  const newNavHeaderHeight = isMobile ? mobileHeaderHeight : NAV_SHELL_HEADER_HEIGHT;
  const newNavFooterHeight = isMobile || isTablet ? mobileFooterHeight : 0;
  return {
    useNewNav,
    outsideHeaderHeight: newNavHeaderHeight,
    outsideFooterHeight: newNavFooterHeight,
  };
};

export default useNavShellWindowHeight;
