import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M10.125 19.0435C10.125 18.0083 9.28516 17.1685 8.25 17.1685H4.5C4.15625 17.1685 3.875 16.8872 3.875 16.5435V5.29346C3.875 4.94971 4.15625 4.66846 4.5 4.66846H19.5C19.8438 4.66846 20.125 4.94971 20.125 5.29346V16.5435C20.125 16.8872 19.8438 17.1685 19.5 17.1685H14.082C13.6758 17.1685 13.2812 17.3013 12.957 17.5435L10.125 19.6685V19.0435ZM10.1172 22.02L10.125 22.0122L14.082 19.0435H19.5C20.8789 19.0435 22 17.9224 22 16.5435V5.29346C22 3.91455 20.8789 2.79346 19.5 2.79346H4.5C3.12109 2.79346 2 3.91455 2 5.29346V16.5435C2 17.9224 3.12109 19.0435 4.5 19.0435H6.375H8.25V20.9185V21.0747V21.0864V21.3364V22.1685C8.25 22.4067 8.38281 22.6216 8.59375 22.7271C8.80469 22.8325 9.05859 22.8091 9.25 22.6685L9.91797 22.1685L10.1172 22.02ZM16.4141 8.77002C16.7812 8.40283 16.7812 7.80908 16.4141 7.4458C16.0469 7.08252 15.4531 7.07861 15.0898 7.4458L10.7539 11.7817L8.91797 9.9458C8.55078 9.57861 7.95703 9.57861 7.59375 9.9458C7.23047 10.313 7.22656 10.9067 7.59375 11.27L10.0938 13.77C10.4609 14.1372 11.0547 14.1372 11.418 13.77L16.4141 8.77002Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const MessagePlus = withDSIconMaker(pathConfig, ' icon');

export default MessagePlus;
