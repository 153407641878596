import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const smallPath =
  'M7.47024 10.781C7.76321 11.0739 8.23899 11.0739 8.53196 10.781L13.032 6.28096C13.3249 5.98799 13.3249 5.51221 13.032 5.21924C12.739 4.92627 12.2632 4.92627 11.9702 5.21924L7.99993 9.18955L4.02961 5.22158C3.73665 4.92861 3.26086 4.92861 2.9679 5.22158C2.67493 5.51455 2.67493 5.99033 2.9679 6.2833L7.4679 10.7833L7.47024 10.781Z';
const standardPath =
  'M9.29377 15.1026C9.6844 15.4933 10.3188 15.4933 10.7094 15.1026L16.7094 9.10264C17.1 8.71201 17.1 8.07764 16.7094 7.68701C16.3188 7.29639 15.6844 7.29639 15.2938 7.68701L10 12.9808L4.70627 7.69014C4.31565 7.29951 3.68127 7.29951 3.29065 7.69014C2.90002 8.08076 2.90002 8.71514 3.29065 9.10576L9.29065 15.1058L9.29377 15.1026Z';
const majorPath =
  'M11.2097 18.0249C11.6449 18.4601 12.3487 18.4601 12.7793 18.0249L21.6736 9.13525C22.1088 8.70002 22.1088 7.99626 21.6736 7.56567C21.2384 7.13507 20.5346 7.13044 20.104 7.56567L12.0014 15.6682L3.89426 7.56104C3.45904 7.12581 2.75528 7.12581 2.32468 7.56104C1.89409 7.99626 1.88946 8.70002 2.32468 9.13062L11.2097 18.0249Z';

const pathConfig: IconConfig = {
  small: {
    default: { path: smallPath },
  },
  standard: {
    default: { path: standardPath },
  },
  major: {
    default: { path: majorPath },
  },
};

const ChevronDown = withDSIconMaker(pathConfig, 'chevron down icon');

export default ChevronDown;
