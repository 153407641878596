import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M10 13.6465C9.53587 13.6465 9.09075 13.8309 8.76256 14.159C8.43437 14.4872 8.25 14.9324 8.25 15.3965C8.25 15.8606 8.43437 16.3057 8.76256 16.6339C9.09075 16.9621 9.53587 17.1465 10 17.1465C10.4641 17.1465 10.9092 16.9621 11.2374 16.6339C11.5656 16.3057 11.75 15.8606 11.75 15.3965C11.75 14.9324 11.5656 14.4872 11.2374 14.159C10.9092 13.8309 10.4641 13.6465 10 13.6465ZM10 8.64648C9.53587 8.64648 9.09075 8.83086 8.76256 9.15905C8.43437 9.48724 8.25 9.93236 8.25 10.3965C8.25 10.8606 8.43437 11.3057 8.76256 11.6339C9.09075 11.9621 9.53587 12.1465 10 12.1465C10.4641 12.1465 10.9092 11.9621 11.2374 11.6339C11.5656 11.3057 11.75 10.8606 11.75 10.3965C11.75 9.93236 11.5656 9.48724 11.2374 9.15905C10.9092 8.83086 10.4641 8.64648 10 8.64648ZM11.75 5.39648C11.75 4.93236 11.5656 4.48724 11.2374 4.15905C10.9092 3.83086 10.4641 3.64648 10 3.64648C9.53587 3.64648 9.09075 3.83086 8.76256 4.15905C8.43437 4.48724 8.25 4.93236 8.25 5.39648C8.25 5.86061 8.43437 6.30573 8.76256 6.63392C9.09075 6.96211 9.53587 7.14648 10 7.14648C10.4641 7.14648 10.9092 6.96211 11.2374 6.63392C11.5656 6.30573 11.75 5.86061 11.75 5.39648Z';
const majorPath =
  'M12 17.6391C11.4492 17.6391 10.9209 17.8579 10.5314 18.2474C10.1419 18.6369 9.9231 19.1652 9.9231 19.716C9.9231 20.2669 10.1419 20.7952 10.5314 21.1847C10.9209 21.5742 11.4492 21.793 12 21.793C12.5509 21.793 13.0791 21.5742 13.4686 21.1847C13.8581 20.7952 14.0769 20.2669 14.0769 19.716C14.0769 19.1652 13.8581 18.6369 13.4686 18.2474C13.0791 17.8579 12.5509 17.6391 12 17.6391ZM12 10.716C11.4492 10.716 10.9209 10.9349 10.5314 11.3244C10.1419 11.7139 9.9231 12.2421 9.9231 12.793C9.9231 13.3438 10.1419 13.8721 10.5314 14.2616C10.9209 14.6511 11.4492 14.8699 12 14.8699C12.5509 14.8699 13.0791 14.6511 13.4686 14.2616C13.8581 13.8721 14.0769 13.3438 14.0769 12.793C14.0769 12.2421 13.8581 11.7139 13.4686 11.3244C13.0791 10.9349 12.5509 10.716 12 10.716ZM14.0769 5.86989C14.0769 5.59715 14.0232 5.32707 13.9188 5.07509C13.8145 4.8231 13.6615 4.59415 13.4686 4.40129C13.2758 4.20843 13.0468 4.05544 12.7948 3.95107C12.5428 3.84669 12.2728 3.79297 12 3.79297C11.7273 3.79297 11.4572 3.84669 11.2052 3.95107C10.9532 4.05544 10.7243 4.20843 10.5314 4.40129C10.3386 4.59415 10.1856 4.8231 10.0812 5.07509C9.97682 5.32707 9.9231 5.59715 9.9231 5.86989C9.9231 6.14264 9.97682 6.41271 10.0812 6.6647C10.1856 6.91668 10.3386 7.14564 10.5314 7.3385C10.7243 7.53136 10.9532 7.68434 11.2052 7.78872C11.4572 7.89309 11.7273 7.94682 12 7.94682C12.2728 7.94682 12.5428 7.89309 12.7948 7.78872C13.0468 7.68434 13.2758 7.53136 13.4686 7.3385C13.6615 7.14564 13.8145 6.91668 13.9188 6.6647C14.0232 6.41271 14.0769 6.14264 14.0769 5.86989Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
  major: {
    default: { path: majorPath },
  },
};

const EllipsisVertical = withDSIconMaker(pathConfig, 'three dots dropdown icon');

export default EllipsisVertical;
