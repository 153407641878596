import { View } from '../../../components';
import styled from '../../../core/styled';

const Divider = styled(View)(({ theme: { colorRoles } }) => {
  return {
    border: `1px solid ${colorRoles.borders.borderDefault}`,
    width: '100%',
  };
});

export default Divider;
