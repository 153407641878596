import { FunctionComponent, useEffect, useCallback } from 'react';
import { View, useScrollIntoInputs } from '@talkspace/react-toolkit';
import styled from '../../core/styled/index';
import ReviewTherapist from '../components/ReviewTherapist/index';
import { RouteComponentProps } from '../../core/routerLib/routerLib';
import LoadingScreen from '../../screens/LoadingScreen';
import useTherapistReview from '../hooks/useTherapistReview';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';
import { useMainActions, useMainState } from '../../hooks/mainContext';
import { getUserData } from '../../auth/helpers/token';
import { extractRoomEntitiesFromState } from '../../utils/extractRoomEntitiesFromState';

const Wrapper = styled(View)({
  flex: 1,
  height: '100%',
});

const ReviewTherapistContainer: FunctionComponent<
  RouteComponentProps<{
    roomID: string;
  }>
> = ({ match, history }) => {
  const { roomID } = match.params;
  const [state, { postTherapistReview, getTherapistInfo }] = useTherapistReview();
  const { getAllRooms } = useMainActions();
  const { id: clientUserID } = getUserData();
  const { room } = extractRoomEntitiesFromState(useMainState(), Number(roomID));

  useEffect(() => {
    getAllRooms(clientUserID);
  }, [clientUserID, getAllRooms]);

  useScrollIntoInputs();

  const closeFrameIfExists = () => {
    if (ReactFrameService.instance().isInFrame()) {
      ReactFrameService.instance().closePopup();
      return true;
    }
    return false;
  };

  const onBackButtonPress = () => {
    if (closeFrameIfExists()) return;
    history.goBack();
  };

  const onCloseButtonPress = useCallback(() => {
    if (closeFrameIfExists()) return;
    history.push(`/room/${roomID}`);
  }, [history, roomID]);

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (state && Object.keys(state.therapistReviewData).includes('success')) {
      setTimeout(() => {
        onCloseButtonPress();
      }, 1000);
    }
  }, [onCloseButtonPress, state]);

  useEffect(() => {
    getTherapistInfo(Number(roomID));
  }, [getTherapistInfo, roomID]);

  if (!state.isTherapistDataReady || !room) return <LoadingScreen />;

  return (
    <Wrapper>
      <ReviewTherapist
        isLoading={state.isLoading}
        error={state.error}
        handleBackButtonPress={onBackButtonPress}
        handleCloseButtonPress={onCloseButtonPress}
        therapistID={state.therapist.id || 0}
        therapistName={state.therapist.firstName || ''}
        postReview={postTherapistReview}
        room={room}
      />
    </Wrapper>
  );
};

export default ReviewTherapistContainer;
