import { Standard, TextDS } from '@talkspace/react-toolkit';

import styled from '@/core/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';

import useWindowWidth from '../../hooks/useWindowWidth';
import MenuNavLink from '../MenuNavLink';
import Checkmark2 from '../Icons/Checkmark2';

const SettingLabel = styled(TextDS)<{
  isSelected: boolean;
}>(({ isSelected }) => {
  return {
    marginLeft: isSelected ? 9 : 33,
    overflow: 'hidden',
    ...webOnlyStyle({
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),
  };
});

interface Props<T extends unknown> {
  options: T[];
  onSelect: (item: T) => void;
  checkIfSelected: (item: T) => boolean;
  getKey: (item: T) => string;
  getDisplayName: (item: T) => string;
}

const SettingsList = <T extends unknown>({
  options,
  onSelect,
  checkIfSelected,
  getKey,
  getDisplayName,
}: Props<T>) => {
  const { isMobile } = useWindowWidth();

  if (!options.length) {
    return (
      <Standard variant="standardDarkGrey" style={{ marginLeft: 45, marginTop: 11 }}>
        None available
      </Standard>
    );
  }

  return (
    <>
      {options.map((setting) => {
        const isSelected = checkIfSelected(setting);
        return (
          <MenuNavLink key={getKey(setting)} row onPress={() => onSelect(setting)}>
            {isSelected && <Checkmark2 style={{ marginLeft: 12 }} />}

            <SettingLabel variant={isMobile ? 'body' : 'bodySm'} isSelected={isSelected}>
              {getDisplayName(setting)}
            </SettingLabel>
          </MenuNavLink>
        );
      })}
    </>
  );
};

export default SettingsList;
