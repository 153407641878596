import { Button, TextDS, View, spacing, useWindowWidthState } from '@talkspace/react-toolkit';
import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import { CalendarPlus } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { ClientUsageStats, Subscription } from 'ts-frontend/types';
import { getBookSessionCTAText } from 'ts-frontend/utils/displayStrings';
import { getIsMessagingEnabled } from 'ts-frontend/helpers/subscription';
import { useHistory } from '@/core/routerLib';
import { trackCTAClick } from '../../utils/analytics/events';

const { space100, space400, space800 } = spacing;

const BookingsListEmpty = ({
  clientUsageStats,
  subscriptions,
}: {
  clientUsageStats: ClientUsageStats | undefined;
  subscriptions: Subscription[];
}) => {
  const { isMobile } = useWindowWidthState();
  const history = useHistory();
  const { completedSessionsTotal } = clientUsageStats || { completedSessionsTotal: 0 };
  const titleText = 'You don’t have anything booked yet';
  const hasMessagingEnabledPlan = !!subscriptions.find((subscription) =>
    getIsMessagingEnabled({ subscription })
  );
  const subtitleText =
    completedSessionsTotal < 12
      ? 'Let’s keep your momentum going! It can take about 12 sessions to start feeling better.'
      : 'Let’s keep your momentum going! Book another session to continue your progress.';
  const onPress = () => {
    trackCTAClick('Book a session', 'schedule');
    history.push('/in-room-scheduling/choose-provider');
  };
  return (
    <View align="center" style={{ marginTop: space800 }}>
      <CircleUnderlay size={136}>
        <CalendarPlus height={73} width={74} />
      </CircleUnderlay>
      <TextDS variant="headingLg" style={{ marginTop: space400 }}>
        {titleText}
      </TextDS>
      <TextDS
        variant="bodySm"
        colorRole="textSubtle"
        style={{ textAlign: 'center', marginTop: space100 }}
      >
        {subtitleText}
      </TextDS>
      <Button
        dataQa="bookingsListEmptyBookOrStartSession"
        text={getBookSessionCTAText({ isMessagingEnabled: hasMessagingEnabledPlan })}
        style={{ width: isMobile ? '100%' : 'fit-content', marginTop: space400 }}
        onPress={onPress}
      />
    </View>
  );
};

export default BookingsListEmpty;
