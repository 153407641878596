import { AccountType } from '../entities/Room';

import { Nullable } from './UserDefined';
import { InvoiceLineItem } from './payment';

type Currency = 'USD' | 'GBP' | 'AUD';

const planModalityDict = {
  messagingOnly: 'messagingOnly',
  messagingAndLive: 'messagingAndLive',
  liveOnly: 'liveOnly',
};
type PlanModality = keyof typeof planModalityDict;
interface ValidatedCoupon {
  amount: number;
  code: string;
  isRecurring: boolean;
}

export interface SubscriptionInfo {
  planID: number;
  name: string;
  price: string;
  discounts?: ValidatedCoupon[];
  voucherValue?: number;
  totalPrice?: number;
  lineItems?: InvoiceLineItem[];
  planDisplayName: string;
  billingCycleUnit?: string;
  billingCycleValue?: number;
  creditMonthlyCount: number;
  creditRenewalDate: Date;
  renewalDate?: string;
  endSubscriptionDate?: string;
  endOnHold?: string;
  canChangeProvider: boolean;
  canChangePlan: boolean;
  canCreateBooking: boolean;
  isActive: boolean;
  isIntroCreditEligible: boolean;
  isTrial: boolean;
  isFreeConsultation: boolean;
  isClosedInactive: boolean;
  isCanceled: boolean;
  isPendingSubscription: boolean;
  trialEnd?: string;
  currency: Currency;
  isB2B: boolean;
  isB2C: boolean;
  isBH: boolean;
  canPauseService: boolean;
  customerBalanceCents?: number;
  showNeedHelp: boolean;
}

interface Therapist {
  image: string;
  fullName: string;
  title: string;
  type: string;
}

export interface VideoCredit {
  creditTotal: number;
  creditAvailable?: number;
  creditMinutes?: number;
  creditMinutesOptions?: number[];
  creditRemaining?: number;
  validFrom: string;
  validTo: string;
  type: string;
}

export interface LiveVideoSessionStatus {
  psychiatricEvaluationCompletedAt: Nullable<string>;
  psychiatricEvaluationNextStartTime: Nullable<string>;
  psychiatryFollowUpLastCompletedAt: Nullable<string>;
  psychiatryFollowUpNextStartTime: Nullable<string>;
  introSessionCompletedAt: Nullable<string>;
  introSessionNextStartTime: Nullable<string>;
  therapySessionLastCompletedAt: Nullable<string>;
  therapySessionNextStartTime: Nullable<string>;
  therapySessionScheduledAt: Nullable<string>;
}

export interface InsuranceStatus {
  id: number;
  payerName: string;
  eligibilityStatus: 'not eligible' | 'eligible' | 'timeout';
  roomID: number;
  gracePeriod: number;
  postGracePeriod: number;
  lastEligibilityCheckDate: string;
  isPermanentIneligible: boolean;
}
export interface PlanDetail {
  planID: number;
  planModality: PlanModality;
  accountType: AccountType;
}

export interface FirstPurchase {
  conversionDate: Date;
}

export interface Subscription {
  id: number;
  therapistID: number;
  participantID: number;
  participantEmail: string;
  roomType: string;
  status: number;
  subscription: SubscriptionInfo;
  participantCountry: string;
  therapist: Therapist;
  videoCredits?: VideoCredit[];
  liveVideoSessionStatus?: LiveVideoSessionStatus;
  insuranceStatus?: InsuranceStatus;
  isClosedIneligible?: boolean;
  planDetail?: PlanDetail;
  firstPurchase?: FirstPurchase;
  redeemedVideoCredit?: VideoCredit;
  funnelVariation: string;
}
