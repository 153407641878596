import { PaymentDetails, CreditMinutes } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Checkout } from 'checkout';
import {
  View,
  Spinner,
  useWindowWidth,
  SparklingCalendar,
  Button,
  BaseButton,
  Big,
  useEmotionTheme,
  CancellationPolicyMessage,
} from '@talkspace/react-toolkit';

import { getParamByName } from 'ts-frontend/utils';
import {
  trackCancellationPolicyClick,
  trackJoinedAdHocSession,
} from 'ts-analytics/mixpanel/events';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { useRouteMatch, useHistory, useLocation } from '@/core/routerLib';
import styled from '@/core/styled';
import { getUserData } from '@/auth/helpers/token';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import InRoomSchedulingError from '../inRoomSchedulingError';
import BookingAvatars from '../BookingAvatars';
import { deleteLastElementInPath } from '../../utils/url';
import getFunnelName from '../../utils/getFunnelName';
import { InRoomSchedulingLocation, ConfirmBookingOptions, TherapistInfo } from '../../types';
import {
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
} from '../../hooks/inRoomSchedulingContext';
import { BookingDates, BookingDatesOnBoarding } from '../BookingDates';
import { CANCELLATION_WINDOW_HOURS } from '../../utils/constants';
import redirectToLiveSession from '../../../chat/utils/redirectToLiveSession';

import useRepeatingBooking from '../../hooks/useRepeatingBooking';
import BottomButtonContainer from '../BottomButtonContainer';

const AvatarWrapper = styled(View)({});

const getButtonText = ({ modality, schedulerMode, isReschedule, isJoin, isRecurringBooking }) => {
  if (isRecurringBooking && schedulerMode === 'providerScheduled') return 'Confirm sessions';
  if (modality === 'messaging') return 'Start session now';
  if (schedulerMode === 'providerScheduled') return 'Confirm session';
  if (schedulerMode === 'providerScheduled' && isJoin) return 'Join session';
  if (isReschedule) return 'Reschedule session';
  return isRecurringBooking ? 'Book sessions' : 'Book session';
};

interface Props {
  isTherapist: boolean;
  handleConfirmAppointment: (options: Partial<ConfirmBookingOptions>) => void;
  onClosePress: () => void;
  therapist?: TherapistInfo;
  paymentDetails?: PaymentDetails;
  bookWithIntroSession?: boolean;
  isFromCheckInWizard: boolean;
  isFromPostLVSCheckInWizard: boolean;
  handleReviewCancellationPolicy: () => void;
}

const BookingCheckout: FunctionComponent<Props> = ({
  onClosePress,
  isTherapist,
  therapist,
  handleConfirmAppointment,
  paymentDetails,
  bookWithIntroSession = false,
  isFromCheckInWizard,
  isFromPostLVSCheckInWizard,
  handleReviewCancellationPolicy,
}) => {
  const { repeatingSessionsFull2, bookSession0Copay: bookSession0CopayActive } = useFlags();
  const {
    params: { roomID },
    url,
  } = useRouteMatch<{ roomID: string }>();
  const location = useLocation<InRoomSchedulingLocation>();
  const history = useHistory();
  const bookingID = getParamByName('bookingID');
  const [redirectIsLoading, setRedirectIsLoading] = useState(false);
  const [totalDueText, setTotalDueText] = useState<string | undefined>(undefined);
  const [chargeInfoText, setChargeInfoText] = useState<string | undefined>(undefined);
  const [chargeTimeText, setChargeTimeText] = useState<string | undefined>(undefined);
  const { colors } = useEmotionTheme();
  const closeModal = useCloseModal();

  const {
    room,
    planInfo,
    lineItems,
    total,
    savings,
    isLoading,
    selectedTimeslot,
    selectedCreditOption,
    selectedCancelBooking,
    selectedConfirmBooking,
    selectedBookingToReschedule,
    shouldShowBookingSuccess,
    isError,
    errorMessage,
    schedulerMode,
    isJoin,
    isClientConfirmError,
    isClientRescheduleError,
    couponState,
    modality,
    isBHAdHoc,
    videoCall,
    videoCallID,
    adHocDuration,
  } = useInRoomSchedulingState();
  const {
    dispatchApplyCoupon,
    dispatchGetCheckoutData,
    dispatchGetBookingToConfirm,
    dispatchGetBookingToReschedule,
    dispatchModalityType,
  } = useInRoomSchedulingActions();

  const { isRecurringBooking, repeatingTimeslots, repeatingMeta } = useRepeatingBooking({
    selectedBooking: selectedConfirmBooking,
    onlyConfirmedOrTentative: true,
  });

  const isCouples = room?.roomType === 'couples_room';
  const isBH = room?.isSessionBased;
  const isPsychiatry = room?.roomType === 'psychiatry_room';
  const planAllowsCoupons = planInfo ? planInfo.allowCoupons !== false : true;

  const { isReschedule = false, roomID: stateRoomID, flowVariant } = location.state || {};
  const { isMobile } = useWindowWidth();
  const { id: userID } = getUserData();

  const handleClickCancellationPolicy = () => {
    trackCancellationPolicyClick({
      userID,
      roomID: Number(roomID),
      providerID: therapist?.id!,
      planID: selectedCreditOption?.planID || selectedConfirmBooking?.planID || undefined,
      sessionModality: modality,
      flow: 'Checkout',
    });
    handleReviewCancellationPolicy();
  };

  const handleDeclineClick = useCallback(() => {
    history.push(
      `/in-room-scheduling/room/${roomID}/decline-booking/booking/${bookingID}?batch=true`
    );
  }, [bookingID, history, roomID]);

  useEffect(() => {
    if (shouldShowBookingSuccess) {
      if (isJoin && bookingID) {
        dispatchGetBookingToConfirm(Number(roomID), bookingID);
      } else {
        history.push(`${deleteLastElementInPath(url)}/booking-success?batch=${isRecurringBooking}`);
      }
    }
  }, [
    shouldShowBookingSuccess,
    history,
    url,
    isJoin,
    roomID,
    bookingID,
    dispatchGetBookingToConfirm,
    selectedConfirmBooking,
    isRecurringBooking,
  ]);

  useEffect(() => {
    if (bookingID) {
      const finalRoomID = bookWithIntroSession ? stateRoomID : Number(roomID);
      if (isReschedule) {
        dispatchGetBookingToReschedule(Number(finalRoomID), bookingID);
      } else {
        const ignoreCreditForRecurring = true;
        dispatchGetBookingToConfirm(Number(finalRoomID), bookingID, ignoreCreditForRecurring);
      }
    }
  }, [
    bookingID,
    roomID,
    stateRoomID,
    bookWithIntroSession,
    dispatchGetBookingToConfirm,
    isReschedule,
    dispatchGetBookingToReschedule,
  ]);

  useEffect(() => {
    if (isReschedule && selectedBookingToReschedule?.modality) {
      dispatchModalityType(selectedBookingToReschedule.modality);
    } else if (selectedConfirmBooking?.modality) {
      dispatchModalityType(selectedConfirmBooking.modality);
    }
  }, [selectedConfirmBooking, dispatchModalityType, isReschedule, selectedBookingToReschedule]);

  useEffect(() => {
    if (isError) return;
    if (schedulerMode === 'providerScheduled') {
      let planID = selectedConfirmBooking?.planID
        ? selectedConfirmBooking?.planID
        : selectedCreditOption?.planID;

      if (!planID && isReschedule && selectedCancelBooking?.planID) {
        planID = selectedCancelBooking?.planID;
      }

      if (!planID && room?.planID) {
        ({ planID } = room);
      }

      if (
        (isRecurringBooking ||
          isJoin ||
          isReschedule ||
          selectedConfirmBooking?.videoCreditID ||
          (!selectedCreditOption?.availableCredits &&
            !selectedCreditOption?.isEligibilityFileBH)) &&
        planID
      ) {
        // if it's isJoin screen, or reschedule, or the booking have a credit or the user doesn't have an appropriate credit and the user have planID
        // get checkout data to allow them to purchase a credit
        dispatchGetCheckoutData(planID, isRecurringBooking);
      }
    }
  }, [
    dispatchGetCheckoutData,
    isError,
    isJoin,
    isReschedule,
    room,
    schedulerMode,
    selectedCancelBooking?.planID,
    selectedConfirmBooking?.planID,
    selectedConfirmBooking?.videoCreditID,
    selectedCreditOption?.availableCredits,
    selectedCreditOption?.isEligibilityFileBH,
    selectedCreditOption?.planID,
    isRecurringBooking,
  ]);

  useEffect(() => {
    if (
      schedulerMode === 'clientScheduled' &&
      selectedCreditOption &&
      selectedCreditOption.planID
    ) {
      dispatchGetCheckoutData(selectedCreditOption.planID, isRecurringBooking);
    }
  }, [dispatchGetCheckoutData, selectedCreditOption, schedulerMode, isRecurringBooking]);

  let shouldRedeemCredit = false;
  if (selectedCancelBooking && isReschedule) {
    const startTimeMinus24hrs = moment(selectedCancelBooking.startTime).subtract(
      CANCELLATION_WINDOW_HOURS,
      'hours'
    );
    const isClientScheduledBooking = selectedCancelBooking.scheduledByUserType === 'client';
    const isBookingConfirmed = selectedCancelBooking.timekitBookingState === 'confirmed';
    // redeem credit if prev booking is less than 24 hours away and the client scheduled or confirmed it
    shouldRedeemCredit =
      (isClientScheduledBooking || isBookingConfirmed) && moment().isAfter(startTimeMinus24hrs);
  }

  const buttonText = getButtonText({
    modality,
    schedulerMode,
    isReschedule,
    isJoin,
    isRecurringBooking,
  });

  useEffect(() => {
    if (
      !shouldShowBookingSuccess &&
      planInfo &&
      therapist &&
      selectedCreditOption &&
      !isTherapist
    ) {
      trackEvent('Session Payment', {
        amountDue: Number(
          total.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })
        ),
        therapistID: therapist.id,
        roomID,
        planID: planInfo.id,
        modality,
        creditType: selectedCreditOption.type,
        sessionLengthMinutes: modality === 'messaging' ? null : selectedCreditOption.creditMinutes,
        funnelName: getFunnelName({ isFromCheckInWizard, isFromPostLVSCheckInWizard }),
      });
    }
  }, [
    isFromCheckInWizard,
    isFromPostLVSCheckInWizard,
    isTherapist,
    modality,
    planInfo,
    roomID,
    selectedCreditOption,
    shouldShowBookingSuccess,
    therapist,
    total,
  ]);

  useEffect(() => {
    if (isBHAdHoc && videoCall?.callStartedAt && therapist) {
      if (planInfo) {
        trackJoinedAdHocSession({
          userID,
          roomID: Number(roomID),
          sessionModality: videoCall.modality,
          providerID: therapist.id,
          sessionLength: videoCall.booking.creditMinutes,
          planID: planInfo.id,
        });
      }

      if (ReactFrameService.instance().isInFrame()) {
        closeModal({
          navigateTo: 'liveCall',
          metadata: {
            roomID: Number(roomID),
            modality: videoCall.modality,
            creditMinutes: videoCall.booking.creditMinutes as CreditMinutes,
            videoCallID: videoCall.videoCallID,
          },
        });
      } else {
        const videoCallState = {
          therapistUserID: therapist.id,
          roomID: Number(roomID),
          therapistFirstName: videoCall.therapist.therapistFirstName,
          therapistLastName: videoCall.therapist.therapistLastName,
          startTime: videoCall.booking.startTime,
          creditMinutes: videoCall.booking.creditMinutes,
          videoCreditType: videoCall.booking.type,
          videoCallID: videoCall.videoCallID,
          modality: videoCall.modality,
          tokenExpiresAt: moment(videoCall.booking.startTime)
            .add(videoCall.booking.creditMinutes || 30, 'minute')
            .toISOString(),
        };

        setRedirectIsLoading(true);
        redirectToLiveSession(Number(roomID), videoCallState);
      }
    }
  }, [isBHAdHoc, roomID, therapist, videoCall, closeModal, planInfo, userID]);

  useEffect(() => {
    if (selectedCreditOption && room) {
      if (isBH) {
        if (selectedCreditOption.omitCopay) {
          setTotalDueText('Your cost per session');
          setChargeInfoText(
            'Your cost per session will vary based on whether you have supplemental coverage.'
          );
          setChargeTimeText(`Charged after ${isRecurringBooking ? 'each' : 'the'} session`);
        } else {
          setTotalDueText('Copay');
          setChargeInfoText(
            'Your copay may not be accurately reflected in the amount shown here. If your plan has a cost-share that is different from the above, the charge will be adjusted after each session is processed.'
          );
          setChargeTimeText(
            `Charged at the time of ${isRecurringBooking ? 'each' : 'the'} session`
          );
        }
      } else {
        setTotalDueText(undefined);
        setChargeInfoText(undefined);
        setChargeTimeText(undefined);
      }
    }
  }, [selectedCreditOption, isBH, room, isRecurringBooking]);

  const handleChangePaymentClick = () => {
    const baseURL = `${deleteLastElementInPath(url)}/payment-details`;
    const searchParams = new URLSearchParams();
    if (isBHAdHoc && videoCallID && adHocDuration) {
      searchParams.append('isBHAdHoc', String(isBHAdHoc));
      searchParams.append('videoCallID', String(videoCallID));
      searchParams.append('adHocDuration', String(adHocDuration));
    }
    history.push(`${baseURL}?${searchParams.toString()}`);
  };

  if (isError && errorMessage && !isClientRescheduleError) {
    history.push(`${deleteLastElementInPath(url)}/payment-details`);
    return null;
  }

  if (
    isError ||
    (!isReschedule && isClientConfirmError) ||
    (isReschedule && isClientRescheduleError)
  ) {
    return <InRoomSchedulingError onClosePress={onClosePress} />;
  }

  if ((!planInfo && isLoading) || redirectIsLoading) {
    return <Spinner />;
  }

  if (
    modality !== 'messaging' &&
    !isBHAdHoc &&
    (!therapist || !selectedTimeslot || !selectedCreditOption)
  ) {
    return null;
  }

  const getAvatar = () => {
    if (flowVariant === 'bookAndActivate') {
      return <SparklingCalendar style={{ marginTop: 16 }} />;
    }
    return (
      therapist && (
        <AvatarWrapper>
          <BookingAvatars
            therapistUserID={therapist.id}
            selectedTimeslot={selectedTimeslot}
            modality={modality}
            shouldShowBookingSuccess={shouldShowBookingSuccess}
            isCouples={isCouples}
            isSmallAvatars
          />
        </AvatarWrapper>
      )
    );
  };

  const getBookingDate = () =>
    flowVariant === 'bookAndActivate' ? (
      <BookingDatesOnBoarding
        selectedTimeslot={selectedTimeslot}
        selectedCreditOption={selectedCreditOption}
        style={{ marginTop: 16 }}
      />
    ) : (
      <BookingDates
        modality={modality}
        selectedTimeslot={selectedTimeslot}
        selectedCreditOption={selectedCreditOption}
        isRecurringBooking={isRecurringBooking}
        repeatingMeta={repeatingMeta}
        repeatingTimeslots={repeatingTimeslots}
        isTherapist={isTherapist}
      />
    );

  return (
    <View
      flex={isMobile ? 1 : 0}
      align="center"
      justify="space-between"
      style={{ marginBottom: isMobile ? 0 : 45 }}
    >
      <View flex={1} align="center">
        {getAvatar()}
        {getBookingDate()}
        {planInfo && (
          <View style={{ marginTop: 20, width: 335 }}>
            <View style={{ marginBottom: isMobile ? 100 : 0 }}>
              <Checkout
                modality={modality}
                planReviewCardTitle={isBH ? 'Session details' : 'Subscription plan'}
                selectedSubscription={planInfo}
                lineItems={lineItems}
                total={total}
                savings={savings}
                paymentDetails={paymentDetails}
                buttonText={buttonText}
                showInsuranceDisclaimer={isBH}
                onApplyCoupon={dispatchApplyCoupon}
                scrollTop={0}
                isProcessing={isLoading}
                couponState={couponState}
                showCouponField={planAllowsCoupons && isPsychiatry && !isBH}
                onReviewCancellationPolicyClick={
                  isBHAdHoc || modality === 'messaging' ? undefined : handleClickCancellationPolicy
                }
                onChangePayment={handleChangePaymentClick}
                onCompleteCheckout={() =>
                  handleConfirmAppointment({
                    isReschedule,
                    isPurchase: true,
                    shouldRedeemCredit,
                    modality,
                    isRecurringBooking,
                  })
                }
                onSecondaryClick={
                  repeatingSessionsFull2 && isRecurringBooking ? handleDeclineClick : undefined
                }
                secondaryCtaText={
                  repeatingSessionsFull2 && isRecurringBooking ? 'Decline sessions' : undefined
                }
                totalDueText={totalDueText}
                maxCostPostSession={selectedCreditOption?.maxCostPostSession}
                chargeInfoText={chargeInfoText}
                chargeTimeText={chargeTimeText}
                showSavings={!isBH}
                plural={isRecurringBooking}
                isConfirmSession={!!selectedConfirmBooking}
                hideLineItems={
                  (bookSession0CopayActive && isBH && total === 0 && !isTherapist) ||
                  selectedCreditOption?.omitCopay ||
                  modality === 'messaging'
                }
              />
            </View>
            {repeatingSessionsFull2 && isRecurringBooking && isMobile && (
              <BottomButtonContainer
                propsV0={{
                  style: {
                    borderTop: `0.5px solid ${colors.permaLondonGray}`,
                  },
                }}
              >
                <Button
                  disabled={isLoading}
                  isLoading={isLoading}
                  onPress={() =>
                    handleConfirmAppointment({
                      isReschedule,
                      isPurchase: true,
                      shouldRedeemCredit,
                      modality,
                      isRecurringBooking,
                    })
                  }
                  text={buttonText}
                  dataQa={`inRoomSchedulingBookingCheckoutConfirmSessions${
                    isReschedule ? 'Reschedule' : 'Schedule'
                  }`}
                  stretch
                  style={{ marginTop: 16, backgroundColor: colors.permaTalkspaceDarkGreen }}
                />
                <BaseButton
                  style={{ marginTop: 14 }}
                  onPress={handleDeclineClick}
                  dataQa="inRoomSchedulingBookingCheckoutDecline"
                >
                  <Big variant="bigMedium" style={{ color: colors.permaTalkspaceDarkGreen }}>
                    Decline sessions
                  </Big>
                </BaseButton>
                {!room?.isEAP && (
                  <CancellationPolicyMessage
                    dataQa="inRoomSchedulingBooking"
                    onCancellationPolicyPress={handleClickCancellationPolicy}
                    style={{ marginTop: 16 }}
                  />
                )}
              </BottomButtonContainer>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default BookingCheckout;
