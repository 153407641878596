import { Component, HTMLAttributes, RefObject } from 'react';
import * as React from 'react';
import styled from '@emotion/styled';
import {
  View,
  Text,
  Spinner,
  ChatSessionBanner,
  ArrowUp,
  ArrowDown,
  FillContainerBanner,
} from '@talkspace/react-toolkit';
import { CloudSlash } from '@talkspace/react-toolkit/src/designSystems/icons';
import { COLORS } from '@talkspace/react-toolkit/src/constants/commonStyles';
import { getUserData } from '@/auth/helpers/token';
import Banner from '../Banner';
import ScrollViewComponent from '../ScrollViewComponent';
import ChatBanner from '../ChatBanner';
import { createEventHandlerA11y } from './ScrollView.a11y';
import { UserTyping } from '../../redux/constants/chatTypes';
import TypingIndicator from '../TypingIndicator';
import { EActiveSession, ParticipantUsers } from '../../entities/ActiveSession';

interface ScrollViewProps<T> extends HTMLAttributes<HTMLDivElement> {
  items: T[];
  renderRow: (item: T, index: number) => JSX.Element;
  scrollThreshold?: number;
  shouldRenderNewItemAlert?: boolean;
  onScrollTop: () => unknown;
  onScrollBottom: () => unknown;
  scrollViewRef: RefObject<HTMLDivElement>;
  isLoadingHistorical: boolean;
  banners?: JSX.Element[];
  priorityBanner?: JSX.Element;
  isLiveChatBannerOpen: boolean;
  isAccordionOpen: boolean;
  isTherapistChat: boolean;
  handleAccordionToggle: () => void;
  handleEndLiveChat: () => void;
  activeSession: EActiveSession | null;
  SLAComponent?: React.FC<{}>;
  userTyping: UserTyping;
  isOffline?: boolean;
}

const NewItemAlertWrapper = styled(View)<{ hidden?: boolean }>(({ hidden }) => {
  return {
    position: 'sticky',
    bottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: hidden ? 'none' : 'flex',
  };
});

const NewItemButton = styled(View)({
  width: 154,
  height: 35,
  backgroundColor: 'rgba(34, 47, 45, 0.9)',
  fontSize: 13,
  borderRadius: 30,
  boxShadow: '0 2px 4px 0 hsla(174, 100%, 21%, 0.13)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const NewItemAlert = ({ scrollToLastItem, ...otherProps }) => (
  <NewItemAlertWrapper {...otherProps}>
    <NewItemButton onClick={scrollToLastItem}>
      <Text dataQa="newMessageAlert" style={{ color: COLORS.white, fontSize: 15 }}>
        New message
      </Text>
      <ArrowDown style={{ marginLeft: 8, marginRight: 2, marginTop: 2 }} height={9} width={15} />
    </NewItemButton>
  </NewItemAlertWrapper>
);

const ChatBanners = ({
  isLiveChatBannerOn,
  isOffline,
  banners,
}: {
  isLiveChatBannerOn: boolean;
  isOffline?: boolean;
  banners: JSX.Element[];
}) => {
  const numOfBanners = banners?.length || 0;
  const bannerItems = banners?.map((banner, index) => (
    <View
      key={banner.key}
      style={{
        position: 'absolute',
        top: 66 * (numOfBanners > 1 ? index + 1 : index) + (isOffline ? 44 : 0),
        left: 0,
        right: 0,
        zIndex: 2,
      }}
    >
      <Banner showBottomShadow={numOfBanners - 1 === index && !isLiveChatBannerOn}>
        {banners[index]}
      </Banner>
    </View>
  ));
  return <>{bannerItems}</>;
};

const TopBanner: React.FC<{ show: boolean; isOffline?: boolean }> = ({
  show = false,
  isOffline,
  children,
}) =>
  show ? (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,
      }}
    >
      {isOffline && (
        <FillContainerBanner variant="disabled" Icon={CloudSlash} text="No internet connection" />
      )}
      <Banner>{children}</Banner>
    </View>
  ) : null;

export const didClientJoin = (
  participants: ParticipantUsers[] | undefined,
  targetID: number | undefined
): boolean => !!participants?.some(({ userID, joinedAt }) => userID === targetID && joinedAt);

class ScrollView<T> extends Component<ScrollViewProps<T>> {
  eventHandlerA11y: (e: KeyboardEvent) => void;

  prevScrollTop = 0;

  prevScrollHeight = 0;

  ticking = false;

  constructor(props) {
    super(props);
    const { scrollViewRef } = props;
    this.eventHandlerA11y = createEventHandlerA11y(scrollViewRef);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const {
      scrollViewRef: { current },
    } = this.props;
    if (current) {
      current.addEventListener('keydown', this.eventHandlerA11y);
    }
  }

  componentDidUpdate({ isLoadingHistorical: prevIsLoadingHistorical }) {
    const {
      scrollViewRef: { current },
      isLoadingHistorical,
    } = this.props;
    if (current !== null && prevIsLoadingHistorical && !isLoadingHistorical) {
      const prevScrollPosition = current.scrollHeight - this.prevScrollHeight + this.prevScrollTop;
      this.scrollToPosition(prevScrollPosition);
    }
    if (current !== null && this.prevScrollHeight !== current.scrollHeight)
      this.prevScrollHeight = current.scrollHeight;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    const {
      scrollViewRef: { current },
    } = this.props;
    if (current) {
      current.removeEventListener('keydown', this.eventHandlerA11y);
    }
  }

  handleScroll = () => {
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        const { onScrollBottom, onScrollTop, scrollViewRef, scrollThreshold = 100 } = this.props;
        const previouslyAtTop = this.prevScrollTop < scrollThreshold;
        const current = scrollViewRef.current || document.body;
        if (current.scrollTop <= 0 && !previouslyAtTop) {
          // This solves mobile rendering issues
          current.scrollTop = 0;
          this.prevScrollTop = current.scrollTop;
          this.ticking = false;
          if (onScrollTop) onScrollTop();
          return;
        }
        if (current.scrollTop < scrollThreshold && !previouslyAtTop) {
          // on scrolling to the top fire the loading messages history
          if (onScrollTop) onScrollTop();
        } else if (
          this.prevScrollTop !== current.scrollTop &&
          current.scrollHeight - current.scrollTop <= current.clientHeight + scrollThreshold
        ) {
          // dismiss "new message" when reaching towards the bottom of the page
          if (onScrollBottom) onScrollBottom();
        }
        this.prevScrollTop = current.scrollTop;
        this.ticking = false;
      });
      this.ticking = true;
    }
  };

  scrollToPosition = (fromTop) => {
    const {
      scrollViewRef: { current },
    } = this.props;
    const scrollOptions = {
      top: fromTop,
      left: 0,
    };
    if (current && current.scrollTo) current.scrollTo(scrollOptions);
  };

  handleNewItemAlertClick = () => {
    const {
      scrollViewRef: { current },
      onScrollBottom,
    } = this.props;
    if (current) this.scrollToPosition(current.scrollHeight);
    // dismiss "new message"
    if (onScrollBottom) onScrollBottom();
  };

  getChatSessionPosition = (accordionOpen: boolean, bannerCount: number = 0) => {
    if (accordionOpen && bannerCount !== 0) {
      return 66 * (bannerCount + 1);
    }
    if (!accordionOpen && bannerCount !== 0) {
      return 66;
    }

    return 0;
  };

  renderMultiBanners = () => {
    const { banners, isLiveChatBannerOpen, isAccordionOpen, isOffline, handleAccordionToggle } =
      this.props;

    const arrowStyles = {
      style: { width: 21, height: 21, marginTop: 10 },
      fill: COLORS.heatherGrey,
    };

    return (
      <>
        {banners && banners.length > 1 && (
          <View
            style={{
              position: isOffline ? 'relative' : 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }}
          >
            <Banner showBottomShadow={!isAccordionOpen && !isLiveChatBannerOpen}>
              <ChatBanner
                boldTitle
                onPress={handleAccordionToggle}
                title={`You have ${banners.length} pending tasks`}
                overrideRightIcon={
                  isAccordionOpen ? <ArrowUp {...arrowStyles} /> : <ArrowDown {...arrowStyles} />
                }
              />
            </Banner>
          </View>
        )}
        {((isAccordionOpen && banners) || (banners && banners.length === 1)) && (
          <View>
            <ChatBanners
              banners={banners}
              isLiveChatBannerOn={isLiveChatBannerOpen}
              isOffline={isOffline}
            />
          </View>
        )}
      </>
    );
  };

  render() {
    const {
      items,
      renderRow,
      scrollViewRef,
      shouldRenderNewItemAlert,
      isLoadingHistorical,
      style = {},
      SLAComponent,
      userTyping,
      isLiveChatBannerOpen,
      isAccordionOpen,
      banners,
      priorityBanner,
      isTherapistChat,
      activeSession,
      handleEndLiveChat,
      isOffline,
    } = this.props;

    return (
      <>
        <TopBanner show={isLoadingHistorical}>
          <Text>Loading messages...</Text>
          <Spinner isLoading />
        </TopBanner>
        {!isLoadingHistorical && priorityBanner}
        {!priorityBanner && (!!banners || isLiveChatBannerOpen) && (
          <TopBanner show={!isLoadingHistorical} isOffline={isOffline}>
            {this.renderMultiBanners()}
            {isLiveChatBannerOpen && (
              <View
                style={{
                  position: 'absolute',
                  top: this.getChatSessionPosition(isAccordionOpen, banners ? banners.length : 0),
                  left: 0,
                  right: 0,
                  zIndex: 2,
                }}
              >
                <Banner showBottomShadow>
                  <ChatSessionBanner
                    bannerStyle={{ width: '100%' }}
                    callStartedAt={activeSession?.callStartedAt || null}
                    isInProviderChat={isTherapistChat}
                    didClientJoin={didClientJoin(activeSession?.clients, getUserData().id)}
                    didProviderJoin={!!activeSession?.therapist?.joinedAt}
                    handleEndSession={handleEndLiveChat}
                  />
                </Banner>
              </View>
            )}
          </TopBanner>
        )}
        <ScrollViewComponent ref={scrollViewRef} onScroll={this.handleScroll} style={{ flex: 1 }}>
          <View
            flex={1}
            justify="space-between"
            style={{
              paddingTop: 10,
              paddingLeft: 20,
              paddingRight: 6,
              ...style,
            }}
          >
            <View>{items.map(renderRow)}</View>
            <NewItemAlert
              hidden={!shouldRenderNewItemAlert}
              scrollToLastItem={this.handleNewItemAlertClick}
            />
            {SLAComponent && !activeSession?.callStartedAt && <SLAComponent />}
            {userTyping.show && activeSession?.callStartedAt && (
              <View aria-live="assertive">
                <TypingIndicator displayName={userTyping.displayName} />
              </View>
            )}
          </View>
        </ScrollViewComponent>
      </>
    );
  }
}

export default ScrollView;
