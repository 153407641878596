import { TouchableView } from '@talkspace/react-toolkit';

import styled, { EmotionStyle } from '../../../core/styled/styled';

const FlatRoundedButton = styled(TouchableView)<{ style?: EmotionStyle }>(
  ({ style, theme: { colors } }) => {
    return {
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      width: 345,
      height: 57,
      fontSize: 14,
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.blueHaze,
      ...style,
    };
  }
);

export default FlatRoundedButton;
