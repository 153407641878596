import { FunctionComponent, useState, useEffect } from 'react';
import moment from 'moment';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { Spinner } from '@talkspace/react-toolkit';
import { useLocation, withRouter, RouteComponentProps } from '@/core/routerLib';
import appConfigs from '@/utils/configs';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import InRoomSchedulingError from '../components/inRoomSchedulingError';
import { deleteLastElementInPath } from '../utils/url';
import { TherapistInfo } from '../types';
import {
  useInRoomSchedulingState,
  useInRoomSchedulingActions,
} from '../hooks/inRoomSchedulingContext';
import { CANCELLATION_WINDOW_HOURS } from '../utils/constants';
import { ChargeType } from '../../ts-frontend/types';
import useQueryClientTransactions from '../hooks/useQueryClientTransactions';
import useRepeatingBooking from '../hooks/useRepeatingBooking';

import { CancelBooking, CancelBookingRecurring } from '../components/CancelBooking';

const CancelBookingContainer: FunctionComponent<
  {
    therapist: TherapistInfo;
    isCancel: boolean;
    onClosePress: () => void;
    isTherapist: boolean;
    clientUserID?: number;
  } & RouteComponentProps<{ roomID: string; bookingID: string }>
> = ({
  therapist,
  history,
  match,
  isCancel: isCancelRoute,
  onClosePress,
  isTherapist,
  clientUserID,
}) => {
  const { roomID, bookingID } = match.params;
  const isiOS = ReactFrameService.isiOS();
  const isAndroid = ReactFrameService.isAndroid();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cancelBatch = queryParams.get('batch') === 'true';

  const {
    selectedCreditOption,
    selectedCancelBooking,
    isClientSelectedBookingError,
    room,
    isError,
    isLoading,
    errorMessage,
    localTimezone,
  } = useInRoomSchedulingState();

  const isEligible =
    errorMessage !== 'user_ineligible' &&
    !errorMessage?.includes('We are not able to locate your record with the health plan.');

  const {
    dispatchDeclineBooking,
    dispatchDismissBooking,
    dispatchGetSelectedBooking,
    dispatchSetIsError,
  } = useInRoomSchedulingActions();
  const [selectedAction, setSelectedAction] = useState<'cancel' | 'reschedule' | 'add_to_calendar'>(
    appConfigs.featureFlags.addToCalendar && !isiOS && !isAndroid ? 'add_to_calendar' : 'reschedule'
  );
  const { pendingInvoicesChargeTypes } =
    useFlags<{ pendingInvoicesChargeTypes?: Array<ChargeType> }>();
  const isClientTransactionsEnabled =
    !!pendingInvoicesChargeTypes &&
    pendingInvoicesChargeTypes.length > 0 &&
    !isTherapist &&
    !!room?.isSessionBased &&
    !!clientUserID;
  const { data: pendingInvoices, isError: isPendingInvoicesError } = useQueryClientTransactions(
    {
      chargeTypes: pendingInvoicesChargeTypes,
      clientUserID: clientUserID!,
    },
    {
      enabled: isClientTransactionsEnabled,
    }
  );

  const checkedPendingInvoices =
    (!!pendingInvoicesChargeTypes && (!isClientTransactionsEnabled || !!pendingInvoices)) ||
    isPendingInvoicesError;

  const { repeatingTimeslots, isAllConfirmed } = useRepeatingBooking({
    selectedBooking: selectedCancelBooking,
  });

  useEffect(() => {
    dispatchGetSelectedBooking(Number(roomID), bookingID, isCancelRoute);
  }, [bookingID, roomID, isCancelRoute, dispatchGetSelectedBooking]);

  const scheduledByProvider = selectedCancelBooking
    ? selectedCancelBooking.scheduledByUserType === 'provider'
    : undefined;
  const startTimeMinus24hrs = selectedCancelBooking
    ? moment(selectedCancelBooking.startTime).subtract(CANCELLATION_WINDOW_HOURS, 'hours')
    : undefined;
  const isBookingInLessThan24hrs = moment().isAfter(startTimeMinus24hrs);
  const isBookingConfirmed = selectedCancelBooking
    ? selectedCancelBooking.timekitBookingState === 'confirmed'
    : undefined;
  const shouldCancelAndGoToCancelConfirm =
    (!isBookingConfirmed && selectedAction === 'cancel') ||
    (isBookingConfirmed && !isBookingInLessThan24hrs && selectedAction === 'cancel');
  const isBookingDeclinedByTherapist = selectedCancelBooking
    ? selectedCancelBooking.timekitBookingState === 'declined' &&
      selectedCancelBooking.scheduledByUserType === 'client'
    : undefined;
  const canDeclineOrCancel = scheduledByProvider && !isBookingConfirmed ? 'decline' : 'cancel';

  useEffect(() => {
    if (cancelBatch && isBookingConfirmed && selectedCancelBooking && repeatingTimeslots) {
      if (isAllConfirmed) {
        dispatchSetIsError(
          {
            name: 'CancelBookingBatchError',
            message:
              'These sessions were already confirmed. You can still cancel them individually for free up until 24 hours before each session starts.',
          },
          { errorTitle: 'Can’t decline sessions' }
        );
      } else {
        dispatchSetIsError(
          {
            name: 'CancelBookingBatchError',
            message:
              'You have already confirmed and/or declined the sessions. You can still cancel any individual confirmed session for free up to 24 hours before each session starts.',
          },
          { errorTitle: 'These sessions have already been confirmed or declined' }
        );
      }
    }
  }, [
    cancelBatch,
    dispatchSetIsError,
    isBookingConfirmed,
    selectedCancelBooking,
    repeatingTimeslots,
    isAllConfirmed,
  ]);

  useEffect(() => {
    if (
      !cancelBatch &&
      canDeclineOrCancel === 'cancel' &&
      !isCancelRoute &&
      selectedCancelBooking
    ) {
      const { pathname, search } = history.location;
      const redirectPath = `${pathname.replace('/decline-booking', '/cancel-booking')}${
        search || ''
      }`;
      history.replace(redirectPath);
    }
  }, [canDeclineOrCancel, cancelBatch, history, isCancelRoute, selectedCancelBooking]);

  useEffect(() => {
    if (cancelBatch) {
      setSelectedAction('cancel');
    } else if (canDeclineOrCancel === 'decline') {
      setSelectedAction('reschedule');
    }
  }, [canDeclineOrCancel, cancelBatch]);

  if ((!selectedCancelBooking && !isError) || isLoading) {
    return <Spinner />;
  }

  if (isClientSelectedBookingError || (isError && isEligible)) {
    return <InRoomSchedulingError onClosePress={onClosePress} />;
  }

  const handleAddToCalendarPress = () => {
    setSelectedAction('add_to_calendar');
  };

  const handleReschedulePress = () => {
    setSelectedAction('reschedule');
  };

  const handleCancelPress = () => {
    setSelectedAction('cancel');
  };

  const handleContinuePress = () => {
    const baseURL = deleteLastElementInPath(match.url, 3);
    if (selectedAction === 'add_to_calendar') {
      history.push(`${baseURL}/add-to-calendar`);
      return;
    }
    if (selectedAction !== 'reschedule' && isBookingConfirmed && isBookingInLessThan24hrs) {
      history.push(
        `/room/${roomID}/session-details/${selectedCancelBooking?.id}/cancel-session${
          !isEligible ? '/ineligible' : ''
        }`
      );
      return;
    }
    if (
      selectedAction !== 'reschedule' &&
      scheduledByProvider &&
      !isBookingConfirmed &&
      selectedCancelBooking
    ) {
      dispatchDeclineBooking({
        bookingID: selectedCancelBooking.id,
        declineBatch: !!selectedCancelBooking.repeatingBookingID && cancelBatch,
      });
      history.push(`${baseURL}/confirm-decline${cancelBatch ? '?batch=true' : ''}`);
      return;
    }
    if (isBookingDeclinedByTherapist && selectedCancelBooking) {
      dispatchDismissBooking(selectedCancelBooking.id);
      onClosePress();
      return;
    }
    if (shouldCancelAndGoToCancelConfirm && selectedCancelBooking) {
      if (!isEligible) {
        history.push(
          `/room/${roomID}/session-details/${selectedCancelBooking.id}/cancel-session/ineligible`
        );
        return;
      }
      history.push(
        `/room/${roomID}/session-details/${selectedCancelBooking.id}/cancel-session${
          pendingInvoices && pendingInvoices.length > 0 ? `?hasPendingInvoices=true` : ''
        }`
      );
      return;
    }

    if (pendingInvoices && pendingInvoices.length > 0 && selectedAction === 'reschedule') {
      history.replace(
        `/in-room-scheduling/room/${roomID}/pending-invoices?source=rescheduleBooking&bookingID=${bookingID}`
      );
      return;
    }

    history.push(`${baseURL}/select-timeslot`, {
      isReschedule: selectedAction === 'reschedule',
    });
  };
  return cancelBatch && repeatingTimeslots ? (
    <CancelBookingRecurring
      therapist={therapist}
      isBookingDeclinedByTherapist={isBookingDeclinedByTherapist}
      room={room}
      isBookingConfirmed={isBookingConfirmed}
      isBookingInLessThan24hrs={isBookingInLessThan24hrs}
      checkedPendingInvoices={checkedPendingInvoices}
      canDeclineOrCancel={canDeclineOrCancel}
      selectedAction={selectedAction}
      handleCancelPress={handleCancelPress}
      handleContinuePress={handleContinuePress}
      timezone={localTimezone}
      repeatingTimeslots={repeatingTimeslots}
    />
  ) : (
    <CancelBooking
      therapist={therapist}
      isTherapist={isTherapist}
      selectedCancelBooking={selectedCancelBooking}
      isBookingDeclinedByTherapist={isBookingDeclinedByTherapist}
      room={room}
      isBookingConfirmed={isBookingConfirmed}
      isBookingInLessThan24hrs={isBookingInLessThan24hrs}
      checkedPendingInvoices={checkedPendingInvoices}
      canDeclineOrCancel={canDeclineOrCancel}
      selectedAction={selectedAction}
      selectedCreditOption={selectedCreditOption}
      handleAddToCalendarPress={handleAddToCalendarPress}
      handleReschedulePress={handleReschedulePress}
      handleCancelPress={handleCancelPress}
      handleContinuePress={handleContinuePress}
    />
  );
};

export default withRouter(CancelBookingContainer);
