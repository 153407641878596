import { FunctionComponent } from 'react';
import { BaseButton, Large, Standard } from '@talkspace/react-toolkit';

import styled from '../../../core/styled/styled';
import FlatRoundedButton from '../FlatRoundedButton';

const PaddedText = styled(Large)({
  marginTop: 10,
  marginBottom: 50,
});

interface Props {
  therapistFirstName: string;
  nextReplyTime: string;
  onAskReplyPress: () => void;
  onCancelPress: () => void;
  header: string;
}

const Missed1Week: FunctionComponent<Props> = (props: Props) => {
  const { therapistFirstName, nextReplyTime, onAskReplyPress, onCancelPress, header } = props;
  return (
    <>
      <PaddedText as="h1" variant="largeDarkGrey">
        {header}
      </PaddedText>
      <FlatRoundedButton
        onPress={onAskReplyPress}
        style={{ marginBottom: 10, paddingLeft: 5, paddingRight: 5 }}
      >
        <Large variant="largeBoldWideGreen">
          Please ask {therapistFirstName} to reply by {nextReplyTime}
        </Large>
      </FlatRoundedButton>
      <BaseButton onPress={onCancelPress} style={{ backgroundColor: '#fff' }}>
        <Standard variant="standardDarkGrey">No worries, I’ll wait a little more</Standard>
      </BaseButton>
    </>
  );
};

export default Missed1Week;
