import moment from 'moment';
import {
  CardWithContent,
  useEmotionTheme,
  TextDS,
  LiveSessionIconSmallV2,
  getSpacing,
  SpacingView,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { Booking } from 'ts-frontend/types';
import styled from '@/core/styled';
import CalendarIcon from './CalendarIcon';

const BookingCard = styled(CardWithContent)({
  '&:hover .calendar-icon-box': {
    backgroundColor: 'transparent',
  },
  '&:active .calendar-icon-box': {
    backgroundColor: 'transparent',
  },
});

interface YourSupportTeamBookingCardProps {
  booking: Booking;
  isLoading?: boolean;
  isDisabled?: boolean;
  onPress?: () => void;
  BottomRowButton?: React.ReactNode;
  dataQa?: string;
}

const YourSupportTeamBookingCard = ({
  booking,
  isLoading,
  isDisabled,
  BottomRowButton,
  onPress,
  dataQa = 'yourSupportTeamBookingCard',
}: YourSupportTeamBookingCardProps) => {
  const { colorRoles } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const startTimeMoment = moment(booking.startTime);
  const endTimeMoment = moment(booking.startTime).add(booking.creditMinutes || 45, 'minutes');
  return (
    <SpacingView itemSpacing="space300">
      <BookingCard
        dataQa={dataQa}
        isLoading={isLoading}
        centerContent
        hideCaret
        cardVariant="noBorder"
        padding={0}
        enableRightColHover
        Icon={
          <CalendarIcon month={startTimeMoment.format('MMM')} day={startTimeMoment.format('D')} />
        }
        RightCol={!isMobile && BottomRowButton}
        TopRow={
          <TextDS as="span">
            <LiveSessionIconSmallV2
              dataQa={`liveSession-${booking.modality}-Modality`}
              style={{ marginBottom: -1 }}
              modality={booking.modality}
              color={colorRoles.typography.textSubtle}
              width={16}
              fill
            />
            <TextDS
              variant="headingXs"
              as="span"
              colorRole="textSubtle"
              style={{ marginLeft: getSpacing('space050') }}
            >
              Next live session
            </TextDS>
          </TextDS>
        }
        titleText={
          <TextDS as="span">
            <TextDS variant="headingSm" as="span">
              {startTimeMoment.format('dddd, MMMM D')}
            </TextDS>
            {isMobile && <br />}
            <TextDS
              variant="bodySm"
              as="span"
              style={{ marginLeft: isMobile ? 0 : getSpacing('space100') }}
            >
              {startTimeMoment.format('h:mma')} – {endTimeMoment.format('h:mma')}
            </TextDS>
          </TextDS>
        }
        onPress={onPress}
        disabled={isDisabled}
      />
      {isMobile && BottomRowButton}
    </SpacingView>
  );
};
export default YourSupportTeamBookingCard;
