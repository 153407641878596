import { FunctionComponent } from 'react';
import View from '../../../components/View';
import { Big } from '../../../components/Typography';
import CloseButton from '../CloseButton';
import SkipNavButton from '../../../components/SkipNavButton';
import styled, { EmotionStyle, useEmotionTheme } from '../../../core/styled';
import { SkipNavigation } from '../../../types';

export interface PanelHeaderProps {
  title?: string;
  titleId?: string;
  renderLeft?: (() => JSX.Element) | null;
  renderRight?: (() => JSX.Element) | null;
  onRightPress?: () => void;
  height?: string;
  style?: EmotionStyle;
  skipNavs?: SkipNavigation[];
  isChatHidden?: boolean;
  isMobile?: boolean;
  dataQa?: string;
  useNewNavHeader?: boolean;
  isNewNav?: boolean;
}

const PanelHeaderWrapper = styled(View)<Partial<PanelHeaderProps>>(
  ({ theme: { colors }, useNewNavHeader }) => {
    const newNavBigStyle = useNewNavHeader
      ? {
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
        }
      : {};

    return {
      backgroundColor: colors.white,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
      paddingLeft: 20,
      paddingRight: 16,
      ...newNavBigStyle,
    };
  }
);

const PanelHeader: FunctionComponent<PanelHeaderProps> = ({
  title,
  titleId,
  onRightPress,
  renderLeft,
  renderRight,
  style = {},
  skipNavs,
  isChatHidden,
  isMobile,
  dataQa,
  useNewNavHeader = false,
  isNewNav,
}) => {
  const newNavBigStyle = useNewNavHeader
    ? {
        gridColumnStart: 2,
        justifySelf: 'center',
      }
    : {};
  const newNavButtonStyle = useNewNavHeader
    ? {
        justifySelf: 'end',
      }
    : {};
  const { colorRoles } = useEmotionTheme();
  const newNavCloseButton = isNewNav ? (
    <CloseButton
      onPress={onRightPress}
      style={{ marginTop: 5, borderRadius: 5 }}
      dataQa={dataQa}
      wrapperStyle={newNavButtonStyle}
      backgroundColor={colorRoles.icons.iconInverseDefault}
      color={colorRoles.icons.iconBrandDefault}
      height={42}
      width={42}
    />
  ) : (
    <CloseButton
      onPress={onRightPress}
      style={{ marginTop: 5, borderRadius: 5 }}
      dataQa={dataQa}
      wrapperStyle={newNavButtonStyle}
    />
  );
  return (
    <PanelHeaderWrapper style={style} useNewNavHeader={useNewNavHeader}>
      {renderLeft ? (
        renderLeft()
      ) : (
        <Big as="h1" id={titleId} style={newNavBigStyle}>
          {title}
        </Big>
      )}
      {skipNavs && !isChatHidden && !isMobile
        ? skipNavs.map((skipNav) => (
            <SkipNavButton
              key={skipNav.buttonText}
              buttonText={skipNav.buttonText}
              returnID={skipNav.returnID}
              onPress={skipNav.onPress}
            />
          ))
        : null}
      {renderRight ? renderRight() : onRightPress && newNavCloseButton}
    </PanelHeaderWrapper>
  );
};

export default PanelHeader;
