import { forwardRef } from 'react';
import View from '../View';
import HiddenText from '../HiddenText';
import styled, { EmotionStyle } from '../../core/styled';
import useScrollIntoInputs from '../../hooks/useScrollIntoInputs';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import { useContainerFocus, useUniqueID } from '../../hooks/a11yHelper';
import useFlagsSafe from '../../hooks/useFlagsSafe';

export const DEFAULT_HEADER_HEIGHT = 67;

interface Props {
  hideHeader?: boolean;
  headerHeight?: number;
  outsideHeaderHeight?: number;
  outsideFooterHeight?: number;
  shouldScroll?: boolean;
  shouldNotFocus?: boolean;
  renderHeader: null | (() => JSX.Element);
  title?: string;
  titleId?: string;
  roomID?: number;
  panelId?: string;
  containerStyle?: EmotionStyle;
  contentWrapperStyle?: EmotionStyle;
  noHeaderLine?: boolean;
  dataQa?: string;
}

const StyledContainer = styled(View)({
  '&:focus': {
    outline: 'none',
  },
});

const HeaderWrapper = styled(View)<{
  isMobile: boolean;
  headerHeight: number;
  noHeaderLine?: boolean;
}>(({ isMobile, headerHeight, noHeaderLine, theme: { colors } }) => {
  return {
    flexDirection: 'row',
    height: headerHeight,
    width: '100%',
    borderBottomWidth: noHeaderLine ? 0 : 1,
    borderBottomColor: colors.permaLightGrey,
    backgroundColor: colors.white,
    borderBottomStyle: 'solid',
    ...(isMobile
      ? {
          top: 0,
        }
      : {
          position: 'revert',
        }),
  };
});

const ContentWrapper = styled(View)<{
  headerHeight: number;
  windowHeight: number;
  shouldScroll: boolean;
  memberNewNav?: boolean;
  outsideFooterHeight: number;
}>(({ memberNewNav, shouldScroll, windowHeight, headerHeight, outsideFooterHeight }) => {
  return {
    height: memberNewNav
      ? windowHeight - headerHeight - outsideFooterHeight
      : windowHeight - headerHeight,
    overflowY: shouldScroll ? 'auto' : 'unset',
    marginTop: 0,
    paddingBottom: 'env(safe-area-inset-bottom)',
  };
});
const ResponsiveLayoutWithHeader = forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      renderHeader,
      children,
      outsideHeaderHeight = 0,
      outsideFooterHeight = 0,
      shouldScroll = true,
      shouldNotFocus = false,
      title,
      titleId,
      roomID,
      panelId,
      containerStyle = {},
      contentWrapperStyle = {},
      noHeaderLine,
      dataQa,
      hideHeader,
      headerHeight = hideHeader ? 0 : DEFAULT_HEADER_HEIGHT,
    },
    ref
  ) => {
    useScrollIntoInputs();
    const { isMobile, height } = useWindowWidthState();
    const { containerRef } = useContainerFocus(roomID, undefined, shouldNotFocus);
    const hiddenTitleId = useUniqueID('hiddenTitleId');
    const { memberNewNav } = useFlagsSafe();
    let finalAriaLabelledBy;
    if (titleId) {
      finalAriaLabelledBy = titleId;
    } else if (title) {
      finalAriaLabelledBy = hiddenTitleId;
    }
    return (
      <StyledContainer
        tabIndex={-1}
        ref={containerRef}
        aria-labelledby={finalAriaLabelledBy}
        id={panelId}
        role={panelId ? 'region' : undefined}
        style={containerStyle}
        data-qa={dataQa}
      >
        {title && <HiddenText id={hiddenTitleId}>{title}</HiddenText>}
        {renderHeader && !hideHeader && (
          <HeaderWrapper
            headerHeight={headerHeight}
            isMobile={isMobile}
            noHeaderLine={noHeaderLine}
          >
            {renderHeader()}
          </HeaderWrapper>
        )}
        <ContentWrapper
          memberNewNav={memberNewNav === 'treatment'}
          ref={ref}
          windowHeight={height}
          headerHeight={headerHeight + outsideHeaderHeight}
          outsideFooterHeight={outsideFooterHeight}
          shouldScroll={shouldScroll}
          style={contentWrapperStyle}
        >
          {children}
        </ContentWrapper>
      </StyledContainer>
    );
  }
);

export default ResponsiveLayoutWithHeader;
