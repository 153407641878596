import { FunctionComponent, useRef, useEffect } from 'react';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import styled, { EmotionStyle } from '@/core/styled';

const Video = styled.video({
  maxWidth: 480,
  maxHeight: 480,
  ...webOnlyStyle({
    '@media(max-width: 1125px)': {
      maxWidth: 340,
      maxHeight: 340,
    },
    '@media(max-width: 750px)': {
      maxWidth: 315,
      maxHeight: 315,
    },
  }),
});

export interface VideoPlayerViewProps {
  source?: string;
  playing: boolean;
  onPlay: Function;
  onPause: Function;
  style?: EmotionStyle;
  captionsUrl?: string;
  thumbnailURL?: string;
}

const VideoPlayerView: FunctionComponent<VideoPlayerViewProps> = ({
  style,
  onPlay,
  source,
  onPause,
  playing,
  captionsUrl,
  thumbnailURL,
  ...otherProps
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [playing]);

  return (
    <Video
      aria-label="video message"
      controls
      src={source}
      ref={videoRef}
      onPlay={() => {
        // Note: We need the play here to prevent the "Permission denied" error
        // on browsers that prevent programmatic media playing
        // even though it's already on a useEffect
        if (videoRef.current) videoRef.current.play();
        if (onPlay) onPlay();
      }}
      onPause={() => {
        if (videoRef.current) videoRef.current.pause();
        if (onPause) onPause();
      }}
      poster={thumbnailURL}
      style={{
        ...style,
      }}
      {...otherProps}
    >
      {captionsUrl && <track kind="captions" src={captionsUrl} {...otherProps} />}
    </Video>
  );
};

export default VideoPlayerView;
