import {
  BackNextButton,
  Big,
  PanelHeader,
  ResponsiveLayoutWithHeader,
  View,
} from '@talkspace/react-toolkit';
import { useCloseModal } from '@/utils/ModalsContextProvider';

interface Props {
  children: React.ReactNode;
  onPressBack?: () => void;
}
const BaseWrapper = ({ children, onPressBack }: Props) => {
  const closeModal = useCloseModal();
  return (
    <ResponsiveLayoutWithHeader
      renderHeader={() => (
        <PanelHeader
          renderLeft={() => (
            <>
              <BackNextButton onPress={onPressBack} />
              <Big>Cancel session</Big>
            </>
          )}
          onRightPress={() => closeModal()}
        />
      )}
      contentWrapperStyle={{ alignItems: 'center' }}
    >
      <View align="center" style={{ padding: '60px 32px', textAlign: 'center', maxWidth: 425 }}>
        {children}
      </View>
    </ResponsiveLayoutWithHeader>
  );
};

export default BaseWrapper;
