import { Col, Grid, Row, TextDS, spacing, useWindowWidthState } from '@talkspace/react-toolkit';
import { useAppReviewCheckStorage } from 'ts-ionic/plugins/appReview';

import ClinicalProgressRoomContainer from '../../clinicalProgress/containers/ClinicalProgressRoomContainer';

const { space200, space400 } = spacing;

const JourneyScreen = () => {
  const isMobile = useWindowWidthState();
  useAppReviewCheckStorage();
  return (
    <Grid fluid rowSpacing="space200">
      <Row>
        <Col>
          <TextDS variant="heading2xl" style={{ marginTop: isMobile ? space200 : space400 }}>
            Journey
          </TextDS>
        </Col>
      </Row>
      <Row>
        <Col xs={4} sm={8}>
          <ClinicalProgressRoomContainer isV2 />
        </Col>
      </Row>
    </Grid>
  );
};

export default JourneyScreen;
