import { FunctionComponent, useEffect } from 'react';
import { Spinner, useWindowWidth, View } from '@talkspace/react-toolkit';

import { trackBookingCompleted } from 'ts-analytics/mixpanel/events';

import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import useRepeatingBooking from '../../hooks/useRepeatingBooking';

import { TherapistInfo } from '../../types';

import { getUserData } from '@/auth/helpers/token';
import { useParams } from '@/core/routerLib';
import BookingConfirmationSuccess from './BookingConfirmationSuccess';
import BookingConfirmationRecurring from './BookingConfirmationRecurring';

interface Props {
  isTherapist: boolean;
  handleOnClosePress: () => void;
  handleOnCTAPress: () => void;
  isWithin24Hours: boolean;
  hasBreakAfterSession?: boolean;
  therapist?: TherapistInfo;
  isInTherapistCRM?: boolean;
}

const BookingConfirmationController: FunctionComponent<Props> = ({
  isTherapist,
  handleOnClosePress,
  handleOnCTAPress,
  isWithin24Hours,
  hasBreakAfterSession,
  therapist,
  isInTherapistCRM,
}) => {
  const { isMobile } = useWindowWidth();
  const {
    room,
    modality,
    selectedTimeslot,
    selectedCreditOption,
    shouldShowBookingSuccess,
    recurringAvailableTimeslots,
    selectedConfirmBooking,
  } = useInRoomSchedulingState();
  const { roomID } = useParams<{ roomID: string }>();
  const { id: userID } = getUserData();

  const { isRecurringBooking } = useRepeatingBooking({
    selectedBooking: selectedConfirmBooking,
    onlyConfirmedOrTentative: true,
  });

  useEffect(() => {
    if (!shouldShowBookingSuccess) {
      handleOnClosePress();
    }
  }, [shouldShowBookingSuccess, handleOnClosePress]);

  useEffect(() => {
    if (therapist?.id && !isTherapist && selectedCreditOption) {
      trackBookingCompleted({
        userID,
        roomID: Number(roomID),
        providerID: therapist.id,
        sessionModality: modality,
        sessionLength: modality === 'messaging' ? null : selectedCreditOption?.creditMinutes,
        planID: selectedCreditOption.planID,
      });
    }
  }, [isTherapist, modality, roomID, selectedCreditOption, therapist?.id, userID]);

  if (!shouldShowBookingSuccess) {
    return <Spinner />;
  }

  return (
    <View
      flex={isMobile ? 1 : 0}
      align="center"
      justify="space-between"
      style={{ marginBottom: isMobile ? 0 : 45 }}
    >
      <View flex={1} align="center">
        {recurringAvailableTimeslots || isRecurringBooking ? (
          <BookingConfirmationRecurring
            isTherapist={isTherapist}
            handleOnClosePress={handleOnClosePress}
            isWithin24Hours={isWithin24Hours}
            hasBreakAfterSession={hasBreakAfterSession}
            therapist={therapist}
          />
        ) : (
          <BookingConfirmationSuccess
            isTherapist={isTherapist}
            handleOnCTAPress={handleOnCTAPress}
            isWithin24Hours={isWithin24Hours}
            hasBreakAfterSession={hasBreakAfterSession}
            therapist={therapist}
            room={room}
            modality={modality}
            selectedCreditOption={selectedCreditOption}
            selectedTimeslot={selectedTimeslot}
            isInTherapistCRM={isInTherapistCRM}
          />
        )}
      </View>
    </View>
  );
};

export default BookingConfirmationController;
