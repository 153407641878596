import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { TouchableView, View, Modal, Spinner, CloseButton } from '@talkspace/react-toolkit';
import {
  PromiseMessageEvent,
  PROMISE_MESSAGE_TYPE,
  usePromiseMessageContextActions,
} from 'ts-promise-message';
import styled from '../../core/styled';
import { COLORS } from '../../utils/design';
import { ClosePopupAction } from '../../auth/reactFrame/ReactFrameTypes';

const Content = styled(TouchableView)({
  backgroundColor: COLORS.white,
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
  position: 'absolute',
});

interface Props {
  isLoading: boolean;
  src: string;
  title: string;
  onClose: (data?: ClosePopupAction) => void;
  onTokenRequest?: (refresh: boolean) => Promise<{ token: string; userID: string }>;
  onFinishedLoading?: () => void;
  showCloseButton?: boolean;
  onPromiseMessage?: (data: PromiseMessageEvent) => void;
}

type eventTypeNames =
  | 'closePopup'
  | 'resizeFrameHeight'
  | 'finishLoading'
  | 'authToken'
  | typeof PROMISE_MESSAGE_TYPE;

type reactFrameEventsNames = 'refreshToken' | typeof PROMISE_MESSAGE_TYPE;

interface TSFrameEvent {
  type: eventTypeNames;
  data: any;
}

const IFrameModal: FunctionComponent<Props> = ({
  isLoading,
  src,
  title,
  showCloseButton,
  onClose,
  onTokenRequest,
  onFinishedLoading,
  onPromiseMessage,
}) => {
  const iFrameURLRef = useRef(new URL(src));
  const iFrameContainerRef = useRef<HTMLIFrameElement>(null);
  const [shouldShowCloseButton, setShouldShowCloseButton] = useState(showCloseButton);
  const { receivedMessage } = usePromiseMessageContextActions();

  useEffect(() => {
    function postMessage(type: reactFrameEventsNames, data: unknown) {
      if (
        iFrameContainerRef.current &&
        iFrameContainerRef.current.contentWindow &&
        iFrameContainerRef.current.contentWindow.postMessage
      ) {
        iFrameContainerRef.current.contentWindow.postMessage(
          {
            type,
            data,
          },
          iFrameURLRef.current.origin
        );
      }
    }

    function receiveMessage(event) {
      if (onTokenRequest) {
        // check origin
        if (iFrameURLRef.current.origin !== event.origin) {
          return;
        }
        const { data: eventData } = event;

        if (!eventData || !eventData.type) return;

        const { type, data } = eventData as TSFrameEvent;
        switch (type) {
          case 'authToken':
            onTokenRequest(true)
              .then((tokenData) => {
                postMessage('refreshToken', tokenData);
              })
              // eslint-disable-next-line no-console
              .catch(console.error);
            break;
          case 'closePopup':
            onClose(data && data.return);
            break;
          case 'finishLoading':
            onTokenRequest(false)
              .then((tokenData) => {
                postMessage('refreshToken', tokenData);
                if (!showCloseButton) setShouldShowCloseButton(false);
                if (onFinishedLoading) onFinishedLoading();
              })
              // eslint-disable-next-line no-console
              .catch(console.error);
            break;
          case PROMISE_MESSAGE_TYPE:
            receivedMessage(postMessage, event);
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(`event type unknown ${type}`);
            break;
        }
      }
    }

    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [
    onClose,
    onFinishedLoading,
    onTokenRequest,
    onPromiseMessage,
    receivedMessage,
    showCloseButton,
    src,
  ]);

  return (
    <Modal isVisible>
      <Content>
        {shouldShowCloseButton && (
          <CloseButton
            dataQa="iframeModalCloseButton"
            onPress={() => onClose()}
            style={{
              position: 'fixed',
              top: 16,
              right: 16,
            }}
          />
        )}
        {isLoading && (
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
            }}
          >
            <Spinner />
          </View>
        )}
        <iframe
          src={src}
          frameBorder="0"
          title={title}
          width="100%"
          height="100%"
          ref={iFrameContainerRef}
          style={{
            visibility: isLoading ? 'hidden' : 'visible',
          }}
        />
      </Content>
    </Modal>
  );
};

export default IFrameModal;
