import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M19.3596 4.95702L19.8359 5.43333C20.206 5.80335 20.206 6.40169 19.8359 6.76778L18.6904 7.91721L16.8758 6.10252L18.0213 4.95702C18.3913 4.587 18.9896 4.587 19.3557 4.95702H19.3596ZM10.2586 12.7236L15.5413 7.43697L17.356 9.25166L12.0694 14.5343C11.9552 14.6485 11.8135 14.7312 11.66 14.7745L9.35718 15.4319L10.0146 13.129C10.0579 12.9755 10.1405 12.8338 10.2547 12.7197L10.2586 12.7236ZM16.6868 3.62257L8.92024 11.3852C8.57777 11.7277 8.32977 12.1489 8.19987 12.6094L7.07405 16.5459C6.97958 16.8765 7.07012 17.2308 7.31418 17.4749C7.55823 17.7189 7.91251 17.8095 8.24317 17.715L12.1796 16.5892C12.6441 16.4553 13.0653 16.2073 13.4038 15.8688L21.1704 8.10616C22.2765 7.00002 22.2765 5.20501 21.1704 4.09888L20.6941 3.62257C19.588 2.51643 17.7929 2.51643 16.6868 3.62257ZM5.46406 5.15778C3.55095 5.15778 2 6.70873 2 8.62183V19.3289C2 21.242 3.55095 22.793 5.46406 22.793H16.1711C18.0842 22.793 19.6352 21.242 19.6352 19.3289V14.9201C19.6352 14.3966 19.214 13.9754 18.6904 13.9754C18.1669 13.9754 17.7457 14.3966 17.7457 14.9201V19.3289C17.7457 20.1989 17.0411 20.9035 16.1711 20.9035H5.46406C4.59411 20.9035 3.88948 20.1989 3.88948 19.3289V8.62183C3.88948 7.75188 4.59411 7.04726 5.46406 7.04726H9.87285C10.3964 7.04726 10.8176 6.62606 10.8176 6.10252C10.8176 5.57897 10.3964 5.15778 9.87285 5.15778H5.46406Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const PenToSquare = withDSIconMaker(pathConfig, 'pen on notepad');

export default PenToSquare;
