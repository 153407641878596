import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const smallPath =
  'M1.75 5C1.75 4.17266 2.42266 3.5 3.25 3.5H9.25C10.0773 3.5 10.75 4.17266 10.75 5V11C10.75 11.8273 10.0773 12.5 9.25 12.5H3.25C2.42266 12.5 1.75 11.8273 1.75 11V5ZM14.8539 4.33906C15.0977 4.47031 15.25 4.72344 15.25 5V11C15.25 11.2766 15.0977 11.5297 14.8539 11.6609C14.6102 11.7922 14.3148 11.7781 14.0828 11.6234L11.8328 10.1234L11.5 9.90078V9.5V6.5V6.09922L11.8328 5.87656L14.0828 4.37656C14.3125 4.22422 14.6078 4.20781 14.8539 4.33906Z';
const standardPath =
  'M1 6.39648C1 5.29336 1.89688 4.39648 3 4.39648H11C12.1031 4.39648 13 5.29336 13 6.39648V14.3965C13 15.4996 12.1031 16.3965 11 16.3965H3C1.89688 16.3965 1 15.4996 1 14.3965V6.39648ZM18.4719 5.51523C18.7969 5.69023 19 6.02773 19 6.39648V14.3965C19 14.7652 18.7969 15.1027 18.4719 15.2777C18.1469 15.4527 17.7531 15.434 17.4438 15.2277L14.4438 13.2277L14 12.9309V12.3965V8.39648V7.86211L14.4438 7.56523L17.4438 5.56523C17.75 5.36211 18.1437 5.34023 18.4719 5.51523Z';

const pathConfig: IconConfig = {
  small: {
    default: { path: smallPath },
  },
  standard: {
    default: { path: standardPath },
  },
};

const Video = withDSIconMaker(pathConfig, 'video icon');

export default Video;
