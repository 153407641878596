import { BaseApiHelper } from 'ts-frontend/apiHelpers';
import {
  Subscription,
  InformedConsentAPIResponse,
  InformedConsentStatusAPI,
  QMSessionTypeEnum,
  InformedConsentV2StatusAPI,
} from 'ts-frontend/types';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import Base64 from 'ts-frontend/utils/Base64';
import { MessagePreviewAPI } from 'ts-frontend/entities/MessagePreview';
import { RoomsAPIResponse } from '@/reducers/mainReducer';
import { ClientAPI } from '@/entities/Me';
import { ECustomerInfo } from '@/entities/CustomerInfo';
import { ECMSItem } from '@/entities/CMSItem';
import { EMatchingPreferences } from '@/entities/MatchingPreferences';
import { EPresentingProblems } from '@/entities/PresentingProblems';
import { ImplicitBusinessHoursAPI } from '@/clientChat/reducers/inPlatformMatchingReducer';
import {
  setTokens,
  getBypassTwoFactorToken,
  removeBypassTwoFactorToken,
} from '@/auth/helpers/token';
import {
  ChangePasswordResponse,
  LoginOauth2Response,
  LoginResponse,
  RegisterNewUserResponse,
  CognitoRegisterNewUserResponse,
  VerifyResponse,
  TwoFAStatus,
} from '@/reducers/clientAuthReducer';
import apiHelper from '../core/api/apiHelper';
import apiWrapper from '../core/api/apiWrapper';
import appConfig from './configs';

interface MainsiteResponse {
  data?: {
    'result '?: any;
    result?: any;
    'returnCode '?: number;
    returnCode?: number;
  };
  status?: number;
}

interface CognitoRegistrationMessageUserDetails {
  email: string;
  nickname: string;
  timezone: string;
  phoneNumber?: string;
  phoneNumberCountryCode?: string;
  customerInformation: {
    country?: string;
    state?: string;
  };
}
interface RegistrationMessageUserDetails extends CognitoRegistrationMessageUserDetails {
  password: string;
}

interface VerifyOTPVariables {
  otpToken: string;
  otpKey: number;
  rememberDevice?: boolean;
}

interface EligibilitySessionAPIResponse {
  token: string;
  anonID: string;
}

export interface CommonRegistrationMessage {
  paymentDetails?: {
    voucherCode?: string;
  };
  flowData: {
    registrationUrl: string;
    referrerUrl?: string;
    funnelVariation: string;
    roomType: string;
    friendReferral?: {
      referrerID: number;
      channel?: number;
      platform?: number;
    };
    boughtFrom?: number;
  };
  therapistData?: {
    therapistID: number;
  };
  invitationKey?: string;
  qmSessionID?: string;
}

export interface RegistrationMessage extends CommonRegistrationMessage {
  userDetails: RegistrationMessageUserDetails;
}

export interface CognitoRegistrationMessage extends CommonRegistrationMessage {
  userDetails: CognitoRegistrationMessageUserDetails;
}

export interface GetCustomerInformationMessage {
  data: {
    country: string | null;
    dateOfBirth: string | null;
    educationLevel: string | null;
    ethnicity: string | null;
    gender: string | null;
    preferredName: string | null;
    relationshipStatus: string | null;
    state: string | null;
    therapistEthnicityPreference: string | null;
    therapistGenderPreference: string | null;
    treatmentGoal: string | null;
    workStatus: string | null;
    yearOfBirth: number;
  };
}

export interface GetEmergencyContactMessage {
  data: {
    contactName: string | null;
    contactPhone: string | null;
    relationship: string | null;
    address: string | null;
    address2: string | null;
    city: string | null;
    country: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    phone: string | null;
    state: string | null;
    zipcode: string | null;
  };
}

export interface ApiResponse<ResponseType = any> {
  data: ResponseType;
  included?: any;
}

const chatWrapper = {
  get: (url, options = {}) => apiWrapper.get(`${apiHelper().chatAPIEndpoint}${url}`, options),
  patch: (url, data?, options = {}) =>
    apiWrapper.patch(`${apiHelper().chatAPIEndpoint}${url}`, data, options),
  post: (url, data, options = {}) =>
    apiWrapper.post(`${apiHelper().chatAPIEndpoint}${url}`, data, options),
  put: (url, data?, options = {}) =>
    apiWrapper.put(`${apiHelper().chatAPIEndpoint}${url}`, data, options),
  delete: (url, options = {}) => apiWrapper.delete(`${apiHelper().chatAPIEndpoint}${url}`, options),
};

function alignMainsiteResponse(res: MainsiteResponse): ApiResponse {
  if (res.data) {
    if (res.data['result '] && res.data.result)
      throw new Error('two results found on MainsiteResponse');

    const returnCode = res.data.returnCode ? res.data.returnCode : res.data['returnCode '];

    if (returnCode !== 1) {
      let errorReason = `${returnCode}`;
      switch (returnCode) {
        case 0:
          errorReason = 'Failed';
          break;
        case 2:
          errorReason = 'Empty';
          break;
        case 3:
          errorReason = 'Invalid Request';
          break;
        case 4:
          errorReason = 'Expired Token';
          break;
        default:
          break;
      }
      throw new Error(`MainsiteResponse error: ${errorReason}`);
    }

    return {
      data: res.data.result ? res.data.result : res.data['result '],
    };
  }
  throw new Error('no data found on MainsiteResponse');
}

function prepareMainsiteRequest(data: unknown, method?: string): { params: unknown } {
  return {
    ...(method && {
      method,
    }),
    params: {
      appVersion: '1',
      deviceType: 'pc',
      // @ts-ignore
      ...data,
    },
  };
}

export function postMainsite<ResponseType = any>(
  url: string,
  data?: unknown
): Promise<ApiResponse<ResponseType>> {
  return chatWrapper.post(url, prepareMainsiteRequest(data || {})).then(alignMainsiteResponse);
}

export function postMainsiteUnauthenticated<ResponseType = any>(
  url: string,
  method: string,
  data?: unknown
): Promise<ApiResponse<ResponseType>> {
  return chatWrapper
    .post(url, prepareMainsiteRequest(data || {}, method))
    .then(alignMainsiteResponse);
}

export default class ApiHelper extends BaseApiHelper {
  /**
   * api call to: `/v2/clients/${clientUserID}/subscriptions?include=lvsStatus`
   * wrapWithCancel
   * @param {number} clientUserID
   * @return Promise
   */
  public getLVSSubscriptions = (clientUserID: number): Promise<ApiResponse<Subscription[]>> =>
    this.wrapWithCancel(
      apiWrapper.get(
        `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/subscriptions?include=lvsStatus`
      )
    ).then((res) => res.data);

  /**
   * api call to: `/v2/clients/${clientUserID}/rooms?include=clients,therapistInfo`
   * wrapWithCancel
   * @param  {number} clientUserID
   * @returns Promise
   */
  public getAllRooms = (clientUserID: number): Promise<RoomsAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(
        `${
          apiHelper().apiEndpoint
        }/v2/clients/${clientUserID}/rooms?include=clients,therapistInfo,cmsItems`
      )
    ).then((res) => res.data as RoomsAPIResponse);

  /**
   * api call to: `/v2/clients/${clientUserID}`
   * wrapWithCancel
   * @param  {number} clientUserID
   * @returns Promise
   */
  public getClient = (clientUserID: number): Promise<{ data: ClientAPI }> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/clients/${clientUserID}`)
    ).then((res) => res.data as { data: ClientAPI });

  /**
   * api call to `/v2/clients/${clientUserID}/informed-consent`
   * @returns Promise
   */
  public getInformedConsentStatus = (clientUserID: number): Promise<InformedConsentStatusAPI> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/informed-consent`)
    ).then((res) => res.data.data as InformedConsentStatusAPI);

  /**
   * api call to `/v2/clients/${clientUserID}/informed-consent`
   * @param  {number} clientUserID
   * @returns Promise
   */
  public getInformedConsentV2Status = (clientUserID: number): Promise<InformedConsentV2StatusAPI> =>
    this.wrapWithCancel(
      apiWrapper.get(
        `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/signed-informed-consent`
      )
    ).then((res) => res.data.data as InformedConsentV2StatusAPI);

  /**
   * api call to: `/v2/clients/${clientUserID}/rooms/last-messages`
   * wrapWithCancel
   * @param  {number} clientUserID
   * @returns Promise
   */
  public getLastMessages = (clientUserID: number): Promise<{ data: MessagePreviewAPI[] }> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/rooms/last-messages`)
    ).then((res) => res.data as { data: MessagePreviewAPI[] });

  /**
   * api call to: `/v2/therapist/implicit-business-hours?therapistIDs=77807,94592`
   * wrapWithCancel
   * @param  {number} therapistID
   * @returns Promise
   */
  public getImplicitBusinessHours = (therapistID: number): Promise<ImplicitBusinessHoursAPI> =>
    this.wrapWithCancel(
      apiWrapper.get(
        `${
          apiHelper().apiEndpoint
        }/v2/therapist/implicit-business-hours?therapistIDs=${therapistID}`
      )
    ).then((res) => {
      if (res.data && res.data.error) throw new Error(res.data.error);
      return res.data.data[0];
    });

  /**
   * API Post to: `/v2/auth`
   * @param  {string} email
   * @param  {string} password
   * @returns Promise
   */
  public postLoginClient = (email: string, password: string): Promise<LoginResponse> => {
    const base64 = Base64.btoa(encodeURIComponent(`${email}:${password}`));
    const headers = {
      Authorization: `Basic ${base64}`,
    };

    return apiWrapper
      .post<{ data: LoginResponse }>(
        `${apiHelper().authAPIEndpoint}/v2/auth`,
        {
          userType: 'CLIENT',
          platform: appConfig.platform,
          version: appConfig.appVersion,
        },
        { headers, shouldIgnore401: true }
      )
      .then((res) => res.data.data as LoginResponse);
  };

  /**
   * API Post to: `/v3/2fa/auth`
   * @param  {string} email
   * @param  {string} password
   * @returns Promise
   */
  public postLoginClient2FA = (email: string, password: string): Promise<LoginResponse> => {
    const base64 = Base64.btoa(encodeURIComponent(`${email}:${password}`));
    const bypassTwoFactorToken = getBypassTwoFactorToken();
    const bypassTwoFactorTokenRequestObject = bypassTwoFactorToken ? { bypassTwoFactorToken } : {};
    const headers = {
      Authorization: `Basic ${base64}`,
    };

    // get url param 'preRegisterToken
    const urlParams = new URLSearchParams(window.location.search);
    const preRegisterToken = urlParams.get('preRegisterToken');

    return apiWrapper
      .post<{ data: LoginResponse }>(
        `${apiHelper().authAPIEndpoint}/v3/2fa/auth`,
        {
          userType: 'CLIENT',
          ...bypassTwoFactorTokenRequestObject,
          platform: appConfig.platform,
          version: appConfig.appVersion,
          preRegisterToken,
        },
        { headers, shouldIgnore401: true }
      )
      .then((res) => res.data.data as LoginResponse);
  };

  /**
   * API Post to: `/v3/2fa/status`
   * @returns Promise
   */
  public get2FAStatus = async (): Promise<{ status: TwoFAStatus; phoneNumber: string }> => {
    const response = await apiWrapper.get(`${apiHelper().authAPIEndpoint}/v3/2fa/status`);
    return response.data.data;
  };

  /**
   * API Post to: `/v3/2fa/forget-device`
   * @param {string} bypassTwoFactorToken
   * @returns Promise
   */
  public forgetDevice = async (): Promise<void> => {
    const bypassTwoFactorToken = getBypassTwoFactorToken();
    if (bypassTwoFactorToken) {
      removeBypassTwoFactorToken();
      await apiWrapper.post(`${apiHelper().authAPIEndpoint}/v3/2fa/forget-device`, {
        bypassTwoFactorToken,
      });
    }
  };

  /**
   * API Post to: `/v3/2fa/verify`
   * @param  {string} otpToken
   * @param  {string} otpKey
   * @param {boolean} rememberDevice
   * @returns Promise
   */
  public postVerify2FA = ({
    otpToken,
    otpKey,
    rememberDevice,
  }: VerifyOTPVariables): Promise<VerifyResponse | number> =>
    apiWrapper
      .post<{ data: LoginResponse }>(
        `${apiHelper().authAPIEndpoint}/v3/2fa/verify`,
        {
          otpToken,
          otpKey,
          rememberDevice,
        },
        { shouldIgnore401: true }
      )
      .then((res) => (res.status === 204 ? 204 : (res.data.data as VerifyResponse)));

  public exchangeCognitoTokenForTalkspaceToken = async (
    idToken: string,
    qmSessionID?: string
  ): Promise<LoginResponse> => {
    const headers = {
      Authorization: `Cognito ${idToken}`,
    };

    const response = await apiWrapper.post<{ data: LoginResponse }>(
      `${apiHelper().authAPIEndpoint}/v1/cognito/login`,
      {
        userType: 'CLIENT',
        platform: appConfig.platform,
        version: appConfig.appVersion,
        qmSessionID,
      },
      { headers, shouldIgnore401: true }
    );
    const result = response.data.data;

    return result;
  };

  public loginToAetnaSSOWithHint = async (loginHint: string): Promise<LoginResponse> => {
    const response = await apiWrapper.get<{ data: LoginResponse }>(
      `${apiHelper().authAPIEndpoint}/v3/sso/aetna/login-with-hint?loginHint=${loginHint}`
    );
    const result = response.data.data;

    return result;
  };

  /**
   * API Post to: `/v1/oauth2/auth-code`
   * @param  {string} email
   * @param  {string} password
   * @returns Promise
   */
  public postOauth2LoginClient = (
    email: string,
    password: string
  ): Promise<LoginOauth2Response> => {
    const base64 = Base64.btoa(encodeURIComponent(`${email}:${password}`));
    const headers = {
      Authorization: `Basic ${base64}`,
    };
    const oauthQuery = new URLSearchParams(sessionStorage.getItem('oauthQuery') || {});
    return apiWrapper
      .post<{ data: LoginOauth2Response }>(
        `${apiHelper().authAPIEndpoint}/v1/oauth2/auth-code`,
        {
          userType: 'CLIENT',
          redirectURI: oauthQuery.get('redirect_uri'),
          responseType: oauthQuery.get('response_type'),
          clientID: oauthQuery.get('client_id'),
          scope: oauthQuery.get('scope'),
          state: oauthQuery.get('state'),
        },
        { headers, shouldIgnore401: true }
      )
      .then((res) => res.data.data);
  };

  /**
   * API Post to: `/v2/auth/tlt`
   * @param  {string} tlt
   * @returns Promise
   */
  public postLoginClientWithTLT = async (tlt: string): Promise<LoginResponse> => {
    const headers = {
      Authorization: `Bearer ${tlt}`,
    };

    const res = await apiWrapper.post<{ data: LoginResponse }>(
      `${apiHelper().authAPIEndpoint}/v2/auth/tlt`,
      {
        userType: 'CLIENT',
        platform: appConfig.platform,
        version: appConfig.appVersion,
      },
      { headers }
    );
    const { userID, access, refresh, ...otherTokenData } = res.data.data;
    setTokens({ accessToken: access, refreshToken: refresh, ...otherTokenData }, userID);
    return res.data.data;
  };

  public getTherapistBySlugName = (slugName: string): Promise<ETherapistInfo> =>
    this.wrapWithCancel(
      apiWrapper
        .get(`${apiHelper().apiEndpoint}/v2/therapist/slug/${slugName}`)
        .then((res: ApiResponse) => new ETherapistInfo(res.data.data))
    );

  /**
   * API Post to `/rest/ios/method/registerNewUser` to register a new user
   * @param {string} email
   * @param {string} password
   * @param {string} nickname
   * @param {{}} customerInformation
   * @param {string} phoneNumber
   * @param {string} phoneNumberCountryCode
   * @param {number} referrerID?
   * @param {number} channel?
   * @param {number} platform?
   * @param {string} slugName?
   * @param {string} accessCode?
   * @param {string} invitationKey?
   * @param {string} funnel?
   * @returns {Promise} Resolves if api requests does not fail
   */
  public postRegister = async (
    registrationMessage: RegistrationMessage
  ): Promise<RegisterNewUserResponse> =>
    apiWrapper
      .post<RegisterNewUserResponse>(
        `${apiHelper().apiEndpoint}/v2/registration`,
        registrationMessage,
        { withCredentials: true }
      )
      .then((res) => {
        const { data } = res;
        return data;
      });

  public cognitoPostRegister = async (
    registrationMessage: CognitoRegistrationMessage
  ): Promise<CognitoRegisterNewUserResponse> => {
    const { data } = await apiWrapper.post<{ data: CognitoRegisterNewUserResponse }>(
      `${apiHelper().apiEndpoint}/v2/registration/cognito`,
      registrationMessage,
      { withCredentials: true }
    );
    return data.data;
  };

  /**
   * API Post to `/rest/ios/method/forgotPassword`
   * @param {string} email
   * @returns {Promise} Resolves if api requests does not fail
   */
  public postForgotPassword = (email: string): Promise<void> =>
    this.wrapWithCancel(
      apiWrapper
        .post(`${apiHelper().authAPIEndpoint}/v2/auth/forgot-password`, {
          email,
        })
        .then((res) => res.data)
    );

  /**
   * API Post to `/v2/users/:clientUserID/basic-details`
   * @param {number} clientUserID
   * @param {string} password
   * @param {string} confirmedPassword
   * @returns {Promise} Resolves if api requests does not fail
   */
  public postChangePassword = (
    clientUserID: number,
    password: string,
    confirmedPassword: string
  ): Promise<ChangePasswordResponse> =>
    apiWrapper
      .patch<{ data: ChangePasswordResponse }>(
        `${apiHelper().apiEndpoint}/v2/users/${clientUserID}/basic-details`,
        {
          password,
          confirmedPassword,
        },
        { shouldIgnore401: true }
      )
      .then((res) => res.data.data)
      .catch((error) => {
        if (error.message === '401') throw new Error('The link used is no longer valid.');
        else throw error;
      });

  public getCMSItems = (clientID: number): Promise<ECMSItem[]> =>
    this.wrapWithCancel(
      apiWrapper
        .get(`${apiHelper().apiEndpoint}/v2/clients/${clientID}/cms-items`)
        .then((res: ApiResponse) => res.data.data as ECMSItem[])
    );

  public markCMSItemAsViewed = (clientID: number, CMSItemID: string): Promise<{}> =>
    this.wrapWithCancel(
      apiWrapper
        .post(`${apiHelper().apiEndpoint}/v2/clients/${clientID}/cms-items/${CMSItemID}/viewed`)
        .then((res: ApiResponse) => res.data as {})
    );

  /**
   * api call to: GET `/v2/rooms/${roomID}/match-therapist-preferences`
   * wrapWithCancel
   * @param  {number} roomID
   * @returns Promise
   */
  public getMatchingPreferences = (roomID: string): Promise<EMatchingPreferences> =>
    this.wrapWithCancel(
      apiWrapper
        .get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/match-therapist-preferences`)
        .then((res: ApiResponse) => new EMatchingPreferences(res.data.data))
    );

  /**
   * api call to: POST `/v2/rooms/${roomID}/match-therapist-preferences`
   * wrapWithCancel
   * @param  {number} roomID
   * @param  {EMatchingPreferences} preferences
   * @returns Promise
   */
  public saveMatchingPreferences = (
    roomID: string | number,
    preferences: EMatchingPreferences
  ): Promise<void> =>
    this.wrapWithCancel(
      apiWrapper
        .post(
          `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/match-therapist-preferences`,
          preferences
        )
        .then((res: ApiResponse) => res.data)
    );

  /**
   * api Post to: `/v2/rooms/${roomID}/user-prompts/${userPromptID}`
   * wrapWithCancel
   * @param  {number} roomID
   * @param  {number} userPromptID
   * @returns Promise
   */
  public updateUserPromptToDone = (
    roomID: number,
    userPromptID: number
  ): Promise<{ data: any; status: number }> =>
    apiWrapper.patch(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/user-prompts/${userPromptID}`, {
      status: 'done',
    });

  /**
   * api Post to: `/v3/rooms/${roomID}/video-calls/${videoCallID}`
   * wrapWithCancel
   * @param  {number} roomID
   * @param  {number} videoCallID
   * @param  {number} rating
   * @returns Promise
   */
  public rateVideoCall = (
    roomID: number,
    rating: number,
    videoCallID: number
  ): Promise<{ data: any; status: number }> =>
    apiWrapper.patch(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/video-calls/${videoCallID}`, {
      rating,
    });

  /**
   * api call to: GET `/api/v1/clients/${clientUserID}`
   * wrapWithCancel
   * @param  {number} clientUserID
   * @returns Promise
   */
  public getCustomerInformation = (clientUserID: number): Promise<ECustomerInfo> =>
    this.wrapWithCancel(
      apiWrapper
        .get(`${apiHelper().apiEndpoint}/api/v1/clients/${clientUserID}`)
        .then((res: ApiResponse) => new ECustomerInfo(res.data.data))
    );

  /**
   * api call to: GET `/api/v1/clinical/presenting-problems`
   * wrapWithCancel
   * @returns Promise
   */
  public getPresentingProblems = (): Promise<EPresentingProblems[]> =>
    this.wrapWithCancel(
      apiWrapper
        .get(`${apiHelper().apiEndpoint}/api/v1/clinical/presenting-problems`)
        .then((res: ApiResponse) => res.data.data.map((p) => new EPresentingProblems(p)))
    );

  /**
   * api call to: POST `/v3/users/:UserID/wizard`
   * wrapWithCancel
   * @returns Promise
   */
  public saveWizardStepsResults = (clientUserID: number, payload: any): Promise<ApiResponse> =>
    apiWrapper.post(`${apiHelper().apiEndpoint}/v3/users/${clientUserID}/wizard`, payload);

  /**
   * api call to: GET `/v3/rooms/:roomID/intake-available?type=matching`
   * wrapWithCancel
   * @returns Promise
   */
  public getIntakesStatus = (roomID: number): Promise<ApiResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/intake-available?type=matching`)
    );

  /**
   * api call to: POST `/v2/rooms/:roomID/cases/:caseID/sessionReports`
   * wrapWithCancel
   * @returns Promise
   */
  public postCreateSessionReport = (roomID: number, caseID: number): Promise<ApiResponse> =>
    this.wrapWithCancel(
      apiWrapper.post(
        `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/cases/${caseID}/session-reports`
      )
    );

  /**
   * api call to: `/v2/rooms/{roomID}/provider/{providerUserID}/informed-consents`
   * wrapWithCancel
   * @param  {number} roomID
   * @param  {number} providerUserID
   * @param doesInformedConsentRecordExist
   * @param messageID
   * @returns Promise
   */

  public getInformedConsent = (
    roomID: number,
    providerUserID: number,
    messageID?: number,
    doesInformedConsentRecordExist = true,
    informedConsentID?: number
  ): Promise<InformedConsentAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(
        `${
          apiHelper().apiEndpoint
        }/v2/rooms/${roomID}/provider/${providerUserID}/informed-consent?doesInformedConsentRecordExist=${doesInformedConsentRecordExist}${
          // Don't send messageID if no informedConsentRecord exists
          doesInformedConsentRecordExist && !informedConsentID ? `&messageID=${messageID}` : ''
        }${informedConsentID ? `&informedConsentID=${informedConsentID}` : ''}`
      )
    ).then((res: ApiResponse) => res.data.data);

  /**
   * api call to: `/v2/clients/:clientUserID:/sign-informed=consent`
   * wrapWithCancel
   * @param  {number} userID
   * @returns Promise
   */

  public agreeToInformedConsentV2 = (userID: number): Promise<ApiResponse> =>
    this.wrapWithCancel(
      apiWrapper.put(`${apiHelper().apiEndpoint}/v2/clients/${userID}/sign-informed-consent`, {
        triggeredBy: 'therapist',
      })
    );

  public getQMFrameSession = (
    userID: number,
    sessionType: QMSessionTypeEnum = QMSessionTypeEnum.Eligibility
  ): Promise<EligibilitySessionAPIResponse> =>
    apiWrapper
      .get(`${apiHelper().apiEndpoint}/v2/users/${userID}/eligibility/session/${sessionType}`)
      .then((res) => res.data.data);

  /**
   * api call to: GET `/v3/clients/:clientUserID/customer-information`
   * @returns Promise
   */
  public getCustomerInformationV3 = (
    clientUserID: number
  ): Promise<ApiResponse<GetCustomerInformationMessage>> =>
    apiWrapper.get(`${apiHelper().apiEndpoint}/v3/clients/${clientUserID}/customer-information`);

  /**
   * api call to: GET `/v3/users/:userID/emergency-contact`
   * @returns Promise
   */
  public getEmergencyContactV3 = (
    clientUserID: number
  ): Promise<ApiResponse<GetEmergencyContactMessage>> =>
    apiWrapper.get(`${apiHelper().apiEndpoint}/v3/users/${clientUserID}/emergency-contact`);
}
