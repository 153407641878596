import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M14.0719 7.39653H12.5C11.9469 7.39653 11.5 7.84341 11.5 8.39653C11.5 8.94966 11.9469 9.39653 12.5 9.39653H16.5C17.0532 9.39653 17.5 8.94966 17.5 8.39653V4.39653C17.5 3.84341 17.0532 3.39653 16.5 3.39653C15.9469 3.39653 15.5 3.84341 15.5 4.39653V5.99653L14.95 5.44653C12.2157 2.71216 7.78442 2.71216 5.05005 5.44653C2.31567 8.18091 2.31567 12.6122 5.05005 15.3465C7.78442 18.0809 12.2157 18.0809 14.95 15.3465C15.3407 14.9559 15.3407 14.3215 14.95 13.9309C14.5594 13.5403 13.925 13.5403 13.5344 13.9309C11.5813 15.884 8.41567 15.884 6.46255 13.9309C4.50942 11.9778 4.50942 8.81216 6.46255 6.85903C8.41567 4.90591 11.5813 4.90591 13.5344 6.85903L14.0719 7.39653Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
};

const ArrowRotateRight = withDSIconMaker(pathConfig, 'arrow rotate right icon');

export default ArrowRotateRight;
