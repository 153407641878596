import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M20.5556 5.8763C20.8917 5.8763 21.1667 6.1513 21.1667 6.48741V7.70964H2.83333V6.48741C2.83333 6.1513 3.10833 5.8763 3.44444 5.8763H20.5556ZM21.1667 11.3763V18.7096C21.1667 19.0457 20.8917 19.3207 20.5556 19.3207H3.44444C3.10833 19.3207 2.83333 19.0457 2.83333 18.7096V11.3763H21.1667ZM3.44444 4.04297C2.09618 4.04297 1 5.13915 1 6.48741V18.7096C1 20.0579 2.09618 21.1541 3.44444 21.1541H20.5556C21.9038 21.1541 23 20.0579 23 18.7096V6.48741C23 5.13915 21.9038 4.04297 20.5556 4.04297H3.44444ZM5.58333 15.6541C5.07535 15.6541 4.66667 16.0628 4.66667 16.5707C4.66667 17.0787 5.07535 17.4874 5.58333 17.4874H7.41667C7.92465 17.4874 8.33333 17.0787 8.33333 16.5707C8.33333 16.0628 7.92465 15.6541 7.41667 15.6541H5.58333ZM10.4722 15.6541C9.96424 15.6541 9.55556 16.0628 9.55556 16.5707C9.55556 17.0787 9.96424 17.4874 10.4722 17.4874H14.75C15.258 17.4874 15.6667 17.0787 15.6667 16.5707C15.6667 16.0628 15.258 15.6541 14.75 15.6541H10.4722Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const CreditCard = withDSIconMaker(pathConfig, 'credit card icon');

export default CreditCard;
