import { forwardRef } from 'react';
import { COLORS, COLORSV1, COLORSV2, COLORSV1_1 } from './colors/colors';
import { ColorRolesV0, ColorRolesV1, ColorRolesV2 } from './colors/colorRoles';
import {
  meetsVersion,
  parseVersionKey,
  SemanticVersion,
  sortSemanticVersions,
} from '../utils/semanticVersioning';
import useThemeVersion from '../hooks/useThemeVersion';
import { VersionMap } from './types';
import useShareForwardedRef from '../hooks/useShareForwardedRef';

export const getColorNames = (version: SemanticVersion | string) => {
  const parseKey = parseVersionKey(version);
  switch (parseKey.major) {
    case 2:
      return COLORSV2;
    case 1:
      // 1.1 is v1 components with v2 colors
      return parseKey.minor === 1 ? COLORSV1_1 : COLORSV1;
    default:
      return COLORS;
  }
};

export const getColorRoles = (version: SemanticVersion | string) => {
  const parseKey = parseVersionKey(version);
  switch (parseKey.major) {
    case 2:
      return ColorRolesV2;
    case 1:
      // 1.1 is v1 components with v2 colors
      return parseKey.minor === 1 ? ColorRolesV2 : ColorRolesV1;
    default:
      return ColorRolesV0;
  }
};

interface WithVersioningProps extends Object {
  versionOverride?: SemanticVersion;
}

/**
 * Uses the current theme version to determine which component to render
 * It will use the current version or any version lower than the current version
 * If no version is found, it will render the default component
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- Using Record<string, unknown> breaks usages of this function
export const withVersioning = <Props extends WithVersioningProps = {}>(
  versionMap: VersionMap<Props>
) => {
  const { DefaultComponent, ...versions } = versionMap;

  return forwardRef((props: Props, ref: React.RefObject<React.ReactNode>) => {
    const { versionOverride } = props;
    const forwardedRef = useShareForwardedRef(ref);
    const parsedOverride = parseVersionKey(versionOverride as SemanticVersion);
    const version = useThemeVersion();
    const sortedVersions = sortSemanticVersions(Object.keys(versions) as SemanticVersion[]);

    const versionToUse = sortedVersions.find((v) =>
      meetsVersion(v, versionOverride ? parsedOverride.versionKey : version.versionKey)
    );
    const Component = versionToUse ? versions[versionToUse] : DefaultComponent;
    return <Component {...props} ref={forwardedRef} />;
  });
};
