import View from '../../../components/View';
import { SpacingToken, spacing } from '../../tokens';

export interface SpacingViewProps {
  itemSpacing?: SpacingToken;
  children: React.ReactNode | React.ReactNode[];
  centerContent?: boolean;
  row?: boolean;
  flex?: number;
  asList?: boolean;
  inheritJustify?: boolean;
}
const SpacingView = ({
  itemSpacing = 'space100',
  row = true,
  flex = 1,
  children,
  centerContent,
  asList,
  inheritJustify,
}: SpacingViewProps) => (
  <View
    as={asList ? 'ul' : undefined}
    style={asList ? { paddingLeft: 0 } : undefined}
    flex={flex}
    justify={centerContent ? 'center' : 'start'}
  >
    {(Array.isArray(children) ? children : [children]).filter(Boolean).map((item, index) => (
      <View
        as={asList ? 'li' : undefined}
        key={(typeof item === 'object' && item !== null && 'key' in item && item.key) || index}
        style={index > 0 ? { marginTop: spacing[itemSpacing] } : undefined}
        row={row}
        flex={1}
        defaultJustify={inheritJustify ? 'inherit' : undefined}
      >
        {item}
      </View>
    ))}
  </View>
);
export default SpacingView;
