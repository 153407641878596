import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { SSLHostname } from './ip';

export type Environment = 'local' | 'localSSL' | 'mime' | 'test' | 'canary' | 'prod';

export type CapacitorBackend = 'prod' | 'dev' | 'canary' | 'local' | 'localSSL' | undefined;

const { protocol = 'build:', host = 'cli' } = globalThis.document?.location || {};
const origin = `${protocol}//${host}`;

interface ConfigSet {
  appVersion: string;
  platform: 'web-client' | 'ios-client' | 'android-client';
  endpoints: {
    socketEndpoint: string;
    apiEndpoint: string;
    publicAPIEndpoint: string;
    authAPIEndpoint: string;
    surveyAPIEndpoint: string;
    chatAPIEndpoint: string;
    homePageEndpoint: string;
    cdnEndpoint: string;
    quickMatchWebEndpoint: string;
    quickMatchApiEndpoint: string;
    recurlyAPIEndpoint: string;
    therapistAppEndpoint: string;
    liveVideoEndpoint: string;
    showDevTools: boolean;
  };
  googleMaps: {
    publicKey: string;
  };
  stripe: {
    publicKey: string;
  };
  recurly: {
    publicKey: string;
  };
  social: {
    facebook: {
      appId: string;
    };
  };
  analytics: {
    braze: {
      APIKey: string;
      baseURL: string;
      enableLogging: boolean;
      librarySRC?: string;
    };
    mixpanel: {
      token: string;
      librarySRC?: string;
      apiHost?: string;
      tsAnalyticsEndpoint?: string;
    };
    VWO: {
      librarySRC: string;
      accountID: number;
      purchaseGoalID: number;
      registerGoalID: number;
      initiatedQuickmatchID: number;
      qmLeadCaptureID: number;
      selectedPlanID: number;
      debugMode: boolean;
    };
  };
  launchDarkly: {
    sdkKey: string;
  };
  offers: {
    defaultPrivateRoomOfferId: number;
    defaultCouplesRoomOfferId: number;
    defaultPsychRoomOfferId: number;
  };
  zendesk: {
    subDomain: string;
  };
  appStoresLink: string;
  deepLinks: {
    subDomain: string;
    iOS: {
      bundleID: string;
      appStoreID: string;
    };
    android: {
      apn: string;
    };
  };
  agora: {
    appID: string;
    logLevel: 0 | 2 | 1 | 3 | 4;
  };
  featureFlags: {
    ssoLogin: boolean;
    ssoRegister: boolean;
    treatmentIntake: boolean;
    countryStateDropDown: boolean;
    notesV2: boolean;
    addToCalendar: boolean;
    schedulerV2: boolean;
  };
  sso: {
    aetna: {
      loginUrl: string;
    };
  };
  i18n: {
    staticPath: string;
  };
  ionic: {
    channelName: string;
    appId: string;
    updateMethod: 'none' | 'background';
    maxVersions: number;
    minBackgroundDuration: number;
    iosScheme: string;
    webContentsDebuggingEnabled: boolean;
    hostname: string;
  };
  newRelic: {
    androidToken: string;
    iosToken: string;
    collectorAddress: string;
  };
}

const {
  REACT_APP_IS_MIME,
  REACT_APP_TS_API_HOST,
  REACT_APP_QM_API_HOST,
  REACT_APP_AUTH_API_HOST,
  REACT_APP_SURVEY_API_HOST,
  REACT_APP_CHAT_API_HOST,
  REACT_APP_HOMEPAGE_HOST,
  REACT_APP_QM_WEB_HOST,
  REACT_APP_THERAPIST_WEB_HOST,
  REACT_APP_LIVEVIDEO_API_HOST,
} = process.env;

const config: {
  local: ConfigSet;
  test: ConfigSet;
  mime: ConfigSet;
  canary: ConfigSet;
  prod: ConfigSet;
} = {
  local: {
    appVersion: '1.0.0',
    platform: 'web-client',
    endpoints: {
      socketEndpoint: 'http://localhost:3000',
      apiEndpoint: 'http://localhost:3000',
      publicAPIEndpoint: 'http://localhost:3000',
      authAPIEndpoint: 'http://localhost:3002',
      surveyAPIEndpoint: 'http://localhost:3005',
      chatAPIEndpoint: 'http://localhost:3000',
      homePageEndpoint: 'https://localhost:4000',
      cdnEndpoint: 'https://cdn.dev.talkspace.com',
      recurlyAPIEndpoint: 'https://api.recurly.com/js/v1',
      quickMatchWebEndpoint: 'http://localhost:3014',
      quickMatchApiEndpoint: 'http://localhost:3015',
      therapistAppEndpoint: 'http://localhost:3001',
      liveVideoEndpoint: 'http://localhost:3008',
      showDevTools: true,
    },
    googleMaps: {
      publicKey: 'AIzaSyD4TQpBsK7ysHcRKB7eXqWkVAFsWPVoGX4',
    },
    stripe: {
      publicKey: 'pk_test_ZiR80tYdDex98m382LP40yTg',
    },
    recurly: {
      publicKey: 'ewr1-6DaF8lw39Tdh3rFvbCGylf',
    },
    social: {
      facebook: {
        appId: '216788091770100',
      },
    },
    analytics: {
      braze: {
        APIKey: '3d845c98-6d6a-4bea-96a5-aa587e4f1985',
        baseURL: 'https://sdk.iad-02.braze.com/api/v3',
        enableLogging: true,
        librarySRC: 'https://apx.talkspace.com/tools/brz.js',
      },
      mixpanel: {
        token: 'fa79581068ccb3a9cd3cb59fbc760b0d',
        librarySRC: 'https://apx.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.talkspace.com/mp',
        tsAnalyticsEndpoint: 'http://localhost:3013/local',
      },
      VWO: {
        librarySRC: 'https://apxvo.dev.talkspace.com',
        accountID: 562325,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchID: 203,
        qmLeadCaptureID: 204,
        selectedPlanID: 205,
        debugMode: true,
      },
    },
    launchDarkly: {
      sdkKey: '63188b38630c5d117991ce73',
    },
    offers: {
      defaultPrivateRoomOfferId: 62,
      defaultCouplesRoomOfferId: 103,
      defaultPsychRoomOfferId: 87,
    },
    zendesk: {
      subDomain: 'https://talkspacecanary1617805623.zendesk.com',
    },
    appStoresLink: 'https://talkspace.onelink.me/j3Jj/447a86f8',
    deepLinks: {
      subDomain: 'https://talkspacedev.page.link',
      iOS: {
        bundleID: 'com.talktala.talktala',
        appStoreID: '661829386',
      },
      android: {
        apn: 'com.talkspace.talkspaceapp.debug',
      },
    },
    agora: {
      appID: '8a0d6d66454e450cacecb7733554ae95',
      logLevel: 0,
    },
    featureFlags: {
      ssoLogin: true,
      ssoRegister: false,
      treatmentIntake: true,
      countryStateDropDown: true,
      notesV2: false,
      addToCalendar: true,
      schedulerV2: false,
    },
    sso: {
      aetna: {
        loginUrl: `https://talkspace-dev.auth.us-east-1.amazoncognito.com/authorize?response_type=token&client_id=5d3c7kdp8utnhc15b5aqif1bnt&redirect_uri=${origin}/login/sso/callback&identity_provider=Aetna`,
      },
    },
    i18n: {
      staticPath: '',
    },
    ionic: {
      channelName: '',
      appId: '84f4d867',
      updateMethod: 'none',
      maxVersions: 2,
      minBackgroundDuration: 30,
      iosScheme: 'Client Development',
      webContentsDebuggingEnabled: true,
      hostname: 'localhost',
    },
    newRelic: {
      androidToken: 'AAd2fdfeb18aea0eae76b8cce564ff1a724ed127ae',
      iosToken: 'AA7295d10879482097cc38b451d22b547b5c0f1e60',
      collectorAddress: 'https://apx.dev.talkspace.com/nr',
    },
  },
  mime: {
    appVersion: '1.0.0',
    platform: 'web-client',
    endpoints: {
      socketEndpoint: REACT_APP_TS_API_HOST as string,
      apiEndpoint: REACT_APP_TS_API_HOST as string,
      publicAPIEndpoint: REACT_APP_TS_API_HOST as string,
      authAPIEndpoint: REACT_APP_AUTH_API_HOST as string,
      surveyAPIEndpoint: REACT_APP_SURVEY_API_HOST as string,
      chatAPIEndpoint: REACT_APP_CHAT_API_HOST as string,
      homePageEndpoint: REACT_APP_HOMEPAGE_HOST as string,
      cdnEndpoint: 'https://cdn.dev.talkspace.com',
      quickMatchWebEndpoint: REACT_APP_QM_WEB_HOST as string,
      quickMatchApiEndpoint: REACT_APP_QM_API_HOST as string,
      recurlyAPIEndpoint: 'https://api.recurly.com/js/v1',
      therapistAppEndpoint: REACT_APP_THERAPIST_WEB_HOST as string,
      liveVideoEndpoint: REACT_APP_LIVEVIDEO_API_HOST as string,
      showDevTools: true,
    },
    googleMaps: {
      publicKey: 'AIzaSyBeDp0J8UOf-cU6CSmhpPcTqP40pH_zCpo',
    },
    stripe: {
      publicKey: 'pk_test_ZiR80tYdDex98m382LP40yTg',
    },
    recurly: {
      publicKey: 'ewr1-6DaF8lw39Tdh3rFvbCGylf',
    },
    social: {
      facebook: {
        appId: '216788091770100',
      },
    },
    analytics: {
      braze: {
        APIKey: '3d845c98-6d6a-4bea-96a5-aa587e4f1985',
        baseURL: 'https://sdk.iad-02.braze.com/api/v3',
        enableLogging: true,
        librarySRC: 'https://apx.talkspace.com/tools/brz.js',
      },
      mixpanel: {
        token: '50c9563ae17591f1111c48654ed7773d',
        librarySRC: 'https://apx.dev.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.dev.talkspace.com/mp',
        tsAnalyticsEndpoint: 'https://eventsapi.dev.talkspace.com',
      },
      VWO: {
        librarySRC: 'https://apxvo.dev.talkspace.com',
        accountID: 562328,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchID: 203,
        qmLeadCaptureID: 204,
        selectedPlanID: 205,
        debugMode: true,
      },
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46c',
    },
    offers: {
      defaultPrivateRoomOfferId: 62,
      defaultCouplesRoomOfferId: 103,
      defaultPsychRoomOfferId: 87,
    },
    zendesk: {
      subDomain: 'https://talkspacecanary1617805623.zendesk.com',
    },
    appStoresLink: 'https://talkspace.onelink.me/j3Jj/447a86f8',
    deepLinks: {
      subDomain: 'https://talkspacedev.page.link',
      iOS: {
        bundleID: 'com.talktala.talktala',
        appStoreID: '661829386',
      },
      android: {
        apn: 'com.talkspace.talkspaceapp.debug',
      },
    },
    agora: {
      appID: '8a0d6d66454e450cacecb7733554ae95',
      logLevel: 0,
    },
    featureFlags: {
      ssoLogin: true,
      ssoRegister: false,
      treatmentIntake: true,
      countryStateDropDown: true,
      notesV2: false,
      addToCalendar: true,
      schedulerV2: false,
    },
    sso: {
      aetna: {
        loginUrl: `https://talkspace-dev.auth.us-east-1.amazoncognito.com/authorize?response_type=token&client_id=5d3c7kdp8utnhc15b5aqif1bnt&redirect_uri=${origin}/login/sso/callback&identity_provider=Aetna`,
      },
    },
    i18n: {
      staticPath: '',
    },
    ionic: {
      channelName: '',
      appId: '84f4d867',
      updateMethod: 'none',
      maxVersions: 2,
      minBackgroundDuration: 30,
      iosScheme: 'Client Development',
      webContentsDebuggingEnabled: true,
      hostname: 'capacitor.mime.talkspace.com',
    },
    newRelic: {
      androidToken: 'AAd2fdfeb18aea0eae76b8cce564ff1a724ed127ae',
      iosToken: 'AA7295d10879482097cc38b451d22b547b5c0f1e60',
      collectorAddress: 'https://apx.dev.talkspace.com/nr',
    },
  },
  test: {
    appVersion: '1.0.0',
    platform: 'web-client',
    endpoints: {
      socketEndpoint: 'https://clientapi.dev.talkspace.com',
      apiEndpoint: 'https://clientapi.dev.talkspace.com',
      publicAPIEndpoint: 'https://publicapi.dev.talkspace.com',
      authAPIEndpoint: 'https://clientapi.dev.talkspace.com',
      surveyAPIEndpoint: 'https://survey.dev.talkspace.com',
      chatAPIEndpoint: 'https://api.dev.talkspace.com',
      homePageEndpoint: 'https://www.dev.talkspace.com',
      cdnEndpoint: 'https://cdn.dev.talkspace.com',
      quickMatchWebEndpoint: 'https://match.dev.talkspace.com',
      quickMatchApiEndpoint: 'https://matchapi.dev.talkspace.com',
      recurlyAPIEndpoint: 'https://api.recurly.com/js/v1',
      therapistAppEndpoint: 'https://therapist.dev.talkspace.com',
      liveVideoEndpoint: 'https://livevideo.dev.talkspace.com',
      showDevTools: true,
    },
    googleMaps: {
      publicKey: 'AIzaSyBeDp0J8UOf-cU6CSmhpPcTqP40pH_zCpo',
    },
    stripe: {
      publicKey: 'pk_test_ZiR80tYdDex98m382LP40yTg',
    },
    recurly: {
      publicKey: 'ewr1-6DaF8lw39Tdh3rFvbCGylf',
    },
    social: {
      facebook: {
        appId: '216788091770100',
      },
    },
    analytics: {
      braze: {
        APIKey: '3d845c98-6d6a-4bea-96a5-aa587e4f1985',
        baseURL: 'https://sdk.iad-02.braze.com/api/v3',
        enableLogging: true,
        librarySRC: 'https://apx.talkspace.com/tools/brz.js',
      },
      mixpanel: {
        token: '50c9563ae17591f1111c48654ed7773d',
        librarySRC: 'https://apx.dev.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.dev.talkspace.com/mp',
        tsAnalyticsEndpoint: 'https://eventsapi.dev.talkspace.com',
      },
      VWO: {
        librarySRC: 'https://apxvo.dev.talkspace.com',
        accountID: 562328,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchID: 203,
        qmLeadCaptureID: 204,
        selectedPlanID: 205,
        debugMode: true,
      },
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46c',
    },
    offers: {
      defaultPrivateRoomOfferId: 62,
      defaultCouplesRoomOfferId: 103,
      defaultPsychRoomOfferId: 87,
    },
    zendesk: {
      subDomain: 'https://talkspacecanary1617805623.zendesk.com',
    },
    appStoresLink: 'https://talkspace.onelink.me/j3Jj/447a86f8',
    deepLinks: {
      subDomain: 'https://talkspacedev.page.link',
      iOS: {
        bundleID: 'com.talktala.talktala',
        appStoreID: '661829386',
      },
      android: {
        apn: 'com.talkspace.talkspaceapp.debug',
      },
    },
    agora: {
      appID: '8a0d6d66454e450cacecb7733554ae95',
      logLevel: 0,
    },
    featureFlags: {
      ssoLogin: true,
      ssoRegister: false,
      treatmentIntake: true,
      countryStateDropDown: true,
      notesV2: false,
      addToCalendar: true,
      schedulerV2: false,
    },
    sso: {
      aetna: {
        loginUrl: `https://talkspace-dev.auth.us-east-1.amazoncognito.com/authorize?response_type=token&client_id=5d3c7kdp8utnhc15b5aqif1bnt&redirect_uri=${origin}/login/sso/callback&identity_provider=Aetna`,
      },
    },
    i18n: {
      staticPath: '',
    },
    ionic: {
      channelName: 'Development',
      appId: '84f4d867',
      updateMethod: 'none',
      maxVersions: 2,
      minBackgroundDuration: 30,
      iosScheme: 'Client Development',
      webContentsDebuggingEnabled: true,
      hostname: 'capacitor.dev.talkspace.com',
    },
    newRelic: {
      androidToken: 'AAd2fdfeb18aea0eae76b8cce564ff1a724ed127ae',
      iosToken: 'AA7295d10879482097cc38b451d22b547b5c0f1e60',
      collectorAddress: 'https://apx.dev.talkspace.com/nr',
    },
  },
  canary: {
    appVersion: '1.0.0',
    platform: 'web-client',
    endpoints: {
      socketEndpoint: 'https://clientapi.canary.talkspace.com',
      apiEndpoint: 'https://clientapi.canary.talkspace.com',
      publicAPIEndpoint: 'https://publicapi.canary.talkspace.com',
      authAPIEndpoint: 'https://clientapi.canary.talkspace.com',
      surveyAPIEndpoint: 'https://survey.canary.talkspace.com',
      chatAPIEndpoint: 'https://api.canary.talkspace.com',
      homePageEndpoint: 'https://www.canary.talkspace.com',
      cdnEndpoint: 'https://cdn.canary.talkspace.com',
      quickMatchWebEndpoint: 'https://match.canary.talkspace.com',
      quickMatchApiEndpoint: 'https://matchapi.canary.talkspace.com',
      recurlyAPIEndpoint: 'https://api.recurly.com/js/v1',
      therapistAppEndpoint: 'https://therapist.canary.talkspace.com',
      liveVideoEndpoint: 'https://livevideo.canary.talkspace.com',
      showDevTools: true,
    },
    googleMaps: {
      publicKey: 'AIzaSyD9DxLYEAo7MeAjjO_87b7D2b3rtaFewu4',
    },
    stripe: {
      publicKey: 'pk_test_cMoHUi7Z3bbE26WaLTAbawPU00IyzINpn3',
    },
    recurly: {
      publicKey: 'ewr1-6DaF8lw39Tdh3rFvbCGylf',
    },
    social: {
      facebook: {
        appId: '216788091770100',
      },
    },
    analytics: {
      braze: {
        APIKey: '06270417-0486-4a81-9a8b-3f9e09d791f9',
        baseURL: 'https://sdk.iad-02.braze.com/api/v3',
        enableLogging: true,
        librarySRC: 'https://apx.talkspace.com/tools/brz.js',
      },
      mixpanel: {
        token: 'b2b83bd1e273fb7125936cc158c5e749',
        librarySRC: 'https://apx.canary.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.canary.talkspace.com/mp',
        tsAnalyticsEndpoint: 'https://eventsapi.canary.talkspace.com',
      },
      VWO: {
        librarySRC: 'https://apxvo.canary.talkspace.com',
        accountID: 562328,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchID: 203,
        qmLeadCaptureID: 204,
        selectedPlanID: 205,
        debugMode: true,
      },
    },
    launchDarkly: {
      sdkKey: '63188b1d6dbe1c11ce5dd39c',
    },
    offers: {
      defaultPrivateRoomOfferId: 62,
      defaultCouplesRoomOfferId: 103,
      defaultPsychRoomOfferId: 87,
    },
    zendesk: {
      subDomain: 'https://talkspace-canary.zendesk.com',
    },
    appStoresLink: 'https://talkspace.onelink.me/j3Jj/447a86f8',
    deepLinks: {
      subDomain: 'https://talkspacecanary.page.link',
      iOS: {
        bundleID: 'com.talktala.talktala',
        appStoreID: '661829386',
      },
      android: {
        apn: 'com.talkspace.talkspaceapp.canary',
      },
    },
    agora: {
      appID: '8a0d6d66454e450cacecb7733554ae95',
      logLevel: 0,
    },
    featureFlags: {
      ssoLogin: true,
      ssoRegister: false,
      treatmentIntake: true,
      countryStateDropDown: true,
      notesV2: false,
      addToCalendar: true,
      schedulerV2: false,
    },
    sso: {
      aetna: {
        loginUrl: `https://talkspace-canary.auth.us-east-1.amazoncognito.com/authorize?response_type=token&client_id=322cqur2o9qi4e5er4i5nou2be&redirect_uri=${origin}/login/sso/callback&identity_provider=Aetna`,
      },
    },
    i18n: {
      staticPath: '',
    },
    ionic: {
      channelName: 'Canary',
      appId: '84f4d867',
      updateMethod: 'none',
      maxVersions: 2,
      minBackgroundDuration: 30,
      iosScheme: 'Client Canary',
      webContentsDebuggingEnabled: true,
      hostname: 'capacitor.canary.talkspace.com',
    },
    newRelic: {
      androidToken: 'AAb9bbda5faf0146153df769edaf40bc53636e64c1-NRMA',
      iosToken: 'AA63dfc59915e7307029da74e65dd9d79e3eda2475-NRMA',
      collectorAddress: 'https://apx.canary.talkspace.com/nr',
    },
  },
  prod: {
    appVersion: '1.0.0',
    platform: 'web-client',
    endpoints: {
      socketEndpoint: 'https://clientapi.talkspace.com',
      apiEndpoint: 'https://clientapi.talkspace.com',
      publicAPIEndpoint: 'https://publicapi.talkspace.com',
      authAPIEndpoint: 'https://clientapi.talkspace.com',
      surveyAPIEndpoint: 'https://survey.talkspace.com',
      chatAPIEndpoint: 'https://api.talkspace.com',
      homePageEndpoint: 'https://www.talkspace.com',
      cdnEndpoint: 'https://cdn.talkspace.com',
      quickMatchWebEndpoint: 'https://match.talkspace.com',
      quickMatchApiEndpoint: 'https://matchapi.talkspace.com',
      recurlyAPIEndpoint: 'https://api.recurly.com/js/v1',
      therapistAppEndpoint: 'https://therapist.talkspace.com',
      liveVideoEndpoint: 'https://livevideo.talkspace.com',
      showDevTools: false,
    },
    googleMaps: {
      publicKey: 'AIzaSyBAlaStpXdAgctdhzOY5-BRLVpcglKRqLY',
    },
    stripe: {
      publicKey: 'pk_live_k0WUCH8ZAmOwp2KpOnuTbQ8z',
    },
    recurly: {
      publicKey: 'sc-09NpTivGUTyesWCDp0aqxK',
    },
    social: {
      facebook: {
        appId: '216788091770100',
      },
    },
    analytics: {
      braze: {
        APIKey: 'ddff7a41-032d-42a5-9a82-a89b779b2058',
        baseURL: 'https://sdk.iad-02.braze.com/api/v3',
        enableLogging: false,
        librarySRC: 'https://apx.talkspace.com/tools/brz.js',
      },
      mixpanel: {
        token: 'c18be99a70104cfc390ad47fa5f44df6',
        librarySRC: 'https://apx.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.talkspace.com/mp',
        tsAnalyticsEndpoint: 'https://eventsapi.talkspace.com',
      },
      VWO: {
        librarySRC: 'https://apxvo.talkspace.com',
        accountID: 540341,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchID: 203,
        qmLeadCaptureID: 204,
        selectedPlanID: 205,
        debugMode: false,
      },
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46d',
    },
    offers: {
      defaultPrivateRoomOfferId: 62,
      defaultCouplesRoomOfferId: 103,
      defaultPsychRoomOfferId: 87,
    },
    zendesk: {
      subDomain: 'https://talkspace.zendesk.com',
    },
    appStoresLink: 'https://talkspace.onelink.me/ob51/7f54d3a7',
    deepLinks: {
      subDomain: 'https://sb4q5.app.goo.gl',
      iOS: {
        bundleID: 'com.talktala.talktala',
        appStoreID: '661829386',
      },
      android: {
        apn: 'com.talkspace.talkspaceapp',
      },
    },
    agora: {
      appID: '9694662d6a1740e7ac3498b61a4d8578',
      logLevel: 3,
    },
    featureFlags: {
      ssoLogin: true,
      ssoRegister: false,
      treatmentIntake: true,
      countryStateDropDown: true,
      notesV2: false,
      addToCalendar: true,
      schedulerV2: false,
    },
    sso: {
      aetna: {
        loginUrl: `https://talkspace.auth.us-east-1.amazoncognito.com/authorize?response_type=token&client_id=56tockos7jmbkqms5tvv6ekjgb&redirect_uri=${origin}/login/sso/callback&identity_provider=Aetna`,
      },
    },
    i18n: {
      staticPath: '',
    },
    ionic: {
      channelName: 'Production',
      appId: '84f4d867',
      updateMethod: 'none',
      maxVersions: 2,
      minBackgroundDuration: 30,
      iosScheme: 'Client',
      webContentsDebuggingEnabled: false,
      hostname: 'capacitor.talkspace.com',
    },
    newRelic: {
      iosToken: 'AAeb243fd0c6576a2f970792f44f8dcda2b1eb367b',
      androidToken: 'AA647cba1fe5ffb2b8182bfeae95dc1ea362f9344a',
      collectorAddress: 'https://apx.talkspace.com/nr',
    },
  },
};

const configSSL: Record<Environment, ConfigSet> = {
  ...config,
  localSSL: {
    ...config.local,
    endpoints: {
      socketEndpoint: `https://${SSLHostname}:8009`,
      apiEndpoint: `https://${SSLHostname}:8000`,
      publicAPIEndpoint: `https://${SSLHostname}:8000`,
      authAPIEndpoint: `https://${SSLHostname}:8002`,
      surveyAPIEndpoint: `https://${SSLHostname}:8005`,
      chatAPIEndpoint: `https://${SSLHostname}:8001`,
      homePageEndpoint: `https://${SSLHostname}:8001`,
      cdnEndpoint: 'https://cdn.dev.talkspace.com',
      quickMatchWebEndpoint: `https://${SSLHostname}:8014`,
      quickMatchApiEndpoint: `https://${SSLHostname}:8015`,
      recurlyAPIEndpoint: 'https://api.recurly.com/js/v1',
      therapistAppEndpoint: `https://${SSLHostname}:8011`,
      liveVideoEndpoint: `https://${SSLHostname}:8008`,
      showDevTools: true,
    },
  },
};

const getBuildTimeEnv = (): Environment | null => {
  switch (process.env.REACT_APP_CAPACITOR_BACKEND as CapacitorBackend) {
    case 'prod':
      return 'prod';
    case 'dev':
      return 'test';
    case 'canary':
      return 'canary';
    case 'local':
      return 'local';
    case 'localSSL':
      return 'localSSL';
    default:
      return null;
  }
};

export const detectEnv = (): Environment => {
  if (REACT_APP_IS_MIME === '1') {
    return 'mime';
  }
  switch (origin) {
    case 'https://localhost:4001':
    case 'https://localhost.com:4001': // used by VWO
      return 'local';
    case `https://${SSLHostname}:8010`:
      return 'localSSL';
    case 'https://app-beta.dev.talkspace.com':
    case 'https://app.dev.talkspace.com':
      return 'test';
    case 'https://app.canary.talkspace.com':
    case 'https://app-beta.canary.talkspace.com':
      return 'canary';
    case 'build://cli': // This happens on build time, when calling ionic-related commands like `sync`
    case 'https://localhost':
    case 'capacitor://localhost':
      return getBuildTimeEnv() ?? (process.env.USE_LOCAL_PROXY ? 'localSSL' : 'test');
    default:
      // Appflow uses someSubdomain.appflowapp.com
      return getBuildTimeEnv() ?? 'prod';
  }
};

const appConfig = configSSL[detectEnv()];

if (Capacitor.isNativePlatform()) {
  appConfig.platform = `${Capacitor.getPlatform() as 'ios' | 'android'}-client`;
  appConfig.appVersion = '10.0.0';
  App.getInfo().then((info) => {
    if (info.version) appConfig.appVersion = info.version;
  });
}

export default appConfig;
