import { PaymentDetails, FlowVariant, SessionModality, ChargeType } from 'ts-frontend/types';
import { VoidFunctionComponent, useEffect, useCallback } from 'react';
import moment from 'moment';
import { View, Spinner, useWindowWidth, SparklingCalendar } from '@talkspace/react-toolkit';
import { trackCancellationPolicyClick } from 'ts-analytics/mixpanel/events';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouteMatch, useHistory, useLocation } from '@/core/routerLib';
import { getUserData } from '@/auth/helpers/token';

import InRoomSchedulingError from '../components/inRoomSchedulingError';
import { ConfirmBookingOptions, TherapistInfo, InRoomSchedulingLocation } from '../types';
import {
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
} from '../hooks/inRoomSchedulingContext';
import {
  ConfirmBookingTherapist,
  ConfirmBookingRecurring,
  ConfirmBookingRecurringClient,
  ConfirmBookingJoin,
  ConfirmBookingWithPolicy,
  ConfirmBookingWithoutPolicy,
  ConfirmBookingWithoutPolicyOnboarding,
  ConfirmBookingWithPolicyOnboarding,
} from '../components/ConfirmBooking';

import BookingAvatars from '../components/BookingAvatars';
import { BookingDates, BookingDatesOnBoarding } from '../components/BookingDates';
import { CANCELLATION_WINDOW_HOURS } from '../utils/constants';

import useQueryClientTransactions from '../hooks/useQueryClientTransactions';
import useRepeatingBooking from '../hooks/useRepeatingBooking';
import useChangePrimaryBookingPathParam from '../hooks/useChangePrimaryBookingPathParam';
import useQueryBusinessLine from '../../ts-frontend/hooks/useQueryBusinessLine';

interface Props {
  isTherapist: boolean;
  flowVariant?: FlowVariant;
  preRegistrationSelectedModality?: SessionModality;
  handleConfirmAppointment: (options: Partial<ConfirmBookingOptions>) => void;
  handleOnBoardingBookSession: () => void;
  onClosePress: () => void;
  therapist?: TherapistInfo;
  paymentDetails?: PaymentDetails;
  isPaymentLoading: boolean;
  clientDisplayName?: string;
  bookWithIntroSession?: boolean;
  isCreateBookingActivationError?: boolean;
  isCreateBookingActivationSuccess?: boolean;
  isCreateBookingActivationLoading?: boolean;
  handleReviewCancellationPolicy: () => void;
  requestData?: any;
  requestError?: any;
  clientUserID?: number;
}

const ConfirmBookingContainer: VoidFunctionComponent<Props> = ({
  onClosePress,
  isTherapist,
  flowVariant,
  preRegistrationSelectedModality,
  therapist,
  handleConfirmAppointment,
  handleOnBoardingBookSession,
  paymentDetails,
  isPaymentLoading,
  clientDisplayName,
  bookWithIntroSession = false,
  isCreateBookingActivationError = false,
  isCreateBookingActivationSuccess = false,
  isCreateBookingActivationLoading = false,
  handleReviewCancellationPolicy,
  requestData,
  requestError,
  clientUserID,
}) => {
  const {
    params: { roomID, bookingID },
  } = useRouteMatch<{ roomID: string; bookingID?: string }>();
  const location = useLocation<InRoomSchedulingLocation>();
  const history = useHistory();

  const { data: businessLine } = useQueryBusinessLine(roomID, clientUserID);
  const {
    room,
    planInfo,
    isLoading,
    selectedTimeslot,
    selectedCreditOption,
    selectedCancelBooking,
    selectedConfirmBooking,
    selectedBookingToReschedule,
    shouldShowBookingSuccess,
    isError,
    schedulerMode,
    isJoin,
    isClientConfirmError,
    isClientRescheduleError,
    modality,
    roomPlanID,
    localTimezone,
    isBHAdHoc,
    errorMessage,
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
  } = useInRoomSchedulingState();
  const {
    dispatchGetVideoCall,
    dispatchGetBookingToConfirm,
    dispatchGetBookingToReschedule,
    dispatchSetHasBreakAfterSession,
    dispatchModalityType,
    dispatchGetSubscriptions,
    dispatchSetIsError,
  } = useInRoomSchedulingActions();

  useChangePrimaryBookingPathParam(selectedConfirmBooking);

  const { isReschedule = false, roomID: stateRoomID } = location.state || {};
  const { isMobile } = useWindowWidth();
  const isOnboarding = history.location.pathname.includes('/onboarding');
  const onboardingBaseURL = `/room/${roomID}/onboarding/book-session`;
  const isBookAndActivate = flowVariant === 'bookAndActivate';
  const isSessionBased = room?.isSessionBased;
  const inRoomBaseURL = `/in-room-scheduling/room/${roomID}`;
  const { id: userID } = getUserData();

  const { copayAtTimeOfService: copayAtTimeOfServiceActive } =
    useFlags<{ copayAtTimeOfService: boolean }>();

  const { pendingInvoicesChargeTypes } =
    useFlags<{ pendingInvoicesChargeTypes?: Array<ChargeType> }>();

  const { isRecurringBooking, repeatingTimeslots, conflictingTimeslots } = useRepeatingBooking({
    selectedBooking: selectedConfirmBooking,
    onlyConfirmedOrTentative: true,
  });
  const shouldShowConfirmAndJoin =
    isJoin &&
    schedulerMode === 'providerScheduled' &&
    !!selectedCreditOption?.availableCredits &&
    !selectedConfirmBooking?.videoCreditID;

  const shouldShowJoinDirectly =
    isJoin && schedulerMode === 'providerScheduled' && !!selectedConfirmBooking?.videoCreditID;

  const isClientTransactionsEnabled =
    !!pendingInvoicesChargeTypes &&
    pendingInvoicesChargeTypes.length > 0 &&
    !isTherapist &&
    !!isSessionBased &&
    !!clientUserID &&
    !shouldShowConfirmAndJoin &&
    !shouldShowJoinDirectly &&
    !isBookAndActivate;

  const { data: pendingInvoices, isError: isPendingInvoicesError } = useQueryClientTransactions(
    {
      chargeTypes: pendingInvoicesChargeTypes,
      clientUserID: clientUserID!,
    },
    {
      enabled: isClientTransactionsEnabled,
    }
  );

  const checkedPendingInvoices =
    (!!pendingInvoicesChargeTypes &&
      (!isClientTransactionsEnabled || (!!pendingInvoices && pendingInvoices.length === 0))) ||
    isPendingInvoicesError;

  useEffect(() => {
    if (pendingInvoices && pendingInvoices.length > 0) {
      history.replace(
        `/in-room-scheduling/room/${roomID}/pending-invoices?source=confirmBooking&bookingID=${bookingID}`
      );
    }
  }, [bookingID, history, pendingInvoices, roomID]);

  useEffect(() => {
    if (isTherapist) return;
    dispatchGetVideoCall(Number(roomID));
  }, [dispatchGetVideoCall, isTherapist, roomID]);

  useEffect(() => {
    if (shouldShowBookingSuccess && !isJoin) {
      let url = isTherapist ? `/room/${roomID}/in-room-scheduling` : inRoomBaseURL;
      if (isOnboarding) url = onboardingBaseURL;
      history.push(`${url}/booking-success`);
    }
  }, [
    history,
    isOnboarding,
    isTherapist,
    roomID,
    shouldShowBookingSuccess,
    onboardingBaseURL,
    inRoomBaseURL,
    isJoin,
  ]);

  useEffect(() => {
    if (bookingID) {
      const finalRoomID = bookWithIntroSession ? stateRoomID : Number(roomID);
      if (isReschedule) {
        dispatchGetBookingToReschedule(Number(finalRoomID), bookingID);
      } else {
        dispatchGetBookingToConfirm(Number(finalRoomID), bookingID);
      }
    }
  }, [
    bookingID,
    roomID,
    stateRoomID,
    bookWithIntroSession,
    dispatchGetBookingToConfirm,
    isReschedule,
    dispatchGetBookingToReschedule,
    businessLine,
  ]);

  useEffect(() => {
    if (isReschedule && selectedBookingToReschedule?.modality) {
      dispatchModalityType(selectedBookingToReschedule.modality);
    } else if (selectedConfirmBooking?.modality) {
      dispatchModalityType(selectedConfirmBooking.modality);
    }
  }, [selectedConfirmBooking, dispatchModalityType, isReschedule, selectedBookingToReschedule]);

  useEffect(() => {
    if (!roomPlanID && !isTherapist) {
      dispatchGetSubscriptions(userID, Number(roomID), false);
    }
  }, [dispatchGetSubscriptions, isTherapist, roomID, roomPlanID, userID]);

  const isIneligibleError = isError && errorMessage === 'user_ineligible';

  const shouldShowIneligiblePage =
    copayAtTimeOfServiceActive && isIneligibleError && !isTherapist && !isBHAdHoc;

  useEffect(() => {
    if (shouldShowIneligiblePage) {
      history.replace(`/in-room-scheduling/room/${roomID}/ineligible-member`);
    }
  }, [history, roomID, shouldShowIneligiblePage]);

  const isEligibilityFileBH = selectedCreditOption?.isEligibilityFileBH;

  // must purchase if no credits available
  let isPurchase =
    (((schedulerMode === 'clientScheduled' &&
      !isEligibilityFileBH &&
      !!selectedCreditOption &&
      !selectedCreditOption.availableCredits) ||
      (schedulerMode === 'providerScheduled' &&
        !!selectedConfirmBooking &&
        !selectedConfirmBooking.videoCreditID)) &&
      !isTherapist &&
      !room?.isEAP &&
      !selectedCreditOption?.availableCredits &&
      !selectedCreditOption?.isEligibilityFileBH) ||
    (isBHAdHoc && !selectedCreditOption?.availableCredits) ||
    isRecurringBooking;

  let shouldRedeemCredit = false;
  if (selectedCancelBooking && isReschedule) {
    const startTimeMinus24hrs = moment(selectedCancelBooking.startTime).subtract(
      CANCELLATION_WINDOW_HOURS,
      'hours'
    );
    const isClientScheduledBooking = selectedCancelBooking.scheduledByUserType === 'client';
    const isBookingConfirmed = selectedCancelBooking.timekitBookingState === 'confirmed';
    // redeem credit if prev booking is less than 24 hours away and the client scheduled or confirmed it
    shouldRedeemCredit =
      (isClientScheduledBooking || isBookingConfirmed) && moment().isAfter(startTimeMinus24hrs);
    // if rescheduling a client created booking and not within 24 we reuse the credit, no purchase
    if (!shouldRedeemCredit) isPurchase = false;
    // if provider made booking not paid for and client doesn't have credit then purchase
    const bookingHasCredit = selectedCancelBooking.videoCreditID && !shouldRedeemCredit;
    const clientNeedsToPurchaseForRescheduling =
      !bookingHasCredit && !selectedCreditOption?.availableCredits;
    if (!isClientScheduledBooking) isPurchase = clientNeedsToPurchaseForRescheduling;
  }

  const handleDeclineClick = useCallback(() => {
    history.push(
      `/in-room-scheduling/room/${roomID}/decline-booking/booking/${bookingID}?batch=true`
    );
  }, [history, roomID, bookingID]);

  const handleReserveClick = useCallback(() => {
    const shouldShowCheckout = selectedCreditOption && !isEligibilityFileBH && isPurchase;

    if (shouldShowCheckout) {
      if (businessLine?.isB2BTeen) {
        // NOTE: The following workaround is needed because we need to disallow NYC Teens to book sessions for money as part of the contract with the state
        // It is due to the way booking works right now: when therapist books anything, the booking has no credit associated with it.
        // So it allows therapists to create multiple tentative bookings based on single credit and client in turn is able to book one more session based on the same credit.
        // Consider the scenario:
        // * user has one credit
        // * therapist creates tentative booking (and it is not associated with any credit in the db)
        // * (at this point therapist might create any number of other tentative bookings based on same credit)
        // * user is able to book one too as they see the credit as available (because one(s) that therapist created are not associated with said credit in the db)
        // * after booking, user tries to confirm the booking that therapist created for the same credit and sees the paywall ("Input your credit card details")
        // TODO: We can fix this globally by making therapist bookings behave exactly the same way as the client bookings: when tentative booking is created it will be
        // associated with the credit, no matter which party created the booking. This will make double booking impossible as /video-credit-offers will not return such credits as available.
        // The fix is on backend and is simple, the only thing that is stopping us is we need to sync with all the stakeholders and make sure we don't need the current odd behavior for some specific reason.
        dispatchSetIsError(
          {
            name: 'NYCTeenPurchaseError',
            message: 'You receive one live session credit every month as part of your plan',
          },
          { errorTitle: 'Your monthly session is already scheduled' }
        );
        return;
      }

      const baseURL = isOnboarding ? onboardingBaseURL : inRoomBaseURL;

      if (paymentDetails) {
        const searchParams = new URLSearchParams(location.search);

        if (bookingID) {
          searchParams.set('bookingID', bookingID);
        }

        history.push(`${baseURL}/booking-checkout?${searchParams.toString()}`, {
          isReschedule,
          flowVariant,
        });
        return;
      }
      history.push(`${baseURL}/payment-details${isJoin ? `?isJoin=true` : ''}`);
      return;
    }

    handleConfirmAppointment({
      isReschedule,
      isPurchase,
      shouldRedeemCredit,
      modality,
      isRecurringBooking,
      isB2BTeen: businessLine?.isB2BTeen,
    });
  }, [
    selectedCreditOption,
    isEligibilityFileBH,
    isPurchase,
    handleConfirmAppointment,
    isReschedule,
    shouldRedeemCredit,
    modality,
    isRecurringBooking,
    businessLine?.isB2BTeen,
    isOnboarding,
    onboardingBaseURL,
    inRoomBaseURL,
    paymentDetails,
    history,
    isJoin,
    dispatchSetIsError,
    location.search,
    bookingID,
    flowVariant,
  ]);

  useEffect(() => {
    if (
      !isTherapist &&
      isJoin &&
      !isLoading &&
      (isBHAdHoc || !shouldShowConfirmAndJoin) &&
      !shouldShowJoinDirectly &&
      !isPaymentLoading
    ) {
      handleReserveClick();
    }
  }, [
    handleReserveClick,
    isBHAdHoc,
    isJoin,
    isLoading,
    isPaymentLoading,
    isTherapist,
    shouldShowConfirmAndJoin,
    shouldShowJoinDirectly,
  ]);

  const getBookingAvatar = () => {
    if (isBookAndActivate) {
      return <SparklingCalendar />;
    }

    if (therapist) {
      return (
        <BookingAvatars
          therapistUserID={therapist.id}
          selectedTimeslot={selectedTimeslot}
          modality={modality}
          shouldShowBookingSuccess={shouldShowBookingSuccess && !isJoin}
          isCouples={room?.roomType === 'couples_room'}
          isSmallAvatars
        />
      );
    }

    return null;
  };

  const getBookingDate = () =>
    isBookAndActivate ? (
      <BookingDatesOnBoarding
        selectedTimeslot={selectedTimeslot}
        selectedCreditOption={selectedCreditOption}
        style={{ marginTop: 16 }}
      />
    ) : (
      <BookingDates
        modality={modality}
        selectedTimeslot={selectedTimeslot}
        selectedCreditOption={selectedCreditOption}
        isTherapist={isTherapist}
      />
    );

  const handleConfirmAndJoinClick = () => {
    handleConfirmAppointment({
      isReschedule: false,
      isPurchase: false,
      shouldRedeemCredit: false,
      modality,
    });
  };

  const handleJoinClick = () => {
    dispatchGetVideoCall(Number(roomID));
  };

  const handleClickCancellationPolicy = () => {
    trackCancellationPolicyClick({
      userID,
      roomID: room?.roomID!,
      providerID: therapist?.id!,
      planID: roomPlanID || selectedCreditOption?.planID,
      sessionModality: modality,
      flow: 'Booking confirmation',
    });
    handleReviewCancellationPolicy();
  };

  if (isCreateBookingActivationSuccess) {
    onClosePress();
  }

  if (isCreateBookingActivationError && requestError?.code === 'PAYMENT_ERROR') {
    return (
      <InRoomSchedulingError
        onClosePress={onClosePress}
        message="We were unable to process your payment details. Unfortunately, we cannot match you to a provider until you update them."
        title="Sorry, something went wrong"
        buttonText="Update Payment"
      />
    );
  }

  if (isIneligibleError && isTherapist) {
    return (
      <InRoomSchedulingError
        onClosePress={onClosePress}
        title="Member's insurance plan has expired"
        message="It appears that this member's insurance plan is not updated, please ask them to update their insurance information."
      />
    );
  }

  if (
    (isError && !shouldShowIneligiblePage) ||
    (!isReschedule && isClientConfirmError) ||
    (isReschedule && isClientRescheduleError) ||
    isCreateBookingActivationError
  ) {
    return <InRoomSchedulingError userID={userID} roomID={roomID} onClosePress={onClosePress} />;
  }

  if (
    !checkedPendingInvoices ||
    ((!planInfo || isJoin) && isLoading) ||
    (!isTherapist && isPaymentLoading)
  ) {
    return <Spinner />;
  }

  if (
    !therapist ||
    (!isBHAdHoc && !selectedTimeslot) ||
    !selectedCreditOption ||
    (isBHAdHoc && modality === 'chat' && (shouldShowConfirmAndJoin || shouldShowJoinDirectly))
  ) {
    return null;
  }

  return (
    <View
      flex={isMobile ? 1 : 0}
      align="center"
      justify="space-between"
      style={{ marginBottom: 65, alignItems: 'center' }}
    >
      {getBookingAvatar()}
      {!recurringAvailableTimeslots && !isRecurringBooking && getBookingDate()}
      {(() => {
        if (isTherapist) {
          if (recurringAvailableTimeslots && recurringRequestedTimeslots) {
            return (
              <ConfirmBookingRecurring
                timezone={localTimezone}
                timeslots={recurringRequestedTimeslots}
                availableTimeslots={recurringAvailableTimeslots}
                conflictingTimeslots={recurringConflictingTimeslots}
                clientDisplayName={clientDisplayName}
                onAdd15MinuteBreakCheck={dispatchSetHasBreakAfterSession}
                onScheduleSessionClick={handleReserveClick}
              />
            );
          }
          return (
            <ConfirmBookingTherapist
              timezone={localTimezone}
              clientDisplayName={clientDisplayName}
              onAdd15MinuteBreakCheck={dispatchSetHasBreakAfterSession}
              onScheduleSessionClick={handleReserveClick}
            />
          );
        }

        if (shouldShowConfirmAndJoin || shouldShowJoinDirectly) {
          return (
            <ConfirmBookingJoin
              shouldShowConfirmAndJoin={shouldShowConfirmAndJoin}
              timezone={localTimezone}
              handleJoinClick={handleJoinClick}
              handleConfirmAndJoinClick={handleConfirmAndJoinClick}
            />
          );
        }

        if (isBookAndActivate) {
          if (isSessionBased) {
            return (
              <ConfirmBookingWithPolicyOnboarding
                timezone={localTimezone}
                onClosePress={onClosePress}
                therapist={therapist}
                handleReserveClick={handleOnBoardingBookSession}
                onClickCancellationPolicy={handleClickCancellationPolicy}
                isLoading={isCreateBookingActivationLoading}
                isSuccess={isCreateBookingActivationSuccess}
              />
            );
          }
          return (
            <ConfirmBookingWithoutPolicyOnboarding
              timezone={localTimezone}
              isLoading={isCreateBookingActivationLoading}
              isSuccess={isCreateBookingActivationSuccess}
              handleReserveClick={handleOnBoardingBookSession}
              onClickCancellationPolicy={handleClickCancellationPolicy}
            />
          );
        }

        if (isRecurringBooking && repeatingTimeslots) {
          return (
            <ConfirmBookingRecurringClient
              timezone={localTimezone}
              isReschedule={isReschedule}
              handleReserveClick={handleReserveClick}
              handleDeclineClick={handleDeclineClick}
              onClickCancellationPolicy={handleClickCancellationPolicy}
              repeatingTimeslots={repeatingTimeslots}
              conflictingTimeslots={conflictingTimeslots || undefined}
            />
          );
        }

        if (isSessionBased) {
          return (
            <ConfirmBookingWithPolicy
              therapist={therapist}
              timezone={localTimezone}
              handleReserveClick={handleReserveClick}
              onClickCancellationPolicy={handleClickCancellationPolicy}
            />
          );
        }

        return (
          <ConfirmBookingWithoutPolicy
            timezone={localTimezone}
            onClosePress={onClosePress}
            bookWithIntroSession={bookWithIntroSession}
            isReschedule={isReschedule}
            handleReserveClick={handleReserveClick}
            onClickCancellationPolicy={handleClickCancellationPolicy}
          />
        );
      })()}
    </View>
  );
};

export default ConfirmBookingContainer;
