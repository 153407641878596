import {
  ExtraHuge,
  Standard,
  Passcode,
  View,
  TouchableView,
  CloseButton,
  PasscodeAnimation,
  BackArrow,
} from '@talkspace/react-toolkit';
import { useHistory } from '@/core/routerLib';
import { PASSCODE_LENGTH } from '../constants';
import styled from '@/core/styled';

const Wrapper = styled(View)({
  maxWidth: 375,
  alignSelf: 'center',
  textAlign: 'center',
});

const Subtitle = styled(Standard)<{ isError: boolean }>(({ theme: { colors }, isError }) => {
  return {
    fontSize: 16,
    color: isError ? colors.permaFuchsia : colors.purple600,
    paddingRight: 30,
    paddingLeft: 30,
    fontWeight: 400,
    whiteSpace: 'pre-line',
    height: 50,
  };
});

const StyledCloseButton = styled(CloseButton)(({ theme: { colors } }) => {
  return {
    position: 'absolute',
    top: 20,
    right: 20,
  };
});

interface PasscodeViewProp {
  title: string;
  handleChange: (value: string) => void;
  errorMessage?: string;
  shouldShowBackArrow?: boolean;
  handleBackArrow?: () => void;
  isPasscodeCreated?: boolean;
  subtitle?: string | null;
  inCreateMode?: boolean;
  shouldDisplayCloseButton?: boolean;
  isSuccess?: boolean;
}

const PasscodeView = ({
  title,
  handleChange,
  errorMessage = '',
  shouldShowBackArrow,
  handleBackArrow,
  isPasscodeCreated = false,
  subtitle,
  inCreateMode = false,
  shouldDisplayCloseButton = false,
  isSuccess = false,
}: PasscodeViewProp) => {
  const history = useHistory();
  const isEdit = history.location.pathname.includes('edit');

  const handleCloseButton = () => {
    history.push('/rooms');
  };

  return (
    <View style={{ padding: 20 }}>
      {shouldDisplayCloseButton && <StyledCloseButton onPress={handleCloseButton} />}
      {shouldShowBackArrow && (
        <TouchableView onPress={handleBackArrow}>
          <BackArrow />
        </TouchableView>
      )}
      <Wrapper>
        <PasscodeAnimation
          styles={{ marginTop: -50 }}
          isError={!!errorMessage}
          isPasscodeCreated={isPasscodeCreated}
          isSuccess={isSuccess}
          inCreateMode={inCreateMode}
        />

        <ExtraHuge style={{ marginTop: -85, marginBottom: 16 }}>
          {isPasscodeCreated ? `Passcode ${isEdit ? 'changed' : 'created'}` : title}
        </ExtraHuge>

        <Subtitle isError={!!errorMessage}>{errorMessage || subtitle}</Subtitle>

        <View style={{ marginTop: 20 }}>
          <Passcode
            inputLength={PASSCODE_LENGTH}
            key={title}
            isError={!!errorMessage}
            onChange={handleChange}
            isDisabled={isPasscodeCreated}
          />
        </View>
      </Wrapper>
    </View>
  );
};

export default PasscodeView;
