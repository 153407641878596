import { FunctionComponent } from 'react';
import { View, Large, Standard, Small, useEmotionTheme } from '@talkspace/react-toolkit';
import { useSharedChatState } from 'chat/hooks/sharedChatContext';
import { arrowKeyNavigationClass, ID_SLA_BUTTON } from '../../../utils/IDConstants';
import SLAUtils from '../../utils/SLAUtils';
import { SLAStatus } from '../../reducers/SLAReducer';
import FlatRoundedButton from '../FlatRoundedButton';

interface Props {
  onPress: () => void;
  disabled?: boolean;
  status: SLAStatus;
  replyBy?: Date;
}

const SLAButton: FunctionComponent<Props> = (props) => {
  const { onPress, disabled, status, replyBy } = props;
  const { therapistFirstName } = useSharedChatState();
  const { colors } = useEmotionTheme();

  function renderButtonText() {
    const displayedTime = replyBy ? SLAUtils.getDisplayedTime(replyBy.toString()) : '';

    switch (status) {
      case 'ready':
        return (
          <>
            <Large
              style={{ color: colors.greenText }}
              variant="largeBoldWideGreen"
            >{`Let ${therapistFirstName} know you’re`}</Large>
            <Large style={{ color: colors.greenText }} variant="largeBoldWideGreen">
              ready for them to reply
            </Large>
          </>
        );
      case 'waiting':
        return (
          <>
            <Standard style={{ color: colors.slateGrey }} variant="standardDarkGrey">
              {`${therapistFirstName} has been notified`}
            </Standard>
            <Small style={{ color: colors.slateGrey }}>Expect a reply by {displayedTime}</Small>
          </>
        );
      case 'missed':
        return (
          <>
            <Small
              style={{ color: colors.slateGrey }}
            >{`${therapistFirstName} did not respond on time`}</Small>
            <Large style={{ color: colors.greenText }} variant="largeBoldWideGreen">
              See your options
            </Large>
          </>
        );
      default:
        return (
          <>
            <Standard style={{ color: colors.slateGrey }} variant="standardDarkGrey">
              {`Let ${therapistFirstName} know you’re`}
            </Standard>
            <Standard style={{ color: colors.slateGrey }} variant="standardDarkGrey">
              ready for them to reply
            </Standard>
          </>
        );
    }
  }

  // removing the SLAButton from the tab order after it's been clicked allows for more intuitive keyboard navigation
  const isDisabled = status === 'waiting' || disabled;
  return (
    <View style={{ paddingBottom: 10 }}>
      <FlatRoundedButton
        id={ID_SLA_BUTTON}
        tabIndex={isDisabled ? -1 : 0}
        elementType={isDisabled ? 'div' : undefined}
        className={arrowKeyNavigationClass}
        onPress={onPress}
        disabled={isDisabled}
        style={{ marginTop: 10, width: 245 }}
      >
        {renderButtonText()}
      </FlatRoundedButton>
    </View>
  );
};

export default SLAButton;
