import { Big, styled, View, TSLogo } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';

const Wrapper = styled(View)(({ theme: { colors } }) => {
  return {
    align: 'center',
    backgroundColor: colors.white,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
  };
});

const NavBar = styled(View)(({ theme: { colors } }) => {
  return {
    height: 66,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: colors.white,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `0.5px solid ${colors.heatherGrey}`,
  };
});

const Content = styled(View)({
  flex: 1,
  paddingTop: 0,
  overflowY: 'auto',
});

interface Props {
  titleText?: string;
  children: React.ReactNode;
  showLogo?: boolean;
}

const MeetYourProviderWrapper = ({ titleText = '', children, showLogo }: Props) => {
  const { brandRefreshChanges } = useFlags();
  return (
    <Wrapper>
      <NavBar>
        {showLogo ? (
          <TSLogo variant={brandRefreshChanges ? '2024' : 'old'} />
        ) : (
          <Big>{titleText}</Big>
        )}
      </NavBar>
      <Content align="center">{children}</Content>
    </Wrapper>
  );
};

export default MeetYourProviderWrapper;
