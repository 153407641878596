import { TherapistInfo } from 'ts-frontend/entities/Therapist';
import { ERoom } from 'ts-frontend/entities/Room';

import BookingConfirmationSuccess from 'inRoomScheduling/components/BookingConfirmationSuccess/BookingConfirmationSuccess';

import PostAsyncPromptBaseScreen from './PostAsyncPromptBaseScreen';

interface Props {
  room: ERoom;
  therapist: TherapistInfo;
  onContinue: () => void;
}

const PostAsyncPromptConfirmationScreen = ({ room, therapist, onContinue }: Props) => (
  <PostAsyncPromptBaseScreen title="Session starting">
    <BookingConfirmationSuccess
      isTherapist={false}
      isWithin24Hours={false}
      handleOnCTAPress={onContinue}
      therapist={therapist}
      modality="messaging"
      room={room}
      selectedTimeslot={undefined}
      selectedCreditOption={undefined}
    />
  </PostAsyncPromptBaseScreen>
);

export default PostAsyncPromptConfirmationScreen;
