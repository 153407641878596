import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M12 4.66797C13.7266 4.66797 15.125 6.06641 15.125 7.79297V10.293H8.875V7.79297C8.875 6.06641 10.2734 4.66797 12 4.66797ZM7 7.79297V10.293H5.75C4.37109 10.293 3.25 11.4141 3.25 12.793V20.293C3.25 21.6719 4.37109 22.793 5.75 22.793H18.25C19.6289 22.793 20.75 21.6719 20.75 20.293V12.793C20.75 11.4141 19.6289 10.293 18.25 10.293H17V7.79297C17 5.03125 14.7617 2.79297 12 2.79297C9.23828 2.79297 7 5.03125 7 7.79297ZM5.75 12.168H18.25C18.5938 12.168 18.875 12.4492 18.875 12.793V20.293C18.875 20.6367 18.5938 20.918 18.25 20.918H5.75C5.40625 20.918 5.125 20.6367 5.125 20.293V12.793C5.125 12.4492 5.40625 12.168 5.75 12.168ZM12.9375 15.293C12.9375 14.7734 12.5195 14.3555 12 14.3555C11.4805 14.3555 11.0625 14.7734 11.0625 15.293V17.793C11.0625 18.3125 11.4805 18.7305 12 18.7305C12.5195 18.7305 12.9375 18.3125 12.9375 17.793V15.293Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const LockKeyhole = withDSIconMaker(pathConfig, 'lock key hole icon');

export default LockKeyhole;
