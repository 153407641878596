import { FunctionComponent } from 'react';
import { Button, ExtraHuge, Large, Big } from '@talkspace/react-toolkit';

import styled from '../../../core/styled/styled';

const PaddedText = styled(Large)({
  marginTop: 20,
  marginBottom: 40,
});

interface Props {
  onContinuePress: () => void;
  header: string;
}

const NewTherapist: FunctionComponent<Props> = (props: Props) => {
  const { onContinuePress, header } = props;
  return (
    <>
      <ExtraHuge as="h1">{header}</ExtraHuge>
      <PaddedText variant="largeDarkGrey">
        You’ll receive a message in the room with a selection of new matches for you to choose from.
      </PaddedText>
      <Button onPress={onContinuePress}>
        <Big variant="bigWide" style={{ color: '#fff' }}>
          Continue
        </Big>
      </Button>
    </>
  );
};

export default NewTherapist;
