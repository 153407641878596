import { useState, useEffect } from 'react';
import * as React from 'react';

import SLASocketService from '../services/SLASocketService';
import useSLA from '../hooks/useSLA';
import localStorage from '../../core/storage/localStorage';
import SLAModal from '../components/SLAModal';
import SLAButton from '../components/SLAButton';

let SLASocket: SLASocketService | undefined;

interface Props {
  roomID: number;
}

const SLAContainer: React.FunctionComponent<Props> = (props: Props) => {
  const { roomID } = props;

  const [isModalOpen, setModalOpen] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [
    { SLA, isLoading, nextReplyTime, error },
    { receiveSLA, createSLA, applyCoupon, getNextReplyTime, suggestTherapists, resetSLA },
  ] = useSLA();

  useEffect(() => {
    const receiveSLAHandler = (sla) => {
      receiveSLA(sla);
    };

    if (SLASocket) {
      SLASocket.unmount();
      resetSLA();
    }

    SLASocket = new SLASocketService(receiveSLAHandler, roomID);

    return () => {
      resetSLA();
      if (SLASocket) SLASocket.unmount();
      SLASocket = undefined;
    };
  }, [receiveSLA, resetSLA, roomID]);

  const onButtonPress = () => {
    if (SLA.status === 'ready') {
      const SLACount = Number(localStorage.getItem('SLACount'));
      if (!SLACount) {
        localStorage.setItem('SLACount', '1');
      } else if (SLACount < 3) {
        localStorage.setItem('SLACount', `${SLACount + 1}`);
      } else {
        setButtonDisabled(true);
        createSLA(SLA.roomID, () => setButtonDisabled(false));
        return;
      }
    }
    setModalOpen(true);
  };

  return (
    <>
      {isModalOpen && (
        <SLAModal
          status={SLA.status}
          eligibleForCoupon={SLA.eligibleForCoupon}
          missesCurrentWeek={SLA.missesCurrentWeek}
          roomID={SLA.roomID}
          isLoading={isLoading}
          isError={!!error}
          nextReplyTime={nextReplyTime}
          onClosePress={() => setModalOpen(false)}
          createSLA={createSLA}
          getNextReplyTime={getNextReplyTime}
          applyCoupon={applyCoupon}
          suggestTherapists={suggestTherapists}
        />
      )}
      {SLA.status && SLA.status !== 'unavailable' && (
        <SLAButton
          onPress={onButtonPress}
          disabled={isButtonDisabled}
          status={SLA.status}
          replyBy={SLA.replyBy}
        />
      )}
    </>
  );
};

export default SLAContainer;
