import * as LiveUpdates from '@capacitor/live-updates';
import { SplashScreen } from '@capacitor/splash-screen';
import { safeIonicWrapper } from '../../ionicUtils';
import { liveUpdatesLogger } from '../../loggers';

let currentLiveUpdate: LiveUpdates.SyncResult | null = null;

export const updateWebVersion = safeIonicWrapper(async () => {
  const result = await LiveUpdates.sync().catch((error) => {
    liveUpdatesLogger.error('Live update failed', error);
    return null;
  });
  currentLiveUpdate = result;
  if (result?.activeApplicationPathChanged) {
    liveUpdatesLogger.info('Live update success');
    await LiveUpdates.reload();
    liveUpdatesLogger.info('Live update reload');
  } else {
    await SplashScreen.hide();
    liveUpdatesLogger.info('Splash screen hidden');
  }
}, Promise.resolve(undefined));

export const getLiveUpdateStatus = () => currentLiveUpdate;

export { liveUpdatesLogger };
