import { FunctionComponent } from 'react';
import {
  Large,
  View,
  Massive,
  ExtraHuge,
  InitialsAvatar,
  useWindowWidthState,
  Avatar,
  useEmotionTheme,
  Big,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import apiHelper from '@/core/api/apiHelper';
import CallGradient from './CallGradient';
import SmallSpeakerVideoSettings from './SmallSpeakerVideoSettings';
import VideoCall from './VideoCall';
import { COLORS } from '../../utils/design';
import AudioMuted from '../Icons/AudioMuted';
import VideoMuted from '../Icons/VideoMuted';
import { useVideoCallState } from '../../hooks/videoCallContext';

const NameText: FunctionComponent<{
  isMinimized: boolean;
  isMobile: boolean;
  style: EmotionStyle;
}> = ({ style, isMinimized, isMobile, children, ...props }) => {
  switch (true) {
    case isMinimized:
      return (
        <Big style={style} {...props}>
          {children}
        </Big>
      );
    case isMobile:
      return (
        <ExtraHuge style={style} {...props}>
          {children}
        </ExtraHuge>
      );
    default:
      return (
        <Massive style={style} {...props}>
          {children}
        </Massive>
      );
  }
};

const VideoCallGradients: FunctionComponent<{
  isActiveSpeaker: boolean;
  remoteUserCount: number;
}> = ({ isActiveSpeaker, remoteUserCount }) => (
  <>
    {!!remoteUserCount && isActiveSpeaker && <CallGradient isBottom={false} isLarge />}
    {!!remoteUserCount && isActiveSpeaker && <CallGradient isBottom isLarge />}
    {remoteUserCount > 1 && !isActiveSpeaker && <CallGradient isBottom isLarge={false} />}
  </>
);

const CenterWrapper = styled(View)(({ bottomOffset }: { bottomOffset: number }) => {
  return {
    position: 'absolute',
    top: `calc((100vh - ${bottomOffset}px)/2)`,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  };
});

const ActiveSpeakerMutedScreen: FunctionComponent<{
  userDisplayName: string;
  isTherapistUser: boolean;
  therapistUserID?: number;
  bottomOffset: number;
}> = ({ userDisplayName, therapistUserID, isTherapistUser, bottomOffset }) => {
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const { isMinimized } = useVideoCallState();

  const displayNameStyle = {
    color: colors.white,
    textAlign: 'center',
  };

  const providerImage = {
    image: `${apiHelper().cdnEndpoint}/images/application/therapist/220/${therapistUserID}.jpg`,
  };
  return (
    <View
      style={{
        position: 'absolute',
        backgroundColor: '#000',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <CenterWrapper bottomOffset={bottomOffset}>
        {isTherapistUser ? (
          <Avatar
            {...(therapistUserID && providerImage)}
            style={{
              alignSelf: 'center',
              marginRight: 0,
              marginBottom: isMinimized ? 2 : 9,
            }}
            width={isMinimized ? 64 : 105}
            height={isMinimized ? 64 : 105}
          />
        ) : (
          <InitialsAvatar
            displayName={userDisplayName}
            style={{
              width: isMinimized ? 64 : 105,
              height: isMinimized ? 64 : 105,
              alignSelf: 'center',
              marginRight: 0,
              marginBottom: isMinimized ? 2 : 9,
              fontSize: 40,
            }}
          />
        )}
        <NameText style={displayNameStyle} isMinimized={isMinimized} isMobile={isMobile}>
          {userDisplayName}
        </NameText>
      </CenterWrapper>
    </View>
  );
};

const VideoSettingWrapper = styled(View)({
  borderRadius: 14,
  backgroundColor: COLORS.mirage,
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  margin: 'auto',
  marginTop: 5,
});
type VideoSettingType = 'audio' | 'video';

const VideoSettingPill: FunctionComponent<{
  settingType: VideoSettingType;
  isOn: boolean;
  userDisplayName: string;
}> = ({ settingType, isOn, userDisplayName }) =>
  isOn ? null : (
    <VideoSettingWrapper row>
      {settingType === 'audio' && <AudioMuted height={25} width={15} />}
      {settingType === 'video' && <VideoMuted height={24} width={23} />}
      <Large variant="largeWhite" style={{ marginTop: 2, marginLeft: 10 }}>
        {`${userDisplayName}${settingType === 'audio' ? ' is muted' : "'s video is disabled"}`}
      </Large>
    </VideoSettingWrapper>
  );

const ActiveSpeakerVideoSettings: FunctionComponent<{
  isAudioOn: boolean;
  isVideoOn: boolean;
  userDisplayName: string;
}> = ({ isAudioOn, isVideoOn, userDisplayName }) => {
  const [firstWord] = userDisplayName.split(' ');
  return (
    <View style={{ position: 'absolute', marginTop: 66, width: '100%' }}>
      <VideoSettingPill settingType="video" isOn={isVideoOn} userDisplayName={firstWord} />
      <VideoSettingPill settingType="audio" isOn={isAudioOn} userDisplayName={firstWord} />
    </View>
  );
};
const RemoteCallWrapper = styled(View)<{
  isActiveSpeaker: boolean;
  isOtherAudioOn: boolean;
  isOtherVideoOn: boolean;
}>(
  ({
    isActiveSpeaker,
    isOtherAudioOn,
    isOtherVideoOn,
    theme: {
      window: { width },
    },
  }) => {
    const numberOfMutes = Number(isOtherAudioOn) + Number(isOtherVideoOn);
    const isSmallerScreen = width < 550;
    const distanceFromTop = numberOfMutes ? 74 : 54;
    const responsiveTop = numberOfMutes * 50 + distanceFromTop;
    return isActiveSpeaker
      ? {
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }
      : {
          zIndex: 2,
          position: 'absolute',
          top: isSmallerScreen ? responsiveTop : 20,
          right: 20,
          height: isSmallerScreen ? 114 : 100,
          borderRadius: 5,
          width: isSmallerScreen ? 85 : 100,
          overflow: 'hidden',
        };
  }
);

const RemoteVideoCall: FunctionComponent<{
  isActiveSpeaker: boolean;
  isAudioOn: boolean;
  isVideoOn: boolean;
  isOtherAudioOn: boolean;
  isOtherVideoOn: boolean;
  userDisplayName: string;
  userID?: number;
  remoteUserCount: number;
  therapistUserID?: number;
  isMinimized: boolean;
  bottomOffset: number;
}> = ({
  isActiveSpeaker,
  isAudioOn,
  isVideoOn,
  isOtherAudioOn,
  isOtherVideoOn,
  userDisplayName,
  userID,
  remoteUserCount,
  therapistUserID,
  isMinimized,
  bottomOffset,
}) => (
  <RemoteCallWrapper
    isActiveSpeaker={isActiveSpeaker}
    isOtherVideoOn={isOtherVideoOn}
    isOtherAudioOn={isOtherAudioOn}
  >
    <VideoCall userID={userID} />
    <VideoCallGradients isActiveSpeaker={isActiveSpeaker} remoteUserCount={remoteUserCount} />
    {!!remoteUserCount && isActiveSpeaker && !isVideoOn && (
      <ActiveSpeakerMutedScreen
        userDisplayName={userDisplayName}
        isTherapistUser={therapistUserID === userID}
        therapistUserID={therapistUserID}
        bottomOffset={bottomOffset}
      />
    )}
    {!!remoteUserCount && (!isAudioOn || !isVideoOn) && isActiveSpeaker && !isMinimized && (
      <ActiveSpeakerVideoSettings
        isAudioOn={isAudioOn}
        isVideoOn={isVideoOn}
        userDisplayName={userDisplayName}
      />
    )}
    {!!remoteUserCount && !isActiveSpeaker && !isAudioOn && userID && (
      <SmallSpeakerVideoSettings isAudioOn={isAudioOn} isVideoOn={isVideoOn} />
    )}
  </RemoteCallWrapper>
);

export default RemoteVideoCall;
