import moment from 'moment';

const isSameDay = (d1: Date, d2: Date) => moment(d1).isSame(d2, 'day');

const isDayAfter = (d1: Date, d2: Date) => moment(d1).isSame(moment(d2).add(1, 'days'), 'day');

const isSameWeek = (d1: Date, d2: Date) => moment(d1).isSame(d2, 'week');

const getDisplayedTime = (rawDate: string) => {
  let displayedTime: string;

  const dateTime = new Date(rawDate);
  const time = dateTime
    .toLocaleTimeString(undefined, {
      hour12: true,
      hour: 'numeric',
    })
    .split(' ')
    .join('');

  if (isSameDay(dateTime, new Date())) {
    displayedTime = time;
  } else if (isDayAfter(dateTime, new Date())) {
    displayedTime = time === '12AM' ? 'Midnight today' : `${time} tomorrow`;
  } else if (isSameWeek(dateTime, new Date())) {
    const day = dateTime.toLocaleString('en-us', { weekday: 'long' });
    displayedTime = `${time} ${day}`;
  } else {
    const month = dateTime.toLocaleString('en-us', { month: 'short' });
    const date = dateTime.getUTCDate();
    displayedTime = `${time}, ${month} ${date}`;
  }

  return displayedTime;
};

const SLAUtils = {
  getDisplayedTime,
};

export default SLAUtils;
