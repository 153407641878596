import { useWindowWidth, ResponsiveLayoutWithHeader, PanelHeader } from '@talkspace/react-toolkit';

import HelpScreen from './HelpScreen';
import { CloseModal } from '../../../utils/ModalsContextProvider';
import useNavShellWindowHeight from '@/hooks/useNavShellWindowHeight';

export interface HelpScreenContainerProps {
  closeModal: CloseModal;
}

export const HelpScreenContainer = (props: HelpScreenContainerProps) => {
  const { closeModal } = props;
  const { isMobile } = useWindowWidth();

  const { outsideHeaderHeight, outsideFooterHeight } = useNavShellWindowHeight();

  return isMobile ? (
    <ResponsiveLayoutWithHeader
      outsideHeaderHeight={outsideHeaderHeight}
      outsideFooterHeight={outsideFooterHeight}
      renderHeader={() => <PanelHeader onRightPress={closeModal} />}
      noHeaderLine
    >
      <HelpScreen />
    </ResponsiveLayoutWithHeader>
  ) : (
    <>
      <HelpScreen />
    </>
  );
};

export default HelpScreenContainer;
