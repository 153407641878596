import { useState, createContext, useContext, useEffect } from 'react';
import { ConnectionStatus, Network } from '@capacitor/network';
import { dialogAlert } from 'ts-ionic/plugins/dialog';
import { getIonicPlatform } from 'ts-ionic/plugins/capacitor';

interface NetworkListener {
  removeListener: () => void;
}

const setupIonicNetworkListener = (cb: (status: ConnectionStatus) => void): NetworkListener => {
  Network.addListener('networkStatusChange', cb);
  return {
    removeListener: () => Network.removeAllListeners(),
  };
};

export interface State {
  isOffline?: boolean;
}

export const StateContext = createContext<State | undefined>(undefined);

/**
 * Provides current network status for either Ionic and Web app.
 * The underlying plugin manages web network events as well
 * https://github.com/ionic-team/capacitor-plugins/blob/main/network/src/web.ts
 */
const NetworkProvider = ({ children }) => {
  const [isOffline, setIsOffline] = useState<boolean | undefined>(undefined);

  const handleIonicNetworkChange = (status: ConnectionStatus) => {
    setIsOffline(!status.connected);

    if (getIonicPlatform() !== 'web' && !status.connected) {
      dialogAlert({
        message:
          'The Talkspace app requires internet connection. Make sure your network connection is active and try again',
      });
    }
  };

  useEffect(() => {
    // setup init network status
    (async () => {
      const status = await Network.getStatus();
      setIsOffline(!status);
    })();

    // setup network listeners
    const networkListener = setupIonicNetworkListener(handleIonicNetworkChange);
    return networkListener.removeListener;
  }, []);

  return <StateContext.Provider value={{ isOffline }}>{children}</StateContext.Provider>;
};

const useNetworkProviderState = (): State => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('StateContext must be used within a NetworkProvider');
  }
  return context;
};

export type { ConnectionStatus };

export { Network, setupIonicNetworkListener, NetworkProvider, useNetworkProviderState };
