import Svg, { Path, Defs, G, Use, Mask } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const Hourglass = ({ ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'hourglass';
  return (
    <Svg
      width="90"
      height="80"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 69 76"
      {...props}
    >
      <Defs>
        <Path
          id="b"
          d="M0 9.992C0 4.474 4.475 0 10.002 0h479.996C495.522 0 500 4.475 500 9.992v343.016c0 5.518-4.475 9.992-10.002 9.992H10.002C4.478 363 0 358.525 0 353.008V9.992z"
        />
        <filter
          id="a"
          width="104.4%"
          height="106.1%"
          x="-2.2%"
          y="-1.9%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology in="SourceAlpha" radius="2" result="shadowSpreadOuter1" />
          <feOffset dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0.156418748 0 0 0 0 0.255632926 0 0 0 0.4 0"
          />
        </filter>
        <Path
          id="c"
          d="M14.529 6.818a5.436 5.436 0 017.692-.76c2.332 1.93 2.67 5.402.754 7.753-1.033 1.267-1.94 2.258-2.777 3.008-.823.738-1.926 1.552-3.36 2.504l-3.022 2.003-3.01-2.02C9.34 18.325 8.251 17.52 7.47 16.82c-.796-.713-1.69-1.69-2.756-2.982-1.93-2.338-1.615-5.812.705-7.757a5.436 5.436 0 017.697.711c.264.32.505.604.72.85.21-.24.442-.515.693-.823z"
        />
      </Defs>
      <G
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-566 -305)"
      >
        <Path fill={colors.fordGrey} d="M0 0H1200V850H0z" />
        <G transform="translate(350 244)">
          <Use fill="#000" xlinkHref="#b" href="#b" />
          <Path
            fill="#FFF"
            stroke="#EFF3FA"
            strokeLinejoin="round"
            d="M.5 9.992v343.016c0 5.241 4.254 9.492 9.502 9.492h479.996c5.25 0 9.502-4.249 9.502-9.492V9.992c0-5.241-4.254-9.492-9.502-9.492H10.002C4.752.5.5 4.749.5 9.992z"
          />
        </G>
        <Path
          fill="#4B5A93"
          fillRule="nonzero"
          d="M23.384 14.879a5.436 5.436 0 017.692-.76c2.332 1.931 2.67 5.402.754 7.753-1.033 1.267-1.94 2.258-2.777 3.008-.823.738-1.926 1.553-3.36 2.504l-3.022 2.004-3.01-2.02c-1.466-.983-2.554-1.787-3.336-2.488-.796-.712-1.69-1.689-2.756-2.981-1.93-2.34-1.615-5.812.705-7.758a5.436 5.436 0 017.697.711c.264.32.506.604.72.85.21-.24.442-.515.693-.823z"
          transform="translate(407 304) rotate(-25 124.287 -347.41) rotate(-11 22.688 21.127)"
        />
        <G transform="translate(407 304) rotate(-25 124.287 -347.41) translate(4.372 38.56)">
          <Mask id="1" fill="#fff">
            <Use href="#c" xlinkHref="#c" />
          </Mask>
          <Use
            fill={colors.green}
            fillRule="nonzero"
            transform="rotate(35 13.833 13.066)"
            href="#c"
            xlinkHref="#c"
          />
        </G>
        <G fill={colors.loblollyGrey}>
          <Path
            fillRule="nonzero"
            d="M29.961 31.95l1.629.766c6.734 3.168 11.038 9.98 11.038 17.47v8.776a2.733 2.733 0 01-5.465 0v-8.775c0-5.35-3.075-10.217-7.885-12.48l-4.842-2.277a2.2 2.2 0 00-1.872 0l-4.842 2.278c-4.81 2.262-7.885 7.128-7.885 12.479v8.775a2.733 2.733 0 11-5.465 0v-8.775c0-7.49 4.304-14.303 11.038-17.47l1.629-.767-1.629-.766c-6.734-3.168-11.038-9.98-11.038-17.47V4.935a2.733 2.733 0 115.465 0v8.777c0 5.35 3.075 10.217 7.885 12.48l4.842 2.277a2.2 2.2 0 001.872 0l4.842-2.278c4.81-2.262 7.885-7.128 7.885-12.479V4.936a2.733 2.733 0 115.465 0v8.777c0 7.49-4.304 14.303-11.038 17.47l-1.629.767z"
            transform="translate(407 304) rotate(-25 124.287 -347.41)"
          />
          <Path
            d="M2.754 58.39h41.492a2.754 2.754 0 110 5.508H2.754a2.754 2.754 0 010-5.508zM2.754 0h41.492a2.754 2.754 0 110 5.508H2.754a2.754 2.754 0 010-5.508z"
            transform="translate(407 304) rotate(-25 124.287 -347.41)"
          />
        </G>
      </G>
    </Svg>
  );
};

export default Hourglass;
