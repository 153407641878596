import { FunctionComponent, PropsWithChildren, useRef, useState } from 'react';
import {
  AnimatedSwipeDownModal,
  BaseButton,
  ChevronRight,
  TextDS,
  TouchableView,
  View,
} from '@talkspace/react-toolkit';
import { captionsLanguages } from '@talkspace/configs';
import { useFlags } from 'launchDarkly/FlagsProvider';
import useHover from 'ts-frontend/hooks/useHover';
import {
  trackClickClosedCaptions,
  trackClickVirtualBackground,
} from 'ts-analytics/mixpanel/events';
import styled from '@/core/styled/styled';

import DropdownMenuModal from '../DropdownMenuModal';
import SmallGear from '../Icons/SmallGear';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useVideoCallActions, useVideoCallState } from '../../hooks/videoCallContext';
import SettingsList from './SettingsList';
import { CaptionsLanguage, VirtualBackground } from '../../types/videoCallTypes';
import useMutationConfigureClosedCaptions from '../../hooks/useMutationConfigureClosedCaptions';
import { useSharedChatState } from '../../hooks/sharedChatContext';
import useVirtualBackground from '../../hooks/useVirtualBackground';
import useEscapePress from '../../hooks/useEscapePress';

interface CaptionsLanguageOption {
  value: CaptionsLanguage;
  label: string;
}

const captionsLanguageOptions: Array<CaptionsLanguageOption> = [
  { value: '', label: 'None' },
  ...Object.keys(captionsLanguages).map((key: CaptionsLanguage) => {
    return { value: key, label: captionsLanguages[key] };
  }),
];

interface VirtualBackgroundOption {
  value: VirtualBackground;
  label: string;
}

const getVirtualBackgroundOptions = (isTherapistChat: boolean): Array<VirtualBackgroundOption> => [
  { value: '', label: 'No background' },
  ...(isTherapistChat
    ? [{ value: 'branded', label: 'Branded Talkspace' } as VirtualBackgroundOption]
    : []),
  { value: 'blurred', label: 'Background blur' },
];

const getMediaDisplayName = (media: MediaDeviceInfo) =>
  media.deviceId === 'default' ? 'System default' : media.label;

const GearButton = styled(BaseButton)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? 35 : 60,
      height: isMobile ? 35 : 60,
      position: 'absolute',
      top: isMobile ? 20 : 10,
      left: 20,
    };
  }
);

const getSettingsDropdownStyle = ({ isMobile }: { isMobile: boolean }) => {
  return {
    top: 62,
    left: isMobile ? 20 : 32,
    height: 'auto',
  };
};

const captionsDropdownStyle = {
  height: 'auto',
  width: 167,
  paddingTop: 8,
  paddingBottom: 8,
};

const captionsOptionStyles = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 48,
};

const CaptionsOptionDesktop = styled(View)(({ theme: { colorRoles } }) => {
  return {
    ...captionsOptionStyles,
    '&:hover': { backgroundColor: colorRoles.surfaces.surfaceInteractiveHovered },
  };
});

const CaptionsOptionMobile = styled(TouchableView)(
  ({
    theme: {
      colorRoles: { surfaces },
    },
  }) => {
    return {
      ...captionsOptionStyles,
      '&:active': {
        backgroundColor: surfaces.surfaceInteractivePressed,
      },
    };
  }
);
const StyledSettingHeader = styled(TextDS)({
  marginLeft: 22,
  marginTop: 8,
});

const SettingHeader = ({ children }: PropsWithChildren<{}>) => {
  const { isMobile } = useWindowWidth();

  return (
    <StyledSettingHeader variant={isMobile ? 'headingMd' : 'headingSm'}>
      {children}
    </StyledSettingHeader>
  );
};

const CaptionsSetting = ({ captionsLanguage }: { captionsLanguage: CaptionsLanguage }) => {
  const { isMobile } = useWindowWidth();
  return (
    <>
      <TextDS variant={isMobile ? 'headingMd' : 'headingSm'} style={{ marginLeft: 22 }}>
        Captions
      </TextDS>

      <View row align="center" justify="center" style={{ marginRight: 20 }}>
        <TextDS variant={isMobile ? 'bodySm' : 'bodyXs'} style={{ marginRight: 4 }}>
          {captionsLanguages[captionsLanguage] || 'None'}
        </TextDS>
        <ChevronRight colorType="brand" />
      </View>
    </>
  );
};

interface CallSettingsProps {
  areOtherUsersInCall: boolean;
  isCurrentUserTherapist: boolean;
}

const CallSettings: FunctionComponent<CallSettingsProps> = ({
  areOtherUsersInCall,
  isCurrentUserTherapist,
}) => {
  const { isMobile } = useWindowWidth();

  const captionsButtonRef = useRef<HTMLDivElement>(null);

  const {
    closedCaptions: isActiveClosedCaptions,
    liveSessionBackground: isActiveLiveSessionBackground,
  } = useFlags();

  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

  const [isCaptionsMenuOpen, setIsCaptionsMenuOpen] = useState(false);

  const [isCaptionsOptionHovering, captionsOptionHoverHandlers] = useHover();
  const [isCaptionsDropdownHovering, captionsDropdownHoverHandlers] = useHover();

  const {
    roomID,
    videoCallID,
    cameras,
    audioIns,
    audioOuts,
    selectedCamera,
    selectedAudioIn,
    selectedAudioOut,
    isMinimized,
    captionsLanguage,
    localVideoTrack,
    virtualBackground,
    isVideoOn,
  } = useVideoCallState();

  const {
    isSupported: isBackgroundSupported,
    enableBackground,
    disableBackground,
  } = useVirtualBackground(localVideoTrack);

  const {
    setSelectedCameraAction,
    setSelectedAudioInAction,
    setSelectedAudioOutAction,
    setCaptionsLanguage,
    setVirtualBackground,
  } = useVideoCallActions();

  const { isTherapistChat } = useSharedChatState();

  const { mutateAsync: configureClosedCaptions } = useMutationConfigureClosedCaptions();

  const toggleMenuVisibility = () => {
    setIsSettingsMenuOpen((prev) => !prev);
  };

  const handleMenuClose = () => {
    setIsSettingsMenuOpen(false);
    setIsCaptionsMenuOpen(false);

    if (isCaptionsDropdownHovering) {
      captionsDropdownHoverHandlers.onMouseLeave();
    }

    if (isCaptionsOptionHovering) {
      captionsOptionHoverHandlers.onMouseLeave();
    }
  };

  useEscapePress(isSettingsMenuOpen, handleMenuClose);

  const handleMicrophoneSelect = (option: MediaDeviceInfo) => {
    setSelectedAudioInAction(option);
    handleMenuClose();
  };

  const handleSpeakersSelect = (option: MediaDeviceInfo) => {
    setSelectedAudioOutAction(option);
    handleMenuClose();
  };

  const handleCameraSelect = (option: MediaDeviceInfo) => {
    setSelectedCameraAction(option);
    handleMenuClose();
  };

  const handleCaptionsLanguageSelect = (option: CaptionsLanguageOption): void => {
    if (!videoCallID) {
      return;
    }

    const oldValue = captionsLanguage;

    trackClickClosedCaptions({
      roomID,
      videoCallID,
      captionsLanguage: option.value,
      targets: isCurrentUserTherapist ? ['mixpanelProvider'] : ['mixpanel'],
    });

    setCaptionsLanguage(videoCallID, option.value);

    // Non-empty values are handled as an effect in VideoCallContainer
    if (option.value === '') {
      configureClosedCaptions({ roomID, videoCallID, captionsLanguage: option.value }).catch(() => {
        setCaptionsLanguage(videoCallID, oldValue);
      });
    }

    handleMenuClose();
  };

  const handleVirtualBackgroundSelect = async (option: VirtualBackgroundOption): Promise<void> => {
    if (!videoCallID) {
      return;
    }

    try {
      await (option.value ? enableBackground(option.value) : disableBackground());
      trackClickVirtualBackground({
        roomID,
        videoCallID,
        virtualBackground: option.value,
        targets: isCurrentUserTherapist ? ['mixpanelProvider'] : ['mixpanel'],
      });
      setVirtualBackground(option.value);
      handleMenuClose();
    } catch (e) {
      handleMenuClose();
    }
  };

  if (isMinimized || (!cameras.length && !audioIns.length && !audioOuts.length)) {
    return null;
  }

  const getModalContent = () => (
    <View style={{ marginTop: 16, marginBottom: 8 }}>
      {!!audioIns.length && (
        <>
          <SettingHeader>Microphone</SettingHeader>

          <SettingsList<MediaDeviceInfo>
            options={audioIns}
            onSelect={handleMicrophoneSelect}
            checkIfSelected={(item) => item.deviceId === selectedAudioIn?.deviceId}
            getKey={(item) => item.deviceId}
            getDisplayName={getMediaDisplayName}
          />
        </>
      )}

      {!!audioOuts.length && (
        <>
          <SettingHeader>Speakers</SettingHeader>

          <SettingsList<MediaDeviceInfo>
            options={audioOuts}
            onSelect={handleSpeakersSelect}
            checkIfSelected={(item) => item.deviceId === selectedAudioOut?.deviceId}
            getKey={(item) => item.deviceId}
            getDisplayName={getMediaDisplayName}
          />
        </>
      )}

      {!!cameras.length && (
        <>
          <SettingHeader>Camera</SettingHeader>

          <SettingsList<MediaDeviceInfo>
            options={cameras}
            onSelect={handleCameraSelect}
            checkIfSelected={(item) => item.deviceId === selectedCamera?.deviceId}
            getKey={(item) => item.deviceId}
            getDisplayName={getMediaDisplayName}
          />
        </>
      )}

      {!isMobile && isVideoOn && isBackgroundSupported && isActiveLiveSessionBackground && (
        <>
          <SettingHeader>Background</SettingHeader>

          <SettingsList<VirtualBackgroundOption>
            options={getVirtualBackgroundOptions(isTherapistChat)}
            onSelect={handleVirtualBackgroundSelect}
            checkIfSelected={(item) => item.value === virtualBackground}
            getKey={(item) => item.label}
            getDisplayName={(item) => item.label}
          />
        </>
      )}

      {isActiveClosedCaptions && isMobile && (
        <CaptionsOptionMobile onPress={() => setIsCaptionsMenuOpen(true)}>
          <CaptionsSetting captionsLanguage={captionsLanguage} />
        </CaptionsOptionMobile>
      )}

      {isActiveClosedCaptions && !isMobile && (
        <CaptionsOptionDesktop
          ref={captionsButtonRef}
          {...captionsOptionHoverHandlers}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <CaptionsSetting captionsLanguage={captionsLanguage} />
        </CaptionsOptionDesktop>
      )}
    </View>
  );

  return (
    <View style={{ zIndex: 3 }}>
      <GearButton onPress={toggleMenuVisibility}>
        <SmallGear isBackgroundDark={areOtherUsersInCall} isActive={isSettingsMenuOpen} />
      </GearButton>

      {!isMobile && (
        <>
          <DropdownMenuModal
            isOpen={isSettingsMenuOpen}
            onClose={handleMenuClose}
            style={getSettingsDropdownStyle({ isMobile })}
          >
            {getModalContent()}
          </DropdownMenuModal>

          <DropdownMenuModal
            isOpen={isCaptionsOptionHovering || isCaptionsDropdownHovering}
            onClose={handleMenuClose}
            style={captionsDropdownStyle}
            anchorRef={captionsButtonRef}
            {...captionsDropdownHoverHandlers}
          >
            <SettingsList<CaptionsLanguageOption>
              options={captionsLanguageOptions}
              onSelect={handleCaptionsLanguageSelect}
              checkIfSelected={(item) => captionsLanguage === item.value}
              getKey={(item) => item.label}
              getDisplayName={(item) => item.label}
            />
          </DropdownMenuModal>
        </>
      )}

      {isMobile && (
        <AnimatedSwipeDownModal
          isOpen={isSettingsMenuOpen}
          onClose={handleMenuClose}
          title={isCaptionsMenuOpen ? 'Captions' : 'Call settings'}
          containerStyles={{ padding: '16px 0px' }}
          onBackButtonPress={isCaptionsMenuOpen ? () => setIsCaptionsMenuOpen(false) : undefined}
        >
          {isCaptionsMenuOpen ? (
            <SettingsList<CaptionsLanguageOption>
              options={captionsLanguageOptions}
              onSelect={handleCaptionsLanguageSelect}
              checkIfSelected={(item) => captionsLanguage === item.value}
              getKey={(item) => item.label}
              getDisplayName={(item) => item.label}
            />
          ) : (
            <>{getModalContent()}</>
          )}
        </AnimatedSwipeDownModal>
      )}
    </View>
  );
};

export default CallSettings;
