import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const smallPath =
  'M7.47024 4.21924C7.76321 3.92627 8.23899 3.92627 8.53196 4.21924L13.032 8.71924C13.3249 9.01221 13.3249 9.48799 13.032 9.78096C12.739 10.0739 12.2632 10.0739 11.9702 9.78096L7.99993 5.81064L4.02961 9.77861C3.73665 10.0716 3.26086 10.0716 2.9679 9.77861C2.67493 9.48564 2.67493 9.00986 2.9679 8.71689L7.4679 4.21689L7.47024 4.21924Z';
const standardPath =
  'M9.29377 5.69019C9.6844 5.29956 10.3188 5.29956 10.7094 5.69019L16.7094 11.6902C17.1 12.0808 17.1 12.7152 16.7094 13.1058C16.3188 13.4964 15.6844 13.4964 15.2938 13.1058L10 7.81206L4.70627 13.1027C4.31565 13.4933 3.68127 13.4933 3.29065 13.1027C2.90002 12.7121 2.90002 12.0777 3.29065 11.6871L9.29065 5.68706L9.29377 5.69019Z';
const majorPath =
  'M11.2097 7.56104C11.6449 7.12581 12.3487 7.12581 12.7793 7.56104L21.6736 16.4507C22.1088 16.8859 22.1088 17.5897 21.6736 18.0203C21.2384 18.4509 20.5346 18.4555 20.104 18.0203L12.0014 9.91772L3.89426 18.0249C3.45904 18.4601 2.75528 18.4601 2.32468 18.0249C1.89409 17.5897 1.88946 16.8859 2.32468 16.4553L11.2097 7.56104Z';

const pathConfig: IconConfig = {
  small: {
    default: { path: smallPath },
  },
  standard: {
    default: { path: standardPath },
  },
  major: {
    default: { path: majorPath },
  },
};

const ChevronUp = withDSIconMaker(pathConfig, 'chevron up icon');

export default ChevronUp;
