import { Dispatch, FunctionComponent, SetStateAction, useMemo } from 'react';
import {
  CloseButton,
  ExtraHuge,
  Button,
  Standard,
  View,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { COLORS } from '@/utils/design';
import styled from '@/core/styled';
import { IntakeStatusStates } from '@/treatmentIntake/treatmentIntakeLauncher/types';
import ActionStatus from '@/components/ActionStatus';
import TreatmentIntakeLauncherModalSteps from './TreatmentIntakeLauncherModalSteps';
import ReactFrameService from '../../../../auth/reactFrame/ReactFrameService';

const PageWrapper = styled(View)<{ isWebview: boolean }>(({ isWebview }) => {
  return {
    display: 'flex',
    justifyContent: 'space-around',
    height: isWebview ? '100vh' : '100%',
    width: '100%',
    alignItems: 'center',
  };
});

const ContentWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      display: 'flex',
      backgroundColor: COLORS.white,
      width: 550,
      height: 550,
      borderRadius: 5,
      overflow: 'hidden',
      ...(isMobile && {
        height: '100%',
        width: '100%',
        borderRadius: 0,
      }),
    };
  }
);

const FirstPageWrapper = styled(View)({
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: '100%',
  alignItems: 'center',
});

const CloseButtonWrapper = styled(View)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingTop: 6,
  paddingRight: 6,
});

const Content = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      flex: '1',
      overflow: 'visible',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      paddingLeft: isMobile ? 40 : 90,
      paddingRight: isMobile ? 40 : 90,
    };
  }
);

interface Props {
  onClosePress: () => void;
  isLoading: boolean;
  isError: boolean;
  historyPush: (url: string) => void;
  showWelcomeScreen: boolean;
  setShowWelcomeScreen: Dispatch<SetStateAction<boolean>>;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  roomID: string;
  intakeStatusState: IntakeStatusStates;
  intakeStatus: string;
  onCheckAnimationEnd: () => void;
  freezeDueAnimation: boolean;
  initStep?: number;
}

const TreatmentIntakeLauncherModal: FunctionComponent<Props> = (props) => {
  const {
    roomID,
    onClosePress,
    isLoading,
    isError,
    historyPush,
    showWelcomeScreen,
    setShowWelcomeScreen,
    step,
    setStep,
    intakeStatusState,
    intakeStatus,
    onCheckAnimationEnd,
    freezeDueAnimation,
    initStep,
  } = props;

  const isWebview = useMemo(() => ReactFrameService.instance().isInFrame(), []);
  const { colors } = useEmotionTheme();

  return (
    <PageWrapper isWebview={isWebview}>
      <ContentWrapper>
        {isError || isLoading ? (
          <ActionStatus
            isLoading={isLoading}
            isError={isError}
            errorTitle="Something Went Wrong"
            errorSubTitle="Please check your internet connection and try again."
            errorButtonText="Close"
            errorButtonAction={onClosePress}
            titleStyles={{ fontWeight: 800, fontSize: 29, marginTop: 25 }}
            subTitleStyles={{
              fontSize: 16,
              color: colors.darkGray,
              marginTop: 13,
              marginBottom: 42,
              width: 200,
              textAlign: 'center',
            }}
          />
        ) : (
          <>
            <CloseButtonWrapper>
              <CloseButton onPress={onClosePress} />
            </CloseButtonWrapper>
            <Content>
              <>
                {showWelcomeScreen ? (
                  <FirstPageWrapper>
                    <View>
                      <ExtraHuge style={{ marginBottom: 20 }}>Treatment intake</ExtraHuge>
                      <Standard variant="standardDarkGrey">
                        Like in any healthcare relationship, it’s important to have a history of
                        your mental and physical health to ensure you receive proper care. Please
                        take a few minutes to complete the following sections.
                      </Standard>
                    </View>
                    <Button onClick={() => setShowWelcomeScreen(false)}>Continue</Button>
                  </FirstPageWrapper>
                ) : (
                  <TreatmentIntakeLauncherModalSteps
                    roomID={roomID}
                    step={step}
                    setStep={setStep}
                    historyPush={historyPush}
                    onClosePress={onClosePress}
                    intakeStatusState={intakeStatusState}
                    intakeStatus={intakeStatus}
                    onCheckAnimationEnd={onCheckAnimationEnd}
                    freezeDueAnimation={freezeDueAnimation}
                    initStep={initStep}
                  />
                )}
              </>
            </Content>
          </>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default TreatmentIntakeLauncherModal;
