import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M8.21915 17.2181L9.71915 15.7181C10.0129 15.4244 10.4879 15.4244 10.7785 15.7181C11.0691 16.0119 11.0723 16.4869 10.7785 16.7775L9.28165 18.2806C8.9879 18.5744 8.5129 18.5744 8.22227 18.2806C7.93165 17.9869 7.92852 17.5119 8.22227 17.2212L8.21915 17.2181ZM12.6754 13.5806C12.4004 14.14 11.6035 14.14 11.3285 13.5806L9.88165 10.6431L6.64727 10.1712C6.03165 10.0806 5.7879 9.32748 6.23165 8.8931L8.57227 6.6056L8.01915 3.37748C7.9129 2.76498 8.55665 2.29623 9.10665 2.58685L12.0004 4.11185L14.8973 2.58685C15.4473 2.29623 16.091 2.76498 15.9848 3.37748L15.4316 6.6056L17.7754 8.8931C18.2191 9.32748 17.9723 10.0837 17.3598 10.1712L14.1254 10.6431L12.6785 13.5806H12.6754ZM2.21915 12.2806C1.9254 11.9869 1.9254 11.5119 2.21915 11.2212L3.71915 9.72123C4.0129 9.42748 4.4879 9.42748 4.77852 9.72123C5.06915 10.015 5.07227 10.49 4.77852 10.7806L3.27852 12.2806C2.98477 12.5744 2.50977 12.5744 2.21915 12.2806ZM2.21915 17.2181L6.71915 12.7181C7.0129 12.4244 7.4879 12.4244 7.77852 12.7181C8.06915 13.0119 8.07227 13.4869 7.77852 13.7775L3.28165 18.2806C2.9879 18.5744 2.5129 18.5744 2.22227 18.2806C1.93165 17.9869 1.92852 17.5119 2.22227 17.2212L2.21915 17.2181Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const StarShooting = withDSIconMaker(pathConfig, 'star shooting icon');

export default StarShooting;
