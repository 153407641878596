import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const smallPath =
  'M8 16.3965C10.1217 16.3965 12.1566 15.5536 13.6569 14.0533C15.1571 12.553 16 10.5182 16 8.39648C16 6.27475 15.1571 4.23992 13.6569 2.73963C12.1566 1.23934 10.1217 0.396484 8 0.396484C5.87827 0.396484 3.84344 1.23934 2.34315 2.73963C0.842855 4.23992 0 6.27475 0 8.39648C0 10.5182 0.842855 12.553 2.34315 14.0533C3.84344 15.5536 5.87827 16.3965 8 16.3965ZM11.5312 6.92773L7.53125 10.9277C7.2375 11.2215 6.7625 11.2215 6.47188 10.9277L4.47188 8.92773C4.17813 8.63398 4.17813 8.15898 4.47188 7.86836C4.76562 7.57773 5.24062 7.57461 5.53125 7.86836L7 9.33711L10.4688 5.86523C10.7625 5.57148 11.2375 5.57148 11.5281 5.86523C11.8187 6.15898 11.8219 6.63398 11.5281 6.92461L11.5312 6.92773Z';

const pathConfig: IconConfig = {
  small: {
    default: { path: smallPath },
  },
  standard: {
    default: { path: '' },
  },
};

const CircleCheck = withDSIconMaker(pathConfig, 'circle check icon');

export default CircleCheck;
