import { FunctionComponent } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { View, Big, Standard, useEmotionTheme } from '@talkspace/react-toolkit';
import Star from 'chat/components/Icons/Star';
import { withRouter, RouteComponentProps } from '../../core/routerLib/index';
import HoverItem from '../MenuNavLink/MenuNavLink';
import { trackCTAClick } from '../../utils/analytics/events';

const PanelRoomDetailsTools: FunctionComponent<
  RouteComponentProps<{
    roomID: number;
  }>
> = ({ history, match }) => {
  const { colors } = useEmotionTheme();
  const { memberNewNav } = useFlags();
  return (
    <View style={{ marginTop: 27 }}>
      <Big as="h2" style={{ marginBottom: 16 }}>
        Tools
      </Big>
      <HoverItem
        dataQa="panelRoomStarredMessagesButton"
        aria-label="Open starred messages"
        onPress={() => {
          if (memberNewNav && memberNewNav !== 'disabled') {
            trackCTAClick('Open starred messages', 'messages', { roomID: match.params.roomID });
          }

          history.push({
            pathname: `/room/${match.params.roomID}/starred-messages/${match.params.roomID}`,
            state: {
              from: 'room-details',
            },
          });
        }}
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <View row justify="start" align="center" style={{ paddingLeft: 11 }}>
          <Star fillColor="transparent" strokeColor={colors.green} />
          <Standard style={{ marginLeft: 16 }}>Starred messages</Standard>
        </View>
      </HoverItem>
    </View>
  );
};

export default withRouter(PanelRoomDetailsTools);
