import { FunctionComponent, useRef, useState, useEffect } from 'react';
import { View, Text, HyperLink, Button, useEmotionTheme } from '@talkspace/react-toolkit';
import { RouteComponentProps, withRouter } from '@/core/routerLib/routerLib';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import {
  ID_MESSAGE_DATE,
  ID_BOLD_MESSAGE_TEXT,
  ID_MESSAGE_TEXT,
  ID_BOTTOM_BOLD_MESSAGE_TEXT,
} from '@/utils/IDConstants';
import styled from '@/core/styled';
import { MessageProps } from '../../types';
import Media from '../Media';
import { MessageBodyTextOnly, MessageBodyHasButton } from '../../entities/EMessage';

export type BodyProps = {
  hasMargin?: boolean;
  containerWidth?: number;
  onActionPress?: () => void;
  onSecondaryActionPress?: () => void;
} & Omit<MessageProps, 'squareCorners'> &
  RouteComponentProps;

const BoldText = styled(Text)(({ theme: { colors } }) => {
  return {
    textAlign: 'center',
    maxWidth: 460,
    fontWeight: 'bold',
    fontSize: 27,
    lineHeight: '29px',
    color: colors.TSBlack,
    marginBottom: 12,
    ...webOnlyStyle({
      whiteSpace: 'pre-line',
    }),
  };
});

const Hr = styled(View)(({ theme: { colors } }) => {
  return {
    width: 50,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.green,
    margin: 10,
    marginTop: 12,
  };
});

const MessageButton = styled(Button)(({ theme: { colors }, disabled }) => {
  return {
    backgroundColor: disabled ? undefined : colors.green,
  };
});

const SecondaryMessageButton = styled(Button)(({ theme: { colors }, disabled }) => {
  return {
    backgroundColor: 'transparent',
    color: disabled ? colors.extraLightGrey : colors.green,
    fontSize: 16,
    height: 19,
    minHeight: 19,
    fontWeight: 700,
    marginBottom: 25,
  };
});

const Body: FunctionComponent<BodyProps> = (props) => {
  const { colors, isHighContrast } = useEmotionTheme();
  const {
    currentMessage,
    position,
    hasMargin,
    onActionPress,
    onSecondaryActionPress,
    containerWidth,
  } = props;
  const { text, boldText, bottomBoldText } = currentMessage.messageBody as MessageBodyTextOnly;
  const {
    buttonText,
    secondaryButtonText,
    buttonDestinationPath,
    secondaryButtonDestinationPath,
    buttonParams,
  } = currentMessage.messageBody as MessageBodyHasButton;
  const align = currentMessage.isSystem ? 'center' : 'start';
  const textColor =
    position === 'right' || (!currentMessage.isSystem && isHighContrast)
      ? colors.white
      : colors.black;
  const linkColor = position === 'left' ? colors.therapistLink : colors.clientLink;
  const tokenExpiresAt: string | undefined = buttonParams && buttonParams.tokenExpiresAt;
  const isExpiredNow = !!(tokenExpiresAt && new Date(tokenExpiresAt) < new Date());
  const [isButtonExpired, setIsButtonExpired] = useState<boolean>(isExpiredNow);
  const intervalIDRef = useRef<number>();

  useEffect(() => {
    if (!isExpiredNow && tokenExpiresAt) {
      intervalIDRef.current = window.setInterval(() => {
        const expiresAtSeconds = Math.floor(
          (new Date(tokenExpiresAt).getTime() - new Date().getTime()) / 1000
        );
        if (expiresAtSeconds < 60) {
          setIsButtonExpired(true);
          if (intervalIDRef.current) clearInterval(intervalIDRef.current);
        }
      }, 60000);
    }
    return () => {
      if (intervalIDRef.current) clearInterval(intervalIDRef.current);
    };
  }, [isExpiredNow, tokenExpiresAt]);

  const messageDateID = `${ID_MESSAGE_DATE}-${currentMessage.id}`;
  const boldTextID = `${ID_BOLD_MESSAGE_TEXT}-${currentMessage.id}`;
  const textID = `${ID_MESSAGE_TEXT}-${currentMessage.id}`;
  const bottomBoldTextID = `${ID_BOTTOM_BOLD_MESSAGE_TEXT}-${currentMessage.id}`;
  return (
    <View
      align={align}
      flex={1}
      style={{ marginTop: hasMargin ? 6 : 0, marginBottom: hasMargin ? 6 : 0 }}
    >
      {boldText && <BoldText id={boldTextID}>{boldText}</BoldText>}
      <HyperLink
        textID={textID}
        underline={isHighContrast}
        text={text}
        textColor={textColor}
        linkColor={linkColor}
        isSystem={currentMessage.isSystem}
      />
      {bottomBoldText && (
        <>
          <Hr />
          <BoldText id={bottomBoldTextID}>{bottomBoldText}</BoldText>
        </>
      )}
      {currentMessage.mediaId > 0 && currentMessage.media && (
        <Media
          messageID={currentMessage.id}
          signedMediaURL={currentMessage.media.url}
          bodyType={currentMessage.bodyType}
          thumbnailURL={currentMessage.media.thumbnail}
          color={textColor}
          containerWidth={containerWidth}
          fileName={currentMessage.media.title}
          fileSize={currentMessage.media.fileSize}
          senderName={currentMessage.user.displayName}
          createdAt={currentMessage.createdAt}
          status={currentMessage.media.status}
        />
      )}
      {buttonText && (
        <MessageButton
          aria-describedby={`${boldTextID} ${textID} ${bottomBoldText} ${messageDateID}`}
          text={buttonText}
          disabled={!buttonDestinationPath || isExpiredNow || isButtonExpired}
          onPress={onActionPress}
        />
      )}
      {secondaryButtonText && (
        <SecondaryMessageButton
          text={secondaryButtonText}
          disabled={!secondaryButtonDestinationPath}
          onPress={onSecondaryActionPress}
        />
      )}
    </View>
  );
};

export default withRouter(Body);
