import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const smallPath =
  'M10.7808 7.47036C11.0738 7.76333 11.0738 8.23911 10.7808 8.53208L6.28083 13.0321C5.98787 13.325 5.51208 13.325 5.21912 13.0321C4.92615 12.7391 4.92615 12.2633 5.21912 11.9704L9.18943 8.00005L5.22146 4.02974C4.92849 3.73677 4.92849 3.26099 5.22146 2.96802C5.51443 2.67505 5.99021 2.67505 6.28318 2.96802L10.7832 7.46802L10.7808 7.47036Z';
const standardPath =
  'M14.7063 9.69014C15.0969 10.0808 15.0969 10.7151 14.7063 11.1058L8.70627 17.1058C8.31565 17.4964 7.68127 17.4964 7.29065 17.1058C6.90002 16.7151 6.90002 16.0808 7.29065 15.6901L12.5844 10.3964L7.29377 5.10264C6.90315 4.71201 6.90315 4.07764 7.29377 3.68701C7.6844 3.29639 8.31877 3.29639 8.7094 3.68701L14.7094 9.68701L14.7063 9.69014Z';
const majorPath =
  'M17.2319 12.0027C17.6672 12.4379 17.6672 13.1417 17.2319 13.5723L8.34228 22.4666C7.90706 22.9018 7.20329 22.9018 6.7727 22.4666C6.3421 22.0313 6.33747 21.3276 6.7727 20.897L14.8753 12.7944L6.76807 4.68723C6.33284 4.25201 6.33284 3.54824 6.76807 3.11765C7.20329 2.68706 7.90706 2.68243 8.33765 3.11765L17.2319 12.0027Z';

const pathConfig: IconConfig = {
  small: {
    default: { path: smallPath },
  },
  standard: {
    default: { path: standardPath },
  },
  major: {
    default: { path: majorPath },
  },
};

const ChevronUp = withDSIconMaker(pathConfig, 'chevron up icon');

export default ChevronUp;
