import { FunctionComponent, useEffect, createContext, useContext, useCallback } from 'react';
import { isApple, isAndroid } from 'ts-frontend/utils/device';
import { ClientReactivationAPIResponse } from 'chat/redux/constants/chatTypes';
import { VIDEO_CALL_SESSION_STORAGE_KEY } from 'chat/types/videoCallTypes';
import { AppListener, OnAppUrlOpenCallback } from 'ts-ionic/plugins/capacitor';
import { OpenModal } from '@/utils/ModalsContextProvider';
import ReactFrameService from '../auth/reactFrame/ReactFrameService';
import { withRouter, RouteComponentProps } from '../core/routerLib/routerLib';
import useObjectState from './useObjectState';

const ISRAEL_PLAN_ID = 1146;

export enum DeepLinkAction {
  APPLY_COUPON = 'apply-coupon',
  OFFER = 'offer',
  AFTER_LOGIN = 'after-login',
  FRIEND_REFERRAL = 'friend-referral',
  CHECK_IN = 'check-in',
  MATCHING_INTAKE = 'matching-intake',
  CHANGE_PASSWORD = 'change-password',
  UPDATE_PAYMENT = 'update-payment',
  CHANGE_EMAIL = 'change-email',
  CHANGE_PHONE_NUMBER = 'change-phone-number',
  CHECK_REACTIVATION = 'check-reactivation',
  CURRENT_SUBSCRIPTION = 'current-subscription',
  CANCELLATION_SWITCH_EXIT = 'cancellation-switch-exit',
  PROVIDER_DEPARTING_SWITCH = 'provider-departing-switch',
  VIDEO_CALL = 'video-call',
  PERSONAL_DETAILS = 'personal-details',
  CHECK_LVS_REMINDER_MODAL = 'check-lvs-reminder-modal',
  ACCOUNT = 'account',
}

/**
 * Function that you call with the required parameters, including openModal
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type onActionWithModal<T extends object = {}> = (data: T & { openModal: OpenModal }) => void;

/**
 * Function that takes a callback function with specific data/functions
 * Use it only when you need to run some asynchronous function or something alike
 */
type FunctionWithCallback<T extends object = {}> = (callback: (data: T) => void) => void;

interface DeepLinkState {
  [DeepLinkAction.APPLY_COUPON]: {
    roomID: number;
    openModal: OpenModal;
  };
  [DeepLinkAction.OFFER]: {
    roomID: number;
    openModal: OpenModal;
  };
  [DeepLinkAction.AFTER_LOGIN]: {
    planID: number | null;
    openModal: OpenModal;
  };
  [DeepLinkAction.FRIEND_REFERRAL]: OpenModal;
  [DeepLinkAction.CHECK_IN]: {
    roomID: number;
    openModal: OpenModal;
  };
  [DeepLinkAction.MATCHING_INTAKE]: {
    roomID: number;
    openModal: OpenModal;
  };
  [DeepLinkAction.CHANGE_PASSWORD]: () => void;
  [DeepLinkAction.UPDATE_PAYMENT]: () => void;
  [DeepLinkAction.CHANGE_EMAIL]: () => void;
  [DeepLinkAction.CHANGE_PHONE_NUMBER]: () => void;
  [DeepLinkAction.CHECK_REACTIVATION]: FunctionWithCallback<{
    reactivationPayload: ClientReactivationAPIResponse;
    openModal: OpenModal;
  }>;
  [DeepLinkAction.CURRENT_SUBSCRIPTION]: (onSuccess: Function) => void;
  [DeepLinkAction.CANCELLATION_SWITCH_EXIT]: {
    roomID: string;
    openModal: OpenModal;
  };
  [DeepLinkAction.PROVIDER_DEPARTING_SWITCH]: {
    roomID: number;
    openModal: OpenModal;
  };
  [DeepLinkAction.VIDEO_CALL]: {
    roomID: number;
    openModal: OpenModal;
  };
  [DeepLinkAction.PERSONAL_DETAILS]: {
    openModal: OpenModal | null;
    isMobile: boolean;
  };
  [DeepLinkAction.CHECK_LVS_REMINDER_MODAL]: () => void;
  [DeepLinkAction.ACCOUNT]: {
    openModal: OpenModal;
  };
}

const DeepLinkContext = createContext<React.Dispatch<Partial<DeepLinkState>> | undefined>(
  undefined
);

const DeepLinkContextProviderComponent: FunctionComponent<RouteComponentProps> = (props) => {
  const [state, dispatch] = useObjectState<Partial<DeepLinkState>>({});
  const { location, history, children } = props;

  useEffect(() => {
    const cleanQuerystring = () => {
      // we clean any query strings from the path
      history.replace(location.pathname);
    };
    const triggerAction = (action: DeepLinkAction) =>
      history.replace(`${location.pathname}?action=${action}`);

    const urlParams = new URLSearchParams(location.search);
    const action = urlParams.get('action') as keyof DeepLinkState | null;
    if (!action) return;
    switch (action) {
      // Actions with a case are simple modals that this context provider can do
      // TODO: Give examples of above once openModal is accessible everywhere
      // Some actions require the component to give back some data/function
      case DeepLinkAction.APPLY_COUPON: {
        const actionData = state[action];
        const code = urlParams.get('code');
        if (!code || !actionData) break;
        // Clean query string first otherwise it replace the location.pathname back to `/rooms/`
        // Preventing the modal from showing up
        const { roomID, openModal } = actionData;
        cleanQuerystring();
        openModal(`/voucher-coupon/${code}/room/${roomID}`, undefined, false, true);
        break;
      }
      case DeepLinkAction.OFFER: {
        const actionData = state[action];
        const offerID = urlParams.get('offerID');
        const coupon = urlParams.get('coupon');
        const showPlans = urlParams.get('show_plans');
        const isNoMatches = urlParams.get('isNoMatches');

        if (!offerID || !actionData) break;
        // Clean query string first otherwise it replace the location.pathname back to `/rooms/`
        // Preventing the modal from showing up
        const { roomID, openModal } = actionData;
        const newQueryString = new URLSearchParams();

        if (coupon) newQueryString.set('coupon', coupon);
        if (showPlans) newQueryString.set('show_plans', showPlans);
        if (isNoMatches) newQueryString.set('isNoMatches', isNoMatches);
        cleanQuerystring();
        const url = `/room-offer/${roomID}/offer/${offerID}${
          newQueryString.toString() ? `?${newQueryString.toString()}` : ''
        }`;
        openModal(url);
        break;
      }
      case DeepLinkAction.FRIEND_REFERRAL: {
        const openModal = state[action];
        if (!openModal) break;
        cleanQuerystring();
        openModal('/friend-referral');
        break;
      }
      case DeepLinkAction.CHECK_IN: {
        const checkInSource = urlParams.get('check-in-source');
        const videoCallID = urlParams.get('video-call');
        const actionData = state[action];
        if (!videoCallID || !actionData) break;
        const { roomID, openModal } = actionData;
        if (!openModal) break;
        cleanQuerystring();
        openModal(
          `/check-in/room/${roomID}/source/post-lvs-web/check-in-source/${checkInSource}/video-call/${videoCallID}`
        );
        break;
      }
      case DeepLinkAction.MATCHING_INTAKE: {
        const source = urlParams.get('source');
        const actionData = state[action];
        if (!actionData) break;
        const { roomID, openModal } = actionData;
        if (!openModal) break;
        cleanQuerystring();
        openModal(`/matching-intake/room/${roomID}/source/${source}`);
        break;
      }
      case DeepLinkAction.AFTER_LOGIN: {
        const actionData = state[action];
        if (!actionData) break;
        const { planID, openModal } = actionData;
        if (!openModal) break;
        cleanQuerystring();

        if ((!isAndroid && !isApple) || planID === ISRAEL_PLAN_ID) {
          triggerAction(DeepLinkAction.CHECK_REACTIVATION);
        } else {
          openModal('/switch-app', { planID }, true, false, () =>
            triggerAction(DeepLinkAction.CHECK_REACTIVATION)
          );
        }

        break;
      }
      case DeepLinkAction.CHECK_REACTIVATION: {
        const onAction = state[action];
        if (!onAction) break;
        const newQueryString = new URLSearchParams();
        const coupon = urlParams.get('coupon');
        if (coupon) newQueryString.set('coupon', coupon);

        cleanQuerystring();
        onAction(({ reactivationPayload, openModal }) => {
          if (reactivationPayload.shouldReactivate) {
            if (reactivationPayload.reactivationURL)
              openModal(
                `${reactivationPayload.reactivationURL}${
                  newQueryString.toString() ? `?${newQueryString.toString()}` : ''
                }`
              );
            else
              openModal(
                `/room-reactivation/${reactivationPayload.sendToRoom}${
                  newQueryString.toString() ? `?${newQueryString.toString()}` : ''
                }`
              );
          }
        });
        break;
      }
      // TODO: Make this generalized
      case DeepLinkAction.CURRENT_SUBSCRIPTION: {
        const onAction = state[action];
        if (!onAction) break;
        onAction(() => {
          // Don't clear query string until this callback function
          // is called inside the component
          cleanQuerystring();
        });
        break;
      }
      case DeepLinkAction.CANCELLATION_SWITCH_EXIT: {
        const actionData = state[action];
        const source = urlParams.get('source');
        const contextID = urlParams.get('contextID');
        if (!actionData) break;
        // Clean query string first otherwise it replace the location.pathname back to `/rooms/`
        // Preventing the modal from showing up
        const { roomID, openModal } = actionData;
        cleanQuerystring();
        openModal('/switch-provider', {
          roomID,
          source,
          contextID,
        });
        break;
      }
      case DeepLinkAction.PERSONAL_DETAILS: {
        const actionData = state[action];
        if (!actionData) break;
        // Clean query string first otherwise it replace the location.pathname back to `/rooms/`
        // Preventing the modal from showing up
        const { openModal, isMobile } = actionData;
        cleanQuerystring();

        if (openModal) openModal('/edit-my-information', undefined, isMobile, true);
        break;
      }
      case DeepLinkAction.PROVIDER_DEPARTING_SWITCH: {
        const actionData = state[action];
        if (!actionData) break;
        // Clean query string first otherwise it replace the location.pathname back to `/rooms/`
        // Preventing the modal from showing up
        const { roomID, openModal } = actionData;
        cleanQuerystring();
        openModal('/switch-provider', {
          roomID,
        });
        break;
      }
      case DeepLinkAction.VIDEO_CALL: {
        // check if in iframe and use closeModal with navigateTo: videoCall
        const actionData = state[action];
        if (!actionData) break;
        const { roomID, openModal } = actionData;
        const reactFrameService: ReactFrameService = ReactFrameService.instance();

        cleanQuerystring();

        const lvsMetadata = sessionStorage.getItem(VIDEO_CALL_SESSION_STORAGE_KEY);
        const { modality, videoCallID } = lvsMetadata && JSON.parse(lvsMetadata);

        if (modality === 'chat') {
          openModal(`/chat-session-start`, { videoCallID, roomID }, false, true);
        } else if (reactFrameService.isInFrame()) {
          reactFrameService.closePopup({ navigateTo: 'videoCall' });
        } else {
          history.push(`/room/${roomID}/modal/video-call`);
        }
        break;
      }
      case DeepLinkAction.ACCOUNT: {
        const actionData = state[action];
        if (!actionData) break;

        const { accountSubpagePath } = (location.state || {}) as { accountSubpagePath?: string };

        if (!accountSubpagePath) break;
        // ensure correct routing when modal closes
        history.replace('/home');

        const { openModal } = actionData;
        // Deep linking to the Account page or its subpages in mobile
        // is tricky because they all are a modal experience.
        // openModal called twice will "stack" modal history
        // so when a user completes an action and they return to an account subpage
        // they can then back navigate to the account page
        openModal('/home/account');
        openModal(`/home/account${accountSubpagePath}`);
        break;
      }

      default: {
        // Others are not so straight-forward, thus they are fully implemented
        // in the respective components, we just call the function
        const onAction = state[action];
        if (Object.values(DeepLinkAction).includes(action) && typeof onAction === 'function') {
          cleanQuerystring();
          // @ts-ignore: If we are handling all the cases above, onAction's type will be `never` and it will complain if you attempt to call it
          onAction();
        } else {
          // eslint-disable-next-line no-console
          console.warn('useDeepLink - Unknown or unhandled action');
        }
        break;
      }
    }
  }, [location.search, location.pathname, location.state, history, state]);

  const onAppUrlOpen: OnAppUrlOpenCallback = useCallback((event) => {
    if (event.slug) {
      window.location.href = event.slug;
    }
  }, []);

  return (
    <DeepLinkContext.Provider value={dispatch}>
      <AppListener onAppUrlOpen={onAppUrlOpen} />
      {children}
    </DeepLinkContext.Provider>
  );
};

export const DeepLinkContextProvider = withRouter(DeepLinkContextProviderComponent);

type UseDeepLinkAction = <T extends keyof DeepLinkState>(
  action: T
) => (actionData: DeepLinkState[T]) => void;

// Using callback for strict typing
export const useDeepLinkAction: UseDeepLinkAction = (action) =>
  // Workaround rule of hooks preventing using hooks on callbacks unless called `useXYZ`;
  function useDeepDeeplink(actionData) {
    const setDeepLinkState = useContext(DeepLinkContext);
    if (setDeepLinkState === undefined)
      throw new Error('DeepLinkContext must be used within a ContextProvider');

    // Prevent overupdating the state
    useEffect(() => {
      setDeepLinkState({ [action]: actionData });
    }, [actionData, setDeepLinkState]);
  };
