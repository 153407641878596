import { useCallback, useEffect, useState } from 'react';
import { TouchableView, View } from '@talkspace/react-toolkit';
import {
  getIonicPasscode,
  setIonicStorage,
  PASSCODE_ATTEMPTS,
  getIonicPasscodeAttempts,
  removeIonicStorage,
} from '../../../plugins/secureStorage';
import PasscodeView from './PasscodeView';
import { PASSCODE_LENGTH, MAX_PASSCODE_ATTEMPTS } from '../constants';
import styled from '@/core/styled';
import { logout, refreshToken } from '@/auth/auth';

const StyledTouchableView = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    textAlign: 'center',
    color: colors.accessibilityGreenDark,
    fontSize: 14,
    fontWeight: 600,
    marginTop: 20,
  };
});

const EnterPasscode = ({ handleAppResumed }: { handleAppResumed: (val: boolean) => void }) => {
  const [passcode, setPasscode] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleLoginWithEmail = useCallback(() => {
    logout();
  }, []);

  const handleCorrectCode = useCallback(() => {
    setIsSuccess(true);
    setTimeout(() => {
      handleAppResumed(false);
      removeIonicStorage(PASSCODE_ATTEMPTS);
      refreshToken();
    }, 1000);
  }, [handleAppResumed]);

  const handleWrongCode = useCallback(async () => {
    const currentNumberOfAttempts = await getIonicPasscodeAttempts();
    if (currentNumberOfAttempts === MAX_PASSCODE_ATTEMPTS) {
      handleLoginWithEmail();
    } else {
      await setIonicStorage({
        key: PASSCODE_ATTEMPTS,
        value: (currentNumberOfAttempts + 1).toString(),
      });
    }
    setErrorMessage('Incorrect passcode.\nPlease try again.');
  }, [handleLoginWithEmail]);

  useEffect(() => {
    if (passcode?.length === PASSCODE_LENGTH) {
      const validatePasscode = async () => {
        const { value } = await getIonicPasscode();
        if (passcode !== value) {
          handleWrongCode();
        } else {
          handleCorrectCode();
        }
      };
      validatePasscode();
    }
  }, [passcode, handleCorrectCode, handleWrongCode]);

  const handleSetPasscode = (otpKey: string) => {
    setErrorMessage('');
    setPasscode(otpKey);
  };

  return (
    <View>
      <PasscodeView
        title="Enter passcode"
        handleChange={handleSetPasscode}
        errorMessage={errorMessage}
        shouldShowBackArrow={false}
        isSuccess={isSuccess}
      />
      <StyledTouchableView onPress={handleLoginWithEmail}>Log in with email</StyledTouchableView>
    </View>
  );
};

export default EnterPasscode;
