import SocketService from '../../utils/socket/SocketService';
import SLA from '../reducers/SLAReducer';

export default class SLASocketService {
  roomID: number;

  receiveSLADispatch: Function;

  io: SocketService;

  constructor(receiveSLADispatch: Function, roomID: number) {
    this.roomID = roomID;
    this.receiveSLADispatch = receiveSLADispatch;
    this.io = SocketService.instance();
    this.socketInit();
  }

  private receiveSlaEventListener = (e) => {
    this.receiveSlaHandler(e.detail.SLA);
  };

  private socketInit = () => {
    document.addEventListener('slaChanged', this.receiveSlaEventListener);
    this.io.on('slaChanged', this.receiveSlaHandler);
  };

  public unmount = () => {
    document.removeEventListener('slaChanged', this.receiveSlaEventListener);
    this.io.off('slaChanged', this.receiveSlaHandler);
  };

  private receiveSlaHandler = (data) => {
    const updatedSLA = new SLA(data);
    if (updatedSLA.roomID === this.roomID) this.receiveSLADispatch(updatedSLA);
  };
}
