import { FunctionComponent } from 'react';
import { BaseButton, ExtraHuge, Large, Standard } from '@talkspace/react-toolkit';

import styled from '../../../core/styled/styled';
import FlatRoundedButton from '../FlatRoundedButton';

const PaddedText = styled(Large)({
  marginTop: 10,
  marginBottom: 50,
});

const FlatButton = styled(BaseButton)({
  marginTop: 20,
  marginBottom: 40,
  backgroundColor: '#fff',
});

interface Props {
  therapistFirstName: string;
  onCouponPress: () => void;
  onCancelPress: () => void;
  header: string;
}

const Missed3Weeks: FunctionComponent<Props> = (props: Props) => {
  const { onCouponPress, onCancelPress, therapistFirstName, header } = props;
  return (
    <>
      <ExtraHuge as="h1" style={{ textAlign: 'center' }}>
        {header}
      </ExtraHuge>
      <PaddedText variant="largeDarkGrey">
        {therapistFirstName} missed their expected response time for the third time this week. We
        sincerely apologize for the inconvenience. As a loyal client, we’d like to make it right.
      </PaddedText>
      <FlatRoundedButton onPress={onCouponPress} style={{ height: 64 }}>
        <Large variant="largeBoldWideGreen">
          Credit my account for one complimentary week of therapy
        </Large>
      </FlatRoundedButton>
      <FlatButton onPress={onCancelPress}>
        <Standard variant="standardDarkGrey">I’ll wait just a little longer</Standard>
      </FlatButton>
    </>
  );
};

export default Missed3Weeks;
