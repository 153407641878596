import { FunctionComponent } from 'react';
import { BaseButton, ExtraHuge, Large } from '@talkspace/react-toolkit';

import styled from '../../../core/styled/styled';
import Hourglass from '../../assets/Hourglass';

const PaddedText = styled(Large)({
  marginTop: 10,
  marginBottom: 40,
});

const FlatButton = styled(BaseButton)({
  marginTop: 50,
  marginBottom: 40,
  backgroundColor: '#fff',
});

interface Props {
  therapistFirstName?: string;
  onClosePress: () => void;
  header: string;
}

const Patience: FunctionComponent<Props> = (props: Props) => {
  const { therapistFirstName, onClosePress, header } = props;

  return (
    <>
      <Hourglass style={{ marginBottom: 30 }} />
      <ExtraHuge as="h1">{header}</ExtraHuge>
      {therapistFirstName && (
        <PaddedText variant="largeDarkGrey">We've notified {therapistFirstName}</PaddedText>
      )}
      <FlatButton onPress={onClosePress}>
        <Large variant="largeBoldWideGreen">Close</Large>
      </FlatButton>
    </>
  );
};

export default Patience;
