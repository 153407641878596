import { useMemo, UIEventHandler, useState, useEffect } from 'react';
import {
  View,
  useEmotionTheme,
  TouchableView,
  SpacingView,
  TextDS,
  Divider,
  spacing,
  ScrollView,
  TSLogo,
} from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  NAV_SHELL_HEADER_HEIGHT,
  NAV_SHELL_MOBILE_FOOTER_HEIGHT,
  NAV_SHELL_MOBILE_HEADER_HEIGHT,
} from 'ts-frontend/constants';
import { useHistory, useLocation } from '@/core/routerLib';
import styled from '@/core/styled';
import NavItem from './NavItem';
import HeaderItem from './HeaderItem';
import TopLeftCornerCurveSvg from './TopLeftCornerCurveSvg';
import SwoopBackground from './SwoopBackground';
import useNavShellData from '../../hooks/useNavShellData';
import useNavShellEffects from '../../hooks/useNavShellEffects';
import usePopupEligibilityWarning from '../../hooks/usePopupEligibilityWarning';
import { trackEvent } from '@/utils/analytics/eventTracker';

const { space050, space100, space150, space200 } = spacing;

const NAV_SHELL_VISIBLE_PATHS = ['home', 'room', 'rooms', 'clinical-progress-rooms'];
const NAV_SHELL_EXCLUDE_PATHS = ['onboarding', 'session-details'];

const AUTH_ROUTES = [
  '/login',
  '/login/sso',
  '/login/sso/callback',
  '/login/sso/callback',
  '/login/sso/register',
  '/login/sso/close',
  '/signup',
  '/forgot-password',
  '/reset-password',
  '/change-password',
  '/oauth',
];

export const Styled = {
  NavShell: styled(View)<{ isInMobileVideoCall?: boolean }>(
    ({
      theme: {
        colorRoles,
        window: { isMobile, height },
      },
      isInMobileVideoCall,
    }) => {
      const mobileScrollAdjustedHeight = isInMobileVideoCall
        ? height
        : height - NAV_SHELL_MOBILE_FOOTER_HEIGHT;

      const defaultStyle = {
        width: '100vw',
        height: '100vh',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: colorRoles.surfaces.neutralDefault,
      };
      const mobileStyle = {
        ...defaultStyle,
        backgroundColor: colorRoles.surfaces.defaultSubtleDefault,
        maxHeight: mobileScrollAdjustedHeight,
      };
      return isMobile ? mobileStyle : defaultStyle;
    }
  ),
  Header: styled(View)<{ isHomePage?: boolean }>(
    ({
      isHomePage,
      theme: {
        window: { isMobile },
        colorRoles,
      },
    }) => {
      if (isMobile)
        return {
          height: NAV_SHELL_MOBILE_HEADER_HEIGHT,
          width: '100%',
          padding: `${space100}px ${space200}px ${space100}px ${space200}px`,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
          overflow: 'hidden',
          zIndex: 2,
          backgroundColor: isHomePage ? undefined : colorRoles.surfaces.defaultSubtleDefault,
        };
      return {
        height: NAV_SHELL_HEADER_HEIGHT,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      };
    }
  ),
  NavPanel: styled(View)(
    ({
      theme: {
        window: { isTablet, isMobile },
      },
    }) => {
      if (isMobile) {
        return {
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: `${space100}px ${space200}px ${space050 + 34}px ${space200}px`,
          position: 'fixed',
          bottom: 0,
          backgroundColor: 'white',
        };
      }
      if (isTablet)
        return {
          top: 80,
          left: 10,
          width: 60,
          position: 'absolute',
        };

      return {
        width: 160,
        top: 86,
        left: 16,
        position: 'absolute',
      };
    }
  ),
  Body: styled(View)({
    flex: 1,
    overflow: 'hidden',
    flexDirection: 'row',
  }),
  ContentTopLeftCurve: styled(View)({
    width: 12,
    height: 12,
    position: 'absolute',
    zIndex: 10,
  }),
  Content: styled(View)(
    ({
      theme: {
        colorRoles,
        window: { isTablet, isMobile },
      },
    }) => {
      if (isMobile)
        return {
          flex: 1,
          height: '100%',
          width: '100%',
        };
      return {
        flex: 1,
        height: '100%',
        marginLeft: isTablet ? 80 : 192,
        borderTopLeftRadius: 12,
        backgroundColor: colorRoles.surfaces.defaultSubtleDefault,
        transition: isTablet ? 'margin-left 0.3s ease-out' : 'margin-left 0.1s ease-in',
      };
    }
  ),
  Logo: styled(View)<{ isTablet: boolean }>(({ isTablet }) => {
    return {
      top: 0,
      left: 16,
      position: 'absolute',
    };
  }),
  AccountSection: styled(View)({
    flexDirection: 'row',
    justifyContent: 'space-between',
    top: 10,
    right: 16,
    position: 'absolute',
  }),
  BottomNavBar: styled(View)<{ isNewNav: boolean }>(({ isNewNav }) => {
    return {
      width: '100%',
      gap: 4,
      position: isNewNav ? 'relative' : undefined,
    };
  }),
  NavigationButtonText: ({ children }) => (
    <TextDS colorRole="textBrandDefault" variant="headingSm">
      {children}
    </TextDS>
  ),
  NavHeaderButton: styled(TouchableView)({
    gap: 8,
    padding: `${space100}px ${space200}px ${space100}px ${space150}px`,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
};

type LayoutComponentProps = {
  header: React.ReactElement;
  tabs: Array<React.ReactElement>;
  logo: React.ReactElement;
  children: React.ReactNode;
  isHomePage?: boolean;
};

const LayoutComponent = ({ children, header, tabs, logo, isHomePage }: LayoutComponentProps) => {
  const {
    window: { isTablet, isMobile },
    colorRoles,
  } = useEmotionTheme();
  const location = useLocation();

  const [scrollPos, setScrollPos] = useState(0);

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    setScrollPos(e.currentTarget.scrollTop);
  };
  const useNewNav = useNewMemberNav();
  const isInMobileVideoCall = isMobile && location.pathname.includes('/modal/video-call');
  const mobileHeader = isInMobileVideoCall ? (
    <></>
  ) : (
    <Styled.Header isHomePage={isHomePage}>{header}</Styled.Header>
  );

  return (
    <Styled.NavShell isInMobileVideoCall={isInMobileVideoCall}>
      {isMobile && isHomePage && (
        <SwoopBackground isHeader style={{ position: 'absolute', left: 0, top: -scrollPos }} />
      )}
      {isMobile ? mobileHeader : <Styled.Header />}
      <Styled.Body>
        <Styled.Content>
          {!isMobile && (
            <>
              {/* Defined in tab order */}
              <Styled.Logo isTablet={isTablet}>{logo}</Styled.Logo>
              <Styled.NavPanel>
                <SpacingView itemSpacing={isTablet ? 'space200' : 'space050'}>{tabs}</SpacingView>
              </Styled.NavPanel>
              <Styled.AccountSection>{header}</Styled.AccountSection>
              <Styled.ContentTopLeftCurve>
                <TopLeftCornerCurveSvg color={colorRoles.surfaces.neutralDefault} />
              </Styled.ContentTopLeftCurve>
            </>
          )}
          <ScrollView
            onScroll={isMobile ? handleScroll : undefined}
            style={
              isMobile && !isInMobileVideoCall ? { maxHeight: 'calc(100vh - 140px)' } : undefined
            }
          >
            {children}
          </ScrollView>
        </Styled.Content>
      </Styled.Body>
      {isMobile && !isInMobileVideoCall && (
        <Styled.BottomNavBar isNewNav={useNewNav}>
          <Divider />
          <Styled.NavPanel>{tabs}</Styled.NavPanel>
        </Styled.BottomNavBar>
      )}
    </Styled.NavShell>
  );
};

const ClientNavShell: React.FC = ({ children }) => {
  const { colorRoles } = useEmotionTheme();
  const history = useHistory();
  const {
    aggregatedRoomData: { dataByRoomID },
  } = useNavShellData();
  useNavShellEffects();
  const onLogoPress = () => {
    if (['/home', '/'].includes[history.location.pathname]) return;
    history.push('/home');
  };
  const location = useLocation();
  const isHomePage = location.pathname === '/home';
  const useNewNav = useNewMemberNav();

  const anyHasUnreadMessage = useMemo(
    () => dataByRoomID && Object.values(dataByRoomID).some((roomData) => roomData.hasUnreadMessage),
    [dataByRoomID]
  );

  const anyHasSurveyDue = useMemo(
    () =>
      dataByRoomID &&
      Object.values(dataByRoomID).some((roomData) =>
        roomData.upcomingSurveys?.some((survey) => survey.isDue)
      ),
    [dataByRoomID]
  );

  usePopupEligibilityWarning();

  if (!useNewNav) {
    return <>{children}</>;
  }

  return (
    <LayoutComponent
      isHomePage={isHomePage}
      logo={
        <TouchableView onPress={onLogoPress}>
          <TSLogo variant="2024" color={colorRoles.icons.iconBrandDefault} />
        </TouchableView>
      }
      header={
        <>
          <HeaderItem
            headerType="help"
            route="/home/help"
            modalRoute="/home/help"
            dataQa="navShellHeaderItemHelp"
            analyticsName="Help"
          />
          <HeaderItem
            headerType="account"
            route="/home/account"
            modalRoute="/home/account"
            dataQa="navShellHeaderItemAccount"
            analyticsName="Account"
          />
        </>
      }
      tabs={[
        <NavItem
          key="home"
          tabName="home"
          route="/home"
          dataQa="navShellItemHome"
          analyticsName="Home"
        />,
        <NavItem
          key="schedule"
          tabName="schedule"
          route="/home/schedule"
          dataQa="navShellNavItemSchedule"
          analyticsName="Schedule"
        />,
        <NavItem
          key="messages"
          tabName="messages"
          route="/rooms"
          isSelectedRegex={/^\/room\/\d+(\/.*)?$/}
          isSelectedExceptions={['clinical-progress']}
          dataQa="navShellNavItemMessages"
          hasAlert={!!anyHasUnreadMessage}
          analyticsName="Messages"
        />,
        <NavItem
          key="journey"
          tabName="journey"
          route="/home/journey"
          isSelectedRegex={/^\/room\/\d+\/clinical-progress(\/.*)?$/}
          dataQa="navShellNavItemJourney"
          hasAlert={!!anyHasSurveyDue}
          analyticsName="Journey"
        />,
      ]}
    >
      {children}
    </LayoutComponent>
  );
};

const ClientNavShellAuthWrapper = ({ children }) => {
  const location = useLocation();
  const useNewNav = useNewMemberNav();
  const { memberNewNav } = useFlags();

  useEffect(() => {
    if (memberNewNav && memberNewNav !== 'disabled') {
      trackEvent('TS Experiment Session', {
        experimentName: 'member-site-nav',
        variantName: memberNewNav,
      });
    }
  }, [memberNewNav]);

  if (!useNewNav) {
    return <>{children}</>;
  }

  if (AUTH_ROUTES.some((authRoute) => location.pathname.startsWith(authRoute))) {
    return <>{children}</>;
  }

  // This whitelist should be moved to ClientNavShell so the hooks can persist between route changes
  // but for safety for first deploy I'm keeping it in this wrapper
  //
  // use whitelist of base paths to allow nav shell to show

  const basePath = location.pathname.split('/')[1];
  const basePathAllowed = NAV_SHELL_VISIBLE_PATHS.some((path) => basePath === path);
  const pathExcluded = NAV_SHELL_EXCLUDE_PATHS.some((path) => location.pathname.includes(path));

  if (!basePathAllowed || pathExcluded) {
    return <>{children}</>;
  }

  return <ClientNavShell>{children}</ClientNavShell>;
};

export default ClientNavShellAuthWrapper;
