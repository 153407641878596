import { TouchableView, withHover, WithHoverProps } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { COLORS } from '../../utils/design';

const MenuNavLink = styled(TouchableView)<WithHoverProps>(
  ({
    theme: {
      window: { isMobile },
    },
    isHovering,
  }) => {
    return {
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: isMobile ? 48 : 40,
      borderRadius: 5,
      marginLeft: 10,
      marginRight: 10,
      backgroundColor: isHovering ? COLORS.a11yLinkWaterGrey : 'transparent',
      ...webOnlyStyle({
        display: 'flex',
      }),
    };
  }
);

export default withHover(MenuNavLink);
