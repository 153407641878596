import { App, AppInfo, URLOpenListenerEvent } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { getDeviceSubType, getIsIonic, safeIonicWrapper } from '../../ionicUtils';
import { IonicInfo } from './types';
import { useIonicEffect } from '../../hooks';
import { deepLinkLogger } from '../../loggers';

export type { IonicInfo, AppInfo, URLOpenListenerEvent };

export type OnAppUrlOpenCallback = (
  event: URLOpenListenerEvent & { slug: string | null; deepLinkType: DeepLinkType }
) => void;

export const DEFAULT_LISTENER_RETURN: Promise<PluginListenerHandle> & PluginListenerHandle =
  Object.assign(Promise.resolve({ remove: () => Promise.resolve() }), {
    remove: () => Promise.resolve(),
  });

export const convertFileSrc = safeIonicWrapper<typeof Capacitor.convertFileSrc>((filePath) => {
  const src = Capacitor.convertFileSrc(filePath);
  // This string manipulation should only be necessary when doing hot-reloading, local-proxy, and perhaps also in live updates.
  if (!src.includes(window.location.origin)) {
    const url = new URL(src);
    const { origin } = url;
    return src.replace(origin, window.location.origin);
  }
  return src;
}, '');

export const addIonicAppListener = safeIonicWrapper(App.addListener, DEFAULT_LISTENER_RETURN);

export const getAppInfo = safeIonicWrapper(App.getInfo, Promise.resolve({}) as any); // No default for web

export const getDeviceInfo = safeIonicWrapper(Device.getInfo, Promise.resolve({}) as any); // No default for web

const getIonicInfoNative = async (): Promise<IonicInfo | null> => {
  if (!getIsIonic()) return null;
  const { name: appName, ...appInfo } = await App.getInfo();
  const { name: deviceName, ...deviceInfo } = await Device.getInfo();

  return {
    appName,
    deviceName,
    ...appInfo,
    ...deviceInfo,
    deviceSubType: getDeviceSubType(deviceInfo.model),
  };
};

export const getIonicInfo = safeIonicWrapper(getIonicInfoNative, Promise.resolve(null));

export const getIonicPlatform = safeIonicWrapper(Capacitor.getPlatform, 'web');

type DeepLinkType = 'dl-to' | 'link-param' | 'raw';

const getDeepLinkType = (url: string): DeepLinkType => {
  if (url.includes('/dl/?to=')) return 'dl-to';
  if (url.includes('link=')) return 'link-param';
  return 'raw';
};

export const AppListener = ({ onAppUrlOpen }: { onAppUrlOpen: OnAppUrlOpenCallback }) => {
  useIonicEffect(() => {
    const listener = App.addListener('appUrlOpen', (event) => {
      deepLinkLogger.log('App URL Open', event);
      // Get the pathname from the URL for the following cases:
      // There will be URLs with the `link` query param. Pull the pathname of that URL inside the `link` query parameter
      //    https://talkspacedev.page.link/?link=https://app.dev.talkspace.com/home&apn=com.talkspace.talkspaceapp.debug&isi=661829386&ibi=com.talktala.talktala&cid=9049012986190636011&_osl=https://talkspacedev.page.link/DfSf&_fpb=CNQGEIkDGgVlbi1VUw==&_cpt=cpit&_iumenbl=1&_iumchkactval=1&_plt=520&_uit=2483&_cpb=1
      // The dl/?to= URL will have the slug in the `to` query parameter
      //    https://origin/dl/?to=/refund-purchase/source/email
      // The other type of URLs will include the slug in the URL itself
      //    https://origin/home
      const url = new URL(event.url);
      let slug: string | null = null;
      const deepLinkType = getDeepLinkType(url.href);

      // Get slug from URL based on the conditions above. verifiedUrl will be the `href` of the URL without the origin (pathname + query + hash)
      try {
        switch (deepLinkType) {
          case 'raw':
          case 'dl-to':
            [, slug] = url.href.split(url.origin);
            break;
          case 'link-param': {
            const tmpUrl = new URL(decodeURIComponent(url.searchParams.get('link') || ''));
            slug = tmpUrl.pathname + tmpUrl.search + tmpUrl.hash;
            break;
          }
          default:
            throw new Error(`Invalid deep link type ${deepLinkType}`);
        }
      } catch (error) {
        deepLinkLogger.error('Error parsing deep link', {
          error,
          url: url.href,
          deepLinkType,
          event,
        });
      }

      deepLinkLogger.log('Resulting deep link', {
        slug,
        deepLinkType,
      });

      onAppUrlOpen({ ...event, slug, deepLinkType });
    });
    return () => {
      listener.remove();
    };
  }, [onAppUrlOpen]);
  return null;
};
