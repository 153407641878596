import { History } from 'history';
import { routePromise } from 'ts-frontend/helpers';
import { isAndroid, isiOS } from '../../auth/reactFrame/helpers';

export type Platforms = 'web' | 'iOS' | 'Android';

const getPlatform = (): Platforms => {
  if (isAndroid()) return 'Android';
  if (isiOS()) return 'iOS';
  return 'web';
};

const isCommercialRoute = (url?: string) => {
  const location = url ? new URL(url, window.location.href) : window.location;

  const { pathname, search, hash } = location;
  const commercialPath = [
    '/cancel-non-subscription',
    '/cancel-subscription',
    '/refund-purchase',
    '/change-password',
    '/eligibility-widget',
    '/eligibility-warning',
    '/what-to-expect',
    '/add-new-service',
    '/forgot-password',
    '/in-room-scheduling',
    '/insurance-eligibility',
    '/login',
    '/meet-your-provider',
    '/oauth',
    '/room-offer',
    '/room-reactivation',
    '/superbills',
    '/signup',
    '/update-coverage',
    '/change-provider',
    '/payment-wizard',
  ];

  const urlSearchParams = new URLSearchParams(search);
  const hasSSO = !!urlSearchParams.get('sso');

  const urlHashParams = new URLSearchParams(hash.replace('#', '?'));
  const hasTLT = !!urlHashParams.get('tlt');
  const hasAnalytics = !!urlHashParams.get('analytics');

  if (hasAnalytics) return true;

  if (hasSSO || hasTLT) return false;

  return pathname === '/' || commercialPath.some((path) => pathname.startsWith(path));
};

const safeRouteNavigateOrPush = (
  history: History,
  url: string,
  newTab: boolean = false
): Promise<void> => {
  if (newTab) {
    const win = window.open(url, '_blank');
    win && win.focus();
    return Promise.resolve();
  }
  if (isCommercialRoute() === isCommercialRoute(url)) {
    // no need to change mode
    history.push(url);
    return Promise.resolve();
  }
  return routePromise(url);
};

export { isCommercialRoute, safeRouteNavigateOrPush, getPlatform };
