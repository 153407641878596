import { Capacitor } from '@capacitor/core';
import type { AgentConfiguration } from '@newrelic/newrelic-capacitor-plugin';
import { NativeFeaturesEnum, shouldShowNativeFeature } from '../../ionicUtils';

// eslint-disable-next-line import/prefer-default-export
export const newRelicInit = async (
  {
    androidToken,
    iosToken,
    collectorAddress,
  }: {
    androidToken: string;
    iosToken: string;
    collectorAddress: string;
  },
  webSDKInit: Function
) => {
  if (!shouldShowNativeFeature(NativeFeaturesEnum.NEW_RELIC)) {
    webSDKInit();
    return;
  }
  let appToken: string | undefined;
  if (Capacitor.getPlatform() === 'ios') {
    appToken = iosToken;
  } else if (Capacitor.getPlatform() === 'android') {
    appToken = androidToken;
  }
  const originalFetch = window.fetch;
  const { NewRelicCapacitorPlugin, NREnums } = await import('@newrelic/newrelic-capacitor-plugin');
  // TODO: New Relic's import overrides `fetch`. It adds headers that our back-ends don't support and result in a CORS issue.
  // Temporarily disabling New Relic's fetch override until we can find a solution.
  window.fetch = originalFetch;

  const agentConfig: AgentConfiguration = {
    // Android Specific
    // Optional:Enable or disable collection of event data.
    analyticsEventEnabled: true,
    // Optional:Enable or disable crash reporting.
    crashReportingEnabled: true,
    // Optional:Enable or disable interaction tracing. Trace instrumentation still occurs, but no traces are harvested. This will disable default and custom interactions.
    interactionTracingEnabled: true,
    // Optional:Enable or disable reporting successful HTTP requests to the MobileRequest event type.
    networkRequestEnabled: true,
    // Optional:Enable or disable reporting network and HTTP request errors to the MobileRequestError event type.
    networkErrorRequestEnabled: true,
    // Optional:Enable or disable capture of HTTP response bodies for HTTP error traces, and MobileRequestError events.
    httpResponseBodyCaptureEnabled: true,
    // Optional:Enable or disable agent logging.
    loggingEnabled: true,
    // Optional:Specifies the log level. Omit this field for the default log level.
    // Options include: ERROR (least verbose), WARNING, INFO, VERBOSE, AUDIT (most verbose).
    logLevel: NREnums.LogLevel.INFO,
    // iOS Specific
    // Optional:Enable/Disable automatic instrumentation of WebViews
    webViewInstrumentation: true,
    // Optional:Set a specific collector address for sending data. Omit this field for default address.
    // collectorAddress: "",
    // Optional:Set a specific crash collector address for sending crashes. Omit this field for default address.
    // crashCollectorAddress: "",
    // Optional:Enable or disable sending JS console logs to New Relic.
    sendConsoleEvents: true,
    // Optional:Enable or disable reporting data using different endpoints for US government clients
    // fedRampEnabled: false,
    // Optional: Enable or disable offline data storage when no internet connection is available.
    offlineStorageEnabled: true,
    collectorAddress,
    crashCollectorAddress: collectorAddress,
    // iOS Specific
    // Optional: Enable or disable Background Reporting.
    // backgroundReportingEnabled: true, // This is only available in version 1.4.1 and above.

    // iOS Specific
    // Optional: Enable or disable to use our new, more stable event system for iOS agent.
    // newEventSystemEnabled: true, // This is only available in version 1.4.1 and above.
  };

  if (appToken)
    NewRelicCapacitorPlugin.start({ appKey: appToken, agentConfiguration: agentConfig });
};
